import React, { useState, useEffect } from 'react';
import { Tabs, ListItemAvatar, Tab, Box, Typography, Grid, Button, List, ListItem, ListItemText, Collapse, CardContent, Card, Divider, TextField, Avatar, Select, MenuItem } from '@mui/material';
import { Edit, Email, Phone, AdminPanelSettings } from "@mui/icons-material";
import CustomModal from '../common/CustomModal';
import { genericGet } from '../../services/apiServices'
import { GET_ROLE, GET_PROFILE, GET_ORG_USERS } from '../../services/apiRoutes'
import CreateUserManagement from './CreateMasters/CreateUserManagement';
const App = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [openMenu, setOpenMenu] = useState(null);
  const [profile, setProfile] = useState([]);
  const [companyRoles, setCompanyRoles] = useState([])
  const [companyUsers, setCompanyUsers] = useState([])
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };


  const [selectedUser, setSelectedUser] = useState();
  const [open, setOpen] = useState(false);

  const handleUserClick = (user) => {
    console.log(user);
    setSelectedUser(user);
  };



  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getRoles = async () => {
    // setLoading(true);
    try {
      const response = await genericGet({ url: GET_ROLE });
      if (response.status == 200) {
        let reponseData = response?.data?.companyRoles.map((v) => ({
          id: v?.roleId,
          title: v?.roleName,
          name: v?.roleName,
          roleDescription: v?.roleDescription,
          parentRole: v?.parentRole?.roleName
        }))
        setCompanyRoles(reponseData)
      }
    } catch (error) {

    } finally {
    }
  };
  const getOrgUsers = async () => {
    // setLoading(true);
    try {
      const response = await genericGet({ url: GET_ORG_USERS });
      if (response.status == 200) {
        let reponseData = response?.data?.companyUsers.map((v) => ({
          firstName: v?.user?.firstName,
          lastName: v?.user?.lastName,
          email: v?.user?.email,
          mobile: v?.user?.mobile,
          profileName: v?.profile?.profileName,
          role: v?.role?.roleName,
          companyUserId: v?.companyUserId,
          name: `${v?.user?.firstName} ${v?.user?.lastName}`
        }))
        setCompanyUsers(reponseData)
      }
    } catch (error) {

    } finally {
    }
  };
  console.log(companyUsers)
  const getProfiles = async () => {
    try {
      const response = await genericGet({ url: GET_PROFILE });
      if (response.status == 200) {
        let reponseData = response.data.companyProfiles.map((v) => ({
          id: v?.profileId,
          title: v?.profileName,
          name: v?.profileName,
          profileDescription: v?.profileDescription,
        }))
        setProfile(reponseData);
      }
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getRoles()
    getProfiles()
    getOrgUsers()
  }, [])

  return (
    <Box sx={{ display: 'flex', height: '100%', background: '#ddd', overflowY: 'none' }}>
      {/* Sidebar
      <Drawer
        variant="permanent"
        sx={{ width: 240, flexShrink: 0, marginTop:'4rem', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '0px 0px 0px 0px', [`& .MuiDrawer-paper`]: { width: 240, marginTop:'4rem',boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '0px 8px 8px 0px',background:'#eee',height:'88vh' } }}
      >
        <List>
          <ListItem button onClick={() => handleMenuClick('general')}>
            <ListItemText primary="General" />
            {openMenu === 'general' ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openMenu === 'general'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button sx={{ pl: 4 }}>
                <ListItemText primary="Personal Settings" />
              </ListItem>
              <ListItem button sx={{ pl: 4 }}>
                <ListItemText primary="Users" />
              </ListItem>
              <ListItem button sx={{ pl: 4 }}>
                <ListItemText primary="Company Settings" />
              </ListItem>
            </List>
          </Collapse>

          <ListItem button onClick={() => handleMenuClick('security')}> 
            <ListItemText primary="Security Control" />
            {openMenu === 'security' ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openMenu === 'security'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button sx={{ pl: 4 }}>
                <ListItemText primary="Roles" />
              </ListItem>
              <ListItem button sx={{ pl: 4 }}>
                <ListItemText primary="Permissions" />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer> */}

      {/* Main Content */}
      <Box sx={{ flex: 1, p: 3, backgroundColor: '#fff', height: '88vh' }}>
        {/* Header */}
        {/* <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Users</Typography>
          <Button variant="contained" color="primary">
            + New User
          </Button>
        </Grid>

        <Divider sx={{ my: 2 }} /> */}

        {/* Tabs */}
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label="Users" />
          {/* <Tab label="Active Users" /> */}
        </Tabs>

        {/* Tab Content */}
        {activeTab === 0 && (
          <Grid container spacing={2}>
            {/* Left Panel */}
            <Grid item xs={5}>
              {/* <Card>
                <CardContent sx={{ display: "flex", alignItems: "center" }}> */}
              <Box p={2}>
                <Box display="flex" justifyContent="flex-end" mb={2}>
                  <Button variant="contained" onClick={handleOpen}>New User</Button>

                  {/* <Button variant="contained" color="error">Deactivate</Button> */}
                </Box>
                <List>
                  {companyUsers.map((user) => (
                    <ListItem
                      key={user.companyUserId}
                      button
                      onClick={() => handleUserClick(user)}
                      sx={{ borderRadius: '4px', mb: 1 }}
                    >
                      <ListItemAvatar>
                        <Avatar>{user.name.charAt(0)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Box display="flex" alignItems="center">
                            <Typography component={'h4'} variant="subtitle1">
                              {user.name}
                            </Typography>
                            <Typography
                              variant="caption"
                              bgcolor={user.role === 'Super Admin' ? 'orange' : 'gray'}
                              color="#fff"
                              px={1}
                              ml={1}
                              component={'h6'}
                              borderRadius="4px"
                            >
                              {user.role}
                            </Typography>
                          </Box>
                        }

                        secondary={user.email}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
              {/* </CardContent>
              </Card> */}
            </Grid>
            <Grid item xs={1}>
              <Divider orientation="vertical" />
            </Grid>
            {/* Right Panel */}
            <Grid item xs={6}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        First Name
                      </Typography>
                      <TextField fullWidth size="small" Value={selectedUser?.name} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        Date Format
                      </Typography>
                      <Select fullWidth size="small" defaultValue="DD/MM/YYYY">
                        <MenuItem value="DD/MM/YYYY">DD/MM/YYYY</MenuItem>
                        <MenuItem value="MM/DD/YYYY">MM/DD/YYYY</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        Time Zone
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        defaultValue="(GMT 5:30) India Standard Time (Asia/Kolkata)"
                      />
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="body2" color="textSecondary">
                    Contact Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <Email fontSize="small" sx={{ mr: 1 }} />
                        <Typography variant="body2">          {selectedUser?.email}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center">
                        <Phone fontSize="small" sx={{ mr: 1 }} />
                        <Typography variant="body2">{selectedUser?.mobile}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}

        {activeTab === 1 && (
          <Box>
            <Typography variant="h6">Active Users</Typography>
            <Typography variant="body2" color="textSecondary">
              List of active users will appear here.
            </Typography>
          </Box>
        )}
      </Box>
      <CustomModal
        open={open}
        handleClose={handleClose}
        modalStyles={{
          maxHeight: '65vh',
          top: "10%",
        }}
      >
        <Box sx={{ p: 2 }}>
          <CreateUserManagement companyRoles={companyRoles} profile={profile} handleClose={handleClose} />
        </Box>
      </CustomModal>
    </Box>
  );
};

export default App;

const users = [
  {
    id: 1,
    name: 'Omkar Randive',
    email: 'om.randive@outlook.com',
    role: 'Super Admin',
    position: 'CEO',
    company: 'testCompany',
    locale: {
      language: 'English (United States)',
      country: 'United States',
      dateFormat: 'DD/MM/YYYY',
      timeFormat: '12 Hours',
      timeZone: 'GMT 5:30 India Standard Time (Asia/Kolkata)',
    },
  },
  {
    id: 2,
    name: 'Test User',
    email: 'omkar@gmail.com',
    role: 'Viewer',
    position: 'Manager',
    company: '',
    locale: {
      language: 'English (United States)',
      country: 'United States',
      dateFormat: 'DD/MM/YYYY',
      timeFormat: '12 Hours',
      timeZone: 'GMT 5:30 India Standard Time (Asia/Kolkata)',
    },
  },
];