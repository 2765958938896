import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { ExportMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from '../../../components/common/CustomModal';
import CreateExpCommercialInv from '../CreateForm/CreateExpCommercialInv';
import UpdateExpCommercialInv from '../UpdateForm/UpdateExpCommercialInv';
import MessageBox from '../../common/CustomMessageBox';
import CustomDelete from '../../common/CustomDeleteAlert';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import { LIST_COMMERCIAL_INVOICES_EXPORT, GET_PRODUCTS_LIST, GET_SUPPLIERS_LIST, DELETE_COMMERCIAL_INVOICE_EXPORT } from '../../../services/apiRoutes';
import { genericGet } from '../../../services/apiServices';
import { genericPost } from '../../../services/authServices';
import axios from 'axios';
import CommercialInvoiceView from '../ViewDetails/CommercialInvoiceView';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';
import { getCurrencyList } from '../../../services/CommonServices';

const CommercialInvoice = () => {
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Commercial Invoice');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState({ open: false, text: '', type: '' });
    const [commercialInvExpId, setCommercialInvExpId] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [products, setProducts] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const [newShippingId, setNewShippingId] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [rowId, setRowId] = useState('')



    const navigate = useNavigate();
    const { exportShipmentId } = useParams();
    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);

    let customerId, cifFob;
    const hasFetched = useRef(false);
    useEffect(() => {
        if (shipmentDetails) {
            customerId = shipmentDetails.customerId;
            cifFob = shipmentDetails.cifFob.cifFobName;
            getCommonData();
            fetchData();
            fetchProductsList();
            getCustomersList();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateModal, commercialInvExpId, token, modal]);


    const fetchProductsList = async () => {
        const authToken = sessionStorage.getItem('accessToken');
        const payload = {
            clientCompanyId: customerId,
        };

        try {
            const response = await axios.post(GET_PRODUCTS_LIST, payload, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200 && response.data.success) {
                const productData = response.data.productsList;
                console.log(productData);
                setProducts(productData);
            } else {
                console.error('Failed to fetch products list:', response.data);
            }
        } catch (error) {
            console.error('Error fetching products list:', error);
        }
    };


    const getCustomersList = async () => {
        try {
            const payload = {
                clientCompanyId: customerId,
            };
            const response = await genericPost(GET_SUPPLIERS_LIST, payload, {
            });
            if (response.data && response.data.success && response.data.clientCompanies) {
                const customersData = response.data.clientCompanies.map((customer) => ({
                    id: customer.clientCompanyId,
                    title: customer.clientCompanyName,
                }));
                setCustomerList(customersData);
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching customer list:', error);
            return [];
        }
    };
    const getCommonData = async () => {
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
    }
    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                customerNamesId: customerId,
                shipmentId: shipmentId,
            };
            const response = await genericPost(LIST_COMMERCIAL_INVOICES_EXPORT, payload);
            if (response.data.success) {
                const commercialInvoices = response.data.data.map((invoice, index) => {
                    const particulars = invoice.particulars.map((particular) => ({
                        qty: particular.qty || 0,
                        rate: parseFloat(particular.rate) || 0,
                        amount: (particular.qty || 0) * (parseFloat(particular.rate) || 0),
                    }));

                    const totalAmount = particulars.reduce((total, { amount }) => total + amount, 0);

                    return {
                        commercialInvExpId: invoice.commercialInvExpId || '',
                        index: (index + 1).toString().padStart(2, '0'),
                        userId: invoice.userId || '',
                        companyId: invoice.companyId || '',
                        customerName: invoice.customer.clientCompanyName || '',
                        country: invoice.customer.billingCountry.name || '',
                        reference: invoice.reference || '',
                        commercialInvoiceNo: `${expCommercialPrefix || ''} ${invoice.commercialInvoiceNo || ''} ${expCommercialSuffix || ''}`,
                        date: invoice.date || '',
                        dueDate: invoice.dueDate || '',
                        note: invoice.note || '',
                        createdAt: invoice.createdAt || '',
                        updatedAt: invoice.updatedAt || '',
                        particulars,
                        totalAmount: `${invoice.currency?.currency_symbol || ''} ${totalAmount ? totalAmount.toFixed(2) : ""}`,
                        shipmentIdName: shipmentId || '',
                    };
                });
                const { newShippingId } = response.data;
                setNewShippingId(newShippingId);
                setData(commercialInvoices);
            } else {
                setError('No commercial invoices found');
            }
        } catch (error) {
            setError('Error fetching commercial invoices');
            console.error('Error fetching commercial invoices', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (commercialInvExpId) => {
        setLoading(true);
        const authToken = sessionStorage.getItem('accessToken');

        try {
            const response = await axios.delete(DELETE_COMMERCIAL_INVOICE_EXPORT(commercialInvExpId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                setDeleteAlert(false);
                setMessage({
                    open: true,
                    text: "deleted successfully",
                    type: "success",
                });
                fetchData();
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);

            if (error.response) {
                if (error.response.status === 409) {
                    setMessage({
                        open: true,
                        text: error.response.data?.error || "Cannot delete Data. Related Shipment exists.",
                        type: "error",
                    });
                } else {
                    setMessage({
                        open: true,
                        text: "An error occurred while deleting the Data.",
                        type: "error",
                    });
                }
            } else {
                console.error("Error deleting Data", error);
                setMessage({
                    open: true,
                    text: "Network error. Please try again later.",
                    type: "error",
                });
            }
        }
    };

    // Table columns
    const columns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        {
            id: 'commercialInvoiceNo', label: 'Commercial Invoice No.', accessor: 'commercialInvoiceNo', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'date', label: 'Date', accessor: 'date', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'shipmentIdName', label: 'Shipping Id', accessor: 'shipmentIdName', styleAccessor: {
                textAlign: 'center',
            },
        },
        { id: 'customerName', label: 'Customer Name', accessor: 'customerName' },
        {
            id: 'totalAmount', label: 'Amount', accessor: 'totalAmount', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.commercialInvExpId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.commercialInvExpId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.commercialInvExpId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];


    const handleUpdateClick = (id) => {
        setCommercialInvExpId(id);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true)
        setRowId(rowId)
    }


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setCommercialInvExpId('');
    };
    const handleViewClick = (id) => {
        setCommercialInvExpId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setCommercialInvExpId('');
    };

    const handleBackClick = () => {
        navigate('/app/export/shipment');
    };

    if (!shipmentDetails) {
        return <p>    <CustomLoader open={shipmentLoading} /> </p>;
    }

    const companyName = shipmentDetails.customer.clientCompanyName;
    const phoneNumber = shipmentDetails.customer.mobile;
    const customerName = shipmentDetails.customerId;
    const shipmentId = shipmentDetails.shipmentId;
    const currencySymbol = shipmentDetails?.currency?.currency_symbol ?? '';
    const currencyShort = shipmentDetails?.currency?.currency ?? '';
    const currencyId = shipmentDetails?.currency?.id ?? '';
    const shipmentOrgId = shipmentDetails.exportShipmentId;
    const expCommercialPrefixActivity = shipmentDetails.company.expCommercialPrefixActivity ?? '';
    const expCommercialPrefix = (expCommercialPrefixActivity === 1)
        ? shipmentDetails.company.expCommercialPrefix
        : (expCommercialPrefixActivity === 0 ? '' : '');

    const expCommercialSuffixActivity = shipmentDetails.company.expCommercialSuffixActivity ?? '';
    const expCommercialSuffix = (expCommercialSuffixActivity === 1)
        ? shipmentDetails.company.expCommercialSuffix
        : (expCommercialSuffixActivity === 0 ? '' : '');

    const expProformaPrefixActivity = shipmentDetails.company.expProformaPrefixActivity ?? '';
    const expProformaPrefix = (expProformaPrefixActivity === 1)
        ? shipmentDetails.company.expProformaPrefix
        : (expProformaPrefixActivity === 0 ? '' : '');

    const expProformaSuffixActivity = shipmentDetails.company.expProformaSuffixActivity ?? '';
    const expProformaSuffix = (expProformaSuffixActivity === 1)
        ? shipmentDetails.company.expProformaSuffix
        : (expProformaSuffixActivity === 0 ? '' : '');

    return (
        <div>
            <CustomLoader open={loading} />
            <DetailsPageCard
                columns={columns}
                title="Commercial Invoice"
                buttonTitle="Create Commercial Invoice"
                handleOpen={handleOpen}
                data={data}
                loading={loading}
                error={error}
            />
            <CustomModal open={modal} handleClose={handleClose}>
                <CreateExpCommercialInv
                    fetchData={fetchData}
                    customerList={customerList}
                    productsList={products}
                    customerName={customerName}
                    newShippingId={newShippingId}
                    shipmentId={shipmentId}
                    shipmentOrgId={shipmentOrgId}
                    setMessage={setMessage}
                    currencyList={currencyList}
                    currencyId={currencyId}
                    currencySymbol={currencySymbol}
                    expCommercialPrefix={expCommercialPrefix}
                    expCommercialSuffix={expCommercialSuffix}
                    expProformaPrefix={expProformaPrefix}
                    expProformaSuffix={expProformaSuffix}
                    handleClose={handleClose} />
            </CustomModal>

            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateExpCommercialInv
                    fetchData={fetchData}
                    customerList={customerList}
                    productsList={products}
                    setMessage={setMessage}
                    handleClose={handleCloseUpdate}
                    commercialInvExpId={commercialInvExpId}
                    currencyList={currencyList}
                    expCommercialPrefix={expCommercialPrefix}
                    expCommercialSuffix={expCommercialSuffix}
                    expProformaPrefix={expProformaPrefix}
                    expProformaSuffix={expProformaSuffix}
                />
            </CustomModal>
            <CustomModal open={viewModal} handleClose={handleCloseView}>
                <CommercialInvoiceView
                    fetchData={fetchData}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    customerList={customerList}
                    productsList={products}
                    setMessage={setMessage}
                    handleClose={handleCloseView}
                    commercialInvExpId={commercialInvExpId}
                    expCommercialPrefix={expCommercialPrefix}
                    expCommercialSuffix={expCommercialSuffix}
                    expProformaPrefix={expProformaPrefix}
                    expProformaSuffix={expProformaSuffix}
                />
            </CustomModal>

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}
            />
            {/* </Main> */}
        </div>
    );
};

export default CommercialInvoice;
