import React, { useCallback, useEffect, useState } from 'react';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import DetailsPhotoCard from '../../common/DetailsPage/DetailsPhotoCard';
import { ExportMenu, } from '../../../constants/DetailsPage'
import { Main } from '../../../constants/DetailsMain';
import CustomModal from "../../../components/common/CustomModal";
import CreateDocument from "../CreateForm/CreateDocument";
import CreateLoadingPhoto from "../CreateForm/CreateLoadingPhoto";
import CreateProductPhoto from "../CreateForm/CreateProductPhoto";
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import CustomLoader from '../../common/CustomLoader';
import { getDocumentNameList } from '../../../services/CommonServices';
import MessageBox from '../../common/CustomMessageBox';
import { LIST_ATTATCH_DOCUMENTS, LIST_LOADING_PHOTO, LIST_PRODUCT_PHOTO } from '../../../services/apiRoutes';
import { genericPost } from '../../../services/authServices';


export function AttatchDocument({ customerName, shipmentId, clientCompanyId, shipmentOrgId }) {
    const [leadModal, setLeadModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [documentName, setDocumentName] = useState([]);
    const [error, setError] = useState('');
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });

    useEffect(() => {
        fetchData();
        getCommonData();
    }, []);

    const getCommonData = async () => {
        const documentNameData = await getDocumentNameList();
        setDocumentName(documentNameData);
    }
    const getLastSuffix = (url) => {
        if (url) {
            const parts = url.split('/');
            return parts[parts.length - 1];
        }
        return '';
    };




    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const payload = { clientCompanyId: clientCompanyId, shipmentId: shipmentId, shipmentOrgId: shipmentOrgId, commonShipmentId: 1 };
            const response = await genericPost(LIST_ATTATCH_DOCUMENTS, payload);
            if (response.data.success && response.data.documents) {
                const documentData = response.data.documents.map((document, index) => ({
                    index: (index + 1).toString().padStart(2, '0'),
                    documentName: document?.documentNameList?.title,
                    description: document.description || '',
                    uploadDocument: getLastSuffix(document.uploadDocument),
                    documentUrl: document.uploadDocument,


                }));
                setData(documentData);
            } else {
                setError('No documents found');
            }
        } catch (error) {
            setError('Error fetching documents');
            console.error('Error fetching documents', error);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false);
    };

    const AttachDocumentColumns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        { id: 'documentName', label: 'Document Name', accessor: 'documentName' },
        {
            id: 'documentUrl', label: 'Attach', accessor: 'documentUrl',
            Cell: ({ row }) => (
                <a href={row.documentUrl} target="_blank" rel="noopener noreferrer">
                    {row.uploadDocument}
                </a>
            )
        },

        { id: 'description', label: 'Description', accessor: 'description' },
    ];

    return (
        <>
            {loading && <p> <CustomLoader open={loading} /> </p>}
            {error && <p>{error}</p>}
            <DetailsPageCard
                columns={AttachDocumentColumns}
                data={data}
                title={'Attach Document'}
                buttonTitle={'Attach Document'}
                handleOpen={handleOpen}
            />
            <CustomModal open={leadModal} handleClose={handleClose}>
                <CreateDocument
                    shipmentId={shipmentId}
                    fetchData={fetchData}
                    clientCompanyId={clientCompanyId}
                    documentName={documentName}
                    shipmentOrgId={shipmentOrgId}
                    handleClose={handleClose}
                    setMessage={setMessage}

                />
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
        </>
    );
}



export function ProductPhotoCard({ shipmentId, clientCompanyId, shipmentOrgId }) {
    const [leadModal, setLeadModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });

    useEffect(() => {
        fetchData();
    }, []);

    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false);
    };
    const dateFormat = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString('en-GB', options);
        return formattedDate.replace(/ /g, ', '); // Replace spaces with commas
    };



    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const payload = { clientCompanyId, shipmentId, shipmentOrgId, commanShipmentId: 1 };
            const response = await genericPost(LIST_PRODUCT_PHOTO, payload);

            if (response.data.success && response.data.documents) {
                const documentData = response.data.documents.map((document, index) => ({
                    index: (index + 1).toString().padStart(2, '0'),
                    productPhotoId: document.productPhotoId || '',
                    userId: document.userId || '',
                    companyId: document.companyId || '',
                    clientCompanyId: document.clientCompanyId || '',
                    note: document.note || '',
                    shipmentOrgId: document.shipmentOrgId || '',
                    commanShipmentId: document.commanShipmentId || 1,
                    shipmentId: document.shipmentId || '',
                    captureUpload: document.captureUpload || '',
                    date: dateFormat(document.createdAt || ''),
                }));
                setData(documentData);
            } else {
                setError('No documents found');
            }
        } catch (error) {
            setError('Error fetching documents');
            console.error('Error fetching documents', error);
        } finally {
            setLoading(false);
        }
    }, [clientCompanyId, shipmentId, shipmentOrgId]);

    const images = data.map((document) => ({
        notes: document.note,
        date: document.date,
        label: '',
        imgPath: document.captureUpload,
    }));


    return (
        <>
            <DetailsPhotoCard
                title="Product Photo"
                buttonTitle="Create Product Photo"
                imageData={images}
                handleOpen={handleOpen}
            />
            <CustomModal open={leadModal} handleClose={handleClose}>
                <CreateProductPhoto
                    shipmentId={shipmentId}
                    fetchData={fetchData}
                    clientCompanyId={clientCompanyId}
                    shipmentOrgId={shipmentOrgId}
                    handleClose={handleClose}
                    setMessage={setMessage}
                />
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
        </>
    );
}

export function LoadingPhoto({ shipmentId, clientCompanyId, shipmentOrgId }) {
    const [leadModal, setLeadModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });

    useEffect(() => {
        fetchData();
    }, []);

    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false);
    };
    const dateFormat = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(date).toLocaleDateString('en-GB', options);
        return formattedDate.replace(/ /g, ', '); // Replace spaces with commas
    };



    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const payload = { clientCompanyId, shipmentId, shipmentOrgId, commanShipmentId: 1 };
            const response = await genericPost(LIST_LOADING_PHOTO, payload);

            if (response.data.success && response.data.loadingPhotos) {
                const documentData = response.data.loadingPhotos.map((document, index) => ({
                    index: (index + 1).toString().padStart(2, '0'),
                    loadingPhotoId: document.loadingPhotoId || '',
                    userId: document.userId || '',
                    companyId: document.companyId || '',
                    clientCompanyId: document.clientCompanyId || '',
                    note: document.note || '',
                    shipmentOrgId: document.shipmentOrgId || '',
                    commanShipmentId: document.commanShipmentId || 0,
                    shipmentId: document.shipmentId || '',
                    captureUpload: document.captureUpload || '',
                    date: dateFormat(document.createdAt || '') // Format the createdAt date
                }));
                setData(documentData);
            } else {
                setError('No documents found');
            }
        } catch (error) {
            setError('Error fetching documents');
            console.error('Error fetching documents', error);
        } finally {
            setLoading(false);
        }
    }, [clientCompanyId, shipmentId, shipmentOrgId]);

    const images = data.map((document) => ({
        notes: document.note,
        date: document.date,
        label: '',
        imgPath: document.captureUpload,
    }));

    return (
        <>
            <DetailsPhotoCard
                title="Loading Photo"
                buttonTitle="Create Loading Photo"
                imageData={images}
                handleOpen={handleOpen}

            />
            <CustomModal open={leadModal} handleClose={handleClose}>
                <CreateLoadingPhoto
                    shipmentId={shipmentId}
                    fetchData={fetchData}
                    clientCompanyId={clientCompanyId}
                    shipmentOrgId={shipmentOrgId}
                    handleClose={handleClose}
                    setMessage={setMessage}
                />
            </CustomModal>
        </>
    );
}

export default function Document() {

    const [open, setOpen] = React.useState(true);
    const [leadModal, setLeadModal] = useState(false);

    const [activeTab, setActiveTab] = React.useState('Document');


    const navigate = useNavigate();
    const { exportShipmentId } = useParams();

    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);
    if (!shipmentDetails) {
        return <p> <CustomLoader open={shipmentLoading} /></p>;
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };


    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false)
    }


    const handleBackClick = () => {
        navigate("/app/export/shipement");
    };

    const AttachDocumentColumns = [
        { id: 'sn', label: 'SN', accessor: 'sn' },
        { id: 'documentName', label: 'Document Name', accessor: 'documentName' },
        { id: 'attach', label: 'Attach', accessor: 'attach' },
        { id: 'description', label: 'Description', accessor: 'description' },
    ];

    const customerName = shipmentDetails.customerId;
    const shipmentId = shipmentDetails.shipmentId;
    const clientCompanyId = shipmentDetails.customerId;
    const shipmentOrgId = shipmentDetails.exportShipmentId;
    return (
        <>
            <div>
                {/* <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={ExportMenu(exportShipmentId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}

                />

                <Main open={open}> */}
                <Box>
                    <AttatchDocument
                        customerName={customerName}
                        shipmentId={shipmentId}
                        clientCompanyId={clientCompanyId}
                        shipmentOrgId={shipmentOrgId}
                    />
                </Box>
                <br />
                <Box>
                    <ProductPhotoCard
                        shipmentId={shipmentId}
                        clientCompanyId={clientCompanyId}
                        shipmentOrgId={shipmentOrgId}
                    />
                </Box>
                <br />
                <Box>
                    <LoadingPhoto
                        shipmentId={shipmentId}
                        clientCompanyId={clientCompanyId}
                        shipmentOrgId={shipmentOrgId}
                    />
                </Box>

                {/* </Main> */}
            </div >
        </>
    );
}


const images = [
    {
        label: 'Account Statement',
        imgPath: 'https://i.calameoassets.com/230523125917-528a844974d315a22504d5565f39a286/large.jpg',
    },
    {
        label: 'Bank Statement',
        imgPath: 'https://i.calameoassets.com/140401170644-308f06c9ab46e599583c3aa5cfa7af52/large.jpg',
    },
    {
        label: 'Invoice Book',
        imgPath: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0OoaKRU-pjb8NcXbhRn6LQZEwjKmDAEpQ_Q&s',
    },
    {
        label: 'Transaction Details',
        imgPath: 'https://mi-blogs.s3.amazonaws.com/mi-live/blog/wp-content/uploads/2022/11/21055200/Statement-Example.webp',
    },
];