import React, { useState, useEffect, } from "react";
import { Box, Grid, Divider, Typography, InputAdornment, IconButton } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomButton from "../../common/CustomButton";
import { ClearRounded, SaveRounded, Visibility, VisibilityOff, } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import { genericPost } from '../../../services/authServices'
import { ADD_ORG_USERS } from '../../../services/apiRoutes'
import CustomStyle from "../../common/CustomStyle";
import CustomPasswordFeild from "../../common/CustomPasswordFeild";

const CreateAdmin = ({ handleClose, companyRoles, profile, getOrgUsers, setMessage }) => {

    const validationSchema = Yup.object({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        email: Yup.string().email("Invalid email address").required("Email is required"),
        mobile: Yup.string().required('Mobile Number is required'),
        password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
        roleId: Yup.string().required("User Level is required"),
        profileId: Yup.string().required("Profile is required"),
    });
    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        password: "",
        roleId: null,
        profileId: null,
    };
    const handleSubmit = async (values) => {
        try {
            const response = await genericPost(ADD_ORG_USERS, values, {});

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Added successfully",
                    type: "success",
                });
                getOrgUsers();
                handleClose();

            } else if (response.status === 409) {
                setMessage({
                    open: true,
                    text: response?.message || 'Email/Mobile already exists',
                    type: 'error',
                });
            } else {
                setMessage({
                    open: true,
                    text: response?.message || 'An unexpected error occurred',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong. Please try again.',
                type: 'error',
            });
        }
    };



    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                <Form>
                    <Grid container spacing={1} style={CustomStyle.container}>
                        <Grid item xs={4} md={2}>
                            <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                Create User
                            </Typography>
                        </Grid>
                        <Grid item xs={8} md={10} style={CustomStyle.buttonContainer}>
                            <Box style={CustomStyle.buttonBox}>
                                <CustomButton
                                    startIcon={<SaveRounded />}
                                    title="Save"
                                    size="small"
                                    variant="contained"
                                    type="submit"
                                />
                                <CustomButton
                                    startIcon={<ClearRounded />}
                                    title="Cancel"
                                    size="small"
                                    variant="contained"
                                    onClick={handleClose}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "5px" }}>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField
                                name="firstName"
                                type="text"
                                fieldLabel="First Name"
                                value={values.firstName}
                                required={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.firstName && Boolean(errors.firstName)}
                                helperText={touched.firstName && errors.firstName}
                                placeholder="Enter First Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField
                                name="lastName"
                                type="text"
                                fieldLabel="Last Name"
                                value={values.lastName}
                                required={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.lastName && Boolean(errors.lastName)}
                                helperText={touched.lastName && errors.lastName}
                                placeholder="Enter Last Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField
                                name="email"
                                type="email"
                                fieldLabel=" Email"
                                value={values.email}
                                required={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                placeholder="Enter Email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField
                                name="mobile"
                                type='number'
                                fieldLabel="mobile"
                                value={values.mobile}
                                required={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Mobile Number"
                                error={touched.mobile && !!errors.mobile}
                                helperText={touched.mobile && errors.mobile}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomPasswordFeild
                                name="password"
                                type="password"
                                fieldLabel="Password"
                                value={values.password}
                                required={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.password && Boolean(errors.password)}
                                helperText={touched.password && errors.password}
                                placeholder="Enter Password"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <CustomAutocomplete
                                options={companyRoles}
                                required={true}
                                fieldLabel="User Role"
                                getOptionLabel={(option) => option.title}
                                value={companyRoles.find((v) => v.id == values.roleId)}
                                onChange={(e, newValue) => setFieldValue("roleId", newValue.id)}
                                onBlur={handleBlur}
                                name="roleId"
                                error={touched.role && Boolean(errors.roleId)}
                                helperText={touched.roleId && errors.roleId}
                                placeholder="Select User role"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomAutocomplete
                                options={profile}
                                required={true}
                                fieldLabel="Profile"
                                getOptionLabel={(option) => option.title}
                                value={profile.find((v) => v.id == values.profileId)}
                                name="profileId"
                                onChange={(e, newValue) => setFieldValue("profileId", newValue.id)}
                                onBlur={handleBlur}
                                error={touched.profileId && Boolean(errors.profileId)}
                                helperText={touched.profileId && errors.profileId}
                                placeholder="Select Profile"
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default CreateAdmin;
