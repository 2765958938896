import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomButton from "../common/CustomButton";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from '../common/CustomAutoComplete';
import CustomTextarea from "../common/CustomTextarea";
import { genericPost } from '../../services/authServices';
import { Create_clientCompanies } from '../../services/apiRoutes';
import { getStates, getCities } from "../../services/CommonServices";

import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomStyle from "../common/CustomStyle";

const validationSchema = Yup.object().shape({
  companyAdminId: Yup.number().required('Company Admin is required').integer('Company Admin must be an integer'),
  clientCompanyName: Yup.string().required('Client Company Name is required').max(255, 'Client Company Name cannot exceed 255 characters'),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
    .required('Mobile is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  companyTypeId: Yup.number().required('Company Type is required').integer('Company Type ID must be an integer'),
  ownershipId: Yup.number().required('Ownership is required').integer('Ownership ID must be an integer'),
  industryTypeId: Yup.number().required('Industry Type is required').integer('Industry Type ID must be an integer'),
  billingStreet: Yup.string().required('Billing Street is required').max(255, 'Billing Street cannot exceed 255 characters'),
  billingCountryId: Yup.number().required('Billing Country is required').integer('Billing Country ID must be an integer'),
  billingStateId: Yup.number().required('Billing State is required').integer('Billing State ID must be an integer'),
  billingCityId: Yup.number().required('Billing City is required').integer('Billing City ID must be an integer'),
});

const CreateCompany = ({
  handleClose,
  owner,
  rating,
  companyTypes,
  countries,
  setMessage,
  fetchClientCompanies,
  companyOwnershipTypes,
  industryType,
  businessGroups,

}) => {

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await genericPost(Create_clientCompanies, values);

      if (response.status === 201) {
        setMessage({
          open: true,
          text: response?.data?.message || "Company added successfully",
          type: "success",
        });
        fetchClientCompanies();
        setTimeout(() => {
          handleClose();
        }, 3000);
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      alert('Something went wrong');
    } finally {
      setSubmitting(false);
    }
  };

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [billingStates, setBillingStates] = useState([]);
  const [billingCities, setBillingCities] = useState([]);

  const handleChangeMillingCountry = async (newValue) => {
    const countryId = newValue ? newValue.id : '';
    if (countryId) {
      const stateData = await getStates({ id: countryId });
      setStates(stateData);
      setCities([]);
    } else {
      setStates([]);
      setCities([]);
    }
  };
  const handleChangeBillingCountry = async (newValue) => {
    const countryId = newValue ? newValue.id : '';
    if (countryId) {
      const stateData = await getStates({ id: countryId });
      setBillingStates(stateData);
      setBillingCities([]);
    } else {
      setBillingStates([]);
      setBillingCities([]);
    }
  };
  const handleChangeMillingState = async (newValue) => {
    const stateId = newValue ? newValue.id : '';
    if (stateId) {
      const cityData = await getCities({ id: stateId });
      setCities(cityData);
    } else {
      setCities([]);
    }
  };
  const handleChangeBillingState = async (newValue) => {
    const stateId = newValue ? newValue.id : '';
    if (stateId) {
      const cityData = await getCities({ id: stateId });
      setBillingCities(cityData);
    } else {
      setBillingCities([]);
    }
  };
  const handleCopyMailingToOthers = async (setFieldValue, values) => {
    setFieldValue('billingStreet', values.mailingStreet);
    setFieldValue('billingCountryId', values.mailingCountryId);
    setFieldValue('billingPincode', values.mailingPincode);

    if (values.mailingCountryId) {
      const billingStatesData = await getStates({ id: values.mailingCountryId });
      setBillingStates(billingStatesData);
      setFieldValue('billingStateId', values.mailingStateId);

      if (values.mailingStateId) {
        const billingCitiesData = await getCities({ id: values.mailingStateId });
        setBillingCities(billingCitiesData);
        setFieldValue('billingCityId', values.mailingCityId);
      }
    }
  };

  const handleCopyOthersToMailing = async (setFieldValue, values) => {
    setFieldValue('mailingStreet', values.billingStreet);
    setFieldValue('mailingCountryId', values.billingCountryId);
    setFieldValue('mailingPincode', values.billingPincode);

    if (values.billingCountryId) {
      const mailingStatesData = await getStates({ id: values.billingCountryId });
      setStates(mailingStatesData);
      setFieldValue('mailingStateId', values.billingStateId);

      if (values.billingStateId) {
        const mailingCitiesData = await getCities({ id: values.billingStateId });
        setCities(mailingCitiesData);
        setFieldValue('mailingCityId', values.billingCityId);
      }
    }
  };



  const [formData, setFormData] = useState({
    companyAdminId: '',
    ratingId: '',
    clientCompanyName: '',
    phone: '',
    mobile: '',
    email: '',
    website: '',
    companyTypeId: '',
    ownershipId: '',
    industryTypeId: '',
    employees: '',
    annualRevenue: '',
    businessGroupId: '',
    billingStreet: '',
    billingCountryId: '',
    billingStateId: '',
    billingCityId: '',
    billingPincode: '',
    mailingStreet: '',
    mailingCountryId: '',
    mailingStateId: '',
    mailingCityId: '',
    mailingPincode: '',
    description: '',

  });

  useEffect(() => {
    setFormData({
      companyAdminId: '',
      ratingId: '',
      clientCompanyName: '',
      phone: '',
      mobile: '',
      email: '',
      website: '',
      companyTypeId: '',
      ownershipId: '',
      industryTypeId: '',
      employees: '',
      annualRevenue: '',
      businessGroupId: '',
      billingStreet: '',
      billingCountryId: '',
      billingStateId: '',
      billingCityId: '',
      billingPincode: '',
      mailingStreet: '',
      mailingCountryId: '',
      mailingStateId: '',
      mailingCityId: '',
      mailingPincode: '',
      description: '',
    });
  }, []);

  return (
    <Box sx={{ mx: 'auto', mt: 0 }}>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
          <Form>
            <Grid container spacing={1} style={CustomStyle.container}>
              <Grid item xs={4} md={4}>
                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                  Create Client Company
                </Typography>
              </Grid>
              <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                <Box style={CustomStyle.buttonBox}>
                  <CustomButton
                    startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <SaveRounded />}
                    title="Save"
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  />
                  <CustomButton
                    startIcon={<SaveRounded />}
                    title="Save and New"
                    size="small"
                    variant="contained"
                  />
                  <CustomButton
                    startIcon={<ClearRounded />}
                    title="Cancel"
                    size="small"
                    variant="contained"
                    onClick={handleClose}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={1} sx={{ px: "5%", paddingTop: "5px" }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomAutocomplete
                  name="companyAdminId"
                  fieldLabel="Company Admin"
                  required={true}
                  options={owner}
                  getOptionLabel={(option) => option.title}
                  value={owner.find((option) => option.id === values.companyAdminId) || null}
                  onChange={(e, newValue) => setFieldValue('companyAdminId', newValue ? newValue.id : '')}
                  placeholder="Select Company Admin"
                  error={touched.companyAdminId && !!errors.companyAdminId}
                  helperText={touched.companyAdminId && errors.companyAdminId}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomAutocomplete
                  name="companyTypeId"
                  fieldLabel="Company Type"
                  required={true}
                  options={companyTypes}
                  getOptionLabel={(option) => option.title}
                  value={companyTypes.find((option) => option.id === values.companyTypeId) || null}
                  onChange={(e, newValue) => setFieldValue('companyTypeId', newValue ? newValue.id : '')}
                  placeholder="Select Company Type"
                  error={touched.companyTypeId && !!errors.companyTypeId}
                  helperText={touched.companyTypeId && errors.companyTypeId}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomTextField
                  name="clientCompanyName"
                  fieldLabel="Company Name"
                  placeholder="Enter Company Name"
                  required={true}
                  value={values.clientCompanyName}
                  onChange={handleChange}
                  error={touched.clientCompanyName && !!errors.clientCompanyName}
                  helperText={touched.clientCompanyName && errors.clientCompanyName}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomTextField
                  name="phone"
                  type="number"
                  fieldLabel="Phone"
                  placeholder="Enter The Phone"
                  value={values.phone}
                  onChange={handleChange}

                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomTextField
                  name="mobile"
                  type="number"
                  fieldLabel="Mobile"
                  placeholder="Enter Mobile"
                  required={true}
                  value={values.mobile}
                  onChange={handleChange}
                  error={touched.mobile && !!errors.mobile}
                  helperText={touched.mobile && errors.mobile}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomTextField
                  name="email"
                  fieldLabel="Email"
                  placeholder="Enter Email"
                  required={true}
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomTextField
                  name="website"
                  fieldLabel="Website"
                  placeholder="Enter Website"
                  value={values.website}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomAutocomplete
                  name="ratingId"
                  fieldLabel="Rating"
                  options={rating}
                  getOptionLabel={(option) => option.title}
                  value={rating.find((option) => option.id === values.ratingId) || null}
                  onChange={(e, newValue) => setFieldValue('ratingId', newValue ? newValue.id : '')}
                  placeholder="Select Rating"

                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomAutocomplete
                  name="ownershipId"
                  fieldLabel="Ownership"
                  required={true}
                  options={companyOwnershipTypes}
                  getOptionLabel={(option) => option.title}
                  value={companyOwnershipTypes.find((option) => option.id === values.ownershipId) || null}
                  onChange={(e, newValue) => setFieldValue('ownershipId', newValue ? newValue.id : '')}
                  placeholder="Select Ownership"
                  error={touched.ownershipId && !!errors.ownershipId}
                  helperText={touched.ownershipId && errors.ownershipId}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomAutocomplete
                  name="industryTypeId"
                  fieldLabel="Industry Type"
                  required={true}
                  options={industryType}
                  getOptionLabel={(option) => option.title}
                  value={industryType.find((option) => option.id === values.industryTypeId) || null}
                  onChange={(e, newValue) => setFieldValue('industryTypeId', newValue ? newValue.id : '')}
                  placeholder="Select Industry Type"
                  error={touched.industryTypeId && !!errors.industryTypeId}
                  helperText={touched.industryTypeId && errors.industryTypeId}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomTextField
                  name="employees"
                  fieldLabel="Number of Employees"
                  placeholder="Enter Number of Employees"
                  type="number"
                  value={values.employees}
                  onChange={handleChange}

                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomTextField
                  name="annualRevenue"
                  fieldLabel="Annual Revenue"
                  placeholder="Enter Annual Revenue"
                  type="number"
                  value={values.annualRevenue}
                  onChange={handleChange}

                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomAutocomplete
                  name="businessGroupId"
                  fieldLabel="Business Group"
                  options={businessGroups}
                  getOptionLabel={(option) => option.title}
                  value={businessGroups.find((option) => option.id === values.businessGroupId) || null}
                  onChange={(e, newValue) => setFieldValue('businessGroupId', newValue ? newValue.id : '')}
                  placeholder="Select Business Group"

                />
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container spacing={1} style={{ paddingBottom: "5px" }}>
                    <Grid item xs={12} md={8}>
                      <Typography
                        style={{
                          color: "#212529",
                          fontWeight: 500,
                          fontSize: "20px",
                        }}
                      >
                        Address Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomAutocomplete
                        options={top100Films}
                        getOptionLabel={(option) => option.title}
                        placeholder="Copy Address"
                        onChange={(event, newValue) => {
                          if (newValue && newValue.id === 1) {
                            handleCopyMailingToOthers(setFieldValue, values);
                          } else if (newValue && newValue.id === 2) {
                            handleCopyOthersToMailing(setFieldValue, values);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    required={true}
                    name="billingStreet"
                    type="text"
                    fieldLabel="Billing Street"
                    placeholder="Enter Billing Street"
                    value={values.billingStreet}
                    onChange={handleChange}
                    error={touched.billingStreet && !!errors.billingStreet}
                    helperText={touched.billingStreet && errors.billingStreet}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    name="mailingStreet"
                    type="text"
                    fieldLabel="Mailing Street"
                    placeholder="Enter Mailing Street"
                    value={values.mailingStreet}
                    onChange={handleChange}

                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomAutocomplete
                    required={true}
                    options={countries}
                    fieldLabel="Billing Country"
                    getOptionLabel={(option) => option.title}
                    value={countries.find((option) => option.id === values.billingCountryId) || null}
                    onChange={(e, newValue) => {
                      setFieldValue('billingCountryId', newValue ? newValue.id : '');
                      handleChangeBillingCountry(newValue);
                    }}
                    placeholder="Select Billing Country"
                    error={touched.billingCountryId && !!errors.billingCountryId}
                    helperText={touched.billingCountryId && errors.billingCountryId}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomAutocomplete
                    options={countries}
                    fieldLabel="Mailing Country"
                    getOptionLabel={(option) => option.title}
                    value={countries.find((option) => option.id === values.mailingCountryId) || null}
                    onChange={(e, newValue) => {
                      setFieldValue('mailingCountryId', newValue ? newValue.id : '');
                      handleChangeMillingCountry(newValue);
                    }}
                    placeholder="Select Mailing Country"

                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomAutocomplete
                    options={billingStates}
                    fieldLabel="Billing State"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={billingStates.find((option) => option.id === values.billingStateId) || null}
                    onChange={(e, newValue) => {
                      setFieldValue('billingStateId', newValue ? newValue.id : '');
                      handleChangeBillingState(newValue);
                    }}
                    placeholder="Select Billing State"
                    error={touched.billingStateId && !!errors.billingStateId}
                    helperText={touched.billingStateId && errors.billingStateId}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomAutocomplete
                    options={states}
                    fieldLabel="Mailing State"
                    getOptionLabel={(option) => option.title}
                    value={states.find((option) => option.id === values.mailingStateId) || null}
                    onChange={(e, newValue) => {
                      setFieldValue('mailingStateId', newValue ? newValue.id : '');
                      handleChangeMillingState(newValue);
                    }}
                    placeholder="Select Mailing State"

                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomAutocomplete
                    options={billingCities}
                    fieldLabel="Billing City"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={billingCities.find((option) => option.id === values.billingCityId) || null}
                    onChange={(e, newValue) => setFieldValue('billingCityId', newValue ? newValue.id : '')}
                    placeholder="Select Billing City"
                    error={touched.billingCityId && !!errors.billingCityId}
                    helperText={touched.billingCityId && errors.billingCityId}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomAutocomplete
                    options={cities}
                    fieldLabel="Mailing City"
                    getOptionLabel={(option) => option.title}
                    value={cities.find((option) => option.id === values.mailingCityId) || null}
                    onChange={(e, newValue) => setFieldValue('mailingCityId', newValue ? newValue.id : '')}
                    placeholder="Select Mailing City"

                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    name="billingPincode"
                    fieldLabel="Billing Pincode"
                    type="number"
                    placeholder="Enter Billing Pincode"
                    value={values.billingPincode}
                    onChange={handleChange}

                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    name="mailingPincode"
                    fieldLabel="Mailing Pincode"
                    type="number"
                    placeholder="Enter Mailing Pincode"
                    value={values.mailingPincode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={8}>
                  <CustomTextarea
                    name="description"
                    fieldLabel="Notes"
                    value={values.description}
                    onChange={handleChange}
                    placeholder="Write a Notes"
                  />
                </Grid>

              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box >
  );
};

export default CreateCompany;


const top100Films = [
  { title: "Mailing to Billing", id: 1 },
  { title: "Billing to Mailing ", id: 2 },
];
