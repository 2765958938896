import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { GET_IMPORT_SHIPMENT_COSTING } from "../../services/apiRoutes";
import CustomReport from "../common/CustomReport";
const title = "Import Shipment Costing Report";

const ImportShipmentCostingReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'date', header: 'Date', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'proformaInvoiceNo', header: 'PI No.', size: 100, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'supplierName', header: 'Supplier Name', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'country', header: 'Country', size: 100, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'numberOfContainers', header: 'No. Of Container', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'totalQty', header: 'Total Qty', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'totalAmount', header: 'Total Cost', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'coastPerUnit', header: 'Cost per Unit', size: 100, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton onClick={() => handleViewClick(row.original.commercialInvoiceId)}>
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );
    const handleViewClick = (commercialInvoiceId) => {
        navigate(`/app/reports/importshipmentcostingreport/${commercialInvoiceId}`);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_IMPORT_SHIPMENT_COSTING });
            if (response.data.success) {
                const commercialInvoices = response.data.data.map((invoice, index) => {
                    const totalQty = invoice.particulars.reduce((acc, item) => acc + Number(item.qty || 0), 0);
                    const totalAmount = invoice.particulars.reduce((acc, item) => acc + (Number(item.qty || 0) * parseFloat(item.rate || 0)), 0);
                    const costPerUnit = totalQty > 0 ? (totalAmount / totalQty).toFixed(2) : '0.00';
                    return {
                        commercialInvoiceId: invoice.commercialInvoiceId || '',
                        supplierNamesId: invoice.supplierNamesId || '',
                        index: (index + 1).toString().padStart(2, '0'),
                        reference: invoice.reference || '',
                        supplierName: invoice.clientCompany?.clientCompanyName || '',
                        noOfContainers: invoice.importShipment?.noOfContainers || '',
                        country: invoice.clientCompany?.mailingCountry?.name || '',
                        date: invoice.date || '',
                        dueDate: invoice.dueDate || '',
                        note: invoice.note || '',
                        upload: invoice.ciUpload || '',
                        proformaInvoiceNo: invoice?.ProformaInvoice?.proformaInvoiceNo || '',
                        numberOfContainers: invoice.numberOfContainers || '',
                        totalQty,
                        totalAmount: totalAmount.toFixed(2),
                        coastPerUnit: costPerUnit,
                        particulars: invoice.particulars.map(({
                            particularName: name = '',
                            qty = '',
                            rate = '',
                            unit = '',
                            description = ''
                        }) => ({
                            name, qty, rate, unit, description
                        }))
                    };
                });

                setData(commercialInvoices);
            } else {
                setError('No commercial invoices found');
            }
        } catch (error) {
            setError('Error fetching commercial invoices');
            console.error('Error fetching commercial invoices', error);
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        fetchData()
    }, [token])

    const defaultView = 'importshipmentcostingreport';
    const mainContent = (
        <DataTable
            title={title}
            columns={columns}
            data={data}
        />
    );

    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>

    );
};
export default ImportShipmentCostingReport;
