import axios from 'axios';
const getAccessToken = () => sessionStorage.getItem('accessToken');

export const getHeaders = (isPatch = false, token) => {

  const accessToken = getAccessToken();
  return {
    accept: 'application/json',
    // 'Content-Type': isPatch ? 'application/json-patch+json' : 'application/json',
    'Authorization': token ? `Bearer ${token}` : `Bearer ${accessToken}` // Fixed typo here
  };
};

const isContentTypeJSON = (headers) =>
  headers.get('content-type')?.includes('application/json') ?? false;

const isContentTypePdf = (headers) =>
  headers.get('content-type')?.includes('application/pdf') ?? false;

export const createTransactionResultFrom = (response) => ({
  ok: response.ok,
  status: response.status,
  headers: response.headers,
});

export const genericGet = async ({ url }) => {
  try {
    const response = await axios.get(url, { headers: getHeaders(false) });

    const transactionResult = createTransactionResultFrom(response);

    if (response.status >= 200 && response.status <= 299) {
      transactionResult.data = response.data;
    } else if (response.status >= 400 && response.status <= 499) {
      const responseError = response.data;
      throw new Error(responseError ? responseError.error?.[0].reason : 'general error');
    }

    return transactionResult;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error?.[0].reason || 'general error');
    }
    throw new Error(error.message);
  }
};

export const genericPost = async (url, body, token) => {
  const accessToken = getAccessToken();

  try {
    const response = await axios.post(url, body, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': token ? `Bearer ${token}` : `Bearer ${accessToken}`
      },
    });

    // Create transaction result from response
    const transactionResult = createTransactionResultFrom(response);

    // Assuming createTransactionResultFrom and isContentTypeJSON are utility functions you have
    const isJson = response.headers['content-type']?.includes('application/json');

    if (response.status >= 200 && response.status <= 299) {
      transactionResult.data = isJson ? response.data : undefined;
    }

    if (response.status >= 400 && response.status <= 499) {
      transactionResult.error = isJson ? response.data : undefined;
    }

    return transactionResult;
  } catch (error) {
    // Handle axios-specific error structure
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const isJson = error.response.headers['content-type']?.includes('application/json');
      const errorData = isJson ? error.response.data : undefined;

      return {
        error: errorData,
        status: error.response.status,
        // Optionally add more fields to the transactionResult based on your needs
      };
    } else if (error.request) {
      // The request was made but no response was received
      return {
        error: 'No response received',
        status: 0,
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      return {
        error: error.message,
        status: 0,
      };
    }
  }
};


export const genericPut = async (url, body, token) => {
  try {
    const response = await axios.put(url, body, { headers: getHeaders(false, token) });
    const transactionResult = createTransactionResultFrom(response);

    // Check if the response status is within the success range
    if (response.status >= 200 && response.status <= 299) {
      transactionResult.data = response.data;
    } else if (response.status >= 400 && response.status <= 499) {
      // Handle client errors
      const responseError = response.data;
      throw new Error(responseError?.error?.[0]?.reason || 'Client error');
    } else if (response.status >= 500) {
      // Handle server errors
      throw new Error('Server error');
    }

    return transactionResult;
  } catch (error) {
    // Handle errors from Axios
    if (error.response) {
      throw new Error(error.response.data?.error?.[0]?.reason || 'Request error');
    }
    // Handle other errors
    throw new Error(error.message || 'Unknown error');
  }
};

export const genericDownloadPdf = async ({ url, fileName }) => {
  const accessToken = getAccessToken();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`, // Ensure headers include the Authorization if required
      },
    });

    const transactionResult = createTransactionResultFrom(response);
    const isPDF = isContentTypePdf(response.headers);

    if (response.status >= 200 && response.status <= 299) {
      if (isPDF) {
        const blobResponse = await response.blob();
        const objectURL = URL.createObjectURL(blobResponse);
        const anchorEl = document.createElement('a');
        anchorEl.href = objectURL;
        anchorEl.download = `${fileName}.pdf`;
        anchorEl.click();
        URL.revokeObjectURL(objectURL);
      } else {
        throw new Error('Not a PDF');
      }
    }

    return transactionResult;
  } catch (error) {
    return { ok: false };
  }
};

export const genericPostFileUpload = async (url, body) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: body instanceof FormData ? body : JSON.stringify(body),
      headers: body instanceof FormData ? {} : getHeaders(false), // Adjust headers for FormData
    });

    const transactionResult = createTransactionResultFrom(response);
    const isJson = isContentTypeJSON(response.headers);

    if (response.status >= 200 && response.status <= 299) {
      transactionResult.data = isJson ? await response.json() : undefined;
    } else if (response.status >= 400 && response.status <= 499) {
      transactionResult.error = isJson ? await response.json() : undefined;
    }

    return transactionResult;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const printFunction = (elementId, uniqueIframeId) => {
  const content = document.getElementById(elementId);
  let pri;

  // Check if iframe with uniqueIframeId already exists
  if (document.getElementById(uniqueIframeId)) {
    pri = document.getElementById(uniqueIframeId).contentWindow;
  } else {
    // Create a new iframe if it doesn't exist
    const iframe = document.createElement('iframe');
    iframe.setAttribute('title', uniqueIframeId);
    iframe.setAttribute('id', uniqueIframeId);
    iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;');
    document.body.appendChild(iframe);
    pri = iframe.contentWindow;
  }

  // Open the document, write the content, and trigger the print
  pri.document.open();
  pri.document.write(content.innerHTML);
  pri.document.close();
  pri.focus();
  pri.print();
};

export const convertNumberToWords = (num) => {
  if (isNaN(num)) return 'Invalid number';

  const ones = [
    '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
    'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen',
  ];

  const tens = [
    '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety',
  ];

  const scales = ['', 'Thousand', 'Million', 'Billion'];

  if (num === 0) return 'Zero';

  let words = '';

  const chunk = (n) => Math.floor(num / Math.pow(1000, n)) % 1000;

  for (let i = 0; i < scales.length; i++) {
    const chunkValue = chunk(i);
    if (chunkValue > 0) {
      const chunkWords = [];
      const hundreds = Math.floor(chunkValue / 100);
      if (hundreds) chunkWords.push(ones[hundreds] + ' Hundred');

      const remainder = chunkValue % 100;
      if (remainder < 20) {
        chunkWords.push(ones[remainder]);
      } else {
        chunkWords.push(tens[Math.floor(remainder / 10)]);
        chunkWords.push(ones[remainder % 10]);
      }

      words = chunkWords.filter(Boolean).join(' ') + ' ' + scales[i] + ' ' + words;
    }
  }

  return words.trim() + ' Only';
};


// Function to render HTML content safely
export const renderHtmlContent = (htmlString) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

