import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import CustomModal from "../../components/common/CustomModal";
import CreateUserManagement from "./CreateMasters/CreateUserManagement";
import CustomMaster from "../common/CustomMaster";
import { DELETE_ORG_USERS, GET_ORG_USERS, GET_PROFILE, GET_ROLE } from "../../services/apiRoutes";
import { genericGet } from "../../services/apiServices";
import UpdateUserManagement from "./UpdateMaster/UpdateUserManagement";
import MessageBox from "../common/CustomMessageBox";
import CustomDelete from "../common/CustomDeleteAlert";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { AccessDenied, hasCreatePermission, hasDeletePermission, hasEditPermission, hasViewPermission } from "../../services/CommonServices";

const title = "Admin Login";

const UserManagement = () => {
    const [openModal, setOpenModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [rowId, setRowId] = useState('');
    const [loading, setLoading] = useState(false);
    const [permission, setPermissions] = useState()
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        let rolePermisionsData = JSON.parse(sessionStorage.getItem('userData')) || []
        let editPermision = hasEditPermission(currentPath, rolePermisionsData?.permissions)
        let deletePermission = hasDeletePermission(currentPath, rolePermisionsData?.permissions)
        let createPermision = hasCreatePermission(currentPath, rolePermisionsData?.permissions)
        let viewPermision = hasViewPermission(currentPath, rolePermisionsData?.permissions)

        setPermissions({
            edit: editPermision,
            delete: deletePermission,
            create: createPermision,
            view: viewPermision,
        })
    }, [])
    const handleOpen = () => {
        setOpenModal(true);
    };
    const handleClose = () => {
        setOpenModal(false)
    }

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);

    const [activeTab, setActiveTab] = useState(0);
    const [openMenu, setOpenMenu] = useState(null);
    const [profile, setProfile] = useState([]);
    const [companyRoles, setCompanyRoles] = useState([])
    const [companyUsers, setCompanyUsers] = useState([])
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };


    const [selectedUser, setSelectedUser] = useState();
    const [open, setOpen] = useState(false);

    const handleUserClick = (user) => {
        console.log(user);
        setSelectedUser(user);
    };
    const handleUpdateClick = (companyUserId) => {
        setSelectedId(companyUserId);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };


    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true);
        setRowId(rowId);
    };

    const getRoles = async () => {
        // setLoading(true);
        try {
            const response = await genericGet({ url: GET_ROLE });
            if (response.status == 200) {
                let reponseData = response?.data?.companyRoles.map((v) => ({
                    id: v?.roleId,
                    title: v?.roleName,
                    name: v?.roleName,
                    roleDescription: v?.roleDescription,
                    parentRole: v?.parentRole?.roleName
                }))
                setCompanyRoles(reponseData)
            }
        } catch (error) {

        } finally {
        }
    };
    const getOrgUsers = async () => {
        // setLoading(true);
        try {
            const response = await genericGet({ url: GET_ORG_USERS });
            if (response.status == 200) {
                let reponseData = response?.data?.companyUsers.map((v, index) => ({
                    sn: (index + 1).toString().padStart(2, '0'),
                    firstName: v?.user?.firstName,
                    lastName: v?.user?.lastName,
                    email: v?.user?.email,
                    mobile: v?.user?.mobile,
                    profileName: v?.profile?.profileName,
                    role: v?.role?.roleName,
                    companyUserId: v?.companyUserId,
                    userId: v?.userId,
                    name: `${v?.user?.firstName} ${v?.user?.lastName}`
                }))
                setCompanyUsers(reponseData)
            }
        } catch (error) {

        } finally {
        }
    };
    console.log(companyUsers)
    const getProfiles = async () => {
        try {
            const response = await genericGet({ url: GET_PROFILE });
            if (response.status == 200) {
                let reponseData = response.data.companyProfiles.map((v) => ({
                    id: v?.profileId,
                    title: v?.profileName,
                    name: v?.profileName,
                    profileDescription: v?.profileDescription,
                }))
                setProfile(reponseData);
            }
        } catch (error) {
        } finally {
        }
    };
    useEffect(() => {
        getRoles()
        getProfiles()
        getOrgUsers()
    }, [])

    const handleDelete = async (userId) => {
        setLoading(true);
        const authToken = sessionStorage.getItem('accessToken');

        try {
            const response = await axios.delete(DELETE_ORG_USERS(userId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                getOrgUsers();
                setDeleteAlert(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);

            if (error.response) {
                if (error.response.status === 409) {
                    setMessage({
                        open: true,
                        text: error.response.data?.error || "Cannot delete Client Company. Related Shipment exists.",
                        type: "error",
                    });
                } else {
                    setMessage({
                        open: true,
                        text: "An error occurred while deleting the client company.",
                        type: "error",
                    });
                }
            } else {
                console.error("Error deleting client company", error);
                setMessage({
                    open: true,
                    text: "Network error. Please try again later.",
                    type: "error",
                });
            }
        }
    };
    const columns = useMemo(
        () => [
            {
                accessorKey: "sn", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" },
                enableSorting: true,
                enableColumnFilter: true
            },
            {
                accessorKey: 'name', header: 'User Name', tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 200
            },
            {
                accessorKey: 'email', header: 'Email', tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 200
            },
            { accessorKey: 'mobile', header: 'Mobile', tableStyle: { textAlign: "center" }, size: 120 },
            { accessorKey: 'role', header: 'Role', tableStyle: { textAlign: "center" }, size: 150 },
            { accessorKey: 'profileName', header: 'Profile Name', tableStyle: { textAlign: "center" }, size: 180 },
            { accessorKey: 'userId', header: 'User Id', tableStyle: { textAlign: "center" }, size: 100 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                            {permission?.edit &&
                                <IconButton onClick={() => handleUpdateClick(row.original.companyUserId)}>
                                    <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                                </IconButton>
                            }
                            {permission?.delete &&
                                <IconButton onClick={() => handleOpenDeleteAlert(row.original.userId)}>
                                    <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                                </IconButton>
                            }
                        </div>
                    </>
                ),
            },
        ],
        [permission]
    );


    const defaultView = 'usermanagement';
    const mainContent = (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={companyUsers}
                    handleOpen={handleOpen}
                    buttonTitle="Create User"
                />
                <CustomModal open={openModal} handleClose={handleClose}>
                    <CreateUserManagement
                        getOrgUsers={getOrgUsers}
                        companyRoles={companyRoles}
                        profile={profile}
                        setMessage={setMessage}
                        handleClose={handleClose} />
                </CustomModal>
                <CustomModal
                    open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateUserManagement
                        handleClose={handleCloseUpdate}
                        companyUserId={selectedId}
                        fetchData={getOrgUsers}
                        companyRoles={companyRoles}
                        profile={profile}
                        setMessage={setMessage}
                    />
                </CustomModal>
                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
                <CustomDelete
                    handleDelete={handleDelete}
                    open={deleteAlert}
                    rowId={rowId}
                    handleClose={() => setDeleteAlert(false)}
                />
            </Box>
        </>
    );
    const getMainContent = (mainContent, permission) => {
        return permission?.view ? mainContent : null;
    };

    return (

        <>
            <CustomMaster
                defaultView={defaultView}
                mainContent={getMainContent(mainContent, permission)}
            />
            {!permission?.view && <AccessDenied />}
        </>

    );
};
export default UserManagement;
