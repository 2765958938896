import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Table,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Card,
  Typography,
  Divider,
  Button,

} from "@mui/material";
import CustomModal from "../../common/CustomModal";
import CreateCompanyModules from "./CreateCompanyModules";
import MessageBox from "../../common/CustomMessageBox";
import CustomDelete from "../../common/CustomDeleteAlert";
import { GET_PROFILE, GET_PERMISSIONS, DELETE_PROFILE } from "../../../services/apiRoutes";
import { genericGet } from "../../../services/apiServices";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomMaster from "../../common/CustomMaster";
import { AccessDenied, formatDate, hasCreatePermission, hasDeletePermission, hasEditPermission, hasViewPermission, } from "../../../services/CommonServices";
import UpdateProfiles from "../UpdateMaster/UpdateProfiles";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import UpdatePermission from "../UpdateMaster/UpdatePermission";
import CustomButton from "../../common/CustomButton";
import { useLocation } from "react-router-dom";
import axios from "axios";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';




const Profiles = () => {
  const [open, setOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateProfileModal, setUpdateProfileModal] = useState(false);
  const [permission, setPermissions] = useState()
  const [selectedId, setSelectedId] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [loading, setLoading] = useState(false)

  const [rowId, setRowId] = useState('')
  const handleOpenDeleteAlert = (rowId) => {
    setDeleteAlert(true)
    setRowId(rowId)
  }

  const location = useLocation();

  const currentPath = location.pathname;

  useEffect(() => {
    let rolePermisionsData = JSON.parse(sessionStorage.getItem('userData')) || []
    let editPermision = hasEditPermission(currentPath, rolePermisionsData?.permissions)
    let deletePermission = hasDeletePermission(currentPath, rolePermisionsData?.permissions)
    let createPermision = hasCreatePermission(currentPath, rolePermisionsData?.permissions)
    let viewPermision = hasViewPermission(currentPath, rolePermisionsData?.permissions)
    setPermissions({
      edit: editPermision,
      delete: deletePermission,
      create: createPermision,
      view: viewPermision,
    })
  }, [])



  const [formData, setFormData] = useState({
    profile_name: "",
    modules: [
      {
        module_name: "Report",
        permission_create: true,
        permission_update: false,
        permission_view: true,
        permission_delete: false,
      },
      {
        module_name: "Master",
        permission_create: false,
        permission_update: true,
        permission_view: false,
        permission_delete: true,
      },
    ],
  });
  const [viewData, setViewData] = useState([])
  const [profile, setProfile] = useState([]);

  const [message, setMessage] = useState({
    open: false,
    text: "",
    type: "",
  });
  const handleUpdateClick = (id) => {
    setSelectedId(id);
    getPermissionsByProfileId(id)
    setUpdateModal(true);
  };
  const handleUpdateProfileClick = (id) => {
    setSelectedId(id);
    getPermissionsByProfileId(id);
    setUpdateProfileModal(true);
  };


  const handleCloseUpdate = () => {
    setUpdateModal(false);
    setUpdateProfileModal(false);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form data:", formData);
    // You can handle form submission logic here
  };

  const handleClose = () => {
    try {
      setOpen(false);
    } catch (error) {
      console.error("Error in handleClose:", error);
    }
  };

  const getProfiles = async () => {
    try {
      const response = await genericGet({ url: GET_PROFILE });
      if (response.status == 200) {
        setProfile(response.data.companyProfiles);
      }
    } catch (error) {
    } finally {
    }
  };

  const handleViewOpen = (id) => {
    getPermissionsByProfileId(id)
    setViewModal(true)
  }
  const getPermissionsByProfileId = async (id) => {
    try {
      const response = await genericGet({ url: `${GET_PERMISSIONS}/${id}` });
      if (response.status == 200) {
        setViewData(response.data.profile);
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getProfiles();
  }, []);


  const handleDelete = async (profileId) => {
    setLoading(true);
    const authToken = sessionStorage.getItem('accessToken');

    try {
      const response = await axios.delete(DELETE_PROFILE(profileId), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        setMessage({
          open: true,
          text: "deleted successfully",
          type: "success",
        });
        getProfiles();
        setDeleteAlert(false);

      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      if (error.response) {
        if (error.response.status === 409) {
          setMessage({
            open: true,
            text: error.response.data?.error || "Cannot delete Data. Related Shipment exists.",
            type: "error",
          });
        } else {
          setMessage({
            open: true,
            text: "An error occurred while deleting the Data.",
            type: "error",
          });
        }
      } else {
        console.error("Error deleting Data", error);
        setMessage({
          open: true,
          text: "Network error. Please try again later.",
          type: "error",
        });
      }
    }
  };

  console.log(viewData)
  const defaultView = 'profiles';

  const mainContent = (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          position: "sticky",
          alignItems: "center"
        }}
      >
        <Grid item xs={6} md={6}>
          <Typography
            sx={{ fontWeight: 600, color: "#212529", fontSize: "18px" }}
          >
            Profiles
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          {permission?.create &&
            <CustomButton
              title="Add Profile"
              size="small"
              variant="contained"
              onClick={() => setOpen(true)} />
          }

        </Grid>
      </Grid>
      <Divider />

      <Box sx={{ pt: 2 }}>
        <Box sx={{ overflowX: "auto", overflowY: "auto" }}>
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow sx={{ height: "40px", backgroundColor: "#e7f0f7" }}>
                <TableCell rowSpan={2}>Profile Name</TableCell>
                <TableCell rowSpan={2}>Profile Description</TableCell>
                <TableCell rowSpan={2}>Created By</TableCell>
                <TableCell rowSpan={2}>Permission</TableCell>
                <TableCell rowSpan={2}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {profile &&
                profile.map((v) => (
                  <TableRow sx={{ borderBottom: "1px solid #e0e0e0" }}>
                    <TableCell>{v?.profileName}</TableCell>
                    <TableCell>{v?.profileDescription}</TableCell>
                    <TableCell>{formatDate(v?.createdAt)}</TableCell>
                    <TableCell >

                      {permission?.edit &&
                        <IconButton onClick={() => handleUpdateClick(v.profileId)}>
                          <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                      }
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleViewOpen(v.profileId)}>
                        <VisibilityIcon style={{ color: "#059212" }} />
                      </IconButton>

                      {permission?.edit &&
                        <IconButton onClick={() => handleUpdateProfileClick(v.profileId)}>
                          <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                      }
                      <IconButton
                        onClick={() => handleOpenDeleteAlert(v.profileId)}
                      >
                        <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Box>

      <CustomModal
        open={open}
        handleClose={handleClose}
      >
        <CreateCompanyModules
          handleClose={handleClose}
          setMessage={setMessage}
          getProfiles={getProfiles}
        />
      </CustomModal>
      <MessageBox
        open={message.open}
        onClose={() => setMessage({ ...message, open: false })}
        message={message.text}
        type={message.type}
      />

      <CustomModal
        open={viewModal}
        handleClose={() => setViewModal(false)}
      >

        <Grid container spacing={1} my={1}>
          <Grid item xs={12} sm={6}>
            {viewData && viewData[0]?.profile?.profileName}
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />

        <Box sx={{ pt: 2 }}>
          <Box sx={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow sx={{ height: '40px', backgroundColor: '#e7f0f7' }}>
                  <TableCell sx={{ ...styles.tableCell, width: '200px' }} rowSpan={2}>Module Name</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Create</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Update</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>View</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Delete</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Export</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {viewData.map((module, index) => (
                  <TableRow key={index} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                    <TableCell sx={styles.tableCell}>{module.companyModule?.moduleName}</TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="create"
                        checked={module.creates == 1 ? true : false}
                        disabled
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="update"
                        checked={module.updates == 1 ? true : false}
                        disabled
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="view"
                        checked={module.views == 1 ? true : false}
                        disabled
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="delete"
                        checked={module.deletes == 1 ? true : false}
                        disabled
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="export"
                        checked={module.export == 1 ? true : false}
                        disabled
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </CustomModal>

      <CustomModal
        open={updateProfileModal} handleClose={handleCloseUpdate}>
        <UpdateProfiles
          handleClose={handleCloseUpdate}
          profileId={selectedId}
          fetchData={getProfiles}
          setMessage={setMessage}
          viewData={viewData}
        />
      </CustomModal>
      <CustomModal
        open={updateModal} handleClose={handleCloseUpdate}>
        <UpdatePermission
          handleClose={handleCloseUpdate}
          profileId={selectedId}
          fetchData={getProfiles}
          setMessage={setMessage}
        />
      </CustomModal>
      <CustomDelete
        handleDelete={handleDelete}
        open={deleteAlert}
        rowId={rowId}
        handleClose={() => setDeleteAlert(false)}
      />
    </>

  );
  const getMainContent = (mainContent, permission) => {
    return permission?.view ? mainContent : null;
  };
  return (
    <>
      <CustomMaster
        defaultView={defaultView}
        mainContent={getMainContent(mainContent, permission)}
      />
      {!permission?.view && <AccessDenied />}
    </>
  );
};

export default Profiles;
const styles = {
  tableCell: {
    border: '1px solid #dee2e6',
    color: '#212529',
    fontWeight: 600,
    fontSize: '14px',
    textAlign: 'center',
    p: '4px',
  },
};