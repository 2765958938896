import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import { READ_BUSINESS_GROUP, UPDATE_BUSINESS_GROUP } from '../../../services/apiRoutes';
import CustomButton from '../../common/CustomButton';
import CustomTextField from '../../common/CustomTextField';
import CustomStyle from '../../common/CustomStyle';
import CustomAutocomplete from '../../common/CustomAutoComplete';

const ValidationSchema = Yup.object().shape({
    businessGroupName: Yup.string().required('business Group Name is required'),
    shortName: Yup.string().required('short Name is required'),
    colorId: Yup.string().required('color is required'),
});

const UpdateBusinessGroup = ({ handleClose, businessGroupId, setMessage, fetchData, colorList }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!businessGroupId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_BUSINESS_GROUP(businessGroupId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                setData(response.data.businessGroups);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [businessGroupId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_BUSINESS_GROUP(businessGroupId), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "Export updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                },);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    businessGroupName: data?.businessGroupName || '',
                    shortName: data?.shortName || '',
                    colorId: data?.colorId ? Number(data.colorId) : '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Business Group
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%", paddingTop: "5px" }}>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Business Group Name"
                                    name="businessGroupName"
                                    fieldLabel="Business Group Name"
                                    required
                                    value={values.businessGroupName}
                                    onChange={handleChange}
                                    error={touched.businessGroupName && !!errors.businessGroupName}
                                    helperText={touched.businessGroupName && errors.businessGroupName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Short Name"
                                    name="shortName"
                                    fieldLabel="Short Name"
                                    required
                                    value={values.shortName}
                                    onChange={handleChange}
                                    error={touched.shortName && !!errors.shortName}
                                    helperText={touched.shortName && errors.shortName}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    options={colorList}
                                    fieldLabel="Select Color"
                                    required
                                    getOptionLabel={(option) => `${option.colorName}`}
                                    renderOption={(props, option) => (
                                        <li {...props}
                                            style={{
                                                backgroundColor: option.backgroundColor,
                                                color: option.color,
                                                border: `1px solid ${option.color}`,
                                                display: 'inline-flex',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                whiteSpace: 'nowrap',
                                                borderRadius: '4px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                textTransform: "capitalize",
                                                height: "25px",
                                                padding: "2px 6px 2px 6px",
                                                zIndex: -1,
                                                marginLeft: "40px",
                                                marginRight: "40px",
                                                marginTop: "10px",
                                                marginBottom: "10px"

                                            }}>
                                            {`${option.colorName}`}
                                        </li>
                                    )}
                                    value={colorList.find(color => color.colorId === values.colorId) || null}
                                    onChange={(_, newValue) => {
                                        setFieldValue("colorId", newValue ? newValue.colorId : "");
                                    }}
                                    placeholder="Select color"
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateBusinessGroup;
