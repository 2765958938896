import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { Card, Typography, Divider, CircularProgress } from "@mui/material";
import CustomButton from "../../common/CustomButton";
import {
    ClearRounded,
    SaveRounded,
} from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { genericPost } from "../../../services/authServices";
import { Create_task, GET_SUPPLIERS_LIST } from "../../../services/apiRoutes";
import { getContactPersonList, getOwner } from "../../../services/CommonServices";
import CustomStyle from "../../common/CustomStyle";

const taskValidationSchema = Yup.object().shape({
    taskOwnerId: Yup.number().required('Task Owneris required'),
    assignToId: Yup.number().required('Assign To is required'),
    contactId: Yup.number().required('Contact is required'),
    clientCompanyId: Yup.number().required('Client Company is required'),
    taskName: Yup.string().required('Task Name is required'),
    subject: Yup.string().required('Subject is required'),
    dueDate: Yup.date().required('Due Date is required'),
    taskStatusId: Yup.number().required('Task Status is required'),
    priorityId: Yup.number().required('Priority is required'),
});

const CreateTasks = ({
    handleClose,
    fetchTaskData,
    setMessage,
    taskStatuses,
    priorities,
    data,
    leadId,
}) => {

    const initialValues = {
        taskOwnerId: data.userId,
        userId: data.userId,
        leadId: leadId,
        clientCompanyTrue: '',
        importShipmentId: '',
        exportShipmentId: '',
        assignToId: '',
        contactId: '',
        clientCompanyId: data.supplierId,
        taskName: '',
        subject: '',
        dueDate: '',
        taskStatusId: '',
        priorityId: '',
        description: '',
    };
    const [suppliersList, setSuppliersList] = useState([]);
    const [leadOwner, setLeadOwner] = useState([]);
    const [contactPersonList, setContactPersonList] = useState([]);
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await genericPost(Create_task, values);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "call added successfully",
                    type: "success",
                });
                fetchTaskData()
                setTimeout(() => {
                    handleClose()
                }, 3000)
                handleClose()
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };
    const getCommonData = async () => {
        const leadOwner = await getOwner()
        setLeadOwner(leadOwner);
        const contactPersonList = await getContactPersonList();
        setContactPersonList(contactPersonList);
    }

    const getSuppliersList = async () => {
        try {
            const payload = {
                clientCompanyId: data.clientCompanyId,
            };
            const response = await genericPost(GET_SUPPLIERS_LIST, payload, {
            });
            if (response.data && response.data.success && response.data.clientCompanies) {
                const suppliersData = response.data.clientCompanies.map((supplier) => ({
                    id: supplier.clientCompanyId,
                    title: supplier.clientCompanyName,
                }));
                setSuppliersList(suppliersData);
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching suppliers list:', error);
            return [];
        }
    };
    useEffect(() => {
        getSuppliersList();
        getCommonData();
    }, []);
    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={initialValues}
                validationSchema={taskValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={2}>
                                <Typography sx={{ fontWeight: 600, color: "#212529", fontSize: "18px" }}>
                                    Create Task
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={10} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box sx={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <SaveRounded />}
                                        title="Save"
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ px: "5%", paddingTop: "5px" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="taskOwnerId"
                                    required={true}
                                    fieldLabel="Task Owner"
                                    placeholder="Select"
                                    options={leadOwner}
                                    value={leadOwner.find((option) => option.id === values.taskOwnerId) || null}
                                    getOptionLabel={(option) => option.title}
                                    onChange={(e, newValue) => setFieldValue('taskOwnerId', newValue ? newValue.id : '')}
                                    error={touched.taskOwnerId && !!errors.taskOwnerId}
                                    helperText={touched.taskOwnerId && errors.taskOwnerId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={suppliersList}
                                    fieldLabel="Client Company"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={suppliersList.find((option) => option.id === values.clientCompanyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('clientCompanyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Client Company"
                                    error={touched.clientCompanyId && !!errors.clientCompanyId}
                                    helperText={touched.clientCompanyId && errors.clientCompanyId}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="assignToId"
                                    fieldLabel="Assign To"
                                    required={true}
                                    placeholder="Select"
                                    options={leadOwner}
                                    value={leadOwner.find((option) => option.id === values.assignToId) || null}
                                    getOptionLabel={(option) => option.title}
                                    onChange={(e, newValue) => setFieldValue('assignToId', newValue ? newValue.id : '')}
                                    error={touched.assignToId && !!errors.assignToId}
                                    helperText={touched.assignToId && errors.assignToId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="contactId"
                                    fieldLabel="Contact Person"
                                    required={true}
                                    options={contactPersonList}
                                    placeholder="Select"
                                    value={contactPersonList.find((option) => option.id === values.contactId) || null}
                                    getOptionLabel={(option) => option.title}
                                    onChange={(e, newValue) => setFieldValue('contactId', newValue ? newValue.id : '')}
                                    error={touched.contactId && !!errors.contactId}
                                    helperText={touched.contactId && errors.contactId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="taskName"
                                    fieldLabel="Task"
                                    required={true}
                                    value={values.taskName}
                                    onChange={handleChange}
                                    error={touched.taskName && !!errors.taskName}
                                    helperText={touched.taskName && errors.taskName}
                                    placeholder="Enter Task"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="subject"
                                    fieldLabel="Subject"
                                    required
                                    value={values.subject}
                                    onChange={handleChange}
                                    error={touched.subject && !!errors.subject}
                                    helperText={touched.subject && errors.subject}
                                    placeholder="Enter Subject"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="dueDate"
                                    fieldLabel="Due Date"
                                    type="date"
                                    required
                                    value={values.dueDate}
                                    onChange={handleChange}
                                    error={touched.dueDate && !!errors.dueDate}
                                    helperText={touched.dueDate && errors.dueDate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={taskStatuses}
                                    fieldLabel="Status"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={taskStatuses.find((option) => option.id === values.taskStatusId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('taskStatusId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Status"
                                    error={touched.taskStatusId && !!errors.taskStatusId}
                                    helperText={touched.taskStatusId && errors.taskStatusId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={priorities}
                                    fieldLabel="Priority"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={priorities.find((option) => option.id === values.priorityId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('priorityId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select"
                                    error={touched.priorityId && !!errors.priorityId}
                                    helperText={touched.priorityId && errors.priorityId}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <CustomTextarea
                                    sx={{ height: "100px !important" }}
                                    name="description"
                                    fieldLabel="Description"
                                    value={values.description}
                                    onChange={handleChange}
                                    placeholder="Enter Description"
                                    rows={3}
                                />
                            </Grid>


                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateTasks;

