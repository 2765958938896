import React, { useState, useEffect, useRef, useContext } from 'react';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import DetailsPageCompany from '../../common/DetailsPage/DetailsPageCompany';
import DetailPageClient from '../../common/DetailsPage/DetailPageClient';
import { TaskCardColumns, AppBarMenu, NotesCardColumns } from '../../../constants/DetailsPage'
import { Main } from '../../../constants/DetailsMain';
import { useNavigate, useParams } from 'react-router-dom';
import { genericPost } from '../../../services/authServices';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomModal from "../../../components/common/CustomModal";
import Createimportshipement from "../../../components/importshipement/createimportshipement";
import { Button, Grid, Box, CircularProgress, Stack, Avatar, CardHeader, IconButton, CardContent } from '@mui/material';
// import CustomTextField from "../../common/CustomTextField";
import CustomTextField from "../../common/CustomTextField";
import CustomButton from "../../common/CustomButton";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";

import Typography from '@mui/material/Typography';
import { Card, Divider, } from '@mui/material';
import { useShipmentDetails } from './useShipmentDetails';
import { CREATE_SHIPPING_TRACKER, LIST_SHIPPING_TRACKER } from '../../../services/apiRoutes';
import { getCommanImportShipmentId, getshippingStatus } from '../../../services/CommonServices';
import SailingOutlinedIcon from '@mui/icons-material/SailingOutlined';
import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import AirlineStopsOutlinedIcon from '@mui/icons-material/AirlineStopsOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';
import MessageBox from '../../common/CustomMessageBox';

const ValidationSchema = Yup.object().shape({
    date: Yup.string().required('date is required'),
    status: Yup.number().required('status is required'),
});

export default function Tracker() {
    const { token } = useContext(AuthContext)
    const [activeStep, setActiveStep] = React.useState(0);
    const [data, setData] = useState([]);
    const [shippingStatus, setshippingStatus] = useState([]);

    const { importShipmentId } = useParams();

    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(false);


    const [open, setOpen] = React.useState(true);
    const [leadModal, setLeadModal] = useState(false);

    const [activeTab, setActiveTab] = React.useState('Tracker');
    const navigate = useNavigate();
    const { shipmentDetails, loading: shipmentLoading } = useShipmentDetails(importShipmentId);

    let supplierId;

    const hasFetched = useRef(false);

    useEffect(() => {
        if (shipmentDetails) {
            supplierId = shipmentDetails.supplierId;
            fetchData();
            getCommonData();
            hasFetched.current = true;
        }
    }, [shipmentDetails, token]);

    const [formData] = useState({
        date: '',
        status: '',
        notes: '',
    });

    if (!shipmentDetails) {
        return <p>  <CustomLoader open={shipmentLoading} />  </p>;

    }

    const getCommonData = async () => {
        const shippingStatus = await getshippingStatus();
        setshippingStatus(shippingStatus);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };


    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false)
    }


    const companyName = shipmentDetails.supplier.clientCompanyName;
    const phoneNumber = shipmentDetails.supplier.mobile;
    const clientCompanyId = shipmentDetails.supplierId;
    const shipmentId = shipmentDetails.shipmentId;
    const shipmentOrgId = shipmentDetails.importShipmentId;

    const initial = shipmentDetails.supplier.clientCompanyName ? shipmentDetails.supplier.clientCompanyName.charAt(0).toUpperCase() : '';

    const handleBackClick = () => {
        navigate("/app/import/shipement");
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const payload = {
                clientCompanyId: clientCompanyId,
                date: values.date,
                status: values.status,
                notes: values.notes,
                shipmentId: shipmentId,
                shipmentOrgId: shipmentOrgId,
                commanShipmentId: getCommanImportShipmentId
            };

            const response = await genericPost(CREATE_SHIPPING_TRACKER, payload);

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Shipment Tracker successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
                resetForm();
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: clientCompanyId,
                shipmentId: shipmentId,
                commanShipmentId: getCommanImportShipmentId
            };
            const response = await genericPost(LIST_SHIPPING_TRACKER, payload);

            if (response.data.success && response.data.data) {
                const shippingMarkData = response.data.data.map((mark) => {
                    const matchingEvent = events.find(event => event.shippingTrackerID === mark.shippingStatus?.shippingStatusId);
                    return {
                        shippingTrackerID: mark.shippingTrackerID || '',
                        date: mark.date || '',
                        icon: matchingEvent ? matchingEvent.icon : null,
                        status: mark.shippingStatus?.title || '',
                        notes: mark.notes || '',
                    };
                });
                setData(shippingMarkData);
            } else {
                setError('No shipping marks found');
            }
        } catch (error) {
            setError('Error fetching shipping marks');
            console.error('Error fetching shipping marks', error);
        } finally {
            setLoading(false);
        }
    };

    const events = [
        {
            shippingTrackerID: 1,
            icon: <LocalShippingOutlinedIcon />

        },
        {
            shippingTrackerID: 2,
            icon: <SailingOutlinedIcon />

        },
        {
            shippingTrackerID: 3,
            icon: <DirectionsBoatOutlinedIcon />

        },
        {
            shippingTrackerID: 4,
            icon: <PlaceOutlinedIcon />

        },
        {
            shippingTrackerID: 5,
            icon: <PrecisionManufacturingOutlinedIcon />

        },
        {
            shippingTrackerID: 6,
            icon: <AirlineStopsOutlinedIcon />

        },
        {
            shippingTrackerID: 7,
            icon: <WarehouseOutlinedIcon />

        },
        {
            shippingTrackerID: 8,
            icon: <HourglassEmptyOutlinedIcon />

        },

    ];

    return (
        <>
            <div>


                {/* <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={AppBarMenu(importShipmentId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}

                />
                <Main open={open}> */}
                <CustomLoader open={loading} />

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Card variant="outlined" sx={{ paddingLeft: "10px", paddingRight: "10px" }}>
                            <Grid container spacing={1} sx={{ paddingBottom: "5px", paddingTop: "10px" }}>
                                <Grid item xs={12} md={12}>
                                    <Typography sx={{ color: "#212529", fontWeight: 500, fontSize: "20px" }}>
                                        Create Shipment Tracker
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />

                            <Box sx={{ mx: 'auto', mt: 0 }}>
                                <Formik
                                    initialValues={formData}
                                    validationSchema={ValidationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                                        <Form>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <CustomTextField
                                                        placeholder="Enter Date"
                                                        type="date"
                                                        name="date"
                                                        fieldLabel="Date"
                                                        required={true}
                                                        value={values.date}
                                                        onChange={handleChange}
                                                        error={touched.date && !!errors.date}
                                                        helperText={touched.date && errors.date}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <CustomAutocomplete
                                                        options={shippingStatus}
                                                        fieldLabel="Status"
                                                        required={true}
                                                        getOptionLabel={(option) => option.title}
                                                        value={shippingStatus.find((option) => option.id === values.status) || null}
                                                        onChange={(e, newValue) =>
                                                            setFieldValue('status', newValue ? newValue.id : '')
                                                        }
                                                        placeholder="Select Status"
                                                        error={touched.status && !!errors.status}
                                                        helperText={touched.status && errors.status}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <CustomTextarea
                                                        name="notes"
                                                        fieldLabel="Note"
                                                        value={values.notes}
                                                        onChange={handleChange}
                                                        sx={{ height: "150px !important" }}
                                                        placeholder="Write a Note.."
                                                    />
                                                </Grid>


                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center">
                                                    <CustomButton
                                                        startIcon={<SaveRounded />}
                                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                                        size="small"
                                                        variant="contained"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Card>

                    </Grid>
                    <Grid item xs={6}>
                        <Card variant="outlined" sx={{ paddingLeft: "10px", paddingRight: "10px" }}>
                            <Grid container spacing={1} sx={{ paddingBottom: "5px", paddingTop: "10px" }}>
                                <Grid item xs={12} md={12}>
                                    <Typography sx={{ color: "#212529", fontWeight: 500, fontSize: "20px" }}>
                                        Shipment Tracker
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />

                            {/* <div style={styles.timeline}>
                                    {data.map((event, index) => (
                                        <div style={styles.timelineItem} key={index}>
                                            <div style={styles.timelineTime}>{event.date}</div>
                                            <div style={styles.timelineIcon}>{event.icon}</div>
                                            <Box >
                                                <Stack >
                                                    <div style={verticalLine}></div>
                                                </Stack>
                                            </Box>
                                            <Card style={styles.timelineContent} variant='outlined'>
                                                <h6 style={styles.timelineContentTitle}>{event.status}</h6>
                                            </Card>
                                        </div>
                                    ))}
                                </div> */}
                            <Box sx={{ maxWidth: 400, pr: 5, pl: 5 }}>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {data.map((step, index) => (
                                        <Step key={step.label}>
                                            <StepLabel
                                                style={{ paddingLeft: "0px", fontSize: "25px" }}
                                                icon={step.icon}
                                                onClick={() => setActiveStep(index)}
                                            >

                                                <CardHeader
                                                    // sx={{ fontSize: "15px" }}
                                                    titleTypographyProps={{ fontSize: "15px" }}
                                                    subheaderTypographyProps={{ fontSize: "12px" }}

                                                    // title="Shrimp and Chorizo Paella"
                                                    // subheader="September 14, 2016"
                                                    title={step.status}
                                                    subheader={step.date}
                                                    sx={{ padding: "0" }}

                                                />

                                                {/* <Typography style={{ fontSize: "12px" }}>
                                                        {step.date}
                                                    </Typography>
                                                    <Typography style={{ fontSize: "15px" }}>
                                                        {step.status}
                                                    </Typography> */}
                                            </StepLabel>
                                            <StepContent style={{ marginTop: "-25px" }} sx={{ pt: 2 }}>
                                                <Typography style={{ fontSize: "12px", }}>
                                                    {step.notes}
                                                </Typography>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>

                        </Card>
                    </Grid>
                </Grid>

                {/* </Main> */}

            </div >
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
        </>
    );
}

const verticalLine = {
    borderLeft: '1px solid ',
    height: '50px',
    margin: '0 auto',
};

const styles = {
    timeline: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        margin: '0 auto',
        padding: '20px 0',
        maxWidth: '600px'
    },
    timelineItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'
    },
    timelineTime: {
        flex: '0 0 80px',
        textAlign: 'right',
        paddingRight: '20px',
        color: '#757575',
        fontSize: '10px'
    },
    timelineIcon: {
        flex: '0 0 40px',
        textAlign: 'center',
        fontSize: '24px',
        // paddingTop: "50px"

    },
    timelineContent: {
        flex: 1,
        paddingLeft: '20px',
        // backgroundColor: '#f5f5f5',
        borderRadius: '5px',
        padding: '5px'
    },
    timelineContentTitle: {
        margin: 0,
        fontSize: '14px',
        // color: '#212529'
    },
    timelineContentDescription: {
        margin: 0,
        color: '#757575'
    }
};
