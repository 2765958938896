import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
// export const CompanyMenu = [
//     { value: 1, menu: 'Overview', to: '/app/company/455' },
//     { value: 2, menu: 'Lead', to: '/app/company/lead' },
//     { value: 3, menu: 'Contacts', to: '/app/company/contacts' },
//     { value: 5, menu: 'Import Shipment', to: '/app/company/import' },
//     { value: 6, menu: 'Export Shipment', to: '/app/company/export' }
// ];

export const masterMenu = [
    {
        label: 'General',
        icon: <PersonOutlineOutlinedIcon />,
        subItems: [
            {
                label: 'Personal Settings',
                view: 'personalsettings',
                to: '/app/personalsettings',
            },
            {
                label: 'Company Settings',
                view: 'mycompany',
                to: '/app/mycompany',
            },
            {
                label: 'User Management',
                view: 'usermanagement',
                to: '/app/usermanagement',
            },
        ],
    },
    {
        label: 'Security Control',
        icon: <ManageAccountsOutlinedIcon />,
        subItems: [
            {
                label: 'Profiles',
                view: 'profiles',
                to: '/app/profiles',
            },
            {
                label: 'Roles And Sharing',
                view: 'roles',
                to: '/app/roles',
            },
            {
                label: 'System Setting',
                view: 'systemCompany',
                to: '/app/systemCompany',
            },
            {
                label: 'Term & Conditions',
                view: 'termConditions',
                to: '/app/termConditions',
            },
            // {
            //     label: 'Admin Users',
            //     view: 'adminusers',
            //     to: '/app/adminusers',
            // },
            // {
            //     label: 'Company Users',
            //     view: 'companyusers',
            //     to: '/app/companyusers',
            // },
            // {
            //     label: 'Security Controls',
            //     view: 'securitycontrols',
            //     to: '/app/securitycontrols',
            // },
        ],
    },
    {
        label: 'Master',
        icon: <DatasetOutlinedIcon />,
        subItems: [
            {
                label: 'Expense Ledger Group',
                view: 'expenseledgergroup',
                to: '/app/expenseledgergroup',
            },
            {
                label: 'Shipping Line Master',
                view: 'shippinglinemaster',
                to: '/app/shippinglinemaster',
            },
            {
                label: 'Freight Forward Master',
                view: 'freightforwardmaster',
                to: '/app/freightforwardmaster',
            },
            {
                label: 'Custom Clearing Agency',
                view: 'customclearingagency',
                to: '/app/customclearingagency',
            },
        ],
    },
    {
        label: 'Products',
        icon: <ProductionQuantityLimitsOutlinedIcon />,
        subItems: [
            {
                label: 'Products Group',
                view: 'productsgroup',
                to: '/app/productsgroup',
            },
            {
                label: 'Manage Products',
                view: 'manageproducts',
                to: '/app/manageproducts',
            },
            {
                label: 'Unit Master',
                view: 'unitmaster',
                to: '/app/unitmaster',
            },
        ],
    },
    {
        label: 'Business Master',
        icon: <BusinessOutlinedIcon />,
        subItems: [
            {
                label: 'Business Group',
                view: 'businessgroup',
                to: '/app/businessgroup',
            },
            {
                label: 'Contact Group',
                view: 'contactgroup',
                to: '/app/contactgroup',
            },
            {
                label: 'Contact Types',
                view: 'contacttypes',
                to: '/app/contacttypes',
            },

        ],
    },
    {
        label: 'Status Master',
        icon: <CorporateFareOutlinedIcon />,
        subItems: [
            {
                label: 'Task Statuses',
                view: 'taskstatuses',
                to: '/app/taskstatuses',
            },


            {
                label: 'Ratings',
                view: 'ratings',
                to: '/app/ratings',
            },
            {
                label: 'Lead Statuses',
                view: 'statusesleads',
                to: '/app/statusesleads',
            },
            {
                label: 'Lead Sources',
                view: 'sourceslead',
                to: '/app/sourceslead',
            },
            {
                label: 'Loss Reasons',
                view: 'lossreasons',
                to: '/app/lossreasons',
            },
            {
                label: 'Priorities',
                view: 'priorities',
                to: '/app/priorities',
            },
        ],
    },
    {
        label: 'Customization',
        icon: <SecurityOutlinedIcon />,
        subItems: [
            // {
            //     label: 'Module Settings',
            //     view: 'companymodules',
            //     to: '/app/companymodules',
            // },
            {
                label: 'Modules',
                view: 'modules',
                to: '/app/modules',
            },

            {
                label: 'Template',
                view: 'template',
                to: '/app/template',
            },
            {
                label: 'Color Plate',
                view: 'colorPlate',
                to: '/app/colorplate',
            },
        ],
    },
    {
        label: 'Subscription Plans',
        icon: <AccountBalanceOutlinedIcon />,
        subItems: [
            {
                label: 'Subscription Plans',
                view: 'subscription',
                to: '/app/subscription',
            },
            {
                label: 'Subscription History',
                view: 'subscriptionHistory',
                to: '/app/subscriptionHistory',
            },
        ],
    },
];

export const menuItems = [
    {
        label: 'Import Shipment',
        icon: <DirectionsBoatOutlinedIcon />,
        subItems: [
            {
                label: 'Import Shipment Report',
                view: 'importshipmentreport',
                to: '/app/reports/importshipmentreport',
            },
            {
                label: 'Free Days Report',
                view: 'freedaysreport',
                to: '/app/reports/freedaysreport'
            },
            {
                label: 'Batch no. wise Import ',
                view: 'batchnowiseimportshipmentreport',
                to: '/app/reports/batchnowiseimportshipmentreport'
            },
        ],
    },
    {
        label: 'Export Shipment',
        icon: <RocketLaunchOutlinedIcon />,
        subItems: [
            {
                label: 'Export Shipment Report',
                view: 'exportshipmentreport',
                to: '/app/reports/exportshipmentreport',
            },
            {
                label: 'Proform Inv. Report',
                view: 'proforminvreport',
                to: '/app/reports/proforminvreport',
            },
            {
                label: 'Sales Contract Report',
                view: 'salescontractreport',
                to: '/app/reports/salescontractreport',
            },
            {
                label: 'Commercial Inv. Register',
                view: 'commercialinvoiceregister',
                to: '/app/reports/commercialinvoiceregister',
            },
            {
                label: 'Batch no. wise Export',
                view: 'batchnowiseexportshipmentreport',
                to: '/app/reports/batchnowiseexportshipmentreport',
            },
        ],
    },
    {
        label: 'Ledger',
        icon: <AutoStoriesOutlinedIcon />,
        subItems: [
            {
                label: 'Customer Ledger',
                view: 'customerledger',
                to: '/app/reports/customerledger',
            },
            {
                label: 'Supplier Ledger',
                view: 'supplierledger',
                to: '/app/reports/supplierledger',
            },
        ],

    },
    {
        label: 'Register and Payment ',
        icon: <AccountBalanceOutlinedIcon />,

        subItems: [
            {
                label: 'Payment Register',
                view: 'paymentregister',
                to: '/app/reports/paymentregister',
            },
            {
                label: 'Receipt Register',
                view: 'receiptregister',
                to: '/app/reports/receiptregister',
            },
            {
                label: 'Import payment Report',
                view: 'importpaymentreport',
                to: '/app/reports/importpaymentreport',
            },
            {
                label: 'Export payment Report',
                view: 'exportpaymentreport',
                to: '/app/reports/exportpaymentreport',
            },
        ],

    },
    {
        label: 'Costing',
        icon: <AttachMoneyOutlinedIcon />,
        subItems: [
            {
                label: 'Shipment Costing Report',
                view: 'shipmentcostingreport',
                to: '/app/reports/shipmentcostingreport',
            },
            {
                label: 'Product wise Export',
                view: 'productwiseexportshipment',
                to: '/app/reports/productwiseexportshipment',
            },
            {
                label: 'Import shipment costing',
                view: 'importshipmentcostingreport',
                to: '/app/reports/importshipmentcostingreport',
            },
            {
                label: 'Export shipment costing',
                view: 'exportshipmentcostingreport',
                to: '/app/reports/exportshipmentcostingreport',
            },
        ],
    },
    {
        label: 'Tracking',
        icon: <EmojiTransportationOutlinedIcon />,
        subItems: [
            {
                label: 'Import Shipment Tracking',
                view: 'importshipmenttracking',
                to: '/app/reports/importshipmenttracking',
            },
            {
                label: 'Export Shipment Tracking',
                view: 'exportshipmenttracking',
                to: '/app/reports/exportshipmenttracking',
            },
        ],
    },
    {
        label: 'Report',
        icon: <StickyNote2OutlinedIcon />,
        subItems: [
            {
                label: 'B/L Report',
                view: 'blreport',
                to: '/app/reports/blreport',
            },
            {
                label: 'Product wise import Report',
                view: 'productwiseimportreport',
                to: '/app/reports/productwiseimportreport',
            },
            {
                label: 'Product wise export Report',
                view: 'productwiseexportreport',
                to: '/app/reports/productwiseexportreport',
            },
        ],
    }
];

export const costColorPlate = [
    { colorId: 1, backgroundColor: "#FFD7E9", color: "#41001d" },
    { colorId: 2, backgroundColor: "#f0ecff", color: "#0e0047" },
    { colorId: 3, backgroundColor: "#d4d0c4", color: "#464133" },
    { colorId: 4, backgroundColor: "#D9DFC6", color: "#576237" },
    { colorId: 5, backgroundColor: "#dce5e8", color: "#253439" },
    { colorId: 6, backgroundColor: "#F6D6D6", color: "#270909" },
    { colorId: 7, backgroundColor: "#ecdbf9", color: "#160523" },
    { colorId: 8, backgroundColor: "#e9ffe3", color: "#0a2d00" },
    { colorId: 9, backgroundColor: "#C4D7FF", color: "#00143d" },
    { colorId: 10, backgroundColor: "#dcd3dc", color: "#120e13" },
    { colorId: 11, backgroundColor: "#c8eff7", color: "#04191d" },
    { colorId: 12, backgroundColor: "#dfdbd1", color: "#13120d" },
    { colorId: 13, backgroundColor: "#b5c0a8", color: "#20251b" },
    { colorId: 14, backgroundColor: "#FFEEEE", color: "#2b0000" },
    { colorId: 15, backgroundColor: "#CFF6CF", color: "#072407" },
    { colorId: 16, backgroundColor: "#fcddf8", color: "#290425" },
    { colorId: 17, backgroundColor: "#dcdfff", color: "#000532" },
    { colorId: 18, backgroundColor: "#E5F9BD", color: "#263605" },
];

export const AppBarCompany = (clientCompanyId) => [
    { menu: 'Overview', to: `/app/company/${clientCompanyId}` },
    { value: 2, menu: 'Lead', to: `/app/company/lead/${clientCompanyId}` },
    { value: 3, menu: 'Contacts', to: `/app/company/contacts/${clientCompanyId}` },
    { value: 5, menu: 'Import Shipment', to: `/app/company/import/${clientCompanyId}` },
    { value: 6, menu: 'Export Shipment', to: `/app/company/export/${clientCompanyId}` }
];


export const AppBarMenu = (importShipmentId) => [
    { menu: 'Overview', to: `/app/import/shipement/${importShipmentId}` },
    { value: 2, menu: 'Purchase Order', to: `/app/import/purchaseorder/${importShipmentId}` },
    { value: 3, menu: 'Shipping Mark', to: `/app/import/shippingmark/${importShipmentId}` },
    { value: 4, menu: 'Booking', to: `/app/import/booking/${importShipmentId}` },
    { value: 5, menu: 'Tracker', to: `/app/import/tracker/${importShipmentId}` },
    { value: 6, menu: 'Payment', to: `/app/import/payment/${importShipmentId}` },
    { value: 7, menu: 'Expense', to: `/app/import/expense/${importShipmentId}` },
    { value: 8, menu: 'Receipt Voucher', to: `/app/import/receiptvoucher/${importShipmentId}` },
    { value: 9, menu: 'Proforma Invoice', to: `/app/import/proformainvoice/${importShipmentId}` },
    { value: 10, menu: 'Commercial Invoice', to: `/app/import/commercialinvoice/${importShipmentId}` },
    { value: 11, menu: 'Document', to: `/app/import/document/${importShipmentId}` }
];

export const ExportMenu = (exportShipmentId) => [
    { menu: 'Overview', to: `/app/export/shipement/${exportShipmentId}` },
    { value: 2, menu: 'Sales Order', to: `/app/export/salesorder/${exportShipmentId}` },
    { value: 3, menu: 'Shipping Mark', to: `/app/export/shippingmark/${exportShipmentId}` },
    { value: 4, menu: 'Proforma Invoice', to: `/app/export/proformainvoice/${exportShipmentId}` },
    { value: 5, menu: 'Sales Contract', to: `/app/export/salescontract/${exportShipmentId}` },
    { value: 6, menu: 'Commercial Invoice', to: `/app/export/commercialinvoice/${exportShipmentId}` },
    { value: 7, menu: 'Expense Voucher', to: `/app/export/expensevoucher/${exportShipmentId}` },
    { value: 8, menu: 'Receipt Voucher', to: `/app/export/receiptvoucher/${exportShipmentId}` },
    { value: 8, menu: 'Payment Voucher', to: `/app/export/payment/${exportShipmentId}` },
    { value: 9, menu: 'BL Draft', to: `/app/export/bldraft/${exportShipmentId}` },
    { value: 10, menu: 'COA', to: `/app/export/coa/${exportShipmentId}` },
    { value: 11, menu: 'COO', to: `/app/export/coo/${exportShipmentId}` },
    { value: 12, menu: 'Packing List', to: `/app/export/packinglist/${exportShipmentId}` },
    { value: 13, menu: 'Shipment Booking', to: `/app/export/shipmentbooking/${exportShipmentId}` },
    { value: 14, menu: 'Document', to: `/app/export/document/${exportShipmentId}` },


];



export const TaskCardColumns = [
    { id: 'sn', label: 'Sn', accessor: 'sn' },
    { id: 'taskOwner', label: 'Task Owner', accessor: 'taskOwner' },
    { id: 'assignTo', label: 'Assign To', accessor: 'assignTo' },
    { id: 'contactPerson', label: 'Contact Person', accessor: 'contactPerson' },
    { id: 'taskName', label: 'Task Name', accessor: 'taskName' },
    { id: 'subject', label: 'Subject', accessor: 'subject' },
    { id: 'dueDate', label: 'Due Date', accessor: 'dueDate' },
    { id: 'status', label: 'Status', accessor: 'status' },
    { id: 'priority', label: 'Priority', accessor: 'priority' },
    { id: 'description', label: 'Description', accessor: 'description' },
    { id: 'createdAt', label: 'Created At', accessor: 'createdAt' },
    { id: 'action', label: 'Action', accessor: 'action' },
];

export const CallCardColumns = [
    { id: 'sn', label: 'SN', accessor: 'sn' },
    { id: 'callTo', label: 'Call To', accessor: 'callTo' },
    { id: 'callType', label: 'Call Type', accessor: 'callType' },
    { id: 'subject', label: 'Subject', accessor: 'subject' },
    { id: 'details', label: 'Details', accessor: 'details' },
    { id: 'startTime', label: 'Start Time', accessor: 'startTime' },
    { id: 'duration', label: 'Duration', accessor: 'duration' },
    { id: 'purpose', label: 'Purpose', accessor: 'purpose' },
    { id: 'outcome', label: 'Outcome', accessor: 'outcome' },
    { id: 'description', label: 'Description', accessor: 'description' },
    { id: 'calledAt', label: 'Called At', accessor: 'calledAt' },
    { id: 'action', label: 'Action', accessor: 'action' },
];

export const NotesCardColumns = [
    { id: 'sn', label: 'SN', accessor: 'sn' },
    { id: 'notes', label: 'Notes', accessor: 'notes' },
];



export const ShippingBookingCardColumns = [
    // done 
    { id: 'sn', label: 'SN', accessor: 'sn' },
    { id: 'shipmentId', label: 'Shipment ID', accessor: 'shipmentId' },
    { id: 'lp', label: 'LP', accessor: 'lp' },
    { id: 'dp', label: 'DP', accessor: 'dp' },
    { id: 'etd', label: 'ETD', accessor: 'etd' },
    { id: 'eta', label: 'ETA', accessor: 'eta' },
    { id: 'cifFob', label: 'CIF/FOB', accessor: 'cifFob' },
    { id: 'destinationCharges', label: 'Destination Charges', accessor: 'destinationCharges' },
    { id: 'slc', label: 'SLC', accessor: 'slc' },
    { id: 'blCharges', label: 'BL Charges', accessor: 'blCharges' },
    { id: 'noOfContainers', label: 'No. of Containers', accessor: 'noOfContainers' },
    { id: 'shippingLine', label: 'Shipping Line', accessor: 'shippingLine' },
    { id: 'ffa', label: 'FFA', accessor: 'ffa' },
    { id: 'cca', label: 'CCA', accessor: 'cca' },
    { id: 'action', label: 'Action', accessor: 'action' },
];


export const PaymentVoucherCardColumns = [
    // done 
    { id: 'sn', label: 'SN', accessor: 'sn' },
    { id: 'date', label: 'Date', accessor: 'date' },
    { id: 'voucherNo', label: 'Voucher No.', accessor: 'voucherNo' },
    { id: 'supplierName', label: 'Supplier Name', accessor: 'supplierName' },
    { id: 'paymentType', label: 'Payment Type', accessor: 'paymentType' },
    { id: 'amount1', label: 'Amount', accessor: 'amount1' },
    { id: 'baseCurrency', label: 'Base Currency', accessor: 'baseCurrency' },
    { id: 'amount2', label: 'Amount', accessor: 'amount2' },
    { id: 'bankCharges', label: 'Bank Charges', accessor: 'bankCharges' },
    { id: 'action', label: 'Action', accessor: 'action' },
];


export const ExpenseVoucherCardColumns = [
    // done 
    { id: 'srNo', label: 'Sr. No.', accessor: 'srNo' },
    { id: 'date', label: 'Date', accessor: 'date' },
    { id: 'voucherNo', label: 'Voucher No.', accessor: 'voucherNo' },
    { id: 'expenseLedger', label: 'Expense Ledger', accessor: 'expenseLedger' },
    { id: 'amount1', label: 'Amount', accessor: 'amount1' },
    { id: 'baseCurrency', label: 'Base Currency', accessor: 'baseCurrency' },
    { id: 'amount2', label: 'Amount', accessor: 'amount2' },
    { id: 'action', label: 'Action', accessor: 'action' },
];

export const CompanyLeadColumn = [
    { id: 'srNo', label: 'SN', accessor: 'srNo' },
    { id: 'client', label: 'Client', accessor: 'client' },
    { id: 'mobile', label: 'Mobile', accessor: 'mobile' },
    { id: 'email', label: 'Email', accessor: 'email' },
    { id: 'state', label: 'State', accessor: 'state' },
    { id: 'city', label: 'City', accessor: 'city' },
    { id: 'gstin', label: 'GSTIN', accessor: 'gstin' },
    { id: 'contactPersons', label: 'Contact Persons', accessor: 'contactPersons' },
    { id: 'action', label: 'Action', accessor: 'action' }
];


export const ExportShipmentColumn = [
    { id: 'sn', label: 'SN', accessor: 'sn' },
    { id: 'performNo', label: 'Porform No.', accessor: 'performNo' },
    { id: 'date', label: 'Date', accessor: 'date' },
    { id: 'shippingId', label: 'Shipping Id', accessor: 'shippingId' },
    { id: 'customerName', label: 'Customer Name', accessor: 'customerName' },
    { id: 'amount', label: 'Amount', accessor: 'amount' },
    { id: 'action', label: 'Action', accessor: 'action' }
];


// Export Header

