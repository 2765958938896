import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import { ClearRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import { GET_call_id, READ_IMPORT_SHIPPING_BOOKING } from '../../../services/apiRoutes';
import CustomCardView from "../../common/CustomCardView";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const CallView = ({ handleClose, callId }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (!callId) return;

            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(GET_call_id(callId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
                setData(response.data.call);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [callId]);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    const labels = [
        { key: 'callType', label: "call Type", sm: 12, md: 4, },
        { key: 'callingType', label: "calling Type", sm: 12, md: 4, },
        { key: 'executive', label: "Call owner", sm: 12, md: 4, },
        { key: 'subject', label: "subject", sm: 12, md: 6, },
        { key: 'callStartTime', label: "call Start Time", sm: 12, md: 6, },
        { key: 'callDetails', label: "call Details", sm: 12, md: 6, },
        { key: 'callDuration', label: "call Duration", sm: 12, md: 6, },
        { key: 'callPurpose', label: "call Purpose", sm: 12, md: 6, },
        { key: 'callOutcome', label: "call Outcome", sm: 12, md: 6, },
        { key: 'stage', label: "stage", sm: 12, md: 6, },
        { key: 'priority', label: "priority", sm: 12, md: 6, },
        { key: 'leadStatus', label: "lead Status", sm: 12, md: 6, },
        { key: 'lossReason', label: "loss Reason", sm: 12, md: 6, },
        { key: 'description', label: "description", sm: 12, md: 12, },
    ];


    const values = {
        callId: data.callId,
        companyId: data.companyId,
        userId: data.userId,
        callingType: data.callingType,
        subject: data.subject,
        callType: data.callTypeList?.callName || '',
        callDetails: data.callDetails,
        callStartTime: data.callStartTime,
        callDuration: data.callDuration,
        executive: `${data.executive.firstName} ${data.executive.lastName}`,
        companyIdDetails: data.company.companyId,
        contactId: data.contact.contactId,
        callPurpose: data.callPurpose.callPurposeName,
        callOutcome: data.callOutcome.callOutcomeName,
        stage: data.stage.stageName,
        priority: data.priority.priorityName,
        leadStatus: data.leadStatus.leadStatusName,
        lossReason: data.lossReason.lossReasonName,
        description: data.description


    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={values}
                enableReinitialize
            >
                {() => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600 }} style={{ fontSize: "18px", paddingTop: "5px" }}>
                                    Call Details
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>

                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close">
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} onClick={handleClose} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item xs={12}>
                            <CustomCardView label={labels} data={values} />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CallView;
