import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Grid, Divider, Typography, FormLabel } from "@mui/material";
import CustomButton from "../common/CustomButton";
import { ClearRounded, SaveRounded, Replay10Rounded } from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { genericPost, genericGet } from "../../services/apiServices";
import { CreateMyCompany, GET_COMPANY_id, UPDATE_COMPANY, UPDATE_COMPANY_DETAIL } from "../../services/apiRoutes";
import MessageBox from "../common/CustomMessageBox";
import axios from "axios";
import CustomStyle from "../common/CustomStyle";
import CustomAutocomplete from "../common/CustomAutoComplete";
import { getCities, getCountries, getCurrencyList, getStates } from "../../services/CommonServices";

const ValidationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    mobile: Yup.string().required("mobile is required"),
    email: Yup.string().required("email required"),
    website: Yup.string().required("website required"),
    companyUrl: Yup.string().required("companyUrl required"),
    address: Yup.string().required("address required"),
    addressLineOne: Yup.string().required("addressLineOne required"),
    stateId: Yup.string().required("stateId required"),
    cityId: Yup.string().required("cityId required"),
    countryId: Yup.string().required("countryId required"),
    pincode: Yup.string().required("pincode required"),
    financialYearStart: Yup.string().required("financial Year Start required"),
    financialYearEnd: Yup.string().required("financial Year End required"),
    baseCurrency: Yup.string().required("baseCurrency required"),
});

const UpdateCompany = ({ handleClose, companyId, setMessage, fetchData, }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currencyList, setCurrencyList] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const countriesData = await getCountries();
                setCountries(countriesData || []);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        fetchCountries();
    }, []);
    const getCommonData = async () => {
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
        const getCountriesData = await getCountries();
        if (getCountriesData) {
            setCountries(getCountriesData);
        }
    }
    useEffect(() => {
        getCommonData();
    }, []);


    useEffect(() => {
        if (!companyId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(GET_COMPANY_id(companyId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                const companyData = response.data.company;
                setData(companyData);
                if (companyData?.country?.id) {
                    const stateData = await getStates({ id: companyData.country.id });
                    setStates(stateData || []);
                }
                if (companyData?.state?.id) {
                    const cityData = await getCities({ id: companyData.state.id });
                    setCities(cityData || []);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [companyId]);
    const handleChangeCountry = async (newValue, setFieldValue) => {
        const countryId = newValue?.id || "";
        setFieldValue("countryId", countryId);
        setFieldValue("stateId", "");
        setFieldValue("cityId", "");
        setStates([]);
        setCities([]);

        if (countryId) {
            try {
                const stateData = await getStates({ id: countryId });
                setStates(stateData || []);
            } catch (error) {
                console.error("Error fetching states:", error);
            }
        }
    };

    const handleChangeState = async (newValue, setFieldValue) => {
        const stateId = newValue?.id || "";
        setFieldValue("stateId", stateId);
        setFieldValue("cityId", "");
        setCities([]);

        if (stateId) {
            try {
                const cityData = await getCities({ id: stateId });
                setCities(cityData || []);
            } catch (error) {
                console.error("Error fetching cities:", error);
            }
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_COMPANY_DETAIL(companyId), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                },);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };

    const staticPayload = {
        companyName: data.companyName || "",
        mobile: data.mobile || "",
        email: data.email || "",
        website: data.website || "",
        companyUrl: data.companyUrl || "",
        telephone: data.telephone || "",
        fax: data.fax || "",
        address: data.address || "",
        addressLineOne: data.addressLineOne || "",
        addressLineTwo: data.addressLineTwo || "",
        countryId: data?.country?.id || "",
        stateId: data?.state?.id || "",
        cityId: data?.city?.id || "",
        pincode: data.pincode || "",
        gstin: data.gstin || "",
        fiscalYearBeginMonth: data.fiscalYearBeginMonth || "",
        financialYearStart: data.financialYearStart || "",
        financialYearEnd: data.financialYearEnd || "",
        baseCurrency: parseInt(data.baseCurrency || ""),
    };
    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={staticPayload}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={1}
                            style={CustomStyle.container}
                        >
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Company
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                            container
                            spacing={1}
                            style={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "5px" }}
                        >
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="companyName"
                                    fieldLabel="Company Name"
                                    value={values.companyName}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Company Name"
                                    error={touched.companyName && Boolean(errors.companyName)}
                                    helperText={touched.companyName && errors.companyName}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    type="number"
                                    name="mobile"
                                    fieldLabel="Mobile"
                                    value={values.mobile}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Mobile"
                                    error={touched.mobile && Boolean(errors.mobile)}
                                    helperText={touched.mobile && errors.mobile}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="email"
                                    type="email"
                                    fieldLabel="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Email"
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="website"
                                    type="url"
                                    fieldLabel="Website"
                                    value={values.website}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Website"
                                    error={touched.website && Boolean(errors.website)}
                                    helperText={touched.website && errors.website}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="companyUrl"
                                    required={true}
                                    fieldLabel="Company URL"
                                    value={values.companyUrl}
                                    onChange={handleChange}
                                    placeholder="Enter Company URL"
                                    error={touched.companyUrl && Boolean(errors.companyUrl)}
                                    helperText={touched.companyUrl && errors.companyUrl}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="telephone"
                                    type="number"
                                    fieldLabel="Telephone"
                                    value={values.telephone}
                                    onChange={handleChange}
                                    placeholder="Enter Telephone"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="fax"
                                    fieldLabel="Fax"
                                    value={values.fax}
                                    onChange={handleChange}
                                    placeholder="Enter Fax"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.baseCurrency) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('baseCurrency', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Currency"
                                    error={touched.baseCurrency && !!errors.baseCurrency}
                                    helperText={touched.baseCurrency && errors.baseCurrency}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="address"
                                    fieldLabel="Address"
                                    value={values.address}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Address"
                                    error={touched.address && Boolean(errors.address)}
                                    helperText={touched.address && errors.address}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="addressLineOne"
                                    fieldLabel="Address Line One"
                                    value={values.addressLineOne}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Address Line One"
                                    error={touched.addressLineOne && Boolean(errors.addressLineOne)}
                                    helperText={touched.addressLineOne && errors.addressLineOne}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="addressLineTwo"
                                    fieldLabel="Address Line Two"
                                    value={values.addressLineTwo}
                                    onChange={handleChange}
                                    placeholder="Enter Address Line Two"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={countries}
                                    fieldLabel="Country"
                                    required
                                    getOptionLabel={(option) => option?.title || ""}
                                    onChange={(e, newValue) => handleChangeCountry(newValue, setFieldValue)}
                                    value={countries.find((c) => c.id === values.countryId) || null}
                                    placeholder="Select Country"
                                    error={touched.countryId && Boolean(errors.countryId)}
                                    helperText={touched.countryId && errors.countryId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={states}
                                    fieldLabel="State"
                                    required
                                    getOptionLabel={(option) => option?.title || ""}
                                    onChange={(e, newValue) => handleChangeState(newValue, setFieldValue)}
                                    value={states.find((s) => s.id === values.stateId) || null}
                                    placeholder="Select State"
                                    error={touched.stateId && Boolean(errors.stateId)}
                                    helperText={touched.stateId && errors.stateId}
                                    disabled={!values.countryId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={cities}
                                    fieldLabel="City"
                                    required
                                    getOptionLabel={(option) => option?.title || ""}
                                    onChange={(e, newValue) => setFieldValue("cityId", newValue?.id || "")}
                                    value={cities.find((c) => c.id === values.cityId) || null}
                                    placeholder="Select City"
                                    error={touched.cityId && Boolean(errors.cityId)}
                                    helperText={touched.cityId && errors.cityId}
                                    disabled={!values.stateId}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    type="number"
                                    name="pincode"
                                    fieldLabel="Pincode"
                                    value={values.pincode}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Pincode"
                                    error={touched.pincode && Boolean(errors.pincode)}
                                    helperText={touched.pincode && errors.pincode}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="gstin"
                                    fieldLabel="GSTIN"
                                    value={values.gstin}
                                    onChange={handleChange}
                                    placeholder="Enter GSTIN"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    type="month"
                                    name="fiscalYearBeginMonth"
                                    fieldLabel="Fiscal Year Begin Month"
                                    value={values.fiscalYearBeginMonth}
                                    required={true}
                                    onChange={handleChange}
                                    placeholder="Enter Fiscal Year Begin Month"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    type="date"
                                    name="financialYearStart"
                                    fieldLabel="Financial Year Start"
                                    value={values.financialYearStart}
                                    onChange={handleChange}
                                    placeholder="Enter financial Year Start"
                                    error={touched.financialYearStart && !!errors.financialYearStart}
                                    helperText={touched.financialYearStart && errors.financialYearStart}
                                    required={true}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    type="date"
                                    name="financialYearEnd"
                                    fieldLabel="Financial Year End"
                                    value={values.financialYearEnd}
                                    onChange={handleChange}
                                    placeholder="Enter financialYearEnd"
                                    error={touched.financialYearEnd && !!errors.financialYearEnd}
                                    helperText={touched.financialYearEnd && errors.financialYearEnd}
                                    required={true}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Box>
                                    <FormLabel style={fieldLabelstyle}>
                                        {"Company Logo"}
                                    </FormLabel>
                                    <input
                                        type="file"
                                        name="companyLogo"
                                        onChange={(e) => {
                                            let file = e.target.files[0];
                                            setFieldValue("companyLogo", file);
                                            // convertFileToBase64(file, (base64String) => { });
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Box>
                                    <FormLabel style={fieldLabelstyle}>
                                        {"Company Footer"}
                                    </FormLabel>
                                    <input
                                        type="file"
                                        name="companyFooter"
                                        onChange={(e) => {
                                            let file = e.target.files[0];
                                            setFieldValue("companyFooter", file);
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Box>
                                    <FormLabel style={fieldLabelstyle}>
                                        {"Company Sign Stamp"}
                                    </FormLabel>
                                    <input
                                        type="file"
                                        name="companySignStamp"
                                        onChange={(e) => {
                                            let file = e.target.files[0];
                                            setFieldValue("companySignStamp", file);
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateCompany;


const fieldLabelstyle = {
    color: "#212529",
    fontWeight: "600",
    fontSize: "14px",
};