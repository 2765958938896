import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import CustomLoader from "../common/CustomLoader";
import { GET_EXPORT_PAYMENT_REPORT } from "../../services/apiRoutes";
import { genericGet } from "../../services/apiServices";
import CustomReport from "../common/CustomReport";
const title = "Export Payment Report";

const ExportPaymentReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);



    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'date', header: 'Date', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'shipmentId', header: 'Shipment I’d', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'proformaInvoiceNo', header: 'PI No.', size: 100, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'supplierName', header: 'Customer Name', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'mailingCountry', header: 'Country', size: 100, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'amount', header: 'Advance Amount Paid', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'conversionRate', header: 'Conversion Rate', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'amountInBaseCurrency', header: 'Amount in Base Currency', size: 200, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'finalAmountPaid', header: 'Final Amount Paid', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'conversionRateFinal', header: 'Conversion Rate (Final)', size: 150 },
            { accessorKey: 'amountInBaseCurrencyFinal', header: 'Amount in Base Currency (Final)', size: 200, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'shipmentStatus', header: 'Shipment Status', size: 150 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton
                        // onClick={() => handleViewClick(row.original.sn)}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_EXPORT_PAYMENT_REPORT });
            if (response.data.success && response.data.paymentVoucher) {
                const paymentVoucherData = response.data.paymentVoucher.map((voucher, index) => ({
                    sn: voucher.paymentVoucherId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    date: voucher.date || '',
                    voucherNo: voucher.paymentVoucherNo || '',
                    shipmentId: voucher.shipmentId || '',
                    amount: voucher.amount || '',
                    supplierName: voucher.clientCompany?.clientCompanyName || '',
                    mailingCountry: voucher.clientCompany?.mailingCountry?.name || '',
                    paymentType: voucher.paymentType?.paymentName || '',
                    amount1: voucher.amount || '',
                    baseCurrency: voucher.baseCurrency?.currency || '',
                    amount2: voucher.amountInBaseCurrency || '',
                    bankCharges: voucher.bankCharges || '',
                    conversionRate: voucher.conversionRate ? parseFloat(voucher.conversionRate).toFixed(2) : '',
                    amountInBaseCurrency: voucher.amountInBaseCurrency || '',
                    proformaInvoiceNo: voucher.proformaInvoiceNo || '',
                }));
                setData(paymentVoucherData);
            } else {
                setError('No payment vouchers found');
            }
        } catch (error) {
            setError('Error fetching payment vouchers');
            console.error('Error fetching payment vouchers', error);
        } finally {
            setLoading(false);
        }
    };

    const handleViewClick = (importShipmentId) => {
        navigate(`/app/reports/exportpaymentreport/${importShipmentId}`);
    };
    useEffect(() => {
        fetchData()
    }, [token])

    const defaultView = 'exportpaymentreport';
    const mainContent = (
        <>
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
            />
        </>
    );


    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>

    );
};
export default ExportPaymentReport;
