import React, { useState } from 'react';
import axios from 'axios';
import { Box, Typography, Grid, CircularProgress, Divider, Input, Button } from '@mui/material';
import { Formik, Form } from 'formik';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import CustomButton from '../../common/CustomButton';
import CustomStyle from '../../common/CustomStyle';
import { PROFILE_IMAGE } from '../../../services/apiRoutes';

const UpdateProfileImage = ({ handleClose, fetchData, setMessage }) => {
    const [image, setImage] = useState(null);
    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        if (!image) {
            setMessage({ open: true, text: 'Please select an image.', type: 'error' });
            setSubmitting(false);
            return;
        }

        const formData = new FormData();
        formData.append('profileImage', image);
        const authToken = sessionStorage.getItem('accessToken');

        try {
            const response = await axios.post(PROFILE_IMAGE, formData, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200 && response.data.success) {
                setMessage({
                    open: true,
                    text: response.data.message || "Profile image updated successfully",
                    type: "success",
                });

                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 500);
            } else {
                setMessage({ open: true, text: "Something went wrong", type: "error" });
            }
        } catch (error) {
            console.error('Error:', error.response?.data || error);
            setMessage({ open: true, text: 'Image upload failed.', type: 'error' });
        }

        setSubmitting(false);
    };


    return (
        <Formik initialValues={{}} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
                <Form>
                    <Box sx={{ mx: 'auto', mt: 0 }}>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update User Profile
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Input style={{ marginTop: "10px" }} type="file" accept="image/*" onChange={handleImageChange} />
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default UpdateProfileImage;
