import React, { useState, useContext } from "react";
import {
  Box,
  Grid,
  Card,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  InputAdornment,
  IconButton
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomButton from "../common/CustomButton";
import CustomTextField from "../common/CustomTextField";
import { genericPost } from "../../services/authServices";
import { RESET_PASSWORD } from "../../services/apiRoutes";
import MessageBox from "../../components/common/CustomMessageBox";
import { useNavigate,useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export default function ResetPassword() {
  const [tabValue, setTabValue] = useState("reset");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [linkSent, setLinkSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState({
    open: false,
    text: "",
    type: "",
  });
  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const navigate = useNavigate();
  const {token} = useParams();

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(token)
    setSubmitting(true);
    const payload = {
        token:token,
        password: values.password,
    }
    try {
      const response = await genericPost(RESET_PASSWORD, payload);
      if (response.status == 200) {
        setMessage({
            open: true,
            text: "Password reset successfully",
            type: "success",
          });
        setTimeout(()=>{
          navigate('/login')
        },3000)
      } else {
        let errorMessage = response.error.message;
        setMessage({
          open: true,
          text: errorMessage || "Something went wrong",
          type: "error",
        });
        navigate('/login')

      }
    } catch (error) {
      setMessage({
        open: true,
        text: "Something went wrong",
        type: "error",
      });
      navigate('/login')

    } finally {
      // setSubmitting(false);
    }
  };
  const handleResetPassword = () => {
    console.log("reset password");
    setLinkSent(true);
  };
 

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 0,
        backgroundColor: "rgb(77, 184, 255)",
        height: "100vh",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={7}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              p: 2,
              borderRadius: "0px 40% 30px 0px",
              backgroundImage:
                "linear-gradient(to bottom right, #FFFFFF, #FFFFFF)",
              color: "black",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography
                gutterBottom
                style={{ color: "#1C6BFE", fontSize: "60px" }}
              >
                EXIM CRM
              </Typography>
              <img
                src="https://media.istockphoto.com/id/1281150061/vector/register-account-submit-access-login-password-username-internet-online-website-concept.jpg?s=612x612&w=0&k=20&c=9HWSuA9IaU4o-CK6fALBS5eaO1ubnsM08EOYwgbwGBo="
                alt="logo"
                style={{
                  width: "90%",
                  height: "auto",
                  maxWidth: "100%",
                  display: "block",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Card
            variant="outlined"
            style={{
              marginTop: "20%",
              marginRight: "20px",
              marginLeft: "10px",
            }}
          >
            <Box sx={{ textAlign: "center", p: 2, width: "100%" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="auth tabs"
              >
                <Tab
                  value="reset"
                  label="Reset Password"
                  sx={{ fontWeight: "600" }}
                />
              </Tabs>
              <Typography
                variant="h6"
                sx={{ fontSize: "25px", fontWeight: "500" }}
              >
                {forgotPassword ? "" : "Reset your account password"}
              </Typography>
              {tabValue == "reset" && (
                <Formik
                  initialValues={{ password: "", confirmPassword: "" }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Box sx={{ mt: 2, color: "#404040" }}>
                        <>
                        <Box sx={{ mb: 2 }}>
                            <Field
                              as={CustomTextField}
                              type={showPassword ? "text" : "password"}
                              name="password"
                              placeholder="Enter Password"
                              fullWidth
                              error={<ErrorMessage name="password" />}
                              helperText={<ErrorMessage name="password" />}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box sx={{ mb: 2 }}>
                            <Field
                           type={showConfirmPassword ? "text" : "password"}
                           as={CustomTextField}
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              fullWidth
                              error={<ErrorMessage name="confirmPassword" />}
                              helperText={<ErrorMessage name="confirmPassword" />}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <CustomButton
                            variant="contained"
                            color="secondary"
                            title="Reset Password"
                            type="submit"
                            disabled={isSubmitting}
                          />
                        </>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
      <MessageBox
        open={message.open}
        onClose={() => setMessage({ ...message, open: false })}
        message={message.text}
        type={message.type}
      />
    </Box>
  );
}
