import React, { useContext, useMemo, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { GET_SALES_CONTRACT_REPORT } from "../../services/apiRoutes";
import CustomLoader from "../common/CustomLoader";
import CustomReport from "../common/CustomReport";
const title = "Sales Contract Report";

const SalesContractReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'date', header: 'Date', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'shipmentId', header: 'Shipment ID', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'salesContractNo', header: 'Sales Contract', size: 50, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'customerName', header: 'Customer Name', size: 50, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'country', header: 'Country', size: 50, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'totalAmount', header: 'Amount', size: 50, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton >
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_SALES_CONTRACT_REPORT });
            if (response.data.success) {
                const salesContracts = response.data.data.map((contract, index) => {
                    const particulars = contract.particulars.map((particular) => ({
                        qty: particular.qty || 0,
                        rate: parseFloat(particular.rate) || 0,
                        amount: (particular.qty || 0) * (parseFloat(particular.rate) || 0),
                    }));
                    const totalAmount = particulars.reduce((total, { amount }) => total + amount, 0);
                    return {
                        salesContractId: contract.salesContractId || '',
                        index: (index + 1).toString().padStart(2, '0'),
                        userId: contract.userId || '',
                        companyId: contract.companyId || '',
                        shipmentId: contract.shipmentId || '',
                        salesContractNo: contract.salesContractNo || '',
                        customerName: contract.customer.clientCompanyName || '',
                        country: contract.customer.mailingCountry.name || '',
                        reference: contract.reference || '',
                        proformaInvoiceNo: contract.proformaInvoiceNo || '',
                        date: contract.date || '',
                        dueDate: contract.dueDate || '',
                        note: contract.note || '',
                        createdAt: contract.createdAt || '',
                        updatedAt: contract.updatedAt || '',
                        particulars,
                        totalAmount: (totalAmount || 0).toFixed(2),

                    };
                });

                setData(salesContracts);
            } else {
                setError('No sales contracts found');
            }
        } catch (error) {
            setError('Error fetching sales contracts');
            console.error('Error fetching sales contracts', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData()
    }, [token])

    const defaultView = 'salescontractreport';
    const mainContent = (
        <>
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
            />
        </>
    );

    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>
    );
};
export default SalesContractReport;
