import React, { useState } from "react";
import { Box, Grid, Typography, Divider, CircularProgress, Button } from "@mui/material";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomButton from "../../common/CustomButton";
import { ClearRounded, CloudUpload, SaveRounded } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";
import { genericPost } from "../../../services/authServices";
import { CREATE_ATTATCH_DOCUMENTS } from "../../../services/apiRoutes";
import { getCommanImportShipmentId } from "../../../services/CommonServices";
import axios from "axios";
import CustomStyle from "../../common/CustomStyle";

const ValidationSchema = Yup.object().shape({
    // uploadDocument: Yup.string().required('uploadDocument is required'),
    description: Yup.string().required('Description is required'),
    salesContractNo: Yup.string().when('documentId', (documentId, schema) =>
        Number(documentId) === 1 ? schema.required('Sales Contract No. is required') : schema
    ),
    blNo: Yup.string().when('documentId', (documentId, schema) =>
        Number(documentId) === 5 ? schema.required('BL No. is required') : schema
    ),
    boeNo: Yup.string().when('documentId', (documentId, schema) =>
        Number(documentId) === 6 ? schema.required('BOE No. is required') : schema
    ),
});


const CreateAttachDocument = ({ handleClose, clientCompanyId, shipmentId, fetchData, setMessage, documentName, shipmentOrgId }) => {

    // const handleSubmit = async (values, { setSubmitting }) => {
    //     try {
    //         const payload = {
    //             clientCompanyId: values.clientCompanyId,
    //             commonShipmentId: 0,
    //             description: values.description,
    //             shipmentId: values.shipmentId,
    //             documentId: values.documentId,
    //             uploadDocument: values.uploadDocument || "",
    //             salesContractNo: values.documentId === 1 ? values.salesContractNo : "",
    //             blNo: values.documentId === 5 ? values.blNo : "",
    //             boeNo: values.documentId === 6 ? values.boeNo : "",
    //         };


    //         const response = await genericPost(CREATE_ATTATCH_DOCUMENTS, payload);

    //         if (response.status === 201) {
    //             setMessage({
    //                 open: true,
    //                 text: response?.data?.message || "Document attached successfully",
    //                 type: "success",
    //             });
    //             setTimeout(() => {
    //                 fetchData();
    //                 handleClose();
    //             }, 1000);
    //         } else if (response.status === 409) {
    //             setMessage({
    //                 open: true,
    //                 text: response.message || 'Already exists',
    //                 type: 'error',
    //             });
    //         } else {
    //             alert('Something went wrong');
    //         }
    //     } catch (error) {
    //         alert('Something went wrong');
    //     } finally {
    //         setSubmitting(false);
    //     }
    // };

    const handleImageChange = (event, setFieldValue) => {
        const file = event.target.files[0];
        if (file) {
            setFieldValue("uploadDocument", file);
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const formData = new FormData();
            formData.append("clientCompanyId", values.clientCompanyId);
            formData.append("commonShipmentId", getCommanImportShipmentId);
            formData.append("shipmentOrgId", values.shipmentOrgId);
            formData.append("shipmentId", values.shipmentId);
            formData.append("documentId", values.documentId);
            formData.append("description", values.description);
            formData.append("uploadDocument", values.uploadDocument);
            formData.append("salesContractNo", values.documentId === 1 ? values.salesContractNo : "");
            formData.append("blNo", values.documentId === 5 ? values.blNo : "");
            formData.append("boeNo", values.documentId === 6 ? values.boeNo : "");

            // const response = await genericPost(CREATE_ATTATCH_DOCUMENTS, formData, {
            //     headers: { "Content-Type": "multipart/form-data" },
            // });
            const authToken = sessionStorage.getItem('accessToken');
            const response = await axios.post(CREATE_ATTATCH_DOCUMENTS, formData, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Document attached successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
            } else {
                setMessage({
                    open: true,
                    text: response.message || "Something went wrong",
                    type: "error",
                });
            }
        } catch (error) {
            alert("Something went wrong");
        } finally {
            setSubmitting(false);
        }
    };


    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    clientCompanyId: clientCompanyId,
                    commonShipmentId: getCommanImportShipmentId,
                    shipmentOrgId: shipmentOrgId,
                    shipmentId: shipmentId,
                    documentId: '',
                    description: '',
                    uploadDocument: '',
                    salesContractNo: '',
                    blNo: '',
                    boeNo: '',
                }}
                // validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={12} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Attach Document
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton startIcon={<SaveRounded />} title="Save and New" size="small" variant="contained" />
                                    <CustomButton startIcon={<ClearRounded />} title="Cancel" size="small" variant="contained" onClick={handleClose} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={documentName}
                                    fieldLabel="Document Name"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={documentName.find((option) => option.id === values.documentId) || null}
                                    onChange={(e, newValue) => {
                                        setFieldValue("documentId", newValue ? newValue.id : '');
                                        setFieldValue("salesContractNo", "");
                                        setFieldValue("blNo", "");
                                        setFieldValue("boeNo", "");
                                    }}
                                    placeholder="Select Document Name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    id="upload-document"
                                    onChange={(e) => handleImageChange(e, setFieldValue)}
                                />
                                <label htmlFor="upload-document">
                                    <Button
                                        component="span"
                                        variant="contained"
                                        startIcon={<CloudUpload />}
                                        fullWidth
                                        sx={{ mt: 3 }}
                                    >
                                        {values.uploadDocument ? values.uploadDocument.name : "Upload Document"}
                                    </Button>
                                </label>
                                {touched.uploadDocument && errors.uploadDocument && (
                                    <Typography variant="caption" color="error">
                                        {errors.uploadDocument}
                                    </Typography>
                                )}

                            </Grid>


                            {values.documentId === 1 && (
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        type="text"
                                        name="salesContractNo"
                                        fieldLabel="Sales Contract No."
                                        placeholder="Enter Sales Contract No."
                                        required
                                        value={values.salesContractNo}
                                        onChange={handleChange}
                                        error={touched.salesContractNo && Boolean(errors.salesContractNo)}
                                        helperText={touched.salesContractNo && errors.salesContractNo}
                                    />
                                </Grid>
                            )}

                            {values.documentId === 5 && (
                                <>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CustomTextField
                                            type="text"
                                            name="blNo"
                                            fieldLabel="BL No."
                                            placeholder="Enter BL No."
                                            required
                                            value={values.blNo}
                                            onChange={handleChange}
                                            error={touched.blNo && Boolean(errors.blNo)}
                                            helperText={touched.blNo && errors.blNo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <CustomTextField
                                            type="date"
                                            fieldLabel="On Board Date"
                                        />
                                    </Grid>
                                </>
                            )}

                            {values.documentId === 6 && (
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        type="text"
                                        name="boeNo"
                                        fieldLabel="BOE No."
                                        placeholder="Enter BOE No."
                                        required
                                        value={values.boeNo}
                                        onChange={handleChange}
                                        error={touched.boeNo && Boolean(errors.boeNo)}
                                        helperText={touched.boeNo && errors.boeNo}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={8}>
                                <CustomTextarea
                                    name="description"
                                    type="text"
                                    fieldLabel="Description"
                                    onChange={handleChange}
                                    value={values.description}
                                    placeholder="Write a Description"
                                    sx={{ height: '100px !important' }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateAttachDocument;

// const documentOptions = [
//     { id: 1, title: "Sales Contract" },
//     { id: 2, title: "Packing List" },
//     { id: 3, title: "COA" },
//     { id: 4, title: "COO" },
//     { id: 5, title: "BL" },
//     { id: 6, title: "BOE" }
// ];
