import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, Container, Grid, Switch, Typography } from '@mui/material';
import { Divider, styled } from '@mui/material';
import CustomButton from '../common/CustomButton';
import { Save } from '@mui/icons-material';
import CustomTextField from '../common/CustomTextField';
import { TextField, InputAdornment } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomAutocomplete from '../common/CustomAutoComplete';
import { CircularProgress } from '@mui/material';
import { SaveRounded } from '@mui/icons-material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { GET_COMPANY, UPDATE_COMPANY } from '../../services/apiRoutes';
import { genericGet } from '../../services/apiServices';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import MessageBox from '../common/CustomMessageBox';
import CustomMaster from '../common/CustomMaster';
import { useLocation } from "react-router-dom";
import { AccessDenied, hasCreatePermission, hasDeletePermission, hasEditPermission, hasViewPermission } from '../../services/CommonServices';


const SystemCompany = () => {
    const [companies, setCompanies] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const { token } = useContext(AuthContext);
    const [permission, setPermissions] = useState()
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })
    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        let rolePermisionsData = JSON.parse(sessionStorage.getItem('userData')) || []
        let editPermision = hasEditPermission(currentPath, rolePermisionsData?.permissions)
        let deletePermission = hasDeletePermission(currentPath, rolePermisionsData?.permissions)
        let createPermision = hasCreatePermission(currentPath, rolePermisionsData?.permissions)
        let viewPermision = hasViewPermission(currentPath, rolePermisionsData?.permissions)

        setPermissions({
            edit: editPermision,
            delete: deletePermission,
            create: createPermision,
            view: viewPermision,
        })
    }, [])
    const fetchCompanies = async () => {
        try {
            const response = await genericGet({ url: GET_COMPANY });
            const companyData = response?.data?.companies || [];
            const defaultCompanies = companyData.filter((company) => company.isDefault);

            const defaultCompany = defaultCompanies[0] || null;
            const getCompanyId = defaultCompany?.companyId || null;
            setCompanies(defaultCompanies);
            setCompanyId(getCompanyId);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    useEffect(() => {
        fetchCompanies();
    }, [token]);

    // const handleSubmit = async (values, { setSubmitting }) => {
    //     try {
    //         const accessToken = sessionStorage.getItem('accessToken');
    //         const formData = new FormData();
    //         Object.keys(values).forEach((key) => formData.append(key, values[key]));
    //         await axios.put(UPDATE_COMPANY(companyId), formData, {
    //             headers: {
    //                 Authorization: `Bearer ${accessToken}`,
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });

    //         setMessage({
    //             open: true,
    //             text: 'Successfully updated',
    //             type: 'success'
    //         });
    //         fetchCompanies();
    //     } catch (error) {
    //         console.error('Error updating company:', error);
    //         alert('Something went wrong');
    //     } finally {
    //         setSubmitting(false);
    //     }
    // };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_COMPANY(companyId), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchCompanies();
                },);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };


    const StyledButton = ({ isActive, onClick, children, activeColor, deactiveColor, style = {},
    }) => (
        <button
            type="submit"
            onClick={onClick}
            style={{
                ...style,
                backgroundColor: isActive ? activeColor : deactiveColor,
                color: '#fff',
                border: 'none',
                padding: '8px 16px',
                cursor: 'pointer',
            }}
        >
            {children}
        </button>
    );
    const defaultView = 'systemCompany';
    const mainContent = (
        <>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <Box >
                <Formik
                    initialValues={{
                        companyName: companies[0]?.companyName || '',
                        email: companies[0]?.email || '',
                        userId: companies[0]?.userId || '',
                        impShipmentId: companies[0]?.impShipmentId.toString() || '0',
                        expShipmentId: companies[0]?.expShipmentId.toString() || '0',

                        purchasePrefix: companies[0]?.purchasePrefix || '',
                        purchaseSuffix: companies[0]?.purchaseSuffix || '',
                        purchasePrefixActivity: companies[0]?.purchasePrefixActivity.toString() || '0',
                        purchaseSuffixActivity: companies[0]?.purchaseSuffixActivity.toString() || '0',
                        financialYearStart: companies[0]?.financialYearStart || '',
                        financialYearEnd: companies[0]?.financialYearEnd || '',

                        expSalesPrefix: companies[0]?.expSalesPrefix || '',
                        expSalesSuffix: companies[0]?.expSalesSuffix || '',
                        expSalesPrefixActivity: companies[0]?.expSalesPrefixActivity.toString() || '0',
                        expSalesSuffixActivity: companies[0]?.expSalesSuffixActivity.toString() || '0',

                        expProformaPrefix: companies[0]?.expProformaPrefix || '',
                        expProformaSuffix: companies[0]?.expProformaSuffix || '',
                        expProformaPrefixActivity: companies[0]?.expProformaPrefixActivity.toString() || '0',
                        expProformaSuffixActivity: companies[0]?.expProformaSuffixActivity.toString() || '0',

                        expContractPrefix: companies[0]?.expContractPrefix || '',
                        expContractSuffix: companies[0]?.expContractSuffix || '',
                        expContractPrefixActivity: companies[0]?.expContractPrefixActivity.toString() || '0',
                        expContractSuffixActivity: companies[0]?.expContractSuffixActivity.toString() || '0',

                        expCommercialPrefix: companies[0]?.expCommercialPrefix || '',
                        expCommercialSuffix: companies[0]?.expCommercialSuffix || '',
                        expCommercialPrefixActivity: companies[0]?.expCommercialPrefixActivity.toString() || '0',
                        expCommercialSuffixActivity: companies[0]?.expCommercialSuffixActivity.toString() || '0',
                    }}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ isSubmitting, errors, touched, handleChange, values, setFieldValue }) => (
                        <Form>
                            <div>
                                <Accordion style={{ boxShadow: 'none' }} sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={{ fontWeight: "600", fontSize: "14px" }}>
                                        Shipment ID Setting
                                    </AccordionSummary>
                                    <AccordionDetails style={{ marginTop: "-10px" }} >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span>Import Shipment Id Manual</span>
                                                <Switch
                                                    checked={values.impShipmentId === '1'}
                                                    onChange={() => setFieldValue('impShipmentId', values.impShipmentId === '1' ? '0' : '1')}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <span>Export Shipment Id Manual</span>
                                                <Switch
                                                    checked={values.expShipmentId === '1'}
                                                    onChange={() => setFieldValue('expShipmentId', values.expShipmentId === '1' ? '0' : '1')}
                                                />
                                            </Grid>
                                        </Grid>

                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ boxShadow: 'none', marginTop: "5px" }} sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={{ fontWeight: "600", fontSize: "14px" }}>
                                        Import Shipment Prefix and suffix Setting
                                    </AccordionSummary>
                                    <AccordionDetails style={{ marginTop: "-10px" }} >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={4}>
                                                Purchase Order
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                    <Box>Prefix</Box>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                    <Box>Suffix</Box>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div>
                                                    <div style={styles.container}>
                                                        <Box>
                                                            <CustomTextField
                                                                placeholder="Enter Prefix"
                                                                name="purchasePrefix"
                                                                value={values.purchasePrefix}
                                                                onChange={handleChange}
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                paddingLeft: "10px"
                                                            }}
                                                        >
                                                            <StyledButton
                                                                isActive={values.purchasePrefixActivity === '1'}
                                                                onClick={() => handleChange({ target: { name: 'purchasePrefixActivity', value: '1' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "4px 0 0 4px" }}
                                                            >
                                                                yes
                                                            </StyledButton>
                                                            <StyledButton
                                                                isActive={values.purchasePrefixActivity === '0'}
                                                                onClick={() => handleChange({ target: { name: 'purchasePrefixActivity', value: '0' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "0px 4px 4px 0px" }}
                                                            >
                                                                No
                                                            </StyledButton>
                                                        </Box>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={styles.container}>
                                                        <Box>
                                                            <CustomTextField
                                                                placeholder="Enter Suffix"
                                                                name="purchaseSuffix"
                                                                value={values.purchaseSuffix}
                                                                onChange={handleChange}
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                paddingLeft: "10px"
                                                            }}
                                                        >
                                                            <StyledButton
                                                                isActive={values.purchaseSuffixActivity === '1'}
                                                                onClick={() => handleChange({ target: { name: 'purchaseSuffixActivity', value: '1' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "4px 0 0 4px" }}
                                                            >
                                                                yes
                                                            </StyledButton>
                                                            <StyledButton
                                                                isActive={values.purchaseSuffixActivity === '0'}
                                                                onClick={() => handleChange({ target: { name: 'purchaseSuffixActivity', value: '0' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "0px 4px 4px 0px" }}
                                                            >
                                                                No
                                                            </StyledButton>
                                                        </Box>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ boxShadow: 'none', marginTop: "5px" }} sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={{ fontWeight: "600", fontSize: "14px" }}>
                                        Export Shipment Prefix and suffix Setting
                                    </AccordionSummary>
                                    <AccordionDetails style={{ marginTop: "-10px" }} >
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={4}>
                                                Sales Order
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                    <Box>Prefix</Box>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                    <Box>Suffix</Box>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div>
                                                    <div style={styles.container}>
                                                        <Box>
                                                            <CustomTextField
                                                                placeholder="Enter Prefix"
                                                                name="expSalesPrefix"
                                                                value={values.expSalesPrefix}
                                                                onChange={handleChange}
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                paddingLeft: "10px"
                                                            }}
                                                        >
                                                            <StyledButton
                                                                isActive={values.expSalesPrefixActivity === '1'}
                                                                onClick={() => handleChange({ target: { name: 'expSalesPrefixActivity', value: '1' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "4px 0 0 4px" }}
                                                            >
                                                                yes
                                                            </StyledButton>
                                                            <StyledButton
                                                                isActive={values.expSalesPrefixActivity === '0'}
                                                                onClick={() => handleChange({ target: { name: 'expSalesPrefixActivity', value: '0' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "0px 4px 4px 0px" }}
                                                            >
                                                                No
                                                            </StyledButton>
                                                        </Box>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={styles.container}>
                                                        <Box>
                                                            <CustomTextField
                                                                placeholder="Enter Suffix"
                                                                name="expSalesSuffix"
                                                                value={values.expSalesSuffix}
                                                                onChange={handleChange}
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                paddingLeft: "10px"
                                                            }}
                                                        >
                                                            <StyledButton
                                                                isActive={values.expSalesSuffixActivity === '1'}
                                                                onClick={() => handleChange({ target: { name: 'expSalesSuffixActivity', value: '1' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "4px 0 0 4px" }}
                                                            >
                                                                yes
                                                            </StyledButton>
                                                            <StyledButton
                                                                isActive={values.expSalesSuffixActivity === '0'}
                                                                onClick={() => handleChange({ target: { name: 'expSalesSuffixActivity', value: '0' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "0px 4px 4px 0px" }}
                                                            >
                                                                No
                                                            </StyledButton>
                                                        </Box>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={4}>
                                                <div>Proforma Invoice</div>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                    <Box>Prefix</Box>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                    <Box>Suffix</Box>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div>
                                                    <div style={styles.container}>
                                                        <Box>
                                                            <CustomTextField
                                                                placeholder="Enter Prefix"
                                                                name="expProformaPrefix"
                                                                value={values.expProformaPrefix}
                                                                onChange={handleChange}
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                paddingLeft: "10px"
                                                            }}
                                                        >
                                                            <StyledButton
                                                                isActive={values.expProformaPrefixActivity === '1'}
                                                                onClick={() => handleChange({ target: { name: 'expProformaPrefixActivity', value: '1' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "4px 0 0 4px" }}
                                                            >
                                                                Yes
                                                            </StyledButton>
                                                            <StyledButton
                                                                isActive={values.expProformaPrefixActivity === '0'}
                                                                onClick={() => handleChange({ target: { name: 'expProformaPrefixActivity', value: '0' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "0px 4px 4px 0px" }}
                                                            >
                                                                No
                                                            </StyledButton>
                                                        </Box>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={styles.container}>
                                                        <Box>
                                                            <CustomTextField
                                                                placeholder="Enter Suffix"
                                                                name="expProformaSuffix"
                                                                value={values.expProformaSuffix}
                                                                onChange={handleChange}
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                paddingLeft: "10px"
                                                            }}
                                                        >
                                                            <StyledButton
                                                                isActive={values.expProformaSuffixActivity === '1'}
                                                                onClick={() => handleChange({ target: { name: 'expProformaSuffixActivity', value: '1' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "4px 0 0 4px" }}
                                                            >
                                                                Yes
                                                            </StyledButton>
                                                            <StyledButton
                                                                isActive={values.expProformaSuffixActivity === '0'}
                                                                onClick={() => handleChange({ target: { name: 'expProformaSuffixActivity', value: '0' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "0px 4px 4px 0px" }}
                                                            >
                                                                No
                                                            </StyledButton>
                                                        </Box>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div>Commercial Invoice</div>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                    <Box>Prefix</Box>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                    <Box>Suffix</Box>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <div>
                                                    <div style={styles.container}>
                                                        <Box>
                                                            <CustomTextField
                                                                placeholder="Enter  Prefix"
                                                                name="expCommercialPrefix"
                                                                value={values.expCommercialPrefix}
                                                                onChange={handleChange}
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                paddingLeft: "10px"
                                                            }}
                                                        >
                                                            <StyledButton
                                                                isActive={values.expCommercialPrefixActivity === '1'}
                                                                onClick={() => handleChange({ target: { name: 'expCommercialPrefixActivity', value: '1' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "4px 0 0 4px" }}
                                                            >
                                                                Yes
                                                            </StyledButton>
                                                            <StyledButton
                                                                isActive={values.expCommercialPrefixActivity === '0'}
                                                                onClick={() => handleChange({ target: { name: 'expCommercialPrefixActivity', value: '0' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "0px 4px 4px 0px" }}
                                                            >
                                                                No
                                                            </StyledButton>
                                                        </Box>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={styles.container}>
                                                        <Box>
                                                            <CustomTextField
                                                                placeholder="Enter  Suffix"
                                                                name="expCommercialSuffix"
                                                                value={values.expCommercialSuffix}
                                                                onChange={handleChange}
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                paddingLeft: "10px"
                                                            }}
                                                        >
                                                            <StyledButton
                                                                isActive={values.expCommercialSuffixActivity === '1'}
                                                                onClick={() => handleChange({ target: { name: 'expCommercialSuffixActivity', value: '1' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "4px 0 0 4px" }}
                                                            >
                                                                Yes
                                                            </StyledButton>
                                                            <StyledButton
                                                                isActive={values.expCommercialSuffixActivity === '0'}
                                                                onClick={() => handleChange({ target: { name: 'expCommercialSuffixActivity', value: '0' } })}
                                                                activeColor="#007BFF"
                                                                deactiveColor="#D50000"
                                                                style={{ borderRadius: "0px 4px 4px 0px" }}
                                                            >
                                                                No
                                                            </StyledButton>
                                                        </Box>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div>
                                <Card style={{ boxShadow: 'none', marginTop: "5px" }} sx={{ border: '1px solid #ccc', borderRadius: '4px', paddingLeft: "12px", paddingRight: "12px" }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={7}>
                                            <div>
                                                <h3 style={styles.heading}>Financial Year</h3>
                                                <div style={styles.container}>
                                                    <div style={styles.details}>
                                                        Current Period
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            gap: "5px",
                                                        }}
                                                    >
                                                        <CustomTextField
                                                            name="financialYearStart"
                                                            required={true}
                                                            type="date"
                                                            value={values.financialYearStart}
                                                            onChange={handleChange}
                                                            placeholder="Enter financialYearStart Date"
                                                            error={touched.financialYearStart && !!errors.financialYearStart}
                                                            helperText={touched.financialYearStart && errors.financialYearStart}
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                        <span >-</span>
                                                        <CustomTextField
                                                            name="financialYearEnd"
                                                            required={true}
                                                            type="date"
                                                            value={values.financialYearEnd}
                                                            onChange={handleChange}
                                                            placeholder="Enter financialYearEnd Date"
                                                            error={touched.financialYearEnd && !!errors.financialYearEnd}
                                                            helperText={touched.financialYearEnd && errors.financialYearEnd}
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <div>
                                                <h3 style={styles.heading}>Date Formate</h3>
                                                <div style={styles.container}>
                                                    <div style={styles.details}>
                                                        Date Formate
                                                    </div>
                                                    <div style={{ display: "flex", }}>
                                                        <CustomAutocomplete
                                                            options={dateFormats}
                                                            required
                                                            getOptionLabel={(option) => option.title}
                                                            placeholder="Select Date"
                                                            style={{ width: "200px" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <div>
                                                {/* <h3 style={styles.heading}>Language</h3> */}
                                                <div style={styles.container}>
                                                    <div style={styles.details}>
                                                        Language
                                                    </div>
                                                    <div style={{ display: "flex", }}>
                                                        <CustomAutocomplete
                                                            options={internationalLanguages}
                                                            required
                                                            getOptionLabel={(option) => option.title}
                                                            placeholder="Select Date"
                                                            style={{ width: "200px" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Card>
                                <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title='Save'
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                </CardActions>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
    const getMainContent = (mainContent, permission) => {
        return permission?.view ? mainContent : null;
    };
    return (

        <>
            <CustomMaster
                defaultView={defaultView}
                mainContent={getMainContent(mainContent, permission)}
            />
            {!permission?.view && <AccessDenied />}
        </>
    );
};

export default SystemCompany;
const dateFormats = [
    { id: 1, title: 'DD/MM/YYYY' },
    { id: 2, title: 'MM/DD/YYYY' },
    { id: 3, title: 'YYYY/MM/DD' },
];
const internationalLanguages = [
    { id: 1, country: 'India', title: 'Hindi', code: 'hi' },
    { id: 2, country: 'China', title: 'Mandarin Chinese', code: 'zh' },
    { id: 3, country: 'United Kingdom', title: 'English', code: 'en-GB' },
    { id: 4, country: 'Spain', title: 'Spanish', code: 'es' },
    { id: 5, country: 'France', title: 'French', code: 'fr' },
    { id: 6, country: 'Germany', title: 'German', code: 'de' },
    { id: 7, country: 'Russia', title: 'Russian', code: 'ru' },
    { id: 8, country: 'Japan', title: 'Japanese', code: 'ja' },
    { id: 9, country: 'Brazil', title: 'Portuguese', code: 'pt-BR' },
    { id: 10, country: 'Italy', title: 'Italian', code: 'it' },
];


const styles = {
    heading: {
        marginBottom: '1px',
        fontSize: '14px'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1px'
    },
    details: {
        flex: 1,
        marginRight: '1px'
    }
};
