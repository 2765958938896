import React, { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Grid, FormLabel, TextField } from "@mui/material";
import { Card, Typography, Divider } from "@mui/material";
import CustomButton from "../common/CustomButton";
import {
  ClearRounded,
  SaveRounded,
  Replay10Rounded,
} from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from "../common/CustomAutoComplete";
import CustomTextarea from "../common/CustomTextarea";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import { Creact_COMPANIES } from '../../services/apiRoutes';
import { genericPost } from "../../services/apiServices";
import { CreateMyCompany } from "../../services/apiRoutes";
import MessageBox from "../common/CustomMessageBox";
import { getStates, getCities, getCurrencyList } from "../../services/CommonServices";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../../context/AuthContext";

import { useNavigate } from 'react-router-dom';
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";

const CreateCompany = ({
  handleClose,
  stateOptions,
  cityOptions,
  countries,
  firstTimeLogin,
  fetchData,
  setMessage,
  handleSetDefault,
}) => {
  // const [message, setMessage] = useState({
  //   open: false,
  //   text: "",
  //   type: "",
  // });
  const [states, setStates] = useState([]);
  const [cities, setConutries] = useState([]);
  const { token } = useContext(AuthContext)
  const [formData, setFormData] = useState({
    companyName: "",
    mobile: "",
    email: "",
    website: "",
    companyUrl: "",
    defaultCompany: false,
    telephone: "",
    baseCurrency: "",
    fax: "",
    address: "",
    addressLineOne: "",
    addressLineTwo: "",
    stateId: '',
    cityId: '',
    countryId: "",
    pincode: "",
    gstin: "",
    fiscalYearBeginMonth: "",
    companyLogo: "",
    companyFooter: "",
    companySignStamp: "",
    financialYearStart: "",
    financialYearEnd: "",

  });


  const navigate = useNavigate()

  const companyValidationSchema = Yup.object({
    companyName: Yup.string().required("Company Name is required"),
    mobile: Yup.string().required("Mobile is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    website: Yup.string()
      .url("Invalid URL format")
      .required("Website is required"),
    companyUrl: Yup.string().required("Company URL is required"),
    defaultCompany: Yup.boolean(),
    address: Yup.string().required("Address is required"),
    addressLineOne: Yup.string().required("Address Line One is required"),
    countryId: Yup.number().required("Country is required"),
    stateId: Yup.number().required("State is required"),
    baseCurrency: Yup.number().required("base Currency is required"),
    cityId: Yup.number().required("City is required"),
    pincode: Yup.string().required("Pincode is required"),
    financialYearStart: Yup.string().required("financial Year Start required"),
    financialYearEnd: Yup.string().required("financial Year End required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (values[key] instanceof File) {
        formData.append(key, values[key]);
      } else {
        formData.append(key, values[key]);
      }
    });
    try {
      // const response = await genericPost(CreateMyCompany, values, token);
      const getAccessToken = () => sessionStorage.getItem('accessToken');
      const accessToken = getAccessToken();
      const response = await axios.post(CreateMyCompany, values, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': token ? `Bearer ${token}` : `Bearer ${accessToken}`
        },
      });
      if (response.status === 201) {
        console.log(response.data.data)
        if (firstTimeLogin) {
          handleSetDefault(response.data.newCompany.companyId, token)
        }
        setMessage({
          open: true,
          text: response.message || "Company created successfully",
          type: "success",
        });
        setTimeout(() => {
          fetchData();
          handleClose();
        },);
      } else {
        setMessage({
          open: true,
          text: response.error || "Something went wrong",
          type: "error",
        });
      }
    } catch (error) {
      setMessage({
        open: true,
        text: "Something went wrong",
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };


  // const handleSubmit = async (values) => {
  //   try {
  //     const getAccessToken = () => sessionStorage.getItem('accessToken');
  //     const accessToken = getAccessToken();
  //     const response = await axios.post(CreateMyCompany, values, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         'Authorization': token ? `Bearer ${token}` : `Bearer ${accessToken}`
  //       },
  //     });

  //     if (response.status === 201) {
  //       setMessage({
  //         open: true,
  //         text: response?.data?.message || "Added successfully",
  //         type: "success",
  //       });
  //       fetchData();
  //       setTimeout(() => {
  //         handleClose();
  //       }, 3000);
  //     }
  //   } catch (error) {
  //     alert("Something went wrong");
  //   }
  // };



  const [currencyList, setCurrencyList] = useState([]);

  const handleChangeCountry = async (newValue) => {
    let id = newValue?.id;
    const stateData = await getStates({ id });
    setStates(stateData);
  };
  const handleChangeState = async (newValue) => {
    let id = newValue.id;
    const stateData = await getCities({ id });
    setConutries(stateData);
  };

  const convertFileToBase64 = (file, callback) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      callback(base64String);
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };

    reader.readAsDataURL(file);
  };
  const getCommonData = async () => {
    const currencyListData = await getCurrencyList();
    setCurrencyList(currencyListData);
  }
  useEffect(() => {
    getCommonData();
  }, []);

  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={companyValidationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          isSubmitting,
          handleBlur
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} style={{ position: "sticky" }}>
              <Grid item xs={6} md={6}>
                <Typography
                  sx={{ fontWeight: 600 }}
                  style={{ color: "#212529", fontSize: "18px" }}
                >
                  Create Company
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box style={{ display: "flex" }}>
                  <CustomButton
                    startIcon={<SaveRounded />}
                    title={
                      isSubmitting ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Save"
                      )
                    }
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  />
                  <CustomButton
                    startIcon={<ClearRounded />}
                    title="Cancel"
                    size="small"
                    variant="contained"
                    onClick={handleClose}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              spacing={1}
              style={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "5px" }}
            >

              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="companyName"
                  fieldLabel="Company Name"
                  value={values.companyName}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Company Name"
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  id="outlined-number"
                  type="number"
                  name="mobile"
                  fieldLabel="Mobile"
                  value={values.mobile}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Mobile"
                  error={touched.mobile && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="email"
                  type="email"
                  fieldLabel="Email"
                  value={values.email}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Email"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="website"
                  type="url"
                  fieldLabel="Website"
                  value={values.website}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Website"
                  error={touched.website && Boolean(errors.website)}
                  helperText={touched.website && errors.website}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="companyUrl"
                  fieldLabel="Company URL"
                  value={values.companyUrl}
                  required={true}
                  onChange={handleChange}
                  placeholder="Enter Company URL"
                  error={touched.companyUrl && Boolean(errors.companyUrl)}
                  helperText={touched.companyUrl && errors.companyUrl}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="telephone"
                  type="number"
                  fieldLabel="Telephone"
                  value={values.telephone}
                  onChange={handleChange}
                  placeholder="Enter Telephone"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="fax"
                  fieldLabel="Fax"
                  value={values.fax}
                  onChange={handleChange}
                  placeholder="Enter Fax"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomAutocomplete
                  options={currencyList}
                  fieldLabel="Currency"
                  required={true}
                  getOptionLabel={(option) => option.title}
                  value={currencyList.find((option) => option.id === values.baseCurrency) || null}
                  onChange={(e, newValue) =>
                    setFieldValue('baseCurrency', newValue ? newValue.id : '')
                  }
                  placeholder="Select Currency"
                  error={touched.baseCurrency && !!errors.baseCurrency}
                  helperText={touched.baseCurrency && errors.baseCurrency}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="address"
                  fieldLabel="Address"
                  value={values.address}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Address"
                  error={touched.address && !!errors.address}
                  helperText={touched.address && errors.address}

                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="addressLineOne"
                  fieldLabel="Address Line One"
                  value={values.addressLineOne}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Address Line One"
                  error={touched.addressLineOne && !!errors.addressLineOne}
                  helperText={touched.addressLineOne && errors.addressLineOne}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="addressLineTwo"
                  fieldLabel="Address Line Two"
                  value={values.addressLineTwo}
                  onChange={handleChange}
                  placeholder="Enter Address Line Two"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomAutocomplete
                  options={countries}
                  fieldLabel="Country"
                  required={true}
                  getOptionLabel={(option) => option?.title}
                  onChange={(e, newValue) => {
                    handleChangeCountry(newValue);
                    handleChange({
                      target: { name: "countryId", value: newValue.id },
                    });
                  }}
                  placeholder="Select Country"
                  error={touched.countryId && !!errors.countryId}
                  helperText={touched.countryId && errors.countryId}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomAutocomplete
                  options={states}
                  fieldLabel="State"
                  required={true}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, newValue) => {
                    handleChangeState(newValue);

                    handleChange({
                      target: { name: "stateId", value: newValue.id },
                    });
                  }}
                  placeholder="Select State"
                  error={touched.stateId && !!errors.stateId}
                  helperText={touched.stateId && errors.stateId}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomAutocomplete
                  options={cities}
                  fieldLabel="City"
                  required={true}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, newValue) =>
                    handleChange({
                      target: { name: "cityId", value: newValue.id },
                    })
                  }
                  placeholder="Select City"
                  error={touched.cityId && !!errors.cityId}
                  helperText={touched.cityId && errors.cityId}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  type="number"
                  name="pincode"
                  fieldLabel="Pincode"
                  value={values.pincode}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Pincode"
                  error={touched.pincode && !!errors.pincode}
                  helperText={touched.pincode && errors.pincode}

                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="gstin"
                  fieldLabel="GSTIN"
                  value={values.gstin}
                  onChange={handleChange}
                  placeholder="Enter GSTIN"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  type="month"
                  name="fiscalYearBeginMonth"
                  fieldLabel="Fiscal Year Begin Month"
                  value={values.fiscalYearBeginMonth}
                  onChange={handleChange}
                  placeholder="Enter Fiscal Year Begin Month"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  type="date"
                  name="financialYearStart"
                  fieldLabel="Financial Year Start"
                  value={values.financialYearStart}
                  onChange={handleChange}
                  placeholder="Enter financial Year Start"
                  error={touched.financialYearStart && !!errors.financialYearStart}
                  helperText={touched.financialYearStart && errors.financialYearStart}
                  required={true}

                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  type="date"
                  name="financialYearEnd"
                  fieldLabel="Financial Year End"
                  value={values.financialYearEnd}
                  onChange={handleChange}
                  placeholder="Enter financialYearEnd"
                  error={touched.financialYearEnd && !!errors.financialYearEnd}
                  helperText={touched.financialYearEnd && errors.financialYearEnd}
                  required={true}

                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <FormLabel style={fieldLabelstyle}>
                    {"Company Logo"}
                  </FormLabel>
                  <input
                    type="file"
                    name="companyLogo"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      setFieldValue("companyLogo", file);

                      convertFileToBase64(file, (base64String) => { });
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <FormLabel style={fieldLabelstyle}>
                    {"Company Footer"}
                  </FormLabel>
                  <input
                    type="file"
                    name="companyFooter"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      setFieldValue("companyFooter", file);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <FormLabel style={fieldLabelstyle}>
                    {"Company Sign Stamp"}
                  </FormLabel>
                  <input
                    type="file"
                    name="companySignStamp"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      setFieldValue("companySignStamp", file);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* {message.open && (
              <MessageBox
                open={message.open}
                text={message.text}
                type={message.type}
                handleClose={() => setMessage({ ...message, open: false })}
              />
            )} */}
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreateCompany;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const fieldLabelstyle = {
  color: "#212529",
  fontWeight: "600",
  fontSize: "14px",
};