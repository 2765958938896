import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider, FormLabel } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import { UPDATE_IMPORT_SHIPPING_MARK, READ_IMPORT_SHIPPING_MARK, GET_NOTES_ID, UPDATE_NOTES } from '../../../services/apiRoutes';
import CustomStyle from "../../common/CustomStyle";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const ValidationSchema = Yup.object().shape({
    // batchNo: Yup.string().required('Batch Number is required'),
});


const UpdateNotes = ({ handleClose, setMessage, fetchData, notesId, }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const onEditorStateChange = (state) => {
        setEditorState(state);
    };
    const convertToHTMLContent = () => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);
        return htmlContent;
    };


    useEffect(() => {
        if (!notesId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(GET_NOTES_ID(notesId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const noteData = response?.data?.notes;
                setData(noteData);
                // Convert fetched HTML note to editor state
                const htmlContent = noteData?.note || "";
                const contentState = htmlContent ? convertFromHTML(htmlContent) : null;
                setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentState.contentBlocks)));
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [notesId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_NOTES(notesId), {
                ...values,
                note: convertToHTMLContent(),
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "updated successfully",
                    type: "success",
                });
                fetchData();
                setTimeout(() => {
                    handleClose();
                },);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    note: data?.note || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Notes
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>


                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>
                            <Grid item xs={12} md={12}>
                                <FormLabel sx={{ paddingBottom: "10px" }} style={fieldLabelstyle}>Notes</FormLabel>
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={onEditorStateChange}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    wrapperStyle={{
                                        border: '1px solid #CED4DA', borderRadius: '4px',
                                    }}
                                    editorStyle={{ padding: "10px" }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateNotes;
const fieldLabelstyle = {
    color: "#212529",
    fontWeight: "600",
    fontSize: "14px",
};