import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { genericGet } from "../../services/apiServices";
import { GET_PRODUCT_WISE_EXPORT } from "../../services/apiRoutes";
import { AuthContext } from "../../context/AuthContext";
import CustomLoader from "../common/CustomLoader";
import CustomReport from "../common/CustomReport";
const title = "Product Wise Export Report";

const ProductWiseExportReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: 'productName', header: 'Product Name', size: 20, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'formattedQty', header: 'Export Qty by Unit', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'formattedAlternative', header: 'Export Qty by Alternate Unit', size: 180, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton onClick={() => handleViewClick(row.original.particularName)}>
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );

    const handleViewClick = (particularName) => {
        navigate(`/app/reports/productwiseexportreport/${particularName}`);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_PRODUCT_WISE_EXPORT });
            if (response.status === 200 && response.data.success) {
                const productData = response.data.data.map((item, index) => ({
                    index: (index + 1).toString().padStart(2, '0'),
                    particularName: item.particularName,
                    productName: item.productName,
                    unitName: item.unitName,
                    totalQty: item.totalQty,
                    totalRate: item.totalRate,
                    totalAmount: item.totalAmount.toFixed(2),
                    totalUnit: item.totalUnit,
                    formattedQty: `${item.totalQty} / ${item.unitName}`,
                    formattedAlternative: `${item.primaryQty} / ${item.primaryUnit}`,
                }));

                setData(productData);
            } else {
                setError('No data available or unsuccessful response.');
            }
        } catch (error) {
            setError('Error fetching product data');
            console.error('Error fetching product data', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData()
    }, [token])

    const defaultView = 'productwiseexportreport';
    const mainContent = (
        <>
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
            />
        </>
    );
    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>

    );
};
export default ProductWiseExportReport;
