import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import { UPDATE_RECEIPT_VOUCHER_EXPORT, READ_RECEIPT_VOUCHER_EXPORT } from '../../../services/apiRoutes';
import CustomStyle from "../../common/CustomStyle";
import CustomPrefixSuffixField from "../../common/CustomPrefixSuffixField";

const ValidationSchema = Yup.object().shape({
    date: Yup.string().required('Date is required'),
    receiptVoucherNo: Yup.string().required('Receipt Voucher No is required'),
    customerName: Yup.string().required('Customer Name is required'),
    receiptTypeId: Yup.number().required('receipt Type Type is required'),
    amount: Yup.number().required('Amount is required'),
    currencyId: Yup.number().required('Currency is required'),
    bankCharges: Yup.number().required('Bank Charges are required'),
    baseCurrencyId: Yup.number().required('Base Currency is required'),
    conversionRate: Yup.number().required('Conversion Rate is required'),
    baseAmount: Yup.number().required('Base Amount is required'),
});


const UpdateReceiptVoucher = ({ handleClose, setMessage, fetchData, receiptVoucherId, currencyList, receiptType, customerName, customerList }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currencySymbol, setCurrencySymbol] = useState(null);
    const [baseCurrencySymbol, setBaseCurrencySymbol] = useState(null);


    useEffect(() => {
        if (!receiptVoucherId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_RECEIPT_VOUCHER_EXPORT(receiptVoucherId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const currencySymbol = response?.data?.receiptVoucher?.currency?.currency_symbol || '';
                const baseCurrencySym = response?.data?.receiptVoucher?.baseCurrency?.currency_symbol || '';
                setBaseCurrencySymbol(baseCurrencySym);
                setCurrencySymbol(currencySymbol);

                setData(response.data.receiptVoucher);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [receiptVoucherId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_RECEIPT_VOUCHER_EXPORT(receiptVoucherId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "updated successfully",
                    type: "success",
                });
                fetchData();
                setTimeout(() => {
                    handleClose();
                },);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    date: data?.date || '',
                    receiptVoucherNo: data?.receiptVoucherNo || '',
                    customerName: parseInt(data?.customerName || ''),
                    receiptTypeId: data?.receiptTypeId || '',
                    amount: data?.amount || '',
                    currencyId: data?.currencyId || '',
                    bankCharges: data?.bankCharges || '',
                    baseCurrencyId: data?.baseCurrencyId || '',
                    conversionRate: data?.conversionRate || '',
                    baseAmount: data?.baseAmount || '',
                    note: data?.note || '',

                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Receipt Voucher
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />

                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Divider />
                        <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "5px" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={customerList}
                                    placeholder="Customer Name"
                                    fieldLabel="Customer Name"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={customerList.find((option) => option.id === values.customerName) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('customerName', newValue ? newValue.id : '')
                                    }
                                    error={touched.customerName && !!errors.customerName}
                                    helperText={touched.customerName && errors.customerName}
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <CustomTextField
                                    type="date"
                                    name="date"
                                    fieldLabel="Date"
                                    value={values.date}
                                    required={true}
                                    onChange={handleChange}
                                    placeholder="mm/dd/yyyy"
                                    error={touched.date && !!errors.date}
                                    helperText={touched.date && errors.date}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <CustomTextField
                                    name="receiptVoucherNo"
                                    fieldLabel="Receipt Voucher No."
                                    value={values.receiptVoucherNo}
                                    required={true}
                                    onChange={handleChange}
                                    placeholder="Enter Receipt Voucher No."
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}
                                    error={touched.receiptVoucherNo && !!errors.receiptVoucherNo}
                                    helperText={touched.receiptVoucherNo && errors.receiptVoucherNo}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={receiptType}
                                    fieldLabel="Receipt Type"
                                    placeholder="Select Receipt Type"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={receiptType.find((option) => option.id === values.receiptTypeId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('receiptTypeId', newValue ? newValue.id : '')
                                    }
                                    error={touched.receiptTypeId && !!errors.receiptTypeId}
                                    helperText={touched.receiptTypeId && errors.receiptTypeId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    placeholder="Select Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.currencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('currencyId', newValue ? newValue.id : '')
                                    }
                                    fieldLabel="Currency"
                                    error={touched.currencyId && !!errors.currencyId}
                                    helperText={touched.currencyId && errors.currencyId}
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <CustomPrefixSuffixField
                                    prefix={currencySymbol}
                                    name="amount"
                                    fieldLabel="Amount"
                                    value={values.amount}
                                    required={true}
                                    type="number"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setFieldValue("amount", value);
                                        setFieldValue("baseAmount", value * values.conversionRate || 0);
                                    }}
                                    placeholder="Enter Amount"
                                    error={touched.amount && !!errors.amount}
                                    helperText={touched.amount && errors.amount}
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Base Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.baseCurrencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('baseCurrencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Base Currency"
                                    error={touched.baseCurrencyId && !!errors.baseCurrencyId}
                                    helperText={touched.baseCurrencyId && errors.baseCurrencyId}
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}

                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <CustomPrefixSuffixField
                                    prefix={baseCurrencySymbol}
                                    tagLine="(In Base)"
                                    name="conversionRate"
                                    fieldLabel="Conversion Rate"
                                    value={values.conversionRate}
                                    required={true}
                                    type="number"
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setFieldValue("conversionRate", value);
                                        setFieldValue("baseAmount", values.amount * value || 0);
                                    }}
                                    placeholder="Enter Conversion Rate"
                                    error={touched.conversionRate && !!errors.conversionRate}
                                    helperText={touched.conversionRate && errors.conversionRate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <CustomPrefixSuffixField
                                    prefix={baseCurrencySymbol}
                                    tagLine="(In Base Currency)"
                                    name="baseAmount"
                                    fieldLabel="Base Amount"
                                    value={values.baseAmount}
                                    required={true}
                                    type="number"
                                    onChange={handleChange}
                                    placeholder="Enter Base Amount"
                                    readOnly={true}
                                    sx={CustomStyle.readOnly}
                                    error={touched.baseAmount && !!errors.baseAmount}
                                    helperText={touched.baseAmount && errors.baseAmount}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomPrefixSuffixField
                                    prefix={baseCurrencySymbol}
                                    tagLine="(In Base Currency)"
                                    name="bankCharges"
                                    fieldLabel="Bank Charges"
                                    value={values.bankCharges}
                                    required={true}
                                    type="number"
                                    onChange={handleChange}
                                    placeholder="Enter Bank Charges"
                                    error={touched.bankCharges && !!errors.bankCharges}
                                    helperText={touched.bankCharges && errors.bankCharges}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <CustomTextarea
                                    name="note"
                                    type="text"
                                    fieldLabel="Note"
                                    value={values.note}
                                    onChange={handleChange}
                                    placeholder="Write a Note"
                                    sx={{
                                        height: "80px !important"
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateReceiptVoucher;

