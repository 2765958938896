import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import DataTable from "../../components/common/DataTable";
import { AccessDenied, getColorPlate, hasCreatePermission, hasDeletePermission, hasEditPermission, hasViewPermission } from "../../services/CommonServices";
import CustomMaster from "../common/CustomMaster";
import CustomStatus from "../common/CustomStatus";
import CustomAutocomplete from "../common/CustomAutoComplete";
import { useLocation } from "react-router-dom";


const title = "Color Plate";

const ColorPlate = () => {
    const [ratings, setRatings] = useState([]);
    const [permission, setPermissions] = useState()


    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        let rolePermisionsData = JSON.parse(sessionStorage.getItem('userData')) || []
        let editPermision = hasEditPermission(currentPath, rolePermisionsData?.permissions)
        let deletePermission = hasDeletePermission(currentPath, rolePermisionsData?.permissions)
        let createPermision = hasCreatePermission(currentPath, rolePermisionsData?.permissions)
        let viewPermision = hasViewPermission(currentPath, rolePermisionsData?.permissions)

        setPermissions({
            edit: editPermision,
            delete: deletePermission,
            create: createPermision,
            view: viewPermision,
        })
    }, [])

    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: "Color",
                header: "Color",
                size: 100,
                Cell: ({ row }) => (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                            <CustomStatus
                                title={row.original.colorName}
                                sx={{
                                    backgroundColor: row.original.backgroundColor,
                                    color: row.original.color,
                                    textAlign: "center",
                                    display: "inline-block",
                                }}
                            />
                        </div>

                    </>
                ),
                enableSorting: false
            },
        ],
        []
    );

    const fetchRatings = async () => {
        const ratingsData = await getColorPlate();
        setRatings(ratingsData);
    };

    useEffect(() => {
        fetchRatings();
    }, []);
    const defaultView = 'colorPlate';
    const mainContent = (
        <Box sx={{ padding: "10px" }}>
            <DataTable
                title={title}
                columns={columns}
                data={ratings}
                getRowProps={(row) => ({
                    style: {
                        backgroundColor: row.original.backgroundColor,
                        color: row.original.color,
                    }
                })}
            />
        </Box>
    );

    const getMainContent = (mainContent, permission) => {
        return permission?.view ? mainContent : null;
    };


    return (

        <>
            <CustomMaster
                defaultView={defaultView}
                mainContent={getMainContent(mainContent, permission)}
            />
            {!permission?.view && <AccessDenied />}
        </>

    );
};

export default ColorPlate;
