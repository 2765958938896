import React, { useState } from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import { ClearRounded, SaveRounded, Add, CloudUpload } from "@mui/icons-material";
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { CREATE_LOADING_PHOTO } from "../../../services/apiRoutes";
import CustomStyle from "../../common/CustomStyle";

const CreateLoadingPhoto = ({ handleClose, setMessage, clientCompanyId, shipmentId, shipmentOrgId, fetchData }) => {

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const formData = new FormData();
            formData.append("clientCompanyId", values.clientCompanyId);
            formData.append("shipmentId", values.shipmentId);
            formData.append("shipmentOrgId", values.shipmentOrgId);
            formData.append("commanShipmentId", values.commanShipmentId);
            formData.append("note", values.note);
            formData.append("captureUpload", values.captureUpload);

            const authToken = sessionStorage.getItem("accessToken");
            const response = await axios.post(CREATE_LOADING_PHOTO, formData, {
                headers: {
                    "Authorization": `Bearer ${authToken}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 201) {
                setMessage({ open: true, text: "Loading photo uploaded successfully", type: "success" });
                setTimeout(() => handleClose(), 1000);
                fetchData();
                resetForm(); // Reset form fields
            } else {
                setMessage({ open: true, text: "Something went wrong", type: "error" });
            }
        } catch (error) {
            setMessage({ open: true, text: "Error uploading image", type: "error" });
        } finally {
            setSubmitting(false);
        }
    };


    return (
        <>
            <Formik
                initialValues={{
                    clientCompanyId: clientCompanyId,
                    shipmentId: shipmentId,
                    shipmentOrgId: shipmentOrgId,
                    commanShipmentId: 0,
                    note: "",
                    captureUpload: ''
                }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, setFieldValue, values, errors, touched, handleChange }) => (
                    <Form>

                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={12} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Create Loading Photo
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={8}
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title="Save"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title="Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />

                        <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <input
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    id="upload-image"
                                    onChange={(event) => setFieldValue("captureUpload", event.target.files[0])}
                                />
                                <label htmlFor="upload-image">
                                    <Button
                                        component="span"
                                        variant="contained"
                                        startIcon={<CloudUpload />}
                                        fullWidth
                                        sx={{ mt: 3 }}
                                    >
                                        {values.captureUpload ? values.captureUpload.name : "Upload Loading Photo"}
                                    </Button>
                                </label>
                                {touched.captureUpload && errors.captureUpload && (
                                    <Typography variant="caption" color="error">
                                        {errors.captureUpload}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextarea
                                    name="note"
                                    fieldLabel="Note"
                                    placeholder="Enter Note"
                                    value={values.note}
                                    onChange={handleChange}
                                    error={touched.note && Boolean(errors.note)}
                                    helperText={touched.note && errors.note}
                                    sx={{
                                        height: '100px !important',
                                    }}
                                />
                            </Grid>
                        </Grid>

                    </Form>
                )}
            </Formik>
        </>
    );
}
export default CreateLoadingPhoto;
