import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded, AddRounded, DeleteRounded } from "@mui/icons-material";
import { Card, } from "@mui/material";
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import { genericPost } from '../../../services/authServices';
import { CREATE_DUTY, CREATE_PROFORMA_INVOICE, LIST_COMMERCIAL_INVOICES } from '../../../services/apiRoutes';
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomStyle from "../../common/CustomStyle";
import CustomPrefixSuffixField from "../../common/CustomPrefixSuffixField";
import { getCommanImportShipmentId } from "../../../services/CommonServices";

const ValidationSchema = Yup.object().shape({
    clientCompanyId: Yup.number().required('Client Companyis required'),
    commanShipmentId: Yup.number().required('Common Shipment is required'),
    commercialInvoiceId: Yup.number().required('commercial Invoice is required'),
    shipmentId: Yup.string().required('Shipment is required'),
    voucherNo: Yup.string().required('Voucher No. is required'),
    currencyId: Yup.number().required('Currency is required'),
    boeConversionRate: Yup.number().required('BOE Conversion Rate is required'),
    date: Yup.date().required('Date is required'),
    particulars: Yup.array().of(
        Yup.object().shape({
            particular: Yup.string().required('Particular is required'),
            hsn: Yup.string().required('HSN is required'),
            duty: Yup.number().required('Duty is required'),
            amount: Yup.number().required('Amount is required'),
            dutyPersentage: Yup.string().required('Duty % is required'),
            tax: Yup.number().required('Tax is required'),
            taxPersentage: Yup.string().required('Tax % is required'),
        })
    ).required('Particulars are required')
});

const CreateDuty = ({ handleClose, setMessage, currencyList, shipmentOrgId, currencySymbol, newShippingId, currencyId, fetchData, suppliersList, productsList, supplierIdName, shipmentId }) => {

    const [commercialInvoiceList, setCommercialInvoiceList] = useState([]);
    useEffect(() => {
        getCommercialInvoice();
    }, []);
    const getCommercialInvoice = async () => {
        try {
            const payload = {
                supplierNamesId: supplierIdName,
                shipmentId: shipmentId
            };
            const response = await genericPost(LIST_COMMERCIAL_INVOICES, payload, {
            });
            if (response.data && response.data.success) {
                const invoices = response.data.data.map((invoice) => ({
                    id: invoice.commercialInvoiceId,
                    title: invoice.commercialInvoiceNo,
                    reference: invoice.reference,
                    dueDate: invoice.dueDate,
                    note: invoice.note,
                    particulars: invoice.particulars.map((particular) => ({
                        qty: particular.qty,
                        particularName: particular.particularName,
                        rate: particular.rate,
                        amount: particular.amount,
                        description: particular.description,
                        unit: particular.productsUnit.unitName,
                        product: particular.products.productName,
                        hsn: particular?.products?.hsn,
                        dutyPersentage: particular?.products?.dutyPersentage,
                        taxPersentage: particular?.products?.taxPersentage,
                        duty: (particular.amount * particular?.products?.dutyPersentage) / 100,
                        tax: (particular.amount * particular?.products?.taxPersentage) / 100,

                    }))
                }));
                setCommercialInvoiceList(invoices);
            } else {
                console.error("No data available");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                shipmentOrgId: values.shipmentOrgId,
                clientCompanyId: values.clientCompanyId,
                commanShipmentId: values.commanShipmentId,
                commercialInvoiceId: values.commercialInvoiceId,
                shipmentId: values.shipmentId,
                voucherNo: values.voucherNo,
                currencyId: values.currencyId,
                boeConversionRate: values.boeConversionRate,
                boeUpload: values.boeUpload,
                date: values.date,
                note: values.note,
                particulars: values.particulars
            };

            const response = await genericPost(CREATE_DUTY, payload);

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Proforma Invoice created successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
            }
            else if (response.status === 409) {
                setMessage({
                    open: true,
                    text: response.message || 'already exists ',
                    type: 'error',
                });
            }
            else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };
    const currentDate = formatDate(new Date());
    const [formData] = useState({
        clientCompanyId: parseInt(supplierIdName),
        shipmentOrgId: shipmentOrgId,
        commanShipmentId: getCommanImportShipmentId,
        commercialInvoiceId: '',
        currencyId: currencyId,
        shipmentId: shipmentId,
        date: '',
        voucherNo: newShippingId || '',
        boeConversionRate: '',
        boeUpload: '',
        note: '',
        particulars: [
            {
                particular: '',
                hsn: '',
                duty: '',
                amount: '',
                dutyPersentage: '',
                tax: '',
                taxPersentage: '',
            }
        ]
    });
    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Create Duty and Tax
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ px: "0%", paddingTop: "5px" }}>
                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    options={commercialInvoiceList}
                                    fieldLabel="Commercial Invoice"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={
                                        commercialInvoiceList.find(
                                            (option) => option.id === values.commercialInvoiceId
                                        ) || null
                                    }
                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            setFieldValue("commercialInvoiceId", newValue.id);
                                            setFieldValue("commercialInvoiceNo", newValue.title);
                                            setFieldValue("note", newValue.note);
                                            const updatedParticulars = newValue.particulars.map((part) => ({
                                                particular: parseInt(part?.particularName || ''),
                                                qty: part.qty || "",
                                                rate: part.rate || "",
                                                unit: parseInt(part.unit || ""),
                                                amount: part.amount || "",
                                                description: part.description || "",
                                                hsn: part.hsn || "",
                                                dutyPersentage: part.dutyPersentage || "",
                                                taxPersentage: part.taxPersentage || "",
                                                duty: part.duty || "",
                                                tax: part.tax || "",
                                            }));
                                            setFieldValue("particulars", updatedParticulars);
                                        } else {
                                            setFieldValue("commercialInvoiceId", "");
                                            setFieldValue("commercialInvoiceNo", "");
                                            setFieldValue("note", "");
                                            setFieldValue("particulars", [
                                                {
                                                    particular: '',
                                                    qty: "",
                                                    rate: "",
                                                    unit: "",
                                                    amount: "",
                                                    description: "",
                                                    hsn: "",
                                                    dutyPersentage: "",
                                                    taxPersentage: "",
                                                    duty: "",
                                                    tax: "",
                                                },
                                            ]);
                                        }
                                    }}
                                    placeholder="Select Commercial Invoice"
                                    error={touched.commercialInvoiceId && !!errors.commercialInvoiceId}
                                    helperText={touched.commercialInvoiceId && errors.commercialInvoiceId}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    placeholder="Enter Date"
                                    type="date"
                                    name="date"
                                    fieldLabel="Date"
                                    value={values.date}
                                    onChange={handleChange}
                                    error={touched.date && !!errors.date}
                                    helperText={touched.date && errors.date}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    placeholder="Enter Voucher No."
                                    name="voucherNo"
                                    fieldLabel="Voucher No."
                                    required={true}
                                    value={values.voucherNo}
                                    onChange={handleChange}
                                    error={touched.voucherNo && !!errors.voucherNo}
                                    helperText={touched.voucherNo && errors.voucherNo}
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    placeholder="Enter BOE Conversion Rate"
                                    name="boeConversionRate"
                                    fieldLabel="BOE Conversion Rate"
                                    required={true}
                                    type="number"
                                    value={values.boeConversionRate}
                                    onChange={handleChange}
                                    error={touched.boeConversionRate && !!errors.boeConversionRate}
                                    helperText={touched.boeConversionRate && errors.boeConversionRate}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.currencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('currencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Currency"
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}
                                />
                            </Grid>
                        </Grid>
                        <FieldArray
                            name="particulars"
                            render={arrayHelpers => (
                                <>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={6}>
                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <AssignmentLateOutlinedIcon />
                                                <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                                    Particulars
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Box style={{
                                            overflowX: 'auto', overflowY: 'auto'
                                        }}>
                                            <Table style={{ width: "100%" }}>
                                                <TableHead>
                                                    <TableRow style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                                                        <TableCell style={{ ...styles.tablecell, width: "50px" }} rowSpan={2}>No.</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "200px" }} rowSpan={2}>Product Name</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Amount</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>HSN No.</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Duty %</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Duty</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Tax %</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Tax </TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "50px" }} rowSpan={2}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {values.particulars.map((particular, index) => {
                                                        const handleDutyCalculation = (amount, dutyPercentage) => {
                                                            return (amount * dutyPercentage) / 100;
                                                        };

                                                        const handleTaxCalculation = (amount, taxPercentage) => {
                                                            return (amount * taxPercentage) / 100;
                                                        };

                                                        const handleAmountChange = (e, index) => {
                                                            const newAmount = parseFloat(e.target.value) || 0;
                                                            const dutyPercentage = parseFloat(values.particulars[index].dutyPersentage) || 0;
                                                            const taxPercentage = parseFloat(values.particulars[index].taxPersentage) || 0;
                                                            const duty = handleDutyCalculation(newAmount, dutyPercentage);
                                                            const tax = handleTaxCalculation(newAmount, taxPercentage);
                                                            setFieldValue(`particulars.${index}.amount`, newAmount);
                                                            setFieldValue(`particulars.${index}.duty`, duty.toFixed(2));
                                                            setFieldValue(`particulars.${index}.tax`, tax.toFixed(2));
                                                        };

                                                        const handleDutyPercentageChange = (e, index) => {
                                                            const newDutyPercentage = parseFloat(e.target.value) || 0;
                                                            const amount = parseFloat(values.particulars[index].amount) || 0;
                                                            const duty = handleDutyCalculation(amount, newDutyPercentage);

                                                            setFieldValue(`particulars.${index}.dutyPersentage`, newDutyPercentage);
                                                            setFieldValue(`particulars.${index}.duty`, duty.toFixed(2));
                                                        };

                                                        const handleTaxPercentageChange = (e, index) => {
                                                            const newTaxPercentage = parseFloat(e.target.value) || 0;
                                                            const amount = parseFloat(values.particulars[index].amount) || 0;
                                                            const tax = handleTaxCalculation(amount, newTaxPercentage);

                                                            setFieldValue(`particulars.${index}.taxPersentage`, newTaxPercentage);
                                                            setFieldValue(`particulars.${index}.tax`, tax.toFixed(2));
                                                        };
                                                        const handleHSNChange = (e, index) => {
                                                            const newAmount = parseFloat(e.target.value) || 0;
                                                            const dutyPercentage = parseFloat(values.particulars[index].dutyPersentage) || 0;
                                                            const duty = handleDutyCalculation(newAmount, dutyPercentage);
                                                            setFieldValue(`particulars.${index}.amount`, newAmount);
                                                            setFieldValue(`particulars.${index}.duty`, duty.toFixed(2));
                                                        };

                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell style={styles.tablecell}>{index + 1}</TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomAutocomplete
                                                                        options={productsList || []}
                                                                        required={true}
                                                                        getOptionLabel={(option) => option.productName || ''}
                                                                        value={productsList?.find((option) => option.productsId === particular?.particular) || null}
                                                                        onChange={(e, newValue) => {
                                                                            setFieldValue(`particulars.${index}.particular`, newValue ? newValue.productsId : '');
                                                                            setFieldValue(`particulars.${index}.hsn`, newValue ? newValue.hsn : '');
                                                                            setFieldValue(`particulars.${index}.dutyPersentage`, newValue ? newValue.dutyPersentage : '');
                                                                            setFieldValue(`particulars.${index}.taxPersentage`, newValue ? newValue.taxPersentage : '');
                                                                        }}
                                                                        placeholder="Select Product Name"
                                                                        error={touched.particulars?.[index]?.particular && !!errors.particulars?.[index]?.particular}
                                                                        helperText={touched.particulars?.[index]?.particular && errors.particulars?.[index]?.particular}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        required={true}
                                                                        name={`particulars.${index}.amount`}
                                                                        type="number"
                                                                        value={particular.amount}
                                                                        onChange={(e) => handleAmountChange(e, index)}
                                                                        placeholder="Amount"
                                                                        error={touched.particulars?.[index]?.amount && !!errors.particulars?.[index]?.amount}
                                                                        helperText={touched.particulars?.[index]?.amount && errors.particulars?.[index]?.amount}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        required={true}
                                                                        name={`particulars.${index}.hsn`}
                                                                        type="number"
                                                                        value={particular.hsn || ''}
                                                                        onChange={handleHSNChange}
                                                                        placeholder="HSN No."
                                                                        error={touched.particulars?.[index]?.hsn && !!errors.particulars?.[index]?.hsn}
                                                                        helperText={touched.particulars?.[index]?.hsn && errors.particulars?.[index]?.hsn}
                                                                        sx={CustomStyle.readOnly}
                                                                        readOnly={true}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        required={true}
                                                                        name={`particulars.${index}.dutyPersentage`}
                                                                        type="number"
                                                                        value={particular.dutyPersentage || ''}
                                                                        onChange={(e) => handleDutyPercentageChange(e, index)}
                                                                        placeholder="Duty %"
                                                                        error={touched.particulars?.[index]?.dutyPersentage && !!errors.particulars?.[index]?.dutyPersentage}
                                                                        helperText={touched.particulars?.[index]?.dutyPersentage && errors.particulars?.[index]?.dutyPersentage}
                                                                        sx={CustomStyle.readOnly}
                                                                        readOnly={true}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        required={true}
                                                                        type="number"
                                                                        name={`particulars.${index}.duty`}
                                                                        value={particular.duty || ''}
                                                                        onChange={(e) => setFieldValue(`particulars.${index}.duty`, e.target.value)}
                                                                        placeholder="Duty"
                                                                        error={touched.particulars?.[index]?.duty && !!errors.particulars?.[index]?.duty}
                                                                        helperText={touched.particulars?.[index]?.duty && errors.particulars?.[index]?.duty}
                                                                        sx={CustomStyle.readOnly}
                                                                        readOnly={true}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        required={true}
                                                                        name={`particulars.${index}.taxPersentage`}
                                                                        type="number"
                                                                        value={particular.taxPersentage || ''}
                                                                        onChange={(e) => handleTaxPercentageChange(e, index)}
                                                                        placeholder="Tax %"
                                                                        error={touched.particulars?.[index]?.taxPersentage && !!errors.particulars?.[index]?.taxPersentage}
                                                                        helperText={touched.particulars?.[index]?.taxPersentage && errors.particulars?.[index]?.taxPersentage}
                                                                        sx={CustomStyle.readOnly}
                                                                        readOnly={true}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        required={true}
                                                                        name={`particulars.${index}.tax`}
                                                                        type="number"
                                                                        value={particular.tax || ''}
                                                                        onChange={(e) => setFieldValue(`particulars.${index}.tax`, e.target.value)}
                                                                        placeholder="Tax"
                                                                        error={touched.particulars?.[index]?.tax && !!errors.particulars?.[index]?.tax}
                                                                        helperText={touched.particulars?.[index]?.tax && errors.particulars?.[index]?.tax}
                                                                        sx={CustomStyle.readOnly}
                                                                        readOnly={true}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <IconButton onClick={() => arrayHelpers.remove(index)} color="error">
                                                                        <DeleteRounded />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>


                                                <TableRow style={{ height: '40px' }}>
                                                    <TableCell style={styles.tablecell} colSpan={9}>
                                                        <div style={{ margin: "auto", textAlign: "center" }}>
                                                            <CustomButton
                                                                sx={Additemstyle}
                                                                startIcon={<AddRounded />}
                                                                title="Add Item"
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => arrayHelpers.push({
                                                                    particular: '',
                                                                    amount: '',
                                                                    hsn: '',
                                                                    dutyPersentage: '',
                                                                    duty: '',
                                                                    taxPersentage: '',
                                                                    tax: '',
                                                                })}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ height: '40px' }}>
                                                    <TableCell style={styles.tablecellfooter} colSpan={2}>Total</TableCell>
                                                    <TableCell style={styles.tablecellfooter}  >
                                                        {/* {values.particulars
                                                            .reduce((total, particular) => total + (parseFloat(particular.qty) || 0), 0)
                                                        } */}
                                                    </TableCell>
                                                    <TableCell style={styles.tablecellfooter} colSpan={2}  > </TableCell>
                                                    <TableCell style={styles.tablecellfooter} >
                                                        {/* {currencySymbol} &nbsp;
                                                        {values.particulars
                                                            .reduce((total, particular) => total + ((particular.qty || 0) * (particular.rate || 0)), 0)
                                                            .toFixed(2)} */}

                                                    </TableCell>
                                                    <TableCell style={styles.tablecellfooter} > </TableCell>
                                                    <TableCell style={styles.tablecellfooter} > </TableCell>
                                                    <TableCell style={styles.tablecellfooter} > </TableCell>
                                                </TableRow>
                                            </Table>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        />
                        <Grid container spacing={1} style={{ paddingBottom: "5px", paddingTop: "10px" }}>
                            <Grid item xs={12} md={4}>
                                <CustomTextarea
                                    name="note"
                                    fieldLabel="Note"
                                    placeholder="Enter Note"
                                    value={values.note}
                                    onChange={handleChange}
                                    sx={{ height: '50px !important' }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    type="file"
                                    placeholder="Enter Upload BOE"
                                    name="boeUpload"
                                    fieldLabel="Upload BOE"
                                    value={values.boeUpload}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box >
    );
};

export default CreateDuty;
const styles = {
    table: {
        minWidth: 650,
    },
    textMuted: {
        color: '#6c757d',
    },

    labelCell: {
        width: '180px',
    },
    tableContainer: {
        marginBottom: '16px',
    },
    header: {
        marginBottom: '16px',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    customTableRow: {
        height: '20px',
    },
    editButton: {
        textAlign: 'right',
    },
    showDetailsSpan: {
        padding: '8px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
        cursor: 'pointer',
    },
    expandIcon: {
        color: '#212529',
    },
    boxheading: {
        width: 70,
        height: 70,
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 14,
        boxShadow: 3,
    },
    headingname: {
        paddingTop: '10px',
        fontWeight: '600',
        fontSize: '20px',
        color: '#212529',
    },
    avatarcircle: {
        backgroundColor: '#007BFF',
        width: '30px',
        height: '30px',
        fontSize: '15px',
    },
    cardtittile: {
        padding: '8px',
        paddingLeft: '35px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
    },
    tablebutton: {
        backgroundColor: '#007bff',
        color: '#ffff',
        textTransform: 'capitalize',
        borderRadius: 4,
        border: '2px solid #007bff',
        height: 30,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tablecell: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    tablecellfooter: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'right',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },

};


const Additemstyle = {
    color: '#212529 !important',
    textTransform: 'capitalize !important',
    borderRadius: '4px !important',
    border: '1px dashed #aecce4 !important',
    height: '30px !important',
    display: 'inline-flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    backgroundColor: '#e7f0f7 !important',
    width: '100% !important',
};
