
import React, { useState } from 'react';
import { TextField, FormLabel, Box, Typography, FormHelperText, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';


const StyledTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
        height: '35px',
        '& fieldset': {
            border: 'none',
        },
        '&:hover fieldset': {
            border: 'none',
        },
        '&.Mui-focused fieldset': {
            border: 'none',
        },
    },
    '& .MuiInputBase-input': {
        padding: '0 0',

    },
}));

const CustomPasswordFeild = ({ name, value, onChange, fieldLabel,
    required, helperText,
    placeholder,
    InputProps,
    tagLine,
    type = 'text',
    readOnly = false, ...props }) => {

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <Box style={{ marginBottom: '1px' }}>
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
                <FormLabel style={fieldLabelstyle}>{fieldLabel}</FormLabel>
                <Typography sx={tagLineStyle}>{tagLine}</Typography>
            </Box>
            <StyledTextField
                name={name}
                value={value || ''}
                onChange={onChange}
                placeholder={placeholder}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                style={required ? { ...style, ...requiredStyle } : style}
                {...props}
                InputProps={{
                    readOnly,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={showPassword ? 'Hide password' : 'Show password'}
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}

            />
            <FormHelperText error={true} style={helperTextStyle}>{helperText}</FormHelperText>
        </Box>
    );
};



export default CustomPasswordFeild;
const style = {
    width: '100%',
    border: '1px solid #CED4DA',
    borderRadius: '4px',
    paddingLeft: '10px',
    height: '35px',
    marginTop: '4px',
    fontFamily: 'Poppins, sans-serif !important',
    color: '#495057',
    fontSize: '14px',
    marginBottom: '3px'
};

const requiredStyle = {
    borderLeft: "2px solid #F27B77",
}

const fieldLabelstyle = {
    color: "#212529",
    fontWeight: "600",
    fontSize: "14px"
};
const helperTextStyle = {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '12px',
    marginTop: 0,
    color: "red"

}
const tagLineStyle = {
    fontSize: "10px",
    color: "#666",
    paddingLeft: "2px"
};
