import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Typography, Button, IconButton, Avatar, Link, Table, TableBody, TableRow, TableCell } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import CustomButton from '../common/CustomButton';
import { Edit, LinkOffOutlined, Phone } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkIcon from '@mui/icons-material/Link';
import CustomTextField from '../common/CustomTextField';
import { genericGet } from "../../services/apiServices";
import { DELETE_COMPANY_ID, GET_COMPANY_id } from "../../services/apiRoutes";
import { Navigate, useParams } from 'react-router-dom';
import CustomModal from "../common/CustomModal";
import UpdateCompany from "../../components/MyCompany/UpdateCompany";
import { getCountries, getStates } from "../../services/CommonServices";
import CustomMaster from "../common/CustomMaster";
import MessageBox from "../common/CustomMessageBox";
import CustomDelete from "../common/CustomDeleteAlert";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import UpdateCompanyImage from "./UpdateMaster/UpdateCompanyImage";


const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
};

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const CompanySetting = () => {
    const { id } = useParams(); // Get the id parameter from the URL
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [getAllCompany, setGetAllCompany] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [addCompanyModal, setAddCompanyModal] = useState(false);
    const [conutries, setConutries] = useState([]);
    const [updateModal, setUpdateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);


    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [rowId, setRowId] = useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getCompanyList = async () => {
        try {
            const response = await genericGet({ url: GET_COMPANY_id(id) });

            const companyData = response?.data?.companies || [];
            const defaultCompanies = companyData.filter((company) => company.isDefault);
            const defaultCompany = defaultCompanies[0] || null;
            const getCompanyId = defaultCompany?.companyId || null;

            let CompanyData = response.data.company;
            if (CompanyData) {
                setGetAllCompany(CompanyData);
                setCompanyId(getCompanyId);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getCompanyList();
    }, [id]);

    const handleClose = () => {
        setAddCompanyModal(false);
    };
    const handleUpdateClick = (companyId) => {
        setCompanyId(companyId);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };
    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true);
        setRowId(rowId);
    };

    // Function to open image modal
    const handleImageClick = (companyId) => {
        setSelectedId(companyId);
        setImageModalOpen(true);
    };

    // Function to close image modal
    const handleCloseImageModal = () => {
        setImageModalOpen(false);
    };


    const handleDelete = async (companyId) => {
        setLoading(true);
        const authToken = sessionStorage.getItem('accessToken');

        try {
            const response = await axios.delete(DELETE_COMPANY_ID(companyId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: "deleted successfully",
                    type: "success",
                });
                setTimeout(() => {
                    navigate('/app/mycompany');
                }, 3000);
                setDeleteAlert(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);

            if (error.response) {
                if (error.response.status === 409) {
                    setMessage({
                        open: true,
                        text: error.response.data?.error || "Cannot delete Client Company. Related Shipment exists.",
                        type: "error",
                    });
                } else {
                    setMessage({
                        open: true,
                        text: "An error occurred while deleting the client company.",
                        type: "error",
                    });
                }
            } else {
                console.error("Error deleting client company", error);
                setMessage({
                    open: true,
                    text: "Network error. Please try again later.",
                    type: "error",
                });
            }
        }
    };




    const defaultView = 'mycompany';
    const mainContent = (
        <>
            <CustomModal
                modalStyles={{
                    maxHeight: '35vh',
                    top: "10%",
                }}
                open={imageModalOpen} handleClose={handleCloseImageModal}>
                <UpdateCompanyImage
                    handleClose={handleCloseImageModal}
                    companyUserId={selectedId}
                    fetchData={getCompanyList}
                    setMessage={setMessage}
                />
            </CustomModal>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >

                        <Tab
                            label="Company Details"
                            {...a11yProps(1)}
                            sx={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                color: value === 1 ? '#007BFF' : 'inherit'
                            }}
                        />
                    </Tabs>
                </Box>
                {getAllCompany && (
                    <CustomTabPanel value={value} index={0}>
                        <Box sx={{ p: 1, }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item>
                                        <Box textAlign="center" sx={{ position: "relative" }}>
                                            <Avatar
                                                src={getAllCompany.companyLogo}
                                                alt="user-profile"
                                                sx={{ width: 100, height: 100, mx: 'auto' }}
                                            />
                                            <IconButton
                                                onClick={() => handleImageClick(companyId)}
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    right: 0,
                                                    padding: '5px',
                                                    borderRadius: '50%',
                                                    backgroundColor: '#007BFF',
                                                    color: '#fff',
                                                    '&:hover': { backgroundColor: '#007BFF' },
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Box>

                                    </Grid>
                                    <Grid item xs>
                                        <Box display="flex" alignItems="center" mb={1}>
                                            <Typography variant="h6" fontWeight="bold" sx={{ m: 0, fontSize: "18px", fontWeight: "bold" }}>
                                                {getAllCompany.companyName}
                                            </Typography>
                                            <CustomButton
                                                onClick={() => handleUpdateClick(companyId)}
                                                title="Update Company"
                                                size="small"
                                                variant="outlined"
                                                style={{
                                                    color: "#007BFF", textTransform: "capitalize", borderColor: '#007BFF',
                                                    marginLeft: "10px"
                                                }}
                                            />
                                            <IconButton onClick={() => handleOpenDeleteAlert(getAllCompany.companyId)}>
                                                <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                                            </IconButton>

                                        </Box>
                                        <Box mb={1}>
                                            <Link variant="body2" color="textSecondary" sx={{ fontSize: "15px" }}>
                                                {getAllCompany.website}
                                            </Link>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <MailIcon fontSize="small" sx={{ mr: 1 }} />
                                            {getAllCompany.email}

                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box my={2}>
                                <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "bold" }}>Company Information</Typography>
                                <Grid container sx={{ pl: 10 }}>
                                    <Grid item xs={12}>
                                        <Table size="small" sx={{ '& .MuiTableCell-root': { borderBottom: 'none' } }}>
                                            <TableBody>
                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>Company Name</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}>  {getAllCompany.companyName}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>Email</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}> {getAllCompany.email}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>Mobile</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}> {getAllCompany.mobile}</TableCell>
                                                </TableRow>

                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>financial Year Start</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}> {getAllCompany.financialYearStart}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>financial Year End</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}> {getAllCompany.financialYearEnd}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>Address</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}>
                                                        {getAllCompany?.address ?? ''}, {getAllCompany?.city?.name ?? ''}, {getAllCompany?.state?.name ?? ''}, {getAllCompany?.country?.name ?? ''} ({getAllCompany?.pincode ?? ''}).
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </CustomTabPanel>
                )}
            </Box>
            {/* <CustomModal open={addCompanyModal} handleClose={handleClose}>
                <UpdateCompany
                    handleClose={handleClose}
                    conutries={conutries}
                    getStates={getStates}
                />
            </CustomModal> */}
            <CustomModal
                open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateCompany
                    handleClose={handleCloseUpdate}
                    companyId={getAllCompany.companyId}
                    fetchData={getCompanyList}
                    setMessage={setMessage}
                />
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}
            />
        </>
    );
    return (
        <CustomMaster
            defaultView={defaultView}
            mainContent={mainContent} />
    );
};

export default CompanySetting;


