import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { GET_BATCH_WISE_IMP_SHIPMENT } from "../../services/apiRoutes";
import { genericGet, renderHtmlContent } from "../../services/apiServices";
import { formatDate } from "../../services/CommonServices";
import CustomReport from "../common/CustomReport";
const title = "Batch No. Wise Import Shipment Report";

const BatchNoWiseImportShipmentReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'createdAt', header: 'Date', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'shipmentId', header: 'Shipment I’d', size: 100 },
            { accessorKey: 'proformaInvoiceNo', header: 'PI No.', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'BatchNo', header: 'Batch No.', size: 100, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'clientCompanyName', header: 'Supplier Name', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'country', header: 'Country', size: 100, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'ShippingMark', header: 'Shipping Mark', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'blNo', header: 'BL No.', size: 100, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton >
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_BATCH_WISE_IMP_SHIPMENT });
            if (response.data.success && response.data.shippingmark) {
                const shippingMarkData = response.data.shippingmark.map((mark, index) => ({
                    shippingMarkId: mark.shippingmarkData?.shippingMarkId || '',
                    shipmentId: mark.shippingmarkData?.shipmentId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    companyId: mark.shippingmarkData?.companyId || '',
                    blNo: mark?.blNo || '',
                    BatchNo: mark.shippingmarkData?.batchNo || '',
                    proformaInvoiceNo: mark.proformaInvoiceData?.proformaInvoiceNo || '',
                    productName: mark.shippingmarkData?.productsList?.productName || '',
                    clientCompanyName: mark.shippingmarkData?.clientCompany?.clientCompanyName || '',
                    country: mark.shippingmarkData?.clientCompany?.mailingCountry?.name || '',
                    ShippingMark: renderHtmlContent(mark?.shippingmarkData?.shippingMark || ''),
                    createdAt: formatDate(mark.shippingmarkData?.createdAt),

                }));
                setData(shippingMarkData);
            } else {
                setError('No shipping marks found');
            }
        } catch (error) {
            setError('Error fetching shipping marks');
            console.error('Error fetching shipping marks', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData()
    }, [token])

    const defaultView = 'batchnowiseimportshipmentreport';
    const mainContent = (
        <DataTable
            title={title}
            columns={columns}
            data={data}
        />
    );

    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>
    );
};
export default BatchNoWiseImportShipmentReport;
