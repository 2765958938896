import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import axios from "axios";
import { CREATE_ORDER, SAVE_PAYMENT_INFO, START_FREE_TRAIL } from "../services/apiRoutes";
import { genericPost } from "../services/authServices";
import { useNavigate } from "react-router-dom";
import CustomMaster from "../components/common/CustomMaster";

const SubscriptionPlans = ({ isFirstTimeLogin, setValue, setId }) => {
  const yearlyBasePrice = 25; // Yearly base price for 1 user in USD
  const yearlyPerUserPrice = 5; // Price per additional user in USD
  const monthlyBasePrice = 3; // Monthly base price for 1 user in USD
  const monthlyPerUserPrice = 1; // Monthly price per additional user in USD
  const navigate = useNavigate();
  const [additionalUsers, setAdditionalUsers] = useState(1); // Default to 1 user
  const [totalPrice, setTotalPrice] = useState(yearlyBasePrice);
  const [subscriptionType, setSubscriptionType] = useState("yearly");
  const [message, setMessage] = useState({
    message: "",
    status: "",
    type: "",
  });
  const handleClose = () => {

    setMessage((prev) => ({ ...prev, status: false }))
    if (isFirstTimeLogin) {
      navigate('/app/dashboard')
    }

  };

  const handleUserChange = (event) => {
    const users = parseInt(event.target.value, 10) || 1;
    setAdditionalUsers(users);
    updateTotalPrice(subscriptionType, users);
  };

  const updateTotalPrice = (type, users) => {
    if (type === "yearly") {
      setTotalPrice(yearlyBasePrice + (users - 1) * yearlyPerUserPrice);
    } else {
      setTotalPrice(monthlyBasePrice + (users - 1) * monthlyPerUserPrice);
    }
  };

  // Handle subscription type change (monthly/yearly)
  const handleSubscriptionTypeChange = (event, newType) => {
    if (newType) {
      setSubscriptionType(newType);
      updateTotalPrice(newType, additionalUsers);
    }
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    const isRazorpayLoaded = await loadRazorpayScript();
    if (!isRazorpayLoaded) {
      alert("");
      setMessage({
        status: true,
        message:
          "Failed to load Razorpay SDK. Please check your internet connection.",
        type: "error",
      });
      return;
    }

    try {
      const response = await genericPost(CREATE_ORDER, { amount: totalPrice });
      if (response.error) {
        throw new Error(response.error || "Error creating order.");
      }

      const orderData = response.data;
      const options = {
        key: "rzp_test_dD0jhbnTbrfcD3", // Replace with your Razorpay Key ID
        amount: totalPrice * 100,
        currency: "USD",
        name: "Exim CRM",
        description: `${subscriptionType.charAt(0).toUpperCase() + subscriptionType.slice(1)
          } Subscription Payment`,
        order_id: orderData.id,
        handler: async (paymentResponse) => {
          // debugger;
          setMessage({
            status: true,
            message: ` Payment ID: ${paymentResponse.razorpay_payment_id}`,
            type: "success",
          });
          setValue && setValue(2)

          setId && setId(paymentResponse.razorpay_payment_id)
          const paymentInfo = {
            orderId: orderData.id,
            razorpayPaymentId: paymentResponse.razorpay_payment_id,
            razorpayOrderId: paymentResponse.razorpay_order_id,
            razorpaySignature: paymentResponse.razorpay_signature,
            subscriptionId: subscriptionType === "yearly" ? "1" : "2", // Assuming 1 for yearly and 2 for monthly
            totalPrice: totalPrice,
            totalUsers: additionalUsers,
          };

          const saveResponse = await genericPost(
            SAVE_PAYMENT_INFO,
            paymentInfo
          );
          if (saveResponse.status === 201) {
            // alert("Payment and subscription information saved successfully!");

          } else {
            // alert("Failed to save payment information.");
          }
        },
        prefill: {
          name: "Test User",
          email: "user@example.com",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      setMessage({
        status: true,
        message: "Failed to create order. Please try again later.",
        type: "error",
      });
    }
  };

  const plans = [
    {
      planId: "1",
      title: "Yearly Plan",
      price: "$25/year",
      features: [
        "App Management",
        "Attendance Management",
        "Leave System Management",
        "Employee Management",
        "Expense Tracking",
      ],
    },
    {
      planId: "2",
      title: "Monthly Plan",
      price: "$3/month",
      features: [
        "App Management",
        "Attendance Management",
        "Leave System Management",
        "Employee Management",
        "Expense Tracking",
      ],
    },
  ];
  const handleStartFreeTrail = async () => {
    let payload = {
      freeTrialStartedOn: new Date(),
      subscriptionStartedOn: null,
      totalUsers: 1
    }

    try {
      const response = await genericPost(START_FREE_TRAIL, payload);
      if (response.status == 201 || response.status == 200) {
        setMessage({
          open: true,
          text: "Free trial Started",
          type: "success",
        });
        navigate("/app/dashboard")
      }

    } catch (error) {
      setMessage({
        open: true,
        text: 'Something went wrong',
        type: "error",
      });
    }

  }

  const defaultView = 'subscription';
  const mainContent = (
    <>
      <Box sx={{ padding: 4, textAlign: "center" }}>
        {/* <Typography variant="h4" gutterBottom>
        Choose Your Subscription Plan
      </Typography> */}

        {/* Toggle between monthly and yearly plans */}
        <ToggleButtonGroup
          value={subscriptionType}
          exclusive
          onChange={handleSubscriptionTypeChange}
          aria-label="subscription type"
          sx={{ marginBottom: 4 }}
        >
          <ToggleButton value="monthly">Monthly</ToggleButton>
          <ToggleButton value="yearly">Yearly</ToggleButton>
        </ToggleButtonGroup>

        <Grid container spacing={4} justifyContent="center">
          {plans
            .filter((plan) => plan.title.toLowerCase().includes(subscriptionType))
            .map((plan, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card
                  sx={{
                    boxShadow: 3,
                    transition: "0.3s",
                    "&:hover": { boxShadow: 4 },
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", marginBottom: 2 }}
                    >
                      {plan.title}
                    </Typography>

                    <Typography
                      variant="h4"
                      color="primary"
                      sx={{ fontWeight: "bold", marginBottom: 2 }}
                    >
                      {plan.price}
                    </Typography>

                    <Divider sx={{ marginBottom: 2 }} />

                    {/* Features List */}
                    <List dense>
                      {plan.features.map((feature, i) => (
                        <ListItem key={i}>
                          <ListItemIcon>
                            <CheckCircleIcon color="success" />
                          </ListItemIcon>
                          <ListItemText primary={feature} />
                        </ListItem>
                      ))}
                    </List>

                    {/* Add Additional Users */}
                    <TextField
                      fullWidth
                      label="Additional Users"
                      type="number"
                      variant="outlined"
                      value={additionalUsers}
                      onChange={handleUserChange}
                      inputProps={{ min: 1 }}
                      sx={{ marginY: 2 }}
                    />

                    <Typography variant="h6" gutterBottom>
                      Total Price: ${totalPrice}
                    </Typography>

                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handlePayment}
                    >
                      Subscribe Now
                    </Button>
                    {isFirstTimeLogin && (
                      <Button
                        variant={"outlined"}
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={handleStartFreeTrail}
                      >
                        Skip & Start free Trial
                      </Button>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
        <Dialog
          open={message.status}
          onClose={handleClose}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle sx={{ textAlign: "center", mt: 2 }}>
            {message?.type == "success" ? (
              <CheckCircleIcon sx={{ fontSize: 60, color: "green" }} />
            ) : (
              <ErrorOutlineIcon sx={{ fontSize: 60, color: "red" }} />
            )}
          </DialogTitle>
          <DialogContent>
            <Typography variant="h5" align="center" gutterBottom>
              {message?.type == "success"
                ? `Payment Successful!`
                : "payment failed."}
            </Typography>
            <Typography variant="body2" align="center" color="textSecondary">
              {message?.message}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );

  return (
    <CustomMaster
      defaultView={defaultView}
      mainContent={mainContent} />
  );
};

export default SubscriptionPlans;
