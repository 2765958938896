import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from "react-i18next";
import DataTable from '../components/common/DataTable';
import MessageBox from "../components/common/CustomMessageBox";
import CustomDelete from '../components/common/CustomDeleteAlert';
import CustomModal from "../components/common/CustomModal";
import Createcompany from "../components/company/createcompany";
import UpdateClientCompany from "../components/company/UpdateClientCompany";
import { getIndustryType, getRating, getLeadSource, getLeadStatus, getCountries, getBusinessGroup, getStates, getCities, getCompanyTypes, getCompanyOwnershipType, getOwner } from '../services/CommonServices';
import { Delete_clientCompanies, GET_clientCompanies } from "../services/apiRoutes";
import { genericGet } from "../services/apiServices";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import CustomLoader from "../components/common/CustomLoader";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomButton from "../components/common/CustomButton";
import CustomTextField from "../components/common/CustomTextField";
import CustomAutocomplete from "../components/common/CustomAutoComplete";
import CustomFilter from "../components/common/CustomFilter";
import FindReplaceOutlinedIcon from '@mui/icons-material/FindReplaceOutlined';
import { genericPost } from "../services/authServices";
import { hasEditPermission, hasDeletePermission } from '../services/CommonServices'
import { useLocation } from "react-router-dom";

const title = 'Company';

const Company = () => {
  const { token } = useContext(AuthContext)
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null); // State to hold selected ID
  const [clientCompanies, setClientCompanies] = useState([]);
  const [owner, setOwner] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  })
  const [getAllLead, setGetAllLead] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowId, setRowId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [permission, setPermissions] = useState()
  const [industryType, setIndustryType] = useState([]);
  const [businessGroup, setBusinessGroup] = useState([]);
  const [rating, setRating] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [companyOwnershipTypes, setCompanyOwnershipTypes] = useState([]);
  const [filterPayload, setFilterPayload] = useState({
    clientCompanyName: '',
    ratingId: '',
    companyTypeId: '',
    ownershipId: '',
    industryTypeId: '',
    businessGroupId: '',
    billingCountryId: '',
    fromDate: '',
    toDate: ''

  });

  const filterOpen = () => setOpenDrawer(true);
  const filterClose = () => setOpenDrawer(false);



  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleUpdateClick = (clientCompanyId) => {
    setSelectedId(clientCompanyId); // Updated to use selectedId
    setUpdateModal(true);
  };

  const handleOpenDeleteAlert = (rowId) => {
    setDeleteAlert(true);
    setRowId(rowId);
  };

  const handleCloseUpdate = () => {
    setUpdateModal(false);
  };


  const handleDelete = async (clientCompanyId) => {
    setLoading(true);
    const authToken = sessionStorage.getItem('accessToken');

    try {
      const response = await axios.delete(Delete_clientCompanies(clientCompanyId), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        fetchClientCompanies();
        setDeleteAlert(false);
        setMessage({
          open: true,
          text: response?.data?.message || "Delete successfully",
          type: "success",
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      if (error.response) {
        if (error.response.status === 409) {
          setMessage({
            open: true,
            text: error.response.data?.error || "Cannot delete Client Company. Related Shipment exists.",
            type: "error",
          });
        } else {
          setMessage({
            open: true,
            text: "An error occurred while deleting the client company.",
            type: "error",
          });
        }
      } else {
        console.error("Error deleting client company", error);
        setMessage({
          open: true,
          text: "Network error. Please try again later.",
          type: "error",
        });
      }
    }
  };



  const handleFilterChange = (field, value) => {
    setFilterPayload((prev) => ({
      ...prev,
      [field]: value || '',
    }));
  };
  const handleClearFilters = useMemo(() => {
    return () => {
      setFilterPayload({
        clientCompanyName: '',
        ratingId: '',
        companyTypeId: '',
        ownershipId: '',
        industryTypeId: '',
        businessGroupId: '',
        billingCountryId: '',
        fromDate: '',
        toDate: ''
      });
      fetchClientCompanies();
    };
  }, []);


  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    let rolePermisionsData = JSON.parse(sessionStorage.getItem('userData')) || []
    let editPermision = hasEditPermission(currentPath, rolePermisionsData?.permissions)
    let deletePermission = hasDeletePermission(currentPath, rolePermisionsData?.permissions)
    setPermissions({
      edit: editPermision,
      delete: deletePermission
    })
  }, [])

  useEffect(() => {
    fetchClientCompanies()
  }, [token])

  useEffect(() => {
    getCommonData();
  }, []);
  const columns = useMemo(
    () => [
      { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
      {
        accessorKey: "clientCompanyName", header: "Company", size: 150, enableSorting: false, tableHeader: {
          justifyContent: 'flex-start',
        },
      },
      {
        accessorKey: "country", header: "Country", size: 30, enableSorting: false, tableHeader: {
          justifyContent: 'flex-start',
        },
      },
      { accessorKey: "mobile", header: "Contact No.", size: 150, enableSorting: false, tableStyle: { textAlign: "center" }, },
      {
        accessorKey: "email", header: "Email ID", size: 200, enableSorting: false, tableHeader: {
          justifyContent: 'flex-start',
        },
      },
      {
        accessorKey: "Contacts", header: "Company Admin", size: 100, enableSorting: false, tableHeader: {
          justifyContent: 'flex-start',
        },
      },
      {
        accessorKey: "action",
        header: "Action",
        size: 50,
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>

              <IconButton onClick={() => handleViewClick(row.original.clientCompanyId)}>
                <VisibilityIcon style={{ color: "#059212" }} />
              </IconButton>
              {permission?.edit && <IconButton onClick={() => handleUpdateClick(row.original.clientCompanyId)}>
                <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
              </IconButton>}
              {permission?.delete && <IconButton onClick={() => handleOpenDeleteAlert(row.original.clientCompanyId)}>
                <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
              </IconButton>}
            </div>
          </>
        ),
      },
    ],
    [permission]
  );

  const fetchClientCompanies = async () => {
    setLoading(true);
    try {
      // const response = await genericGet({ url: GET_clientCompanies });
      const response = await genericPost(GET_clientCompanies, filterPayload, {});

      if (response.data.success && response.data.clientCompanies) {
        const clientCompanyData = response.data.clientCompanies.map((company, index) => ({
          clientCompanyId: company.clientCompanyId || '',
          index: (index + 1).toString().padStart(2, '0'),
          clientCompanyName: company.clientCompanyName || '',
          phone: company.phone || '',
          mobile: company.mobile || '',
          email: company.email || '',
          address: company.address || '',
          country: company.billingCountry?.name || '',
          Contacts: company.companyAdmin?.firstName || '',
          Contacts: `${company.companyAdmin?.firstName || ''} ${company.companyAdmin?.lastName || ''}`,

        }));
        setClientCompanies(clientCompanyData);
      } else {
        setError('No client companies found');
      }
    } catch (error) {
      setError('Error fetching client companies');
      console.error('Error fetching client companies', error);
    } finally {
      setLoading(false);
    }
  };


  // const getCommonData = async () => {
  //   try {
  //     const [industryTypeData, ratingData, conutriesData] = await Promise.all([
  //       getIndustryType(),
  //       getRating(),
  //       getCountries()
  //     ]);

  //     setIndustryType(industryTypeData);
  //     setRating(ratingData);
  //     setConutries(conutriesData);
  //   } catch (error) {
  //     console.error('Error fetching common data', error);
  //   }
  // };
  const getCommonData = async () => {

    const industryTypeData = await getIndustryType();
    setIndustryType(industryTypeData);

    const ratingData = await getRating();
    setRating(ratingData);

    const countriesData = await getCountries();
    setCountries(countriesData);

    const businessGroupData = await getBusinessGroup();
    setBusinessGroup(businessGroupData);
    const owner = await getOwner();
    setOwner(owner);
    const companyTypesData = await getCompanyTypes();
    setCompanyTypes(companyTypesData);

    const companyOwnershipData = await getCompanyOwnershipType();
    setCompanyOwnershipTypes(companyOwnershipData);
  };

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleViewClick = (clientCompanyId) => {
    // navigate(`/app/company/${clientCompanyId}`);
    navigate(`/app/companyview/${clientCompanyId}`);
  };

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <CustomLoader open={loading} />
        <DataTable
          title={title}
          columns={columns}
          data={clientCompanies}
          handleOpen={handleOpen}
          buttonTitle="Create"
          handleFilterButton={filterOpen}
          fiterButtonTitile="Fiter"

        />
        <CustomModal open={modal} handleClose={handleClose}>
          <Createcompany
            handleClose={handleClose}
            fetchClientCompanies={fetchClientCompanies}
            setMessage={setMessage}
            owner={owner}
            industryType={industryType}
            businessGroups={businessGroup}
            rating={rating}
            countries={countries}
            states={states}
            cities={cities}
            companyTypes={companyTypes}
            companyOwnershipTypes={companyOwnershipTypes}

          />
        </CustomModal>

        <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
          <UpdateClientCompany
            handleClose={handleCloseUpdate}
            getAllLead={getAllLead.find((v) => v.leadId == selectedId)}
            clientCompanyId={selectedId}
            fetchClientCompanies={fetchClientCompanies}
            setMessage={setMessage}
            owner={owner}
            industryType={industryType}
            businessGroups={businessGroup}
            rating={rating}
            countries={countries}
            states={states}
            cities={cities}
            companyTypes={companyTypes}
            companyOwnershipTypes={companyOwnershipTypes}
          />
        </CustomModal>

        <MessageBox
          open={message.open}
          onClose={() => setMessage({ ...message, open: false })}
          message={message.text}
          handleClose={() => setMessage({ ...message, open: false })}
          type={message.type}
        />
        <CustomDelete
          handleDelete={handleDelete}
          open={deleteAlert}
          rowId={rowId}
          handleClose={() => setDeleteAlert(false)}
        />
        <CustomFilter
          drawerTitle="Filter Options"
          drawerWidth={300}
          openDrawer={openDrawer}
          onClose={filterClose}
          drawerContent={
            <>
              <Box>
                <Grid container spacing={1} sx={{ padding: 0, }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <CustomTextField
                      placeholder="Search Company"
                      type="text"
                      value={filterPayload.clientCompanyName}
                      onChange={(e) => handleFilterChange('clientCompanyName', e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <CustomAutocomplete
                      options={countries}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Country"
                      onChange={(event, newValue) => handleFilterChange("billingCountryId", newValue?.id)}
                      value={countries.find((item) => item.id === filterPayload.billingCountryId) || null}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <CustomAutocomplete
                      options={companyTypes}
                      getOptionLabel={(option) => option.title}
                      placeholder="company Types"
                      onChange={(event, newValue) => handleFilterChange("companyTypeId", newValue?.id)}
                      value={companyTypes.find((item) => item.id === filterPayload.companyTypeId) || null}

                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <CustomAutocomplete
                      options={businessGroup}
                      getOptionLabel={(option) => option.title}
                      placeholder="Business Group"
                      onChange={(event, newValue) => handleFilterChange("businessGroupId", newValue?.id)}
                      value={businessGroup.find((item) => item.id === filterPayload.businessGroupId) || null}

                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <CustomAutocomplete
                      options={industryType}
                      getOptionLabel={(option) => option.title}
                      placeholder="Industry Type"
                      onChange={(event, newValue) => handleFilterChange("industryTypeId", newValue?.id)}
                      value={industryType.find((item) => item.id === filterPayload.industryTypeId) || null}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <CustomAutocomplete
                      options={companyOwnershipTypes}
                      getOptionLabel={(option) => option.title}
                      placeholder="Ownership"
                      onChange={(event, newValue) => handleFilterChange("ownershipId", newValue?.id)}
                      value={companyOwnershipTypes.find((item) => item.id === filterPayload.ownershipId) || null}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <CustomAutocomplete
                      options={rating}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Rating"
                      onChange={(event, newValue) => handleFilterChange("ratingId", newValue?.id)}
                      value={rating.find((item) => item.id === filterPayload.ratingId) || null}
                    />
                  </Grid>


                  <Grid item xs={12} sm={12} md={12}>
                    <CustomTextField
                      fieldLabel="from Date"
                      type="date"
                      value={filterPayload.fromDate}
                      onChange={(e) => handleFilterChange('fromDate', e.target.value)}
                      placeholder="From Date"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <CustomTextField
                      fieldLabel="to Date"
                      type="date"
                      value={filterPayload.toDate}
                      onChange={(e) => handleFilterChange('toDate', e.target.value)}
                      placeholder="To Date"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'center', marginTop: "10px" }}>
                    <CustomButton
                      startIcon={<FindReplaceOutlinedIcon />}
                      title="Apply"
                      size="small"
                      variant="contained"
                      onClick={fetchClientCompanies}
                    />
                    <CustomButton
                      variant="outlined"
                      title="Clear"
                      startIcon={<ClearRounded />}
                      style={cif_fob_style}
                      onClick={handleClearFilters}
                    />

                  </Grid>
                </Grid>
              </Box>
            </>
          }
        />

      </Box>
    </>
  );
};

export default Company;

const filterLabelStyle = {
  fontSize: '14px',
  color: "#212529",
  paddingTop: "10px",
  textTransform: "capitalize"
}
const cif_fob_style = {
  backgroundColor: '#ffff',
  color: '#007bff',
  textTransform: "capitalize",
  borderRadius: 4,
  border: '1px solid #007bff',
  height: 30,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: "100px"

}
