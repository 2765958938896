import React, { useState, useEffect, useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import CustomModal from '../../common/CustomModal'; // Assuming you have a CustomModal component
import { genericGet, renderHtmlContent } from '../../../services/apiServices';
import { Delete_call_id, DELETE_NOTES, GET_NOTES_LIST } from '../../../services/apiRoutes';
import CustomDelete from '../../common/CustomDeleteAlert'
import axios from 'axios';
import MessageBox from '../../common/CustomMessageBox';
import CustomLoader from '../../common/CustomLoader';
import { genericPost } from '../../../services/authServices';
import CustomStatus from '../../common/CustomStatus';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import CreateNotes from '../CreateOverView/CreateNotes';
import UpdateNotes from '../UpdateOverView/UpdateNotes';


export function Notes({ data, leadId }) {
    const [notesData, setNotesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedCall, setSelectedCall] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [rowId, setRowId] = useState('')
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })
    const [viewModal, setViewModal] = useState(false);
    const handleOpenView = (notesId) => {
        setSelectedCall(notesId);
        setViewModal(true);
    };
    const handleCloseView = () => {
        setViewModal(false);
        setSelectedCall('');
    };

    const handleViewClick = (notesId) => {
        handleOpenView(notesId);
        setSelectedCall(notesId);
    };
    const handleOpenCreate = () => {
        setCreateModalOpen(true);
    };
    const handleCloseCreate = () => {
        setCreateModalOpen(false);
    };
    const handleOpenUpdate = (notesId) => {
        setSelectedCall(notesId);
        setUpdateModalOpen(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModalOpen(false);
        setSelectedCall(null);
    };

    const handleUpdateClick = (call) => {
        handleOpenUpdate(call);
    };
    const handleDelete = async (notesId) => {
        setLoading(true)
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(DELETE_NOTES(notesId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                fetchData()
                setDeleteAlert(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error deleting lead", error);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const payload = {
                clientCompanyId: data.clientCompanyId,
                leadId: leadId,
            };
            const response = await genericPost(GET_NOTES_LIST, payload);
            if (response.data.success && response.data.notes) {
                const mappedNotes = response.data.notes.map((note, index) => ({
                    notesId: note.notesId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    userId: note.userId || '',
                    companyId: note.companyId || '',
                    note: renderHtmlContent(note.note || ''),
                    clientCompanyId: note.clientCompanyId || '',
                    leadId: note.leadId || '',
                    importShipmentId: note.importShipmentId || '',
                    exportShipmentId: note.exportShipmentId || '',
                    createdAt: note.createdAt || '',
                    updatedAt: note.updatedAt || ''
                }));
                setNotesData(mappedNotes);
            } else {
                setError('No calls found.');
            }
        } catch (err) {
            setError('Error fetching call data.');
            console.error('Error fetching call data:', err);
        } finally {
            setLoading(false);
        }
    };
    const handleOpenDeleteAlert = (notesId) => {
        setDeleteAlert(true)
        setRowId(notesId)
    }
    useEffect(() => {
        fetchData();
    }, []);


    if (loading) return <p>   <CustomLoader open={loading} /> </p>;
    if (error) return <p>{error}</p>;

    const Column = [
        {
            id: 'index',
            label: 'SN',
            accessor: 'index',
            sx: { width: { xs: "40px", sm: "40px", md: "40px" } },
        },
        { id: 'note', label: 'Notes', accessor: 'note' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            sx: { width: { xs: "50px", sm: "50px", md: "50px" } },
            Cell: ({ row }) => (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        {/* <IconButton onClick={() => handleViewClick(row.notesId)} style={{ marginRight: '1px' }}>
                            <VisibilityIcon style={{ color: "#059212" }} />
                        </IconButton> */}
                        <IconButton onClick={() => handleUpdateClick(row.notesId)} style={{ marginRight: '1px' }}>
                            <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteAlert(row.notesId)}>
                            <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                        </IconButton>
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            <DetailsPageCard
                Icon={NoteOutlinedIcon}
                columns={Column}
                data={notesData}
                title={'Notes'}
                buttonTitle={'Create Notes'}
                handleOpen={handleOpenCreate}
            />
            <CustomModal open={createModalOpen} handleClose={handleCloseCreate}>
                <CreateNotes
                    fetchData={fetchData}
                    handleClose={handleCloseCreate}
                    setMessage={setMessage}
                    clientCompanyId={data.clientCompanyId}
                    data={data}
                    leadId={leadId}
                />
            </CustomModal>

            <CustomModal open={updateModalOpen} handleClose={handleCloseUpdate}>
                <UpdateNotes
                    fetchData={fetchData}
                    handleClose={handleCloseUpdate}
                    notesId={selectedCall}
                    setMessage={setMessage}
                />
            </CustomModal>

            {/* <CustomModal
                open={viewModal}
                modalStyles={{
                    maxHeight: '50vh',
                    top: "10%",
                }}
                handleClose={handleCloseView}>
                <CallView
                    handleClose={handleCloseView}
                    fetchData={fetchData}
                    callId={selectedCall}
                    setMessage={setMessage}
                    CleintCompanyData={data}
                />
            </CustomModal>
 */}
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}

            />
        </>
    );
}
