import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import { READ_ORG_USERS, UPDATE_ORG_USERS } from '../../../services/apiRoutes';
import CustomButton from '../../common/CustomButton';
import CustomTextField from '../../common/CustomTextField';
import CustomStyle from '../../common/CustomStyle';
import CustomAutocomplete from '../../common/CustomAutoComplete';

const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    mobile: Yup.string().required('Mobile Number is required'),
    roleId: Yup.number().required("User Level is required"),
    profileId: Yup.number().required("Profile is required"),
});

const UpdateUserManagement = ({ handleClose, setMessage, companyUserId, companyRoles, profile, fetchData }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!companyUserId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_ORG_USERS(companyUserId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
                setData(response.data.companyUsers);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [companyUserId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                roleId: Number(values.roleId),
                profileId: Number(values.profileId),
                user: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    mobile: values.mobile,
                },
            };

            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_ORG_USERS(companyUserId), payload, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "User Updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 500);
            } else {
                setMessage({
                    open: true,
                    text: "Something went wrong",
                    type: "error",
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: "Something went wrong",
                type: "error",
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    firstName: data?.user?.firstName || '',
                    lastName: data?.user?.lastName || '',
                    email: data?.user?.email || '',
                    mobile: data?.user?.mobile || '',
                    roleId: data?.roleId ? String(data.roleId) : '',
                    profileId: data?.profileId ? String(data.profileId) : '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, values, handleBlur, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update User
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ px: "5%", paddingTop: "5px" }}>
                            <Grid item xs={12} sm={6}>
                                <CustomTextField
                                    name="firstName"
                                    fieldLabel="First Name"
                                    value={values.firstName}
                                    required={true}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                    placeholder="Enter First Name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomTextField
                                    name="lastName"
                                    fieldLabel="Last Name"
                                    value={values.lastName}
                                    required={true}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.lastName && Boolean(errors.lastName)}
                                    helperText={touched.lastName && errors.lastName}
                                    placeholder="Enter Last Name"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomTextField
                                    name="email"
                                    type="email"
                                    fieldLabel="Email"
                                    value={values.email}
                                    required={true}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                    placeholder="Enter Email"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomTextField
                                    name="mobile"
                                    value={values.mobile}
                                    type='number'
                                    fieldLabel="Mobile"
                                    required={true}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.mobile && Boolean(errors.mobile)}
                                    helperText={touched.mobile && errors.mobile}
                                    placeholder="Enter Mobile Number"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={companyRoles}
                                    required={true}
                                    fieldLabel="User Role"
                                    getOptionLabel={(option) => option.title}
                                    value={companyRoles.find((v) => v.id === Number(values.roleId))}
                                    onChange={(e, newValue) => setFieldValue("roleId", newValue?.id || '')}
                                    error={touched.roleId && Boolean(errors.roleId)}
                                    helperText={touched.roleId && errors.roleId}
                                    placeholder="Select User Role"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={profile}
                                    required={true}
                                    fieldLabel="Profile"
                                    getOptionLabel={(option) => option.title}
                                    value={profile.find((v) => v.id === Number(values.profileId))}
                                    onChange={(e, newValue) => setFieldValue("profileId", newValue?.id || '')}
                                    error={touched.profileId && Boolean(errors.profileId)}
                                    helperText={touched.profileId && errors.profileId}
                                    placeholder="Select Profile"
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateUserManagement;
