import React, { useMemo, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { genericGet } from "../../services/apiServices";
import { GET_CUSTOMER_LEDGER } from "../../services/apiRoutes";
import { AuthContext } from "../../context/AuthContext";
import CustomLoader from "../common/CustomLoader";
import CustomReport from "../common/CustomReport";
const title = "Customer Ledger";

const CustomerLedger = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: 'customerName', header: 'Customer Name', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'mailingCountry', header: 'Country', size: 100, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'contactPerson', header: 'Contact Person', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'totalAmount', header: 'Billing Amount', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'receiptAmount', header: 'Receipt Amount', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'balanceAmount', header: 'Balance Amount', size: 50, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton onClick={() => handleViewClick(row.original.clientCompanyId)}>
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );
    const handleViewClick = (clientCompanyId) => {
        navigate(`/app/reports/customerledger/${clientCompanyId}`);
    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_CUSTOMER_LEDGER });
            if (response.data.success && response.data.commercialInvExps) {
                const formattedData = response.data.commercialInvExps.map((invoice, index) => {
                    const totalAmount = invoice.particulars?.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0) || 0;
                    const receiptAmount = parseFloat(invoice.receiptAmount || 0);
                    const balanceAmount = (totalAmount - receiptAmount).toFixed(2); // Calculate balance amount

                    return {
                        clientCompanyId: invoice.commercialInvExpId || '',
                        index: (index + 1).toString().padStart(2, '0'),
                        userId: invoice.userId || '',
                        companyId: invoice.companyId || '',
                        customerNamesId: invoice.customerNamesId || '',
                        currency: invoice.currency?.currency || '',
                        currencySymbol: invoice.currency?.currency_symbol || '',
                        contactPerson: invoice.contacts.map(contact => `${contact.firstName} ${contact.lastName}`).join(', ') || '',
                        reference: invoice.reference || '',
                        commercialInvoiceNo: invoice.commercialInvoiceNo || '',
                        shipmentId: invoice.shipmentId || '',
                        proformaInvoiceNo: invoice.ProformaInvExp?.proformaInvoiceNo || '',
                        date: invoice.date || '',
                        receiptAmount: receiptAmount.toFixed(2), // Ensure receipt amount is formatted
                        balanceAmount, // Include the calculated balance amount
                        dueDate: invoice.dueDate || '',
                        note: invoice.note || '',
                        customerName: invoice.customer?.clientCompanyName || '',
                        mobile: invoice.customer?.mobile || '',
                        mailingCountry: invoice.customer?.mailingCountry?.name || '',
                        billingCountry: invoice.customer?.billingCountry?.name || '',
                        totalAmount: totalAmount.toFixed(2), // Format total amount
                        particulars: invoice.particulars?.map(particular => ({
                            commercialParticularsExpId: particular.commercialParticularsExpId || '',
                            particularName: particular.particularName || '',
                            description: particular.description || '',
                            qty: particular.qty || '',
                            rate: particular.rate || '',
                            amount: particular?.amount || '',
                            unit: particular.productsUnit?.unitName || '',
                            productName: particular.products?.productName || '',
                        })) || []
                    };
                });
                setData(formattedData);
            } else {
                setError('No commercial invoices found');
            }
        } catch (error) {
            setError('Error fetching commercial invoices');
            console.error('Error fetching commercial invoices', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData()
    }, [token])

    const defaultView = 'customerledger';
    const mainContent = (
        <>
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
            />
        </>
    );


    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />

        </>
    );
};
export default CustomerLedger;
