import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import CustomAutocomplete from '../common/CustomAutoComplete';
import CustomButton from '../common/CustomButton';
import CustomMaster from '../common/CustomMaster';
import { useLocation } from "react-router-dom";
import { AccessDenied, hasCreatePermission, hasDeletePermission, hasEditPermission, hasViewPermission } from '../../services/CommonServices';

const Template = ({ values, errors, touched, setFieldValue }) => {
    // Sample voucher data for the autocomplete options
    const [permission, setPermissions] = useState()

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        let rolePermisionsData = JSON.parse(sessionStorage.getItem('userData')) || []
        let editPermision = hasEditPermission(currentPath, rolePermisionsData?.permissions)
        let deletePermission = hasDeletePermission(currentPath, rolePermisionsData?.permissions)
        let createPermision = hasCreatePermission(currentPath, rolePermisionsData?.permissions)
        let viewPermision = hasViewPermission(currentPath, rolePermisionsData?.permissions)

        setPermissions({
            edit: editPermision,
            delete: deletePermission,
            create: createPermision,
            view: viewPermision,
        })
    }, [])

    const templateData = [
        { id: 1, title: 'List' },
        { id: 2, title: 'Ganmtt' },
        { id: 3, title: 'Kanban' },
    ];
    const defaultView = 'template';
    const mainContent = (
        <>
            <Box sx={{ width: '300px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={10}
                    >
                        <CustomAutocomplete
                            options={templateData}
                            fieldLabel="Select Template"
                            required
                            getOptionLabel={(option) => option.title}
                            placeholder="Select Template"
                        />
                    </Grid>
                    <Grid item xs={12} md={2}
                    > <Box sx={{ pt: 3 }}>
                            <CustomButton
                                title="Save"
                                size="small"
                                variant="contained"
                                type="submit"
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Box >
        </>
    );

    const getMainContent = (mainContent, permission) => {
        return permission?.view ? mainContent : null;
    };


    return (

        <>
            <CustomMaster
                defaultView={defaultView}
                mainContent={getMainContent(mainContent, permission)}
            />
            {!permission?.view && <AccessDenied />}
        </>
    );
};

export default Template;
