import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded, AddRounded, DeleteRounded } from "@mui/icons-material";
import { Card, } from "@mui/material";
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import { genericPost } from '../../../services/authServices';
import { CREATE_SALES_CONTRACT_EXPORT, LIST_PROFORMA_INVOICES_EXPORT } from '../../../services/apiRoutes';
import CustomAutocomplete from "../../common/CustomAutoComplete";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomStyle from "../../common/CustomStyle";
import CustomPrefixSuffixField from "../../common/CustomPrefixSuffixField";

const ValidationSchema = Yup.object().shape({
    customerNamesId: Yup.number().required("Customer Name is required"),
    salesContractNo: Yup.string().required("sales Contract is required"),
    proformaInvExpId: Yup.string().required("proforma Inv No. is required"),
    date: Yup.string().required("Date is required"),
    dueDate: Yup.string().required("Due Date is required"),
    particulars: Yup.array()
        .of(
            Yup.object().shape({
                particularName: Yup.string().required("Particular is required"),
                qty: Yup.number().required("Quantity is required"),
                rate: Yup.number().required("Rate is required"),
                amount: Yup.number().required("amount is required"),
                unit: Yup.string().required("Unit is required"),
            })
        )
        .required("Particulars are required"),
});


const CreateSalesContract = ({ handleClose, setMessage, shipmentOrgId, fetchData, currencyId, currencyList, currencySymbol, customerList, productsList, customerName, newShippingId, shipmentId, expProformaPrefix, expProformaSuffix }) => {
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                shipmentOrgId: values.shipmentOrgId,
                customerNamesId: values.customerNamesId,
                proformaInvExpId: values.proformaInvExpId,
                reference: values.reference,
                currencyId: values.currencyId,
                salesContractNo: values.salesContractNo,
                shipmentId: values.shipmentId,
                date: values.date,
                dueDate: values.dueDate,
                note: values.note,
                particulars: values.particulars,
            };

            const response = await genericPost(CREATE_SALES_CONTRACT_EXPORT, payload);

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Sales Contract created successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
            }
            else if (response.status === 409) {
                setMessage({
                    open: true,
                    text: response.message || 'already exists ',
                    type: 'error',
                });
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };
    const currentDate = formatDate(new Date());
    const [proformaInvoiceList, setProformaInvoiceList] = useState([]);
    useEffect(() => {
        getProformaInvoice();
    }, []);

    const getProformaInvoice = async () => {
        try {
            const payload = {
                customerNamesId: customerName,
                shipmentId: shipmentId
            };
            const response = await genericPost(LIST_PROFORMA_INVOICES_EXPORT, payload, {
            });
            if (response.data && response.data.success && response.data.data) {
                const proform = response.data.data.map((invoice) => ({
                    id: invoice.proformaInvExpId || '',
                    // title: invoice.proformaInvoiceNo || '',
                    title: `${expProformaPrefix || ''} ${invoice.proformaInvoiceNo || ''} ${expProformaSuffix || ''}`,
                    reference: invoice.reference || '',
                    dueDate: invoice.dueDate || '',
                    note: invoice.note || '',
                    particulars: invoice.particulars.map((particular) => ({
                        qty: particular.qty || '',
                        particularName: particular.particularName || '',
                        rate: particular.rate || '',
                        description: particular.description || '',
                        amount: particular.amount || '',
                        productName: particular.products?.productName || '',
                        unit: particular.unit || ''
                    }))

                }));
                setProformaInvoiceList(proform);
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching suppliers list:', error);
            return [];
        }
    };

    const [formData] = useState({
        customerNamesId: customerName || "",
        currencyId: currencyId || "",
        reference: "",
        proformaInvExpId: "",
        salesContractNo: newShippingId,
        shipmentOrgId: shipmentOrgId,
        shipmentId: shipmentId,
        date: currentDate || "",
        dueDate: "",
        note: "",
        particulars: [
            {
                particularName: "",
                qty: "",
                rate: "",
                unit: "",
                amount: "",
                description: "",
            },
        ],
    });

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Create Sales Contract
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save And New"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ px: "0%" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <InsertDriveFileOutlinedIcon />
                                    <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                        Bill To
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    options={customerList}
                                    fieldLabel="Customer Name"
                                    required={true}
                                    readOnly={true}
                                    getOptionLabel={(option) => option.title}
                                    value={customerList.find((option) => option.id === values.customerNamesId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('customerNamesId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Supplier Name"
                                    error={touched.customerNamesId && !!errors.customerNamesId}
                                    helperText={touched.customerNamesId && errors.customerNamesId}
                                    sx={CustomStyle.readOnly}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    required={true}
                                    options={proformaInvoiceList}
                                    fieldLabel="Proforma Invoice"

                                    getOptionLabel={(option) => option.title}
                                    value={
                                        proformaInvoiceList.find(
                                            (option) => option.id === values.proformaInvExpId
                                        ) || null
                                    }
                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            setFieldValue("proformaInvExpId", newValue.id);
                                            setFieldValue("proformaInvoiceNo", newValue.title);
                                            setFieldValue("reference", newValue.reference);
                                            setFieldValue("dueDate", newValue.dueDate);
                                            setFieldValue("note", newValue.note);
                                            const updatedParticulars = newValue.particulars.map((particular) => ({
                                                particularName: parseInt(particular?.particularName || ""),
                                                qty: particular.qty || "",
                                                rate: particular.rate || "",
                                                unit: parseInt(particular.unit || ""),
                                                amount: particular.amount || "",
                                                description: particular.description || "",
                                            }));
                                            setFieldValue("particulars", updatedParticulars);
                                        } else {
                                            setFieldValue("proformaInvExpId", "");
                                            setFieldValue("proformaInvoiceNo", "");
                                            setFieldValue("reference", "");
                                            setFieldValue("dueDate", "");
                                            setFieldValue("note", "");
                                            setFieldValue("particulars", [
                                                {
                                                    particularName: "",
                                                    qty: "",
                                                    rate: "",
                                                    unit: "",
                                                    amount: "",
                                                    description: "",
                                                },
                                            ]);
                                        }
                                    }}
                                    placeholder="Select Proforma Invoice"
                                    error={touched.proformaInvExpId && !!errors.proformaInvExpId}
                                    helperText={touched.proformaInvExpId && errors.proformaInvExpId}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    placeholder="Enter Sales Contract No."
                                    name="salesContractNo"
                                    fieldLabel="Sales Contract No."
                                    required={true}
                                    value={values.salesContractNo}
                                    onChange={handleChange}
                                    error={touched.salesContractNo && !!errors.salesContractNo}
                                    helperText={touched.salesContractNo && errors.salesContractNo}
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomTextField
                                    placeholder="Enter Reference"
                                    name="reference"
                                    fieldLabel="Reference"
                                    value={values.reference}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomTextField
                                    required={true}
                                    placeholder="Enter Date"
                                    type="date"
                                    name="date"
                                    fieldLabel="Date"
                                    readOnly={true}
                                    value={values.date}
                                    onChange={handleChange}
                                    sx={{
                                        backgroundColor: "#E9ECEF"
                                    }}
                                    error={touched.date && !!errors.date}
                                    helperText={touched.date && errors.date}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomTextField
                                    placeholder="Enter Due Date"
                                    type="date"
                                    name="dueDate"
                                    fieldLabel="Due Date"
                                    required={true}
                                    value={values.dueDate}
                                    onChange={handleChange}
                                    error={touched.dueDate && !!errors.dueDate}
                                    helperText={touched.dueDate && errors.dueDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.currencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('currencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Currency"
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}
                                    error={touched.currencyId && !!errors.currencyId}
                                    helperText={touched.currencyId && errors.currencyId}
                                />
                            </Grid>

                        </Grid>
                        <FieldArray
                            name="particulars"
                            render={arrayHelpers => (
                                <>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={6}>
                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <AssignmentLateOutlinedIcon />
                                                <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                                    Particulars
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Box style={{ overflowX: 'auto', overflowY: 'auto' }}>
                                            <Table style={{ width: "100%" }}>
                                                <TableHead>
                                                    <TableRow style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                                                        <TableCell style={{ ...styles.tablecell, width: "50px" }} rowSpan={2}>No.</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "300px" }} rowSpan={2}>Particular</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Qty</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Rate</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Unit</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Amount</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "50px" }} rowSpan={2}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {values.particulars.map((particular, index) => {
                                                        const handleQtyChange = (e) => {
                                                            const newQty = parseFloat(e.target.value) || 0;
                                                            const newRate = parseFloat(particular.rate) || 0;
                                                            const newAmount = (newQty * newRate).toFixed(2);
                                                            setFieldValue(`particulars.${index}.qty`, newQty);
                                                            setFieldValue(`particulars.${index}.amount`, newAmount);
                                                        };
                                                        const handleRateChange = (e) => {
                                                            const newRate = parseFloat(e.target.value) || 0;
                                                            const newQty = parseFloat(particular.qty) || 0;
                                                            const newAmount = (newQty * newRate).toFixed(2);
                                                            setFieldValue(`particulars.${index}.rate`, newRate);
                                                            setFieldValue(`particulars.${index}.amount`, newAmount);
                                                        };
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell style={styles.tablecell}>{index + 1}</TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomAutocomplete
                                                                        options={productsList || []}
                                                                        required={true}
                                                                        getOptionLabel={(option) => option.productName || ''}
                                                                        value={productsList?.find((option) => option.productsId === particular?.particularName) || null}
                                                                        onChange={(e, newValue) => {
                                                                            setFieldValue(`particulars.${index}.particularName`, newValue ? newValue.productsId : '');
                                                                            setFieldValue(`particulars.${index}.unit`, newValue ? newValue.productsUnit?.productsUnitId : '');
                                                                        }}
                                                                        placeholder="Select Product Name"
                                                                        error={touched.particulars?.[index]?.particularName && !!errors.particulars?.[index]?.particularName}
                                                                        helperText={touched.particulars?.[index]?.particularName && errors.particulars?.[index]?.particularName}
                                                                    />
                                                                    <CustomTextarea
                                                                        name={`particulars.${index}.description`}
                                                                        value={particular?.description || ''}
                                                                        onChange={handleChange}
                                                                        placeholder="Enter Description"
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        required={true}
                                                                        name={`particulars.${index}.qty`}
                                                                        type="number"
                                                                        value={particular.qty}
                                                                        onChange={handleQtyChange}
                                                                        placeholder="Qty"
                                                                        error={touched.particulars?.[index]?.qty && !!errors.particulars?.[index]?.qty}
                                                                        helperText={touched.particulars?.[index]?.qty && errors.particulars?.[index]?.qty}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        required={true}
                                                                        name={`particulars.${index}.rate`}
                                                                        type="number"
                                                                        value={particular.rate}
                                                                        onChange={handleRateChange}
                                                                        placeholder="Rate"
                                                                        error={touched.particulars?.[index]?.rate && !!errors.particulars?.[index]?.rate}
                                                                        helperText={touched.particulars?.[index]?.rate && errors.particulars?.[index]?.rate}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        required={true}
                                                                        readOnly={true}
                                                                        name={`particulars.${index}.unit`}
                                                                        value={productsList
                                                                            ?.find((option) => option.productsId === particular?.particularName)
                                                                            ?.productsUnit?.unitName || ''}
                                                                        onChange={(e) =>
                                                                            setFieldValue(`particulars.${index}.unit`, e.target.value)
                                                                        }
                                                                        placeholder="Unit"
                                                                        sx={{
                                                                            backgroundColor: "#E9ECEF"
                                                                        }}
                                                                        error={touched.particulars?.[index]?.unit && !!errors.particulars?.[index]?.unit}
                                                                        helperText={touched.particulars?.[index]?.unit && errors.particulars?.[index]?.unit}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        required={true}
                                                                        name={`particulars.${index}.amount`}
                                                                        type="number"
                                                                        value={particular.amount || ''}
                                                                        onChange={handleChange}
                                                                        placeholder="Amount"
                                                                        sx={{
                                                                            backgroundColor: "#E9ECEF"
                                                                        }}
                                                                        readOnly={true}
                                                                        error={touched.particulars?.[index]?.amount && !!errors.particulars?.[index]?.amount}
                                                                        helperText={touched.particulars?.[index]?.amount && errors.particulars?.[index]?.amount}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <DeleteOutlineOutlinedIcon
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                        style={{ fontSize: "20px" }} />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>

                                                <TableRow style={{ height: '40px' }}>
                                                    <TableCell style={styles.tablecell} colSpan={7}>
                                                        <div style={{ margin: "auto", textAlign: "center" }}>
                                                            <CustomButton
                                                                sx={Additemstyle}
                                                                startIcon={<AddRounded />}
                                                                title="Add Item"
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => arrayHelpers.push({
                                                                    particularName: '',
                                                                    qty: '',
                                                                    rate: '',
                                                                    amount: '',
                                                                    description: '',
                                                                    unit: ''
                                                                })}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ height: '40px' }}>
                                                    <TableCell style={styles.tablecellfooter} colSpan={2}  > Total</TableCell>
                                                    <TableCell style={styles.tablecellfooter}  >
                                                        {values.particulars
                                                            .reduce((total, particular) => total + (parseFloat(particular.qty) || 0), 0)
                                                        }                                                    </TableCell>

                                                    <TableCell style={styles.tablecellfooter} colSpan={2}  > </TableCell>
                                                    <TableCell style={styles.tablecellfooter} >
                                                        {currencySymbol} &nbsp;
                                                        {values.particulars
                                                            .reduce((total, particular) => total + ((particular.qty || 0) * (particular.rate || 0)), 0)
                                                            .toFixed(2)}
                                                    </TableCell>
                                                    <TableCell style={styles.tablecellfooter} > </TableCell>
                                                </TableRow>
                                            </Table>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        />
                        <Grid container spacing={1} style={{ paddingBottom: "5px", paddingTop: "10px" }}>
                            <Grid item xs={12} md={7}>
                                <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                    In Words
                                </Typography>
                                <Typography style={{ color: "#212529", fontWeight: 400, fontSize: "14px" }}>
                                    Only
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div>
                                    <Box display="flex" flexDirection="row">
                                        <Box display="inline-flex" alignItems="center" pr={1}>
                                            {currencySymbol}
                                        </Box>
                                        <Box display="inline-flex" alignItems="center">
                                            <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                                Summary
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Card variant="outlined" style={{ padding: "2px" }}>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Typography style={{ fontWeight: "600", fontSize: "15px", paddingLeft: "10px" }}>
                                                Grand Total:
                                            </Typography>
                                            <CustomPrefixSuffixField
                                                required={true}
                                                prefix={currencySymbol}
                                                name="totalAmount"
                                                value={values.particulars
                                                    .reduce((total, particular) => total + ((particular.qty || 0) * (particular.rate || 0)), 0)
                                                    .toFixed(2)} readOnly={true}
                                                placeholder="Total Amount"
                                                sx={{
                                                    backgroundColor: "#E9ECEF"
                                                }}
                                            />
                                        </Box>
                                    </Card>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <CustomTextarea
                                    name="note"
                                    fieldLabel="Note"
                                    placeholder="Enter Note"
                                    value={values.note}
                                    onChange={handleChange}
                                    sx={{ height: '50px !important' }}

                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateSalesContract;
const styles = {
    table: {
        minWidth: 650,
    },
    textMuted: {
        color: '#6c757d',
    },

    labelCell: {
        width: '180px',
    },
    tableContainer: {
        marginBottom: '16px',
    },
    header: {
        marginBottom: '16px',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    customTableRow: {
        height: '20px',
    },
    editButton: {
        textAlign: 'right',
    },
    showDetailsSpan: {
        padding: '8px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
        cursor: 'pointer',
    },
    expandIcon: {
        color: '#212529',
    },
    boxheading: {
        width: 70,  // Width of the box
        height: 70, // Height of the box, making it square
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)', // Radial gradient background
        color: 'white', // Text color
        display: 'flex', // Center content horizontally
        alignItems: 'center', // Center content vertically
        justifyContent: 'center', // Center content horizontally
        borderRadius: 14, // Rounded corners
        boxShadow: 3, // Box shadow for depth
    },
    headingname: {
        paddingTop: '10px',
        fontWeight: '600',
        fontSize: '20px',
        color: '#212529',
    },
    avatarcircle: {
        backgroundColor: '#007BFF',
        width: '30px',
        height: '30px',
        fontSize: '15px',
    },
    cardtittile: {
        padding: '8px',
        paddingLeft: '35px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
    },
    tablebutton: {
        backgroundColor: '#007bff',
        color: '#ffff',
        textTransform: 'capitalize',
        borderRadius: 4,
        border: '2px solid #007bff',
        height: 30,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tablecell: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    tablecellfooter: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'right',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },

};


const Additemstyle = {
    color: '#212529 !important',
    textTransform: 'capitalize !important',
    borderRadius: '4px !important',
    border: '1px dashed #aecce4 !important',
    height: '30px !important',
    display: 'inline-flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    backgroundColor: '#e7f0f7 !important',
    width: '100% !important',
};


const supplierOption = [
    { title: 'supplierOption 1', id: 1 },
    { title: 'supplierOption 2', id: 2 },
    { title: 'supplierOption 3', id: 3 }
];