import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton, CircularProgress } from "@mui/material";
import {
    SaveRounded,
} from "@mui/icons-material";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import CustomTextField from "../common/CustomTextField";
import CustomButton from "../common/CustomButton";
import { genericGet } from "../../services/apiServices"
import { CREATE_PRODUCTS_GROUP, CREATE_UNIT_LIST, DELETE_PRODUCTS_GROUP, GET_PRODUCTS_GROUP } from "../../services/apiRoutes";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { genericPost } from '../../services/authServices';
import MessageBox from "../common/CustomMessageBox";
import CustomMaster from "../common/CustomMaster";
import CustomModal from "../common/CustomModal";
import UpdateProductsgroup from "./UpdateMaster/UpdateProductsgroup";
import CustomDelete from '../common/CustomDeleteAlert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from "axios";

const title = "Products group";
const ValidationSchema = Yup.object().shape({
    productsGroupName: Yup.string().required('products Group Name is required'),
});
const Productsgroup = () => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [ProductsGroupList, setProductsGroupList] = useState([]);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [rowId, setRowId] = useState('')

    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true)
        setRowId(rowId)
    }
    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: 'productsGroupName', header: 'Product Group Name', tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 200
            },
            {
                accessorKey: "action",
                header: "Action",
                size: 10,
                Cell: ({ row }) => (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                            <IconButton onClick={() => handleUpdateClick(row.original.groupId)}>

                                <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                            </IconButton>
                            <IconButton
                                onClick={() => handleOpenDeleteAlert(row.original.groupId)}
                            >
                                <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                            </IconButton>
                        </div>
                    </>
                ),
            },
        ],
    );
    const getProductsGroup = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_PRODUCTS_GROUP });
            if (response.data) {
                const productsGroupData = response.data.productsGroup.map((group, index) => ({
                    ...group,
                    index: (index + 1).toString().padStart(2, '0'),
                    groupId: group.productsGroupId || '',
                    groupName: group.productsGroupName || '',
                    companyId: group.companyId || '',
                    userId: group.userId || '',
                    createdAt: group.createdAt || '',
                    updatedAt: group.updatedAt || '',
                    index: (index + 1).toString().padStart(2, '0'),
                }));
                setProductsGroupList(productsGroupData);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching products group", error);
        }
    };

    const handleUpdateClick = (groupId) => {
        setSelectedId(groupId);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };


    useEffect(() => {
        getProductsGroup()
    }, [])
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const payload = {
                productsGroupName: values.productsGroupName,

            };
            const response = await genericPost(CREATE_PRODUCTS_GROUP, payload);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    getProductsGroup();
                }, 1000);
                resetForm();
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };
    const handleDelete = async (productsGroupId) => {
        setLoading(true);
        const authToken = sessionStorage.getItem('accessToken');

        try {
            const response = await axios.delete(DELETE_PRODUCTS_GROUP(productsGroupId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: "deleted successfully",
                    type: "success",
                });
                getProductsGroup();
                setDeleteAlert(false);

            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);

            if (error.response) {
                if (error.response.status === 409) {
                    setMessage({
                        open: true,
                        text: error.response.data?.error || "Cannot delete Data. Related Shipment exists.",
                        type: "error",
                    });
                } else {
                    setMessage({
                        open: true,
                        text: "An error occurred while deleting the Data.",
                        type: "error",
                    });
                }
            } else {
                console.error("Error deleting Data", error);
                setMessage({
                    open: true,
                    text: "Network error. Please try again later.",
                    type: "error",
                });
            }
        }
    };


    const [formData] = useState({
        productsGroupName: '',
    });

    const defaultView = 'productsgroup';
    const mainContent = (
        <>
            <Box   >
                <Formik
                    initialValues={formData}
                    validationSchema={ValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, errors, touched, setFieldValue, values, handleChange }) => (
                        <Form>
                            <Grid container spacing={2} sx={{ px: "0%" }}>

                                <Grid item xs={12} md={3} lg={3}>
                                    <CustomTextField
                                        placeholder="Enter Product Group Name"
                                        name="productsGroupName"
                                        fieldLabel="Product Group Name"
                                        required
                                        value={values.productsGroupName}
                                        onChange={handleChange}
                                        error={touched.productsGroupName && !!errors.productsGroupName}
                                        helperText={touched.productsGroupName && errors.productsGroupName}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <Box sx={{ pt: 3 }}>
                                        <CustomButton
                                            startIcon={<SaveRounded />}
                                            title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                            size="small"
                                            variant="contained"
                                            type="submit"
                                            disabled={isSubmitting}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
            <DataTable
                title={title}
                columns={columns}
                data={ProductsGroupList}
            />
            <CustomModal
                modalStyles={{
                    maxHeight: '40vh',
                    top: "10%",
                }}
                open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateProductsgroup
                    handleClose={handleCloseUpdate}
                    productsGroupId={selectedId}
                    fetchData={getProductsGroup}
                    setMessage={setMessage}
                />
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}
            />
        </>
    );
    return (
        <CustomMaster
            defaultView={defaultView}
            mainContent={mainContent} />
    );
};
export default Productsgroup;
