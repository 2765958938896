import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CustomModal from "../../common/CustomModal";
import { useShipmentDetails } from './useShipmentDetails';
import { genericGet } from '../../../services/apiServices';
import { DELETE_DUTY, GET_PRODUCTS_LIST, GET_SUPPLIERS_LIST, LIST_DUTIES, } from '../../../services/apiRoutes';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MessageBox from '../../common/CustomMessageBox';
import CustomDelete from '../../common/CustomDeleteAlert';
import axios from 'axios';
import { genericPost } from '../../../services/authServices';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';
import { formatDate, getCurrencyList } from '../../../services/CommonServices';
import CreateDuty from '../CreateForm/CreateDuty';
import UpdateDuty from '../UpdateForm/UpdateDuty';
import DutyView from '../ViewDetails/DutyView';

export default function Duty() {
    const { token } = useContext(AuthContext)
    const { importShipmentId } = useParams();
    const [newShippingId, setNewShippingId] = useState(null);
    const [open, setOpen] = useState(true);
    const [leadModal, setLeadModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [activeTab, setActiveTab] = useState('duty');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [suppliersList, setSuppliersList] = useState([]);
    const [error, setError] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [rowId, setRowId] = useState('')

    const navigate = useNavigate();
    const { shipmentDetails, loading: shipmentLoading } = useShipmentDetails(importShipmentId);

    const [updateModal, setUpdateModal] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [dutyId, setdutyId] = useState('');

    let supplierId, cifFob, currency;

    const hasFetched = useRef(false);

    useEffect(() => {
        if (shipmentDetails) {
            supplierId = shipmentDetails.supplierId;
            cifFob = shipmentDetails.cifFob.cifFobName;
            currency = shipmentDetails.currency.currency;
            getCommonData();
            fetchData();
            fetchProductsList();
            getSuppliersList();
            hasFetched.current = true;

        }
    }, [shipmentDetails, dutyId, token, leadModal]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: supplierId,
                shipmentId: shipmentId,
                commanShipmentId: 0,
            };
            const response = await genericPost(LIST_DUTIES, payload);

            if (response.data.success) {
                const duties = response.data.duty.map((duty, index) => {
                    const totalAmount = duty.particulars.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
                    const totalDuty = duty.particulars.reduce((acc, item) => acc + parseFloat(item.duty || 0), 0);
                    const totalTax = duty.particulars.reduce((acc, item) => acc + parseFloat(item.tax || 0), 0);
                    const firstProductName = duty.particulars[0]?.products?.productName || '';
                    return {
                        index: (index + 1).toString().padStart(2, '0'),
                        dutyId: duty.dutyId || '',
                        userId: duty.userId || '',
                        companyId: duty.companyId || '',
                        clientCompanyId: duty.clientCompanyId || '',
                        currencyName: duty.currency?.currency || '',
                        shipmentId: duty.shipmentId || '',
                        voucherNo: duty.voucherNo || '',
                        date: formatDate(duty.date || ''),
                        currencyId: duty.currencyId || '',
                        boeConversionRate: duty.boeConversionRate || '',
                        boeUpload: duty.boeUpload || '',
                        note: duty.note || '',
                        createdAt: duty.createdAt || '',
                        updatedAt: duty.updatedAt || '',
                        totalAmount: totalAmount ? totalAmount.toFixed(2) : '',
                        totalDuty: totalDuty ? totalDuty.toFixed(2) : '',
                        totalTax: totalTax ? totalTax.toFixed(2) : '',
                        productName: firstProductName,
                        particulars: duty.particulars.map(({ dutyParticularId, particular, hsn, duty, amount, tax, dutyPersentage, taxPersentage }) => ({
                            dutyParticularId,
                            particular,
                            hsn,
                            duty,
                            amount: parseFloat(amount).toFixed(2),
                            tax,
                            dutyPersentage,
                            taxPersentage,
                        }))
                    };
                });
                const { newShippingId } = response.data;
                setNewShippingId(newShippingId);
                setData(duties); // Store the parsed data
            } else {
                setError('No duties found');
            }
        } catch (error) {
            setError('Error fetching duties');
            console.error('Error fetching duties', error);
        } finally {
            setLoading(false);
        }
    };
    const handleDelete = async (dutyId) => {
        setLoading(true);
        const authToken = sessionStorage.getItem('accessToken');

        try {
            const response = await axios.delete(DELETE_DUTY(dutyId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                setDeleteAlert(false);
                setMessage({
                    open: true,
                    text: "deleted successfully",
                    type: "success",
                });
                fetchData();
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);

            if (error.response) {
                if (error.response.status === 409) {
                    setMessage({
                        open: true,
                        text: error.response.data?.error || "Cannot delete Data. Related Shipment exists.",
                        type: "error",
                    });
                } else {
                    setMessage({
                        open: true,
                        text: "An error occurred while deleting the Data.",
                        type: "error",
                    });
                }
            } else {
                console.error("Error deleting Data", error);
                setMessage({
                    open: true,
                    text: "Network error. Please try again later.",
                    type: "error",
                });
            }
        }
    };

    const columns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        {
            id: 'voucherNo', label: 'Voucher No.', accessor: 'voucherNo', styleAccessor: {
                textAlign: 'center'
            }
        },
        {
            id: 'date', label: 'Date', accessor: 'date', styleAccessor: {
                textAlign: 'center'
            }
        },
        { id: 'currencyName', label: 'Currency', accessor: 'currencyName' },

        { id: 'productName', label: 'Product Name', accessor: 'productName' },
        {
            id: 'boeConversionRate', label: 'boe Conversion Rate', accessor: 'boeConversionRate', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'totalAmount', label: 'Amount', accessor: 'totalAmount', styleAccessor: {
                textAlign: 'right',
            },
        },

        {
            id: 'totalDuty', label: 'Duty Amount', accessor: 'totalDuty', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'totalTax', label: 'Tax Amount', accessor: 'totalTax', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.dutyId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.dutyId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.dutyId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];


    const fetchProductsList = async () => {
        const authToken = sessionStorage.getItem('accessToken');
        const payload = {
            clientCompanyId: supplierId,
        };

        try {
            const response = await axios.post(GET_PRODUCTS_LIST, payload, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200 && response.data.success) {
                const productData = response.data.productsList;
                console.log(productData);
                setProducts(productData);
            } else {
                console.error('Failed to fetch products list:', response.data);
            }
        } catch (error) {
            console.error('Error fetching products list:', error);
        }
    };

    const getSuppliersList = async () => {
        try {
            const payload = {
                clientCompanyId: supplierId,

            };

            const response = await genericPost(GET_SUPPLIERS_LIST, payload, {
            });

            if (response.data && response.data.success && response.data.clientCompanies) {
                const suppliersData = response.data.clientCompanies.map((supplier) => ({
                    id: supplier.clientCompanyId,
                    title: supplier.clientCompanyName,
                }));
                setSuppliersList(suppliersData);

            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching suppliers list:', error);
            return [];
        }
    };



    if (!shipmentDetails) {
        return <p>   <CustomLoader open={shipmentLoading} /></p>;
    }

    const getCommonData = async () => {
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
    }


    const handleViewClick = (id) => {
        handleOpenView(id);
    };

    const handleUpdateClick = (id) => {
        setdutyId(id);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setdutyId('');
    };

    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true)
        setRowId(rowId)
    }


    const handleOpen = () => {
        setLeadModal(true);
    };

    const handleClose = () => {
        setLeadModal(false);
    };


    const handleOpenView = (id) => {
        setdutyId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setdutyId('');
    };


    const companyName = shipmentDetails.supplier.clientCompanyName;
    const phoneNumber = shipmentDetails.supplier.mobile;
    const supplierIdName = shipmentDetails.supplierId;
    const shipmentId = shipmentDetails.shipmentId;
    const shipmentOrgId = shipmentDetails.importShipmentId;
    const currencyId = shipmentDetails?.currency?.id ?? '';
    const currencySymbol = shipmentDetails?.currency?.currency_symbol ?? '';
    return (
        <div>
            <CustomLoader open={loading} />
            <DetailsPageCard
                columns={columns}
                title={'Duty and Tax'}
                buttonTitle={'Create Duty and Tax'}
                handleOpen={handleOpen}
                data={data}
                loading={loading}
                error={error}
            />
            <CustomModal open={leadModal} handleClose={handleClose}>
                <CreateDuty
                    fetchData={fetchData}
                    setMessage={setMessage}
                    handleClose={handleClose}
                    suppliersList={suppliersList}
                    shipmentId={shipmentId}
                    productsList={products}
                    supplierIdName={supplierIdName}
                    currencyList={currencyList}
                    currencyId={currencyId}
                    currencySymbol={currencySymbol}
                    newShippingId={newShippingId}
                    shipmentOrgId={shipmentOrgId}

                />
            </CustomModal>

            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateDuty
                    fetchData={fetchData}
                    setMessage={setMessage}
                    handleClose={handleCloseUpdate}
                    dutyId={dutyId}
                    suppliersList={suppliersList}
                    productsList={products}
                    currencyList={currencyList}
                    supplierIdName={supplierIdName}
                    shipmentId={shipmentId}
                />
            </CustomModal>
            <CustomModal open={viewModal} handleClose={handleCloseView}>
                <DutyView
                    fetchData={fetchData}
                    handleClose={handleCloseView}
                    dutyId={dutyId}
                    suppliersList={suppliersList}
                    productsList={products}
                />
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}
            />
        </div>
    );
}
