import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { GET_PRODUCT_WISE_EXP_SHIPMENT } from "../../services/apiRoutes";
import { genericGet } from "../../services/apiServices";
import CustomReport from "../common/CustomReport";
const title = "Product Wise Export Shipment";

const ProductWiseExportShipment = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'date', header: 'Date', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'shipmentId', header: 'Shipment ID', size: 150, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'productName', header: 'Product Name', size: 200, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'qty', header: 'Qty', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'numberOfContainers', header: 'No. of Containers', size: 150, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton >
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_PRODUCT_WISE_EXP_SHIPMENT });
            if (response.data.success) {
                const productNamesBySn = []; // Predefined mapping for SN 1 and SN 2
                let currentSn = 1; // Track the SN for each entry

                const commercialInvoices = response.data.data.flatMap((invoice) =>
                    invoice.particulars.map((particular, index) => {
                        // Assign product name based on SN logic
                        const productName =
                            currentSn <= productNamesBySn.length
                                ? productNamesBySn[currentSn - 1]
                                : particular.products?.productName || "";

                        const totalAmount = particular.qty * parseFloat(particular.rate || 0);

                        const result = {
                            sn: currentSn++, // Increment SN for each entry
                            commercialInvoiceId: invoice.commercialInvoiceId || "",
                            index: (index + 1).toString().padStart(2, '0'),
                            productName,
                            reference: invoice.reference || "",
                            numberOfContainers: invoice.numberOfContainers ?? '',
                            proformaInvoiceNo: invoice.proformaInvoiceNo || "",
                            date: invoice.date || "",
                            shipmentId: invoice.shipmentId || "",
                            dueDate: invoice.dueDate || "",
                            note: invoice.note || "",
                            upload: invoice.ciUpload || "",
                            qty: particular.qty,
                            amount: totalAmount.toFixed(2),
                        };

                        return result;
                    })
                );

                setData(commercialInvoices);
            } else {
                setError("No commercial invoices found");
            }
        } catch (error) {
            setError("Error fetching commercial invoices");
            console.error("Error fetching commercial invoices", error);
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        fetchData()
    }, [token])


    const defaultView = 'productwiseexportshipment';
    const mainContent = (
        <DataTable
            title={title}
            columns={columns}
            data={data}
        />
    );


    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>
    );
};
export default ProductWiseExportShipment;
