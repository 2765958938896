import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { AppBarMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from "../../common/CustomModal";
import CreateProformaInvoice from "../CreateForm/CreateProformaInvoice";
import { useShipmentDetails } from './useShipmentDetails';
import { genericGet } from '../../../services/apiServices';
import { DELETE_PROFORMA_INVOICE, GET_PRODUCTS_LIST, GET_SUPPLIERS_LIST, LIST_PROFORMA_INVOICES } from '../../../services/apiRoutes';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MessageBox from '../../common/CustomMessageBox';
import CustomDelete from '../../common/CustomDeleteAlert';
import UpdateProformaInvoice from '../UpdateForm/UpdateProformaInvoice';
import axios from 'axios';
import { genericPost } from '../../../services/authServices';
import ProformaInvoiceView from '../ViewDetails/ProformaInvoiceView';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';
import { getCurrencyList } from '../../../services/CommonServices';

export default function ProformaInvoiceImport() {
    const { token } = useContext(AuthContext)
    const { importShipmentId } = useParams();
    const [open, setOpen] = useState(true);
    const [leadModal, setLeadModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Proforma Invoice');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [suppliersList, setSuppliersList] = useState([]);
    const [error, setError] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [rowId, setRowId] = useState('')

    const navigate = useNavigate();
    const { shipmentDetails, loading: shipmentLoading } = useShipmentDetails(importShipmentId);

    const [updateModal, setUpdateModal] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [proformainvoiceId, setProformainvoiceId] = useState('');

    let supplierId, cifFob, currency;

    const hasFetched = useRef(false);

    useEffect(() => {
        if (shipmentDetails) {
            supplierId = shipmentDetails.supplierId;
            cifFob = shipmentDetails.cifFob.cifFobName;
            currency = shipmentDetails.currency.currency;
            getCommonData();
            fetchData();
            fetchProductsList();
            getSuppliersList();
            hasFetched.current = true;

        }
    }, [shipmentDetails, proformainvoiceId, token, leadModal]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                supplierNamesId: supplierId,
                shipmentId: shipmentId
            };
            const response = await genericPost(LIST_PROFORMA_INVOICES, payload);

            if (response.data.success) {
                const proformaInvoices = response.data.proformaInvoice.map((invoice, index) => {
                    const totalQuantity = invoice.particulars
                        .reduce((total, particular) => total + (parseFloat(particular.qty) || 0), 0)
                    const totalRate = invoice.particulars.reduce((acc, item) => acc + parseFloat(item.rate || 0), 0);
                    const firstProductName = invoice.particulars.length > 0
                        ? invoice.particulars[0].products.productName
                        : 'N/A';
                    const firstProductUnit = invoice.particulars.length > 0
                        ? invoice.particulars[0].productsUnit.unitName
                        : 'N/A';
                    const totalAmount = invoice.particulars.reduce((acc, item) => acc + (item.qty * parseFloat(item.rate)), 0);
                    return {
                        proformainvoiceId: invoice.proformaInvoiceId || '',
                        index: (index + 1).toString().padStart(2, '0'),
                        ciNo: invoice.proformaInvoiceNo || '',
                        date: invoice.date || '',
                        dueDate: invoice.dueDate || '',
                        reference: invoice.reference || '',
                        note: invoice.note || '',
                        upload: invoice.piUpload || '',
                        currencyName: currency || '',
                        productName: firstProductName || '',
                        firstProductUnit: firstProductUnit || '',
                        totalQty: totalQuantity,
                        totalRate: `${invoice.currency?.currency_symbol || ''} ${totalRate ? totalRate.toFixed(2) : ""}`,
                        totalAmount: `${invoice.currency?.currency_symbol || ''} ${totalAmount ? totalAmount.toFixed(2) : ""}`,
                        particulars: invoice.particulars.map(({
                            particular = '',
                            qty = '',
                            rate = '',
                            unit = ''
                        }) => ({ particular, qty, rate, unit }))
                    };
                });

                setData(proformaInvoices);
            } else {
                setError('No proforma invoices found');
            }
        } catch (error) {
            setError('Error fetching proforma invoices');
            console.error('Error fetching proforma invoices', error);
        } finally {
            setLoading(false);
        }
    };
    const handleDelete = async (proformaInvoiceId) => {
        setLoading(true);
        const authToken = sessionStorage.getItem('accessToken');

        try {
            const response = await axios.delete(DELETE_PROFORMA_INVOICE(proformaInvoiceId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                setDeleteAlert(false);
                setMessage({
                    open: true,
                    text: "deleted successfully",
                    type: "success",
                });
                fetchData();
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);

            if (error.response) {
                if (error.response.status === 409) {
                    setMessage({
                        open: true,
                        text: error.response.data?.error || "Cannot delete Data. Related Shipment exists.",
                        type: "error",
                    });
                } else {
                    setMessage({
                        open: true,
                        text: "An error occurred while deleting the Data.",
                        type: "error",
                    });
                }
            } else {
                console.error("Error deleting Data", error);
                setMessage({
                    open: true,
                    text: "Network error. Please try again later.",
                    type: "error",
                });
            }
        }
    };

    const fetchProductsList = async () => {
        const authToken = sessionStorage.getItem('accessToken');
        const payload = {
            clientCompanyId: supplierId,
        };

        try {
            const response = await axios.post(GET_PRODUCTS_LIST, payload, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200 && response.data.success) {
                const productData = response.data.productsList;
                console.log(productData);
                setProducts(productData);
            } else {
                console.error('Failed to fetch products list:', response.data);
            }
        } catch (error) {
            console.error('Error fetching products list:', error);
        }
    };

    const getSuppliersList = async () => {
        try {
            const payload = {
                clientCompanyId: supplierId,

            };

            const response = await genericPost(GET_SUPPLIERS_LIST, payload, {
            });

            if (response.data && response.data.success && response.data.clientCompanies) {
                const suppliersData = response.data.clientCompanies.map((supplier) => ({
                    id: supplier.clientCompanyId,
                    title: supplier.clientCompanyName,
                }));
                setSuppliersList(suppliersData);

            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching suppliers list:', error);
            return [];
        }
    };



    if (!shipmentDetails) {
        return <p>   <CustomLoader open={shipmentLoading} /></p>;
    }

    const getCommonData = async () => {
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
    }


    const handleViewClick = (id) => {
        handleOpenView(id);
    };

    const handleUpdateClick = (id) => {
        setProformainvoiceId(id);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setProformainvoiceId('');
    };
    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true)
        setRowId(rowId)
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setLeadModal(true);
    };

    const handleClose = () => {
        setLeadModal(false);
    };


    const handleOpenView = (id) => {
        setProformainvoiceId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setProformainvoiceId('');
    };


    const companyName = shipmentDetails.supplier.clientCompanyName;
    const phoneNumber = shipmentDetails.supplier.mobile;
    const supplierIdName = shipmentDetails.supplierId;
    const shipmentId = shipmentDetails.shipmentId;
    const shipmentOrgId = shipmentDetails.importShipmentId;
    const currencyId = shipmentDetails?.currency?.id ?? '';
    const currencySymbol = shipmentDetails?.currency?.currency_symbol ?? '';


    const handleBackClick = () => {
        navigate("/app/import/shipement");
    };

    const proformaInvoiceColumns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        {
            id: 'ciNo', label: 'Proforma Invoice No.', accessor: 'ciNo', styleAccessor: {
                textAlign: 'center'
            }
        },
        {
            id: 'date', label: 'Date', accessor: 'date', styleAccessor: {
                textAlign: 'center'
            }
        },
        { id: 'currencyName', label: 'Currency', accessor: 'currencyName' },

        { id: 'productName', label: 'Product Name', accessor: 'productName' },
        { id: 'firstProductUnit', label: 'Unit', accessor: 'firstProductUnit' },
        {
            id: 'totalQty', label: 'Qty', accessor: 'totalQty', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'totalRate', label: 'Rate', accessor: 'totalRate', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'totalAmount', label: 'Amount', accessor: 'totalAmount', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.proformainvoiceId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.proformainvoiceId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.proformainvoiceId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <div>
            {/* <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={AppBarMenu(importShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />
            <Main open={open}> */}

            <CustomLoader open={loading} />
            <DetailsPageCard
                columns={proformaInvoiceColumns}
                title={'Proforma Invoice'}
                buttonTitle={'Create Proforma Invoice'}
                handleOpen={handleOpen}
                data={data}
                loading={loading}
                error={error}
            />
            <CustomModal open={leadModal} handleClose={handleClose}>
                <CreateProformaInvoice
                    fetchData={fetchData}
                    setMessage={setMessage}
                    handleClose={handleClose}
                    suppliersList={suppliersList}
                    shipmentId={shipmentId}
                    productsList={products}
                    supplierIdName={supplierIdName}
                    currencyList={currencyList}
                    currencyId={currencyId}
                    shipmentOrgId={shipmentOrgId}
                    currencySymbol={currencySymbol}
                />
            </CustomModal>

            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateProformaInvoice
                    fetchData={fetchData}
                    setMessage={setMessage}
                    handleClose={handleCloseUpdate}
                    proformaInvoiceId={proformainvoiceId}
                    suppliersList={suppliersList}
                    productsList={products}
                    currencyList={currencyList}
                />
            </CustomModal>
            <CustomModal open={viewModal} handleClose={handleCloseView}>
                <ProformaInvoiceView
                    fetchData={fetchData}
                    handleClose={handleCloseView}
                    proformaInvoiceId={proformainvoiceId}
                    suppliersList={suppliersList}
                    productsList={products}
                />
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}
            />
            {/* </Main> */}
        </div>
    );
}
