import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, Stack, Typography } from '@mui/material';
import { genericGet } from '../../services/apiServices';
import { GET_DASHBOARD_LIST } from '../../services/apiRoutes';

const colorMap = [
    { range: [0, 10], backgroundColor: '#fae7de', color: '#94867f' },
    { range: [11, 20], backgroundColor: '#dbf2d3', color: '#8da187' },
    { range: [21, 30], backgroundColor: '#cacfe6', color: '#707382' },
    { range: [31, 40], backgroundColor: '#d4c2fc', color: '#6f5aa7' },
    { range: [41, 50], backgroundColor: '#ffd1a9', color: '#a75a39' },
    { range: [51, 60], backgroundColor: '#ffacb7', color: '#703d4f' },
    { range: [61, 70], backgroundColor: '#d1ffd8', color: '#4f7048' },
    { range: [71, 80], backgroundColor: '#c9f0ff', color: '#4a7076' },
    { range: [81, 90], backgroundColor: '#ffc6c6', color: '#704a4a' },
    { range: [91, 100], backgroundColor: '#fff1a8', color: '#706c39' },
];

const getCardStyle = (value) => {
    const style = colorMap.find(({ range }) => value >= range[0] && value <= range[1]);
    return style || { backgroundColor: '#ffffff', color: '#000000' };
};

const CustomCard = ({ title, value }) => {
    const cardStyle = getCardStyle(value);
    return (
        <Card variant="outlined" sx={{ ...cardStyle, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', borderRadius: '10px' }}>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Stack justifyContent="center" alignItems="center">
                    <Typography gutterBottom variant="h5" component="div">
                        {value}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                </Stack>
            </Box>
        </Card>
    );
};

const DashboardCard = () => {
    const [cardData, setCardData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await genericGet({ url: GET_DASHBOARD_LIST });
                if (response.data && response.data.success && response.data.cardData) {
                    setCardData(response.data.cardData);
                } else {
                    console.error('Failed to fetch data:', response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    return (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={4}>
                {cardData.map((data, index) => (
                    <Grid item xs={12} sm={4} md={4} key={index}>
                        <CustomCard title={data.title} value={data.value} />
                    </Grid>
                ))}
            </Grid>
            <br />
        </Box>




    );
};

export default DashboardCard;
