import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { Collapse, } from '@mui/material';
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { menuItems } from '../../constants/DetailsPage';
const drawerWidth = 230;
const miniDrawerWidth = 50;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: open ? 0 : `-${drawerWidth}px`,
        overflow: 'hidden',
        width: `calc(100vw - ${open ? drawerWidth : 0}px)`,
        boxSizing: 'border-box',
    })
);

const CustomReport = ({ mainContent, defaultView, }) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const [miniDrawerOpen, setMiniDrawerOpen] = React.useState(false);

    const [expanded, setExpanded] = React.useState({});
    const [activeView, setActiveView] = React.useState(defaultView);

    const handleToggle = (index) => {
        setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const handleViewChange = (view) => {
        setActiveView(view);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
        setMiniDrawerOpen(false);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setMiniDrawerOpen(true);
    };
    useEffect(() => {
        const initialExpandedIndex = menuItems.findIndex(item =>
            item.subItems.some(subItem => subItem.view === defaultView)
        );
        if (initialExpandedIndex !== -1) {
            setExpanded(prev => ({ ...prev, [initialExpandedIndex]: true }));
        }
    }, [defaultView]);


    const CustomLink = styled(RouterLink)(() => ({
        fontSize: "14px",
        fontWeight: 600,
        textDecoration: 'none',
        transition: 'color 0.2s',
        '&:hover': {
            textDecoration: 'none',
        },
    }));

    const drawerStyle = {
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: drawerWidth,
            top: '60px',
            boxSizing: 'border-box',
            height: 'calc(100vh - 60px)',
        },
    }
    const boxStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 16px',
    }
    const listItemButtonStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pl: 1, pt: 1, pb: 1,
    }
    const miniDrawerStyle = {
        width: miniDrawerOpen ? `${miniDrawerWidth}px` : "0px",
        flexShrink: 0,
        overflow: "hidden",
        transition: "width 0.3s ease",
        '& .MuiDrawer-paper': {
            width: miniDrawerOpen ? `${miniDrawerWidth}px` : "0px",
            top: '60px',
            boxSizing: 'border-box',
            overflow: "hidden",
        },
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                sx={drawerStyle}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <Box sx={boxStyle}>
                    <Typography variant="h4" sx={{ fontWeight: "600" }}>Report</Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Box>
                <Divider />
                <List>
                    {menuItems.map((item, index) => (
                        <React.Fragment key={index}>
                            <ListItem disablePadding>
                                <ListItemButton
                                    sx={listItemButtonStyle}
                                    onClick={() => handleToggle(index)}>
                                    <Typography
                                        sx={{
                                            fontWeight: "600", color: "#333333", fontSize: "14px",
                                        }}
                                    >
                                        {item.icon} &nbsp;{item.label}
                                    </Typography>
                                    {expanded[index] ? <ArrowDropDownOutlinedIcon /> : <ArrowDropUpOutlinedIcon />}
                                </ListItemButton>
                            </ListItem>
                            <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {item.subItems.map((subItem, subIndex) => (
                                        <ListItem key={subIndex} disablePadding
                                            style={{ backgroundColor: activeView === subItem.view ? '#007BFF' : 'inherit', }}  >
                                            <CustomLink to={subItem.to} >
                                                <ListItemButton
                                                    sx={{
                                                        pl: 3, pt: 0, pb: 0,
                                                        fontSize: "14px",
                                                        color: activeView === subItem.view ? '#ffff' : 'inherit',
                                                        fontWeight: activeView === subItem.view ? 'bold' : 'normal',
                                                    }}
                                                    onClick={() => handleViewChange(subItem.view)}
                                                >
                                                    <ListItemText primary={subItem.label} />
                                                </ListItemButton>
                                            </CustomLink>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </List>
            </Drawer>
            <Drawer
                sx={miniDrawerStyle}
                variant="persistent"
                anchor="left"
                open={miniDrawerOpen}
            >
                <Box
                    sx={{
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column',
                    }}
                >
                    <Box sx={boxStyle}>
                        <IconButton onClick={handleDrawerOpen}>
                            {theme.direction === 'ltr' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </Box>
                    <Divider />
                    <List>
                        {menuItems.map((item, index) => (
                            <React.Fragment key={index}>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        sx={listItemButtonStyle}
                                        onClick={() => handleToggle(index)}>
                                        <Typography
                                            sx={{
                                                fontWeight: "600", color: "#333333", fontSize: "14px",
                                            }}
                                        >
                                            {item.icon}
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            </Drawer >
            <Main open={open}>
                {mainContent}
            </Main>
        </Box >
    );
};

export default CustomReport;












