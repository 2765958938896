import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, FormLabel, Grid, IconButton } from "@mui/material";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DataTable from "../../components/common/DataTable";
import { CREATE_TERM_CONDITIONS, GET_TERM_CONDITIONS } from "../../services/apiRoutes"
import { genericPost } from '../../services/authServices';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomLoader from "../../components/common/CustomLoader";
import MessageBox from "../../components/common/CustomMessageBox";
import { AuthContext } from "../../context/AuthContext";
import { genericGet, renderHtmlContent } from "../../services/apiServices";
import { CircularProgress } from "@mui/material";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
    SaveRounded,
} from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import CustomButton from "../common/CustomButton";
import { AccessDenied, getVoucher, hasCreatePermission, hasDeletePermission, hasEditPermission, hasViewPermission } from "../../services/CommonServices";
import CustomAutocomplete from "../common/CustomAutoComplete";
import CustomTextarea from "../common/CustomTextarea";
import CustomMaster from "../common/CustomMaster";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation } from "react-router-dom";


const title = "Term and Condition";

const ValidationSchema = Yup.object().shape({
    voucherName: Yup.string().required('Voucher Name is required'),
    // termConditions: Yup.string().required('Term Conditions is required'),
});

const TermConditions = () => {
    const { token } = useContext(AuthContext)
    const [modal, setModal] = useState(false);
    const [permission, setPermissions] = useState()
    const [data, setData] = useState([]);
    const [voucher, setVoucher] = useState([]);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);

    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const onEditorStateChange = (state) => {
        setEditorState(state);
    };

    const convertToHTMLContent = () => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);
        return htmlContent;
    };

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        let rolePermisionsData = JSON.parse(sessionStorage.getItem('userData')) || []
        let editPermision = hasEditPermission(currentPath, rolePermisionsData?.permissions)
        let deletePermission = hasDeletePermission(currentPath, rolePermisionsData?.permissions)
        let createPermision = hasCreatePermission(currentPath, rolePermisionsData?.permissions)
        let viewPermision = hasViewPermission(currentPath, rolePermisionsData?.permissions)

        setPermissions({
            edit: editPermision,
            delete: deletePermission,
            create: createPermision,
            view: viewPermision,
        })
    }, [])



    const columns = useMemo(
        () => [
            { accessorKey: "id", header: "SN", tableStyle: { textAlign: "center" }, size: 50 },
            {
                accessorKey: "voucherName", header: "Voucher", tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 50
            },
            {
                accessorKey: "termConditions", header: "Term and conditions", tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 600
            },
            {
                accessorKey: "action",
                header: "Action",
                size: 10,
                Cell: ({ row }) => (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>

                            {permission?.edit &&
                                <IconButton>
                                    <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                                </IconButton>
                            }
                            {permission?.delete &&
                                <IconButton>
                                    <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                                </IconButton>
                            }
                        </div>
                    </>
                ),
            },
        ],
        [permission]
    );

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await genericGet({ url: GET_TERM_CONDITIONS });
            if (response.data) {
                const CustumData = response.data.termConditions.map((data, index) => ({
                    ...data,
                    id: data.id || '',
                    voucherName: data.voucher.voucherName || '',
                    termConditions: renderHtmlContent(data.termConditions || ''),

                }));
                setData(CustumData);
                setLoading(false)

            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error fetching ", error);
        }
    };

    const getCommonData = async () => {
        const voucherData = await getVoucher();
        setVoucher(voucherData);
    }

    useEffect(() => {
        fetchData()
        getCommonData()
    }, [token])

    const handleOpen = () => {
        setModal(true);
    };
    const handleClose = () => {
        setModal(false)
    }
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const htmlContent = convertToHTMLContent();
            const termConditions = htmlContent;
            const payload = {
                voucherName: values.voucherName,
                termConditions: termConditions,
            };
            const response = await genericPost(CREATE_TERM_CONDITIONS, payload);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                }, 1000);
                resetForm();
            }
            else if (response.status === 409) {
                setMessage({
                    open: true,
                    text: response?.data?.message || 'Already Exists ',
                    type: 'error',
                });
            }
            else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        voucherName: '',
        termConditions: '',

    });
    const defaultView = 'termConditions';
    const mainContent = (
        <>
            {permission?.create &&
                <Formik
                    initialValues={formData}
                    validationSchema={ValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, errors, touched, setFieldValue, values, handleChange }) => (
                        <Form>
                            <Grid container spacing={2} sx={{ px: "0%" }}>
                                <Grid item xs={12} md={3}>
                                    <CustomAutocomplete
                                        options={voucher}
                                        fieldLabel="Voucher"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={voucher.find((option) => option.id === values.voucherName) || null}
                                        onChange={(e, newValue) =>
                                            setFieldValue('voucherName', newValue ? newValue.id : '')
                                        }
                                        placeholder="Select Voucher"
                                        error={touched.voucherName && !!errors.voucherName}
                                        helperText={touched.voucherName && errors.voucherName}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={7}>
                                <CustomTextarea
                                    placeholder="Enter Term Conditions"
                                    name="termConditions"
                                    fieldLabel="Term Conditions"
                                    required={true}
                                    multiline
                                    rows={4}
                                    value={values.termConditions}
                                    onChange={handleChange}
                                    error={touched.termConditions && !!errors.termConditions}
                                    helperText={touched.termConditions && errors.termConditions}
                                    sx={{ height: "150px !important" }}
                                />
                            </Grid> */}
                                <Grid item xs={12} md={7}>
                                    <FormLabel sx={{ paddingBottom: "10px" }} style={fieldLabelstyle}>Term Conditions</FormLabel>
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={onEditorStateChange}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        wrapperStyle={{
                                            border: '1px solid #CED4DA', borderRadius: '4px',
                                        }}
                                        editorStyle={{ padding: "10px" }}
                                    />
                                    {/* {touched.termConditions && errors.termConditions && (
                                    <Box color="red" fontSize="12px" mt={1}>
                                        {errors.termConditions}
                                    </Box>
                                )} */}
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Box sx={{ pt: 18 }}>
                                        <CustomButton
                                            startIcon={<SaveRounded />}
                                            title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                            size="small"
                                            variant="contained"
                                            type="submit"
                                            disabled={isSubmitting}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            }
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
                handleOpen={handleOpen}
            />

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />

        </>
    );
    const getMainContent = (mainContent, permission) => {
        return permission?.view ? mainContent : null;
    };


    return (

        <>
            <CustomMaster
                defaultView={defaultView}
                mainContent={getMainContent(mainContent, permission)}
            />
            {!permission?.view && <AccessDenied />}
        </>
    );
};
export default TermConditions;

const fieldLabelstyle = {
    color: "#212529",
    fontWeight: "600",
    fontSize: "14px",
};