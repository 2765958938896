import React, { useEffect, useState } from "react";
import { Box, Grid, Table, TableHead, TableRow, TableCell, TableBody, Switch, Card, Typography, Divider, Button, IconButton } from "@mui/material";
import CustomModal from '../common/CustomModal';
import RolesAdd from "./RolesAdd";
import { GET_ROLE } from "../../services/apiRoutes";
import { genericGet } from "../../services/apiServices";
import MessageBox from '../common/CustomMessageBox'
import CustomMaster from "../common/CustomMaster";
import { AccessDenied, formatDate, hasCreatePermission, hasDeletePermission, hasEditPermission, hasViewPermission } from "../../services/CommonServices";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import UpdateRole from "./UpdateMaster/UpdateRole";
import CustomButton from "../common/CustomButton";
import { useLocation } from "react-router-dom";


const Roles = () => {
  const [open, setOpen] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [permission, setPermissions] = useState()

  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    let rolePermisionsData = JSON.parse(sessionStorage.getItem('userData')) || []
    let editPermision = hasEditPermission(currentPath, rolePermisionsData?.permissions)
    let deletePermission = hasDeletePermission(currentPath, rolePermisionsData?.permissions)
    let createPermision = hasCreatePermission(currentPath, rolePermisionsData?.permissions)
    let viewPermision = hasViewPermission(currentPath, rolePermisionsData?.permissions)

    setPermissions({
      edit: editPermision,
      delete: deletePermission,
      create: createPermision,
      view: viewPermision,
    })
  }, [])


  const [formData, setFormData] = useState({
    profile_name: "",
    modules: [
      {
        module_name: "Report",
        permission_create: true,
        permission_update: false,
        permission_view: true,
        permission_delete: false
      },
      {
        module_name: "Master",
        permission_create: false,
        permission_update: true,
        permission_view: false,
        permission_delete: true
      }
    ]
  });
  const [loading, setLoading] = useState()
  const [companyRoles, setCompanyRoles] = useState([])
  const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  })
  const getRoles = async () => {
    setLoading(true);
    try {
      const response = await genericGet({ url: GET_ROLE });
      if (response.status == 200) {
        let reponseData = response?.data?.companyRoles.map((v) => ({
          id: v?.roleId,
          title: v?.roleName,
          name: v?.roleName,
          roleDescription: v?.roleDescription,
          createdAt: formatDate(v?.createdAt || ""),
          parentRole: v?.parentRole?.roleName
        }))
        setCompanyRoles(reponseData)
      }
    } catch (error) {

    } finally {
    }
  };

  useEffect(() => {
    getRoles()
  }, [])


  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form data:", formData);
    // You can handle form submission logic here
  };

  const handleUpdateClick = (id) => {
    setSelectedId(id);
    setUpdateModal(true);
  };

  const handleCloseUpdate = () => {
    setUpdateModal(false);
  };

  const defaultView = 'roles';
  const mainContent = (
    <>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={1}
          sx={{
            position: "sticky",
            alignItems: "center"
          }}
        >
          <Grid item xs={6} md={6}>
            <Typography
              sx={{ fontWeight: 600, color: "#212529", fontSize: "18px" }}
            >
              Roles
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            {permission?.create &&
              <CustomButton
                title="Add Roles"
                size="small"
                variant="contained"
                onClick={() => setOpen(true)} />
            }

          </Grid>
        </Grid>

        <Divider />

        <Box sx={{ pt: 2 }}>
          <Box sx={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow sx={{ height: '40px', backgroundColor: '#e7f0f7' }}>
                  <TableCell rowSpan={2}>Role</TableCell>
                  <TableCell rowSpan={2}>Report To</TableCell>
                  <TableCell rowSpan={2}> Description</TableCell>
                  <TableCell rowSpan={2}>Created By</TableCell>
                  <TableCell rowSpan={2}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyRoles && companyRoles.map((v) =>
                  <TableRow key={v.roleId} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                    <TableCell>{v?.name}</TableCell>
                    <TableCell>{v?.parentRole}</TableCell>
                    <TableCell>{v?.roleDescription}</TableCell>
                    <TableCell>{v?.createdAt}</TableCell>
                    <TableCell>
                      {permission?.edit &&
                        <IconButton
                          onClick={() => handleUpdateClick(v.id)}
                        >
                          <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>)}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </form>
      <CustomModal
        open={open}
        handleClose={() => setOpen(false)}
        modalStyles={{
          maxHeight: '60vh',
          top: "10%",
        }}
      >
        <RolesAdd handleClose={() => setOpen(false)} companyRoles={companyRoles} setMessage={setMessage} getRoles={getRoles} />

      </CustomModal>
      <CustomModal
        modalStyles={{
          maxHeight: '60vh',
          top: "10%",
        }}
        open={updateModal} handleClose={handleCloseUpdate}>
        <UpdateRole
          handleClose={handleCloseUpdate}
          roleId={selectedId}
          companyRoles={companyRoles}
          fetchData={getRoles}
          setMessage={setMessage}
        />
      </CustomModal>
      <MessageBox
        open={message.open}
        onClose={() => setMessage({ ...message, open: false })}
        message={message.text}
        type={message.type}
      />
    </>
  );
  const getMainContent = (mainContent, permission) => {
    return permission?.view ? mainContent : null;
  };


  return (

    <>
      <CustomMaster
        defaultView={defaultView}
        mainContent={getMainContent(mainContent, permission)}
      />
      {!permission?.view && <AccessDenied />}
    </>
  );
};

export default Roles;
