import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Divider, CircularProgress, RadioGroup, Radio } from "@mui/material";
import { Card, } from "@mui/material";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { genericPost } from "../../../services/authServices";
import { Create_call, GET_SUPPLIERS_LIST } from "../../../services/apiRoutes";
import CustomButton from "../../common/CustomButton";
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { getContactPersonList } from '../../../services/CommonServices';
import CustomStyle from '../../common/CustomStyle';


// Define validation schema for the form
const callValidationSchema = Yup.object().shape({
    contactId: Yup.number().required('Contact  is required'),
    callingType: Yup.string().required('Calling Type is required'),
    clientCompanyId: Yup.number().required('Client Company is required'),
    callType: Yup.string().required('Call Type is required'),
    callDetails: Yup.string().required('Call Details are required'),
    callStartTime: Yup.date().required('Call Start Time is required'),
    callPurposeId: Yup.number().required('Call Purpose is required'),
    callOutcomeId: Yup.number().required('Call Outcome is required'),
    stageId: Yup.number().required('Stage is required'),
    priorityId: Yup.number().required('Priority is required'),
    leadStatusId: Yup.number().required('Lead Status is required'),
    lossReasonId: Yup.number().required('loss Reason is required'),
});

const CreateExportCall = ({
    handleClose,
    fetchData,
    setMessage,
    callType,
    leadSource,
    leadStatus,
    companies,
    companyTypes,
    callPurposeTypes,
    callOutcomeTypes,
    prioritiesLossReasons,
    taskStatuses,
    leadStages,
    priorities,
    exportShipmentId,
    data
}) => {

    const initialValues = {
        contactId: '',
        leadId: '',
        callingType: '',
        clientCompanyId: '',
        importShipmentId: '',
        exportShipmentId: exportShipmentId,
        subject: '',
        callType: '',
        callDetails: '',
        callStartTime: '',
        callDuration: '',
        callPurposeId: '',
        callOutcomeId: '',
        description: '',
        stageId: '',
        priorityId: '',
        leadStatusId: '',
        clientCompanyTrue: '',
        lossReasonId: null
    };

    const [suppliersList, setSuppliersList] = useState([]);
    const [contactPersonList, setContactPersonList] = useState([]);



    // Handle form submission
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await genericPost(Create_call, values);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "call added successfully",
                    type: "success",
                });
                fetchData()
                setTimeout(() => {
                    handleClose()
                }, 3000)
                handleClose()
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };
    const getCommonData = async () => {
        const contactPersonList = await getContactPersonList();
        setContactPersonList(contactPersonList);
    }
    const getSuppliersList = async () => {
        try {
            const payload = {
                clientCompanyId: data.customerId,
            };
            const response = await genericPost(GET_SUPPLIERS_LIST, payload, {
            });
            if (response.data && response.data.success && response.data.clientCompanies) {
                const suppliersData = response.data.clientCompanies.map((supplier) => ({
                    id: supplier.clientCompanyId,
                    title: supplier.clientCompanyName,
                }));
                setSuppliersList(suppliersData);
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching suppliers list:', error);
            return [];
        }
    };
    useEffect(() => {
        getSuppliersList();
        getCommonData();
    }, []);



    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={initialValues}
                validationSchema={callValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={2}>
                                <Typography sx={{ fontWeight: 600, color: "#212529", fontSize: "18px" }}>
                                    Create Call
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={10} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box sx={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <SaveRounded />}
                                        title="Save"
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title="Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%", paddingTop: "5px" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <CustomAutocomplete
                                    options={callType}
                                    fieldLabel="Select Call Type"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={callType.find((option) => option.id === values.callType) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('callType', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Select Call Type"
                                    error={touched.callType && !!errors.callType}
                                    helperText={touched.callType && errors.callType}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={suppliersList}
                                    fieldLabel="Client Company"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={suppliersList.find((option) => option.id === values.clientCompanyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('clientCompanyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Client Company"
                                    error={touched.clientCompanyId && !!errors.clientCompanyId}
                                    helperText={touched.clientCompanyId && errors.clientCompanyId}
                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <CustomAutocomplete
                                    name="contactId"
                                    fieldLabel="Select Contact Person"
                                    required={true}
                                    options={contactPersonList}
                                    placeholder="Select"
                                    value={contactPersonList.find((option) => option.id === values.contactId) || null}
                                    getOptionLabel={(option) => option.title}
                                    onChange={(e, newValue) => setFieldValue('contactId', newValue ? newValue.id : '')}
                                    error={touched.contactId && !!errors.contactId}
                                    helperText={touched.contactId && errors.contactId}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>

                                <CustomTextField
                                    name="subject"
                                    fieldLabel="Subject"
                                    value={values.subject}
                                    onChange={handleChange}
                                    placeholder="Enter Subject"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl
                                    component="fieldset"
                                    error={touched.callingType && !!errors.callingType}
                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                >
                                    <FormLabel component="legend" sx={{ marginRight: 2 }} style={{ fontWeight: '600', color: "#212529", fontSize: "14px" }}>
                                        Calling Type
                                    </FormLabel>
                                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.callingType === 'Inbound'}
                                                    onChange={(e) => setFieldValue("callingType", e.target.checked ? 'Inbound' : '')}
                                                />
                                            }
                                            label="Inbound"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.callingType === 'Outbound'}
                                                    onChange={(e) => setFieldValue("callingType", e.target.checked ? 'Outbound' : '')}
                                                />
                                            }
                                            label="Outbound"
                                        />
                                    </FormGroup>
                                </FormControl>
                                {touched.callingType && errors.callingType ? <p style={{ color: "red", fontSize: "12px" }}>{errors.callingType}</p> : null}

                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormControl
                                    component="fieldset"
                                    error={touched.callDetails && !!errors.callDetails}
                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                >
                                    <FormLabel component="legend" sx={{ marginRight: 2 }}
                                        style={{ fontWeight: '600', color: "#212529", fontSize: "14px" }}>
                                        Call Details
                                    </FormLabel>
                                    <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.callDetails === 'current'}
                                                    onChange={(e) => setFieldValue("callDetails", e.target.checked ? 'current' : '')}
                                                />
                                            }
                                            label="Current"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.callDetails === 'complete'}
                                                    onChange={(e) => setFieldValue("callDetails", e.target.checked ? 'complete' : '')}
                                                />
                                            }
                                            label="Complete"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.callDetails === 'Scheduled'}
                                                    onChange={(e) => setFieldValue("callDetails", e.target.checked ? 'Scheduled' : '')}
                                                />
                                            }
                                            label="Scheduled"
                                        />
                                    </FormGroup>
                                </FormControl>
                                {touched.callDetails && errors.callDetails ? <p style={{ color: "red", fontSize: "12px" }}>{errors.callDetails}</p> : null}
                            </Grid>


                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    required={true}
                                    name="callStartTime"
                                    fieldLabel="Call Start Time"
                                    type="datetime-local"
                                    value={values.callStartTime}
                                    onChange={handleChange}
                                    error={touched.callStartTime && !!errors.callStartTime}
                                    helperText={touched.callStartTime && errors.callStartTime}
                                    placeholder="Enter Call Start Time"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="callDuration"
                                    fieldLabel="Call Duration"
                                    type="time"
                                    value={values.callDuration}
                                    onChange={handleChange}
                                    placeholder="Enter Call Duration"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={callPurposeTypes}
                                    fieldLabel="Call Purpose"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={callPurposeTypes.find((option) => option.id === values.callPurposeId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('callPurposeId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Call Purpose"
                                    error={touched.callPurposeId && !!errors.callPurposeId}
                                    helperText={touched.callPurposeId && errors.callPurposeId}

                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={callOutcomeTypes}
                                    fieldLabel="Call Outcome"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={callOutcomeTypes.find((option) => option.id === values.callOutcomeId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('callOutcomeId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Call Outcome"
                                    error={touched.callOutcomeId && !!errors.callOutcomeId}
                                    helperText={touched.callOutcomeId && errors.callOutcomeId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={leadStages}
                                    fieldLabel="Lead Stage"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={leadStages.find((option) => option.id === values.stageId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('stageId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Lead Stage"
                                    error={touched.stageId && !!errors.stageId}
                                    helperText={touched.stageId && errors.stageId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <CustomAutocomplete
                                    options={priorities}
                                    fieldLabel="Priority"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={priorities.find((option) => option.id === values.priorityId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('priorityId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select"
                                    error={touched.priorityId && !!errors.priorityId}
                                    helperText={touched.priorityId && errors.priorityId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <CustomAutocomplete
                                    options={leadStatus}
                                    fieldLabel="Lead Status"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={leadStatus.find((option) => option.id === values.leadStatusId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('leadStatusId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Lead Status"
                                    error={touched.leadStatusId && !!errors.leadStatusId}
                                    helperText={touched.leadStatusId && errors.leadStatusId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <CustomAutocomplete
                                    options={prioritiesLossReasons}
                                    fieldLabel="Loss Reason"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={prioritiesLossReasons.find((option) => option.id === values.lossReasonId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('lossReasonId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Loss Reason"
                                    error={touched.lossReasonId && !!errors.lossReasonId}
                                    helperText={touched.lossReasonId && errors.lossReasonId}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <CustomTextarea
                                    name="description"
                                    fieldLabel="Description"
                                    value={values.description}
                                    onChange={handleChange}
                                    placeholder="Enter Description"
                                    sx={{
                                        height: "100px !important"
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateExportCall;
