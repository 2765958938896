import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  GET_COMPANY,
  GET_ORG_USERS_LIST,
  GET_PROFILE,
  GET_ROLE,
} from "../../services/apiRoutes";
import { genericGet } from "../../services/apiServices";
import { AuthContext } from "../../context/AuthContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Avatar,
  Link,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MailIcon from "@mui/icons-material/Mail";
import CustomButton from "../common/CustomButton";
import CustomLoader from "../common/CustomLoader";
import CustomModal from "../common/CustomModal";
import UpdateCompany from "../MyCompany/UpdateCompany";
import MessageBox from "../common/CustomMessageBox";
import CustomMaster from "../common/CustomMaster";
import { genericPost } from "../../services/authServices";
import UpdateUserManagement from "./UpdateMaster/UpdateUserManagement";
import UpdateProfileImage from "./UpdateMaster/UpdateProfileImage";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const PersonalSettings = () => {
  const [value, setValue] = useState(0); // Added state for Tabs
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [profile, setProfile] = useState([]);
  const [error, setError] = useState(null);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [companyRoles, setCompanyRoles] = useState([]);
  const [data, setData] = useState([]);

  let userData = JSON.parse(sessionStorage.getItem("userData"));

  const [message, setMessage] = useState({
    open: false,
    text: "",
    type: "",
  });

  const { token } = useContext(AuthContext);

  const handleChange = (event, newValue) => {
    setValue(newValue); // Handle tab changes
  };

  const handleUpdateClick = (companyUserId) => {
    setSelectedId(companyUserId);
    setUpdateModal(true);
  };

  const handleCloseUpdate = () => {
    setUpdateModal(false);
  };

  // Function to open image modal
  const handleImageClick = (userId) => {
    setSelectedId(userId);
    setImageModalOpen(true);
  };

  // Function to close image modal
  const handleCloseImageModal = () => {
    setImageModalOpen(false);
  };


  const fetchCompanies = async () => {
    try {
      const response = await genericGet({ url: GET_COMPANY });
      const companyData = response?.data?.companies || [];
      const defaultCompanies = companyData.filter(
        (company) => company.isDefault
      );

      const defaultCompany = defaultCompanies[0] || null;
      const getCompanyId = defaultCompany?.companyId || null;
      setCompanies(defaultCompanies);
      setCompanyId(getCompanyId);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching companies:", error);
      setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const payload = {
        userId: userData?.authUser?.userId,
      };
      const response = await genericPost(GET_ORG_USERS_LIST, payload, {});

      if (response.data.success && response.data.companyUsers) {
        const companyUserData = response.data.companyUsers.map(
          (user, index) => ({
            index: (index + 1).toString().padStart(2, "0"),
            companyUserId: user.companyUserId || "",
            companyId: user.companyId || "",
            orgId: user.orgId || "",
            userId: user.userId || "",
            roleId: user.roleId || "",
            profileId: user.profileId || "",
            createdAt: user.createdAt || "",
            updatedAt: user.updatedAt || "",
            firstName: user.user?.firstName || "",
            lastName: user.user?.lastName || "",
            email: user.user?.email || "",
            mobile: user.user?.mobile || "",
            roleName: user.role?.roleName || "",
            profileName: user.profile?.profileName || "",
          })
        );

        setData(companyUserData);
      } else {
        setError("No company users found");
      }
    } catch (error) {
      setError("Error fetching company users");
      console.error("Error fetching company users", error);
    } finally {
      setLoading(false);
    }
  };
  const getRoles = async () => {
    // setLoading(true);
    try {
      const response = await genericGet({ url: GET_ROLE });
      if (response.status == 200) {
        let reponseData = response?.data?.companyRoles.map((v) => ({
          id: v?.roleId,
          title: v?.roleName,
          name: v?.roleName,
          roleDescription: v?.roleDescription,
          parentRole: v?.parentRole?.roleName,
        }));
        setCompanyRoles(reponseData);
      }
    } catch (error) {
    } finally {
    }
  };
  const getProfiles = async () => {
    try {
      const response = await genericGet({ url: GET_PROFILE });
      if (response.status == 200) {
        let reponseData = response.data.companyProfiles.map((v) => ({
          id: v?.profileId,
          title: v?.profileName,
          name: v?.profileName,
          profileDescription: v?.profileDescription,
        }));
        setProfile(reponseData);
      }
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    fetchCompanies();
    fetchData();
    getProfiles();
    getRoles();
  }, [token]);
  const defaultView = "personalsettings";
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
    }
  };
  const mainContent = (
    <>
      <div>
        <CustomModal
          modalStyles={{
            maxHeight: '35vh',
            top: "10%",
          }}
          open={imageModalOpen} handleClose={handleCloseImageModal}>
          <UpdateProfileImage
            handleClose={handleCloseImageModal}
            companyUserId={selectedId}
            fetchData={fetchCompanies}
            companyRoles={companyRoles}
            profile={profile}
            setMessage={setMessage}
          />
        </CustomModal>
        <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
          <UpdateUserManagement
            handleClose={handleCloseUpdate}
            companyUserId={selectedId}
            fetchData={fetchCompanies}
            companyRoles={companyRoles}
            profile={profile}
            setMessage={setMessage}
          />
        </CustomModal>
        <MessageBox
          open={message.open}
          onClose={() => setMessage({ ...message, open: false })}
          message={message.text}
          handleClose={() => setMessage({ ...message, open: false })}
          type={message.type}
        />
        <CustomLoader open={loading} />

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="User Details"
                {...a11yProps(0)}
                sx={{
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  color: value === 0 ? "#007BFF" : "inherit",
                }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {companies.map((company) => (
              <Box sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} container spacing={2}>
                    <Grid item>
                      <Box textAlign="center" sx={{ position: "relative" }}>
                        <Avatar
                          src={userData?.authUser?.profileImage}
                          alt="user-profile"
                          sx={{ width: 100, height: 100, mx: "auto" }}
                        />

                        <IconButton
                          onClick={() => handleImageClick(userData?.authUser?.userId)}
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            padding: '5px',
                            borderRadius: '50%',
                            backgroundColor: '#007BFF',
                            color: '#fff',
                            '&:hover': { backgroundColor: '#007BFF' },
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          sx={{
                            m: 0,
                            textTransform: "capitalize",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          {userData?.authUser?.firstName}{" "}
                          {userData?.authUser?.lastName}
                        </Typography>
                        <CustomButton
                          title={userData?.role?.roleName}
                          size="small"
                          variant="outlined"
                          style={{
                            color: "#007BFF",
                            textTransform: "capitalize",
                            borderColor: "#007BFF",
                            marginLeft: "10px",
                          }}
                        />
                        {data.map((user, index) => (
                          <CustomButton
                            key={index}
                            onClick={() =>
                              handleUpdateClick(user.companyUserId)
                            }
                            title={`Update User`}
                            size="small"
                            variant="outlined"
                            style={{
                              color: "#007BFF",
                              textTransform: "capitalize",
                              borderColor: "#007BFF",
                              marginLeft: "10px",
                            }}
                          />
                        ))}
                      </Box>
                      <Box mb={1}>
                        <Link
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontSize: "15px" }}
                        >
                          {userData?.role?.roleName} at {company.companyName}
                        </Link>
                      </Box>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <MailIcon fontSize="small" sx={{ mr: 1 }} />
                        {userData?.authUser?.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box my={2}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "15px", fontWeight: "bold" }}
                  >
                    User Information{" "}
                  </Typography>
                  <Grid container sx={{ pl: 10 }}>
                    <Grid item xs={12}>
                      <Table
                        size="small"
                        sx={{
                          "& .MuiTableCell-root": { borderBottom: "none" },
                        }}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell
                              width="120"
                              sx={{ color: "text.secondary", fontSize: "14px" }}
                            >
                              {" "}
                              User Name
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "14px",
                                textTransform: "capitalize",
                              }}
                            >
                              {" "}
                              {userData?.authUser?.firstName}{" "}
                              {userData?.authUser?.lastName}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="120"
                              sx={{ color: "text.secondary", fontSize: "14px" }}
                            >
                              Email
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "14px",
                                textTransform: "capitalize",
                              }}
                            >
                              {userData?.authUser?.email}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="120"
                              sx={{ color: "text.secondary", fontSize: "14px" }}
                            >
                              Mobile
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "14px",
                                textTransform: "capitalize",
                              }}
                            >
                              {" "}
                              {userData?.authUser?.mobile}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              width="120"
                              sx={{ color: "text.secondary", fontSize: "14px" }}
                            >
                              User Roles
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "14px",
                                textTransform: "capitalize",
                              }}
                            >
                              {" "}
                              {userData?.role?.roleName}{" "}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
          </CustomTabPanel>
        </Box>
      </div>
    </>
  );
  return <CustomMaster defaultView={defaultView} mainContent={mainContent} />;
};
