import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Grid, CircularProgress, Divider, Table, TableHead, TableCell, TableBody, TableRow, Switch } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import { GET_PERMISSIONS, READ_PROFILE, UPDATE_PERMISSIONS } from '../../../services/apiRoutes';
import CustomButton from '../../common/CustomButton';
import CustomTextField from '../../common/CustomTextField';
import CustomStyle from '../../common/CustomStyle';
import { genericGet } from '../../../services/apiServices';

const UpdatePermission = ({ handleClose, profileId, setMessage, fetchData }) => {
    const [viewData, setViewData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const getPermissionsByProfileId = async (profileId) => {
        try {
            const response = await genericGet({ url: `${GET_PERMISSIONS}/${profileId}` });
            if (response.status === 200) {
                setViewData(response.data.profile);
            }
        } catch (error) {
            setError('Error fetching data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPermissionsByProfileId(profileId);
    }, [profileId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_PERMISSIONS(profileId), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "Updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };


    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    profile: viewData?.map(item => ({
                        companyModuleId: item.companyModuleId || '',
                        profileId: item.profileId,
                        userId: item.userId,
                        creates: item.creates,
                        updates: item.updates,
                        deletes: item.deletes,
                        views: item.views,
                        exports: item.exports
                    })) || []
                }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Profile
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />

                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Box sx={{ pt: 2 }}>
                            <Box sx={{ overflowX: 'auto', overflowY: 'auto' }}>
                                <Table sx={{ width: '100%' }}>
                                    <TableHead>
                                        <TableRow sx={{ height: '40px', backgroundColor: '#e7f0f7' }}>
                                            <TableCell sx={{ ...styles.tableCell, width: '200px' }} rowSpan={2}>Module Name</TableCell>
                                            <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>
                                                Create
                                            </TableCell>
                                            <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Update</TableCell>
                                            <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>View</TableCell>
                                            <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Delete</TableCell>
                                            <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Export</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {values.profile.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={styles.tableCell}>
                                                    {viewData[index]?.companyModule.moduleName}
                                                </TableCell>
                                                <TableCell sx={styles.tableCell}>
                                                    <Switch
                                                        checked={item.creates === 1}
                                                        onChange={(e) => setFieldValue(`profile[${index}].creates`, e.target.checked ? 1 : 0)}
                                                    />
                                                </TableCell>
                                                <TableCell sx={styles.tableCell}>
                                                    <Switch
                                                        checked={item.updates === 1}
                                                        onChange={(e) => setFieldValue(`profile[${index}].updates`, e.target.checked ? 1 : 0)}
                                                    />
                                                </TableCell>
                                                <TableCell sx={styles.tableCell}>
                                                    <Switch
                                                        checked={item.views === 1}
                                                        onChange={(e) => setFieldValue(`profile[${index}].views`, e.target.checked ? 1 : 0)}
                                                    />
                                                </TableCell>
                                                <TableCell sx={styles.tableCell}>
                                                    <Switch
                                                        checked={item.deletes === 1}
                                                        onChange={(e) => setFieldValue(`profile[${index}].deletes`, e.target.checked ? 1 : 0)}
                                                    />
                                                </TableCell>
                                                <TableCell sx={styles.tableCell}>
                                                    <Switch
                                                        checked={item.exports === 1}
                                                        onChange={(e) => setFieldValue(`profile[${index}].exports`, e.target.checked ? 1 : 0)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box >
    );
};

export default UpdatePermission;
const styles = {
    tableCell: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        p: '4px',
    },
};