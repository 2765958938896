import { Box, Typography, Grid, CircularProgress, Divider, Card } from '@mui/material';
import { Formik, Form } from 'formik';
import axios from 'axios';
import React, { useState, useEffect } from "react";
import { READ_DUTY } from '../../../services/apiRoutes';
import CustomCardView from '../../common/CustomCardView';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import DetailsParticulars from '../../common/DetailsPage/DetailsParticulars';
import { printFunction } from '../../../services/apiServices';
import CustomPrintModel from '../../common/CustomPrint';

const DutyView = ({ handleClose, dutyId }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataParticular, setDataParticular] = useState(null);
    const [totalQty, setTotalQty] = useState(0); // State for total quantity
    const [amountData, setAmount] = useState(0);
    const [totalDuty, setTotalDuty] = useState(0);
    const [totalTax, setTotalTax] = useState(0);

    useEffect(() => {
        if (!dutyId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_DUTY(dutyId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (response.data.success) {
                    const dutyData = response.data.duty;
                    const symbol = dutyData?.currency?.currency_symbol || '';

                    // Map particulars array
                    const particulars = dutyData.particulars.map(particular => ({
                        particularId: particular.dutyParticularId,
                        productName: particular?.products?.productName,
                        hsn: particular.hsn,
                        duty: parseFloat(particular.duty).toFixed(2),
                        amount: parseFloat(particular.amount).toFixed(2),
                        dutyPercentage: `${particular.dutyPersentage}%`,
                        tax: parseFloat(particular.tax).toFixed(2),
                        taxPercentage: `${particular.taxPersentage}%`,
                    }));

                    // Calculate totals
                    const totalAmount = particulars.reduce((sum, item) => sum + parseFloat(item.amount), 0).toFixed(2);
                    const totalDutyCalc = particulars.reduce((sum, item) => sum + parseFloat(item.duty), 0).toFixed(2);
                    const totalTaxCalc = particulars.reduce((sum, item) => sum + parseFloat(item.tax), 0).toFixed(2);

                    // Format totals with currency symbol
                    const formattedTotalAmount = `${symbol} ${totalAmount}`;
                    const formattedTotalDuty = `${symbol} ${totalDutyCalc}`;
                    const formattedTotalTax = `${symbol} ${totalTaxCalc}`;

                    // Set states
                    setData(dutyData);
                    setDataParticular(particulars);
                    setAmount(formattedTotalAmount);
                    setTotalQty(particulars.length);
                    setTotalDuty(formattedTotalDuty);
                    setTotalTax(formattedTotalTax);
                }

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dutyId]);



    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const values = {
        supplierNames: data?.clientCompany?.clientCompanyName || '',
        reference: data?.reference || '',
        commercialInvoiceNo: data?.commercialInvoiceNo || '',
        date: data?.date || '',
        dueDate: data?.dueDate || '',
        note: data?.note || '',
        ciUpload: data?.ciUpload || '',
        currency: data?.currency?.currency || '',
        voucherNo: data?.voucherNo || '',
        boeConversionRate: data?.boeConversionRate || '',
        proformaInvoiceNo: data?.ProformaInvoice?.proformaInvoiceNo || '',
        particulars: data?.particulars.map(particular => ({
            particularName: parseInt(particular.particularName, 10),
            qty: particular.qty,
            rate: particular.rate,
            unit: parseInt(particular.unit, 10),
            description: particular.description,
        })) || [],
    };

    // Define labels for the displayed fields
    const labels = [
        { key: 'supplierNames', label: "Client Company", sm: 12, md: 4, },
        { key: 'date', label: "Date", sm: 12, md: 4, },
        { key: 'voucherNo', label: "Voucher No.", sm: 12, md: 4, },
        { key: 'currency', label: "currency", sm: 12, md: 4, },
        { key: 'boeConversionRate', label: "BOE Conversion Rate", sm: 12, md: 4, },
    ];
    const particularsColumns = [
        {
            id: 'productName',
            label: 'Product Name',
            accessor: 'productName',
        },
        {
            id: 'amount',
            label: 'Amount',
            accessor: 'amount',
            styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'hsn',
            label: 'HSN No.',
            accessor: 'hsn',
            styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'dutyPercentage',
            label: 'Duty %',
            accessor: 'dutyPercentage',
            styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'duty',
            label: 'Duty',
            accessor: 'duty',
            styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'taxPercentage',
            label: 'Tax %',
            accessor: 'taxPercentage',
            styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'tax',
            label: 'Tax',
            accessor: 'tax',
            styleAccessor: {
                textAlign: 'right',
            },
        },
    ];


    const footerData = [
        {
            label: 'Total',
            footerValue: '',
            style: {
                textAlign: 'right',
            },
            colSpan: 1,

        },
        {
            label: '',
            footerValue: amountData,
            style: {
                textAlign: 'right',

            },

        },
        {
            label: '',
            footerValue: totalDuty,
            style: {
                textAlign: 'right',

            },
            colSpan: 3,

        },
        {
            label: '',
            footerValue: totalTax,
            style: {
                textAlign: 'right',

            },
            colSpan: 2,

        },
    ];
    const printContent = () => {
        const elementId = 'contentId';
        const iframeId = 'print-iframe';
        printFunction(elementId, iframeId);
    };
    return (
        <>
            <Box sx={{ mx: 'auto', mt: 0 }}>
                <Formik
                    initialValues={values}
                    enableReinitialize
                >
                    {() => (
                        <Form>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#212529" }}>
                                        Duty
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Box>
                                        <IconButton aria-label="delete">
                                            <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                        </IconButton>
                                        <IconButton aria-label="print" onClick={printContent}>
                                            <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                        </IconButton>
                                        <IconButton aria-label="close" onClick={handleClose}>
                                            <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={0} sx={{ px: "0%" }}>
                                <Grid item xs={12} md={12} >
                                    <CustomCardView
                                        title="Bill To"
                                        label={labels}
                                        data={values}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                            Particulars
                                        </Typography>
                                    </Box>
                                    <DetailsParticulars
                                        title={'Particulars'}
                                        columns={particularsColumns}
                                        data={dataParticular}
                                        footerData={footerData}
                                    />
                                </Grid>

                                <Grid item xs={7}>
                                    <br />
                                    <Box>
                                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                            In Words
                                        </Typography>
                                        <Typography style={{ color: "#212529", fontWeight: 400, fontSize: "14px", textTransform: "uppercase" }}>
                                            Fifteen thousand four hundred forty one.
                                            &nbsp;Only
                                        </Typography>
                                    </Box>
                                    <Box sx={{ pt: 5 }}>
                                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                            Note :
                                        </Typography>
                                        <Typography style={{ color: "#212529", fontWeight: 400, fontSize: "14px", textTransform: "capitalize" }}>
                                            {values.note}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <br />
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                            Summary
                                        </Typography>
                                    </Box>
                                    <Card sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 1, maxWidth: 400 }} variant="outlined">
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography>Amount:</Typography>
                                            <Typography> {amountData}</Typography>
                                        </Box>

                                        <Divider sx={{ my: 0 }} />

                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography fontWeight="bold">Grand Total:</Typography>
                                            <Typography fontWeight="bold">{amountData}</Typography>
                                        </Box>
                                    </Card>
                                </Grid>

                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>

            <CustomPrintModel contentId="contentId">
                <Typography style={{ textAlign: "center" }}>  Invoice</Typography>
                <table border='1px solid black' style={{ width: '100%', borderCollapse: 'collapse', }}>
                    <tbody>
                        <tr >
                            <td style={{ padding: '0px', }}>
                                <p style={{ borderBottom: '1px solid black', width: "100%" }}>Manufacture</p>
                                <p style={{ margin: 0 }}>
                                    601, 6th floor, p3 MAHARASHTRA Pune - 202020
                                </p>
                            </td>
                            <td style={{ padding: '4px' }}>PO 554545.</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '4px', }}>
                                Supplier
                                <p style={{ margin: 0 }}>
                                    cust and suppl(cr) 10, Bihar, Banka - 202020 9865000000
                                </p>
                            </td>
                            <td style={{ padding: '4px' }}>Reference.</td>
                        </tr>
                        <tr>
                            <th>Particular Name</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Unit</th>
                            <th>Amount</th>
                        </tr>
                        <tbody>
                            <tr style={{ borderBottom: 'none' }}>
                                <td>Item 1</td>
                                <td>10</td>
                                <td>$5.00</td>
                                <td>Kg</td>
                                <td>$50.00</td>
                            </tr>
                            <tr style={{ borderBottom: 'none' }}>
                                <td>Item 2</td>
                                <td>20</td>
                                <td>$3.00</td>
                                <td>L</td>
                                <td>$60.00</td>
                            </tr>
                            <tr  >
                                <td>Item 3</td>
                                <td>15</td>
                                <td>$2.50</td>
                                <td>Box</td>
                                <td>$37.50</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
                                <td>$147.50</td>
                            </tr>
                        </tfoot>
                    </tbody>
                </table>
            </CustomPrintModel >
        </>
    );
};

export default DutyView;
