import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import { READ_BUSINESS_GROUP, READ_FREIGHT_FORWARD_AGENCY, UPDATE_BUSINESS_GROUP, UPDATE_FREIGHT_FORWARD_AGENCY } from '../../../services/apiRoutes';
import CustomButton from '../../common/CustomButton';
import CustomTextField from '../../common/CustomTextField';
import CustomStyle from '../../common/CustomStyle';
import CustomTextarea from '../../common/CustomTextarea';
import CustomAutocomplete from '../../common/CustomAutoComplete';

const ValidationSchema = Yup.object().shape({
    agencyName: Yup.string().required('agency Name required'),
    contactPerson: Yup.string().required('contact Person required'),
    emailId: Yup.string().email('Invalid email').required('Email is required'),
    phoneNo: Yup.string()
        .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
        .required('Phone is required'),
    mobileNo: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Mobile is required'),
    colorId: Yup.string().required('color is required'),
});

const UpdateFreightForwardMaster = ({ handleClose, agencyId, setMessage, fetchData, colorList }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!agencyId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_FREIGHT_FORWARD_AGENCY(agencyId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                setData(response.data.freightForwardAgency);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [agencyId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_FREIGHT_FORWARD_AGENCY(agencyId), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "Export updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                },);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    agencyName: data?.agencyName || '',
                    address: data?.address || '',
                    contactPerson: data?.contactPerson || '',
                    emailId: data?.emailId || '',
                    phoneNo: data?.phoneNo || '',
                    mobileNo: data?.mobileNo || '',
                    note: data?.note || '',
                    colorId: data?.colorId ? Number(data.colorId) : '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Freight Forward
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%", paddingTop: "5px" }}>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Agency Name"
                                    name="agencyName"
                                    fieldLabel="Agency Name"
                                    required
                                    value={values.agencyName}
                                    onChange={handleChange}
                                    error={touched.agencyName && !!errors.agencyName}
                                    helperText={touched.agencyName && errors.agencyName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Contact Person"
                                    name="contactPerson"
                                    fieldLabel="Contact Person"
                                    required
                                    value={values.contactPerson}
                                    onChange={handleChange}
                                    error={touched.contactPerson && !!errors.contactPerson}
                                    helperText={touched.contactPerson && errors.contactPerson}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    type="number"
                                    placeholder="Enter Mobile Number"
                                    name="mobileNo"
                                    fieldLabel="Mobile Number"
                                    required
                                    value={values.mobileNo}
                                    onChange={handleChange}
                                    error={touched.mobileNo && !!errors.mobileNo}
                                    helperText={touched.mobileNo && errors.mobileNo}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    type="email"
                                    placeholder="Enter Email"
                                    name="emailId"
                                    fieldLabel="Email"
                                    required
                                    value={values.emailId}
                                    onChange={handleChange}
                                    error={touched.emailId && !!errors.emailId}
                                    helperText={touched.emailId && errors.emailId}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    type="number"
                                    placeholder="Enter Phone Number"
                                    name="phoneNo"
                                    fieldLabel="Phone Number"
                                    required
                                    value={values.phoneNo}
                                    onChange={handleChange}
                                    error={touched.phoneNo && !!errors.phoneNo}
                                    helperText={touched.phoneNo && errors.phoneNo}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Address"
                                    name="address"
                                    fieldLabel="Address"
                                    value={values.address}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextarea
                                    multiline
                                    rows={4}
                                    placeholder="Enter Note"
                                    name="note"
                                    fieldLabel="Note"
                                    value={values.note}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    options={colorList}
                                    fieldLabel="Select Color"
                                    required
                                    getOptionLabel={(option) => `${option.colorName}`}
                                    renderOption={(props, option) => (
                                        <li {...props}
                                            style={{
                                                backgroundColor: option.backgroundColor,
                                                color: option.color,
                                                border: `1px solid ${option.color}`,
                                                display: 'inline-flex',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                whiteSpace: 'nowrap',
                                                borderRadius: '4px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                textTransform: "capitalize",
                                                height: "25px",
                                                padding: "2px 6px 2px 6px",
                                                zIndex: -1,
                                                marginLeft: "40px",
                                                marginRight: "40px",
                                                marginTop: "10px",
                                                marginBottom: "10px"

                                            }}>
                                            {`${option.colorName}`}
                                        </li>
                                    )}
                                    value={colorList.find(color => color.colorId === values.colorId) || null}
                                    onChange={(_, newValue) => {
                                        setFieldValue("colorId", newValue ? newValue.colorId : "");
                                    }}
                                    placeholder="Select color"
                                    error={touched.colorId && !!errors.colorId}
                                    helperText={touched.colorId && errors.colorId}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateFreightForwardMaster;
