import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import { READ_ROLE, UPDATE_ROLE, } from '../../../services/apiRoutes';
import CustomButton from '../../common/CustomButton';
import CustomTextField from '../../common/CustomTextField';
import CustomStyle from '../../common/CustomStyle';
import CustomAutocomplete from '../../common/CustomAutoComplete';
import CustomTextarea from '../../common/CustomTextarea';

const ValidationSchema = Yup.object().shape({
    roleName: Yup.string().required("Role is required"),
    parentRoleId: Yup.string().required("Report to is required"),
});


const UpdateRole = ({ handleClose, roleId, setMessage, companyRoles, fetchData, }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!roleId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_ROLE(roleId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                setData(response.data.companyRoles);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [roleId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_ROLE(roleId), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                },);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    roleName: data?.roleName || '',
                    parentRoleId: data?.parentRoleId || '',
                    roleDescription: data?.roleDescription || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Role
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "5px" }}>
                            <Grid item xs={12} sm={6}>
                                <CustomTextField
                                    name="roleName"
                                    type="text"
                                    fieldLabel="Role"
                                    value={values.roleName}
                                    required={true}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.roleName && Boolean(errors.roleName)}
                                    helperText={touched.roleName && errors.roleName}
                                    placeholder="Enter Role "
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={companyRoles}
                                    required={true}
                                    fieldLabel="Report to"
                                    getOptionLabel={(option) => option.title}
                                    value={companyRoles.find((option) => option.id === values.parentRoleId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('parentRoleId', newValue ? newValue.id : '')
                                    }
                                    onBlur={handleBlur}
                                    error={touched.parentRoleId && Boolean(errors.parentRoleId)}
                                    helperText={touched.parentRoleId && errors.parentRoleId}
                                    placeholder="Select"
                                />

                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <CustomTextarea
                                    fieldLabel="Role Description"
                                    placeholder="Enter Role Description"
                                    rows={4}
                                    name='roleDescription'
                                    value={values.roleDescription}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.roleDescription && Boolean(errors.roleDescription)}
                                    helperText={touched.roleDescription && errors.roleDescription}
                                />
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateRole;
