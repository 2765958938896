import React, { useContext, useMemo, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { genericGet } from "../../services/apiServices";
import { GET_COMMERCIAL_INVOICE_REGISTER } from "../../services/apiRoutes";
import { AuthContext } from "../../context/AuthContext";
import CustomLoader from "../common/CustomLoader";
import CustomReport from "../common/CustomReport";

const title = "Commercial Invoice Register";

const CommercialInvoiceRegister = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'date', header: 'Date', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'shipmentId', header: 'Shipment ID', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'commercialInvoiceNo', header: 'Commercial Invoice', size: 50, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'customerName', header: 'Customer Name', size: 50, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'country', header: 'Country', size: 50, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'totalAmount', header: 'Amount', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'documents', header: 'Documents', size: 50 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton>
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_COMMERCIAL_INVOICE_REGISTER });
            if (response.data.success) {
                const commercialInvoices = response.data.data.map((invoice, index) => {
                    const particulars = invoice.particulars.map((particular) => ({
                        qty: particular.qty || 0,
                        rate: parseFloat(particular.rate) || 0,
                        amount: (particular.qty || 0) * (parseFloat(particular.rate) || 0),
                    }));

                    const totalAmount = particulars.reduce((total, { amount }) => total + amount, 0);

                    return {
                        commercialInvExpId: invoice.commercialInvExpId || '',
                        userId: invoice.userId || '',
                        index: (index + 1).toString().padStart(2, '0'),
                        companyId: invoice.companyId || '',
                        shipmentId: invoice.shipmentId || '',
                        commercialInvoiceNo: invoice.commercialInvoiceNo || '',
                        customerName: invoice.customer.clientCompanyName || '',
                        country: invoice.customer.mailingCountry.name || '',
                        reference: invoice.reference || '',
                        proformaInvoiceNo: invoice.proformaInvoiceNo || '',
                        date: invoice.date || '',
                        dueDate: invoice.dueDate || '',
                        note: invoice.note || '',
                        createdAt: invoice.createdAt || '',
                        updatedAt: invoice.updatedAt || '',
                        particulars,
                        totalAmount: (totalAmount || 0).toFixed(2),


                    };
                });

                // Update the state with the processed commercial invoices
                setData(commercialInvoices);
            } else {
                setError('No commercial invoices found');
            }
        } catch (error) {
            setError('Error fetching commercial invoices');
            console.error('Error fetching commercial invoices', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData()
    }, [token])


    const mainContent = (
        <> <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
            />
        </>
    );

    const defaultView = 'commercialinvoiceregister';

    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>
    );
};
export default CommercialInvoiceRegister;
