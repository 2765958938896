import React, { useEffect, useState, useCallback } from 'react';
import DetailsPageAppbar from '../common/DetailsPage/DetailsPageAppbar';
import { Main } from '../../constants/DetailsMain';
import { Call } from './OverViewMenu/Call';
import { Tasks } from './OverViewMenu/Tasks';
import { Notes } from './OverViewMenu/Notes';
import { useParams } from 'react-router-dom';
import { GET_Lead_leadId, GET_LEAD_RELATED_LIST } from '../../services/apiRoutes';
import axios from 'axios';
import LeadDetail from './OverViewMenu/LeadDetail';
import { useNavigate } from 'react-router-dom';
import CustomCollapse from '../common/CustomCollapse';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CustomLoader from '../common/CustomLoader';
import { genericPost } from '../../services/authServices';

export default function LeadDetails() {
    const { leadId } = useParams();
    const navigate = useNavigate();

    const [open, setOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('Overview');
    const [companyDetails, setCompanyDetails] = useState(null);
    const [relatedList, setRelatedList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);
    const handleScrollTo = (menu) => setActiveTab(menu);
    const handleBackClick = () => navigate("/app/leads");

    const AppBarMenu = [{ menu: 'Overview', to: `/app/leads/${leadId}` }];

    // Fetch Lead Details
    const fetchLeadDetails = useCallback(async () => {
        try {
            setLoading(true);
            const authToken = sessionStorage.getItem('accessToken');
            const response = await axios.get(GET_Lead_leadId(leadId), {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            setCompanyDetails(response.data.lead);
        } catch (error) {
            console.error('Error fetching company details:', error);
            setError('Failed to load lead details');
        } finally {
            setLoading(false);
        }
    }, [leadId]);

    // Fetch Related List
    const fetchRelatedList = useCallback(async (clientCompanyId) => {
        if (!clientCompanyId) return;

        try {
            setLoading(true);
            const payload = { clientCompanyId, leadId };
            const response = await genericPost(GET_LEAD_RELATED_LIST, payload);
            if (response.data.success && response.data.buttonList) {
                setRelatedList(response.data.buttonList);
            } else {
                setError('No related list found');
            }
        } catch (error) {
            console.error('Error fetching button list:', error);
            setError('Failed to load related list');
        } finally {
            setLoading(false);
        }
    }, [leadId]);

    // Fetch data on mount
    useEffect(() => {
        fetchLeadDetails();
    }, [fetchLeadDetails]);

    // Fetch related list after lead details are loaded
    useEffect(() => {
        if (companyDetails?.clientCompany?.clientCompanyId) {
            fetchRelatedList(companyDetails.clientCompany.clientCompanyId);
        }
    }, [companyDetails, fetchRelatedList]);

    // Loading state
    if (loading) return <CustomLoader open={true} />;
    if (error) return <p>{error}</p>;
    if (!companyDetails) return <p>No company details available.</p>;

    const { clientCompany, user } = companyDetails;
    const clientCompanyName = clientCompany?.clientCompanyName || 'N/A';
    const phoneNumber = clientCompany?.mobile || 'N/A';
    const initial = clientCompanyName.charAt(0).toUpperCase();

    const adminData = [
        {
            title: "Lead Owner",
            defaultExpanded: false,
            details: [
                { label: 'Lead Owner', value: `${user.firstName || ''} ${user.lastName || ''}`.trim() },
                { label: 'Email', value: user.email, sm: 12, md: 12 },
                { label: 'Mobile', value: user.mobile, sm: 12, md: 12 },
            ],
        },
    ];

    return (
        <>
            <div>

                <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={AppBarMenu}
                    companyName={clientCompanyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}
                    buttonList={relatedList}
                />

                <Main open={open}>

                    <LeadDetail />
                    <br />
                    <Call leadId={leadId} data={clientCompany} />
                    <br />
                    <Tasks leadId={leadId} data={clientCompany} />
                    <br />
                    <Notes leadId={leadId} data={clientCompany} />
                    <br />
                    <CustomCollapse Icon={PersonOutlineOutlinedIcon} data={adminData} />
                    <br />
                </Main>
            </div>
        </>
    );
}
