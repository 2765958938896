import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { ExportMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from '../../../components/common/CustomModal';
import CreateCOO from '../CreateForm/CreateCOO';
import UpdateCOO from '../UpdateForm/UpdateCOO';
import MessageBox from '../../common/CustomMessageBox';
import CustomDelete from '../../common/CustomDeleteAlert';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import { DELETE_COO_EXPORT, LIST_COO_EXPORT } from '../../../services/apiRoutes';
import { genericGet } from '../../../services/apiServices';
import { genericPost } from '../../../services/authServices';
import COOView from '../ViewDetails/COOView';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';
import axios from 'axios';

const COO = () => {
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('COO');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState({ open: false, text: '', type: '' });
    const [cooId, setCooId] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [rowId, setRowId] = useState('')

    const navigate = useNavigate();
    const { exportShipmentId } = useParams();
    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);


    let customerId, cifFob;
    const hasFetched = useRef(false);
    useEffect(() => {
        if (shipmentDetails) {
            customerId = shipmentDetails.customerId;

            cifFob = shipmentDetails.cifFob.cifFobName;
            fetchData();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateModal, cooId, token, modal]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: customerId,
                shipmentId: shipmentId,
            };

            const response = await genericPost(LIST_COO_EXPORT, payload);

            if (response.data.success) {
                const cooData = response.data.data.map((item, index) => ({
                    cooId: item.cooId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    userId: item.userId || '',
                    companyId: item.companyId || '',
                    clientCompanyId: item.clientCompanyId || '',
                    exporter: item.exporter || '',
                    serialNo: item.serialNo || '',
                    certificateNo: item.certificateNo || '',
                    consignee: item.consignee || '',
                    transportAndRoute: item.transportAndRoute || '',
                    certifyingAuthority: item.certifyingAuthority || '',
                    countryOrRegion: item.countryOrRegion || '',
                    marksAndPackages: item.marksAndPackages || '',
                    descriptionOfGoods: item.descriptionOfGoods || '',
                    hsCode: item.hsCode || '',
                    quantity: item.quantity || '',
                    invoiceDetails: item.invoiceDetails || '',
                    declarationByExporter: item.declarationByExporter || '',
                    certification: item.certification || '',
                    createdAt: item.createdAt || '',
                    updatedAt: item.updatedAt || '',
                    customerName: item.customer?.clientCompanyName || '',
                    customerCountry: item.customer?.mailingCountry?.name || '',
                }));
                setData(cooData);
            } else {
                setError('No COO data found');
            }
        } catch (error) {
            setError('Error fetching COO data');
            console.error('Error fetching COO data', error);
        } finally {
            setLoading(false);
        }
    };
    const handleDelete = async (cooId) => {
        setLoading(true);
        const authToken = sessionStorage.getItem('accessToken');

        try {
            const response = await axios.delete(DELETE_COO_EXPORT(cooId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                setDeleteAlert(false);
                setMessage({
                    open: true,
                    text: "deleted successfully",
                    type: "success",
                });
                fetchData();
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);

            if (error.response) {
                if (error.response.status === 409) {
                    setMessage({
                        open: true,
                        text: error.response.data?.error || "Cannot delete Data. Related Shipment exists.",
                        type: "error",
                    });
                } else {
                    setMessage({
                        open: true,
                        text: "An error occurred while deleting the Data.",
                        type: "error",
                    });
                }
            } else {
                console.error("Error deleting Data", error);
                setMessage({
                    open: true,
                    text: "Network error. Please try again later.",
                    type: "error",
                });
            }
        }
    };



    // Table columns
    const columns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        { id: 'exporter', label: 'COO Name', accessor: 'exporter' },
        { id: 'attach', label: 'Attach', accessor: 'attach' },
        { id: 'descriptionOfGoods', label: 'Description', accessor: 'descriptionOfGoods' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.cooId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.cooId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.cooId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];


    const handleUpdateClick = (id) => {
        setCooId(id);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true)
        setRowId(rowId)
    }


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setCooId('');
    };

    const handleViewClick = (id) => {
        setCooId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setCooId('');
    };

    const handleBackClick = () => {
        navigate('/app/export/shipement');
    };

    if (!shipmentDetails) {
        return <p>  <CustomLoader open={shipmentLoading} /> </p>;
    }

    const addressToClient = `${shipmentDetails?.customer?.billingStreet || ''}, ${shipmentDetails?.customer?.billingCity?.name || ''}, ${shipmentDetails?.customer?.billingState?.name || ''}, ${shipmentDetails?.customer?.billingCountry?.name || ''}, ${shipmentDetails?.customer?.billingPincode || ''}.`.trim();
    const consigneeName = shipmentDetails?.customer?.clientCompanyName;
    const consignee = `${consigneeName || ''}, ${addressToClient || ''}`.trim();
    const addressToCompany = `${shipmentDetails?.company?.address || ''},   ${shipmentDetails?.company?.city?.name || ''}, ${shipmentDetails?.company?.state?.name || ''}, ${shipmentDetails?.company?.country?.name || ''}, ${shipmentDetails?.company?.pincode || ''}.`.trim();
    const exporterName = shipmentDetails?.company?.companyName;
    const exporter = `${exporterName || ''}, ${addressToCompany || ''}`.trim();
    const companyName = shipmentDetails?.customer?.clientCompanyName;
    const phoneNumber = shipmentDetails?.customer?.mobile;
    const customerName = shipmentDetails?.customerId;
    const mailingCountry = shipmentDetails?.customer?.mailingCountry?.name;
    const initial = shipmentDetails?.customer?.clientCompanyName ? shipmentDetails?.customer?.clientCompanyName.charAt(0).toUpperCase() : '';
    const shipmentId = shipmentDetails.shipmentId;
    const shipmentOrgId = shipmentDetails.exportShipmentId;


    // JSX Structure
    return (
        <div>
            {/* <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={ExportMenu(exportShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />
            <Main open={open}> */}
            <CustomLoader open={loading} />
            <DetailsPageCard
                columns={columns}
                title="COO"
                buttonTitle="Create COO"
                handleOpen={handleOpen}
                data={data}
                loading={loading}
                error={error}
            />
            <CustomModal open={modal} handleClose={handleClose}>
                <CreateCOO
                    fetchData={fetchData}
                    customerName={customerName}
                    shipmentId={shipmentId}
                    exporter={exporter}
                    consignee={consignee}
                    mailingCountry={mailingCountry}
                    shipmentOrgId={shipmentOrgId}
                    setMessage={setMessage}
                    handleClose={handleClose} />
            </CustomModal>

            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateCOO
                    fetchData={fetchData}
                    exporter={exporter}
                    consignee={consignee}
                    mailingCountry={mailingCountry}
                    setMessage={setMessage}
                    handleClose={handleCloseUpdate}
                    cooId={cooId}
                />
            </CustomModal>

            <CustomModal open={viewModal} handleClose={handleCloseView}>
                <COOView
                    fetchData={fetchData}
                    mailingCountry={mailingCountry}
                    setMessage={setMessage}
                    handleClose={handleCloseView}
                    cooId={cooId}
                />
            </CustomModal>

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}
            />
        </div>
    );
};

export default COO;
