import { Box, Typography, Grid, CircularProgress, Divider, Card } from '@mui/material';
import { Formik, Form } from 'formik';
import axios from 'axios';
import React, { useState, useEffect } from "react";
import CustomCardView from '../../common/CustomCardView';
import { READ_PROFORMA_INVOICE } from '../../../services/apiRoutes';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import DetailsParticulars from '../../common/DetailsPage/DetailsParticulars';
import { printFunction } from '../../../services/apiServices';
import CustomPrintModel from '../../common/CustomPrint';


const ProformaInvoiceView = ({ handleClose, proformaInvoiceId }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataParticular, setDataParticular] = useState(null);
    const [totalQty, setTotalQty] = useState(0); // State for total quantity
    const [totalRate, setTotalRate] = useState(0); // State for total rate
    const [amountData, setAmount] = useState(0);


    // Fetch data for the proforma invoice
    useEffect(() => {
        if (!proformaInvoiceId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_PROFORMA_INVOICE(proformaInvoiceId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const invoiceData = response.data.proformaInvoice;
                const symbol = response.data.proformaInvoice?.currency?.currency_symbol || '';
                const particulars = invoiceData.particulars.map(particular => ({
                    qty: particular.qty || 0,
                    rate: parseFloat(particular.rate).toFixed(2),
                    amounts: parseFloat(particular.amount).toFixed(2),
                    amount: (particular.qty || 0) * (parseFloat(particular.rate) || 0),
                    productName: particular.products.productName,
                    unitName: particular.productsUnit.unitName,
                }));
                const amountTotal = particulars.reduce((acc, item) => acc + item.amount, 0).toFixed(2);
                const totalAmount = `${symbol} ${amountTotal}`;


                setAmount(totalAmount);
                setData(invoiceData);
                setDataParticular(particulars);
                const totalQuantity = invoiceData.particulars
                    .reduce((total, particular) => total + (parseFloat(particular.qty) || 0), 0)
                setTotalQty(totalQuantity);

                // Calculate total rate
                const totalRateAmount = invoiceData.particulars.reduce((acc, item) => acc + parseFloat(item.rate), 0);
                setTotalRate(totalRateAmount);

            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [proformaInvoiceId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const getLastSuffix = (url) => {
        if (url) {
            const parts = url.split('/');
            return parts[parts.length - 1];
        }
        return '';
    };

    // Define labels as an array of objects for all keys
    const labels = [
        { key: 'clientCompanyName', label: "Supplier", sm: 12, md: 4, },
        { key: 'dueDate', label: "Due Date", sm: 12, md: 4, },
        { key: 'proformaInvoiceNo', label: "Proforma Invoice No", sm: 12, md: 4, },
        { key: 'reference', label: "Reference", sm: 12, md: 4, },
        { key: 'date', label: "Date", sm: 12, md: 4, },
        { key: 'currency', label: "currency", sm: 12, md: 4, },
    ];


    const values = {
        clientCompanyName: data?.clientCompany?.clientCompanyName || '',
        proformaInvoiceNo: data?.proformaInvoiceNo || '',
        reference: data?.reference || '',
        date: data?.date || '',
        dueDate: data?.dueDate || '',
        note: data?.note || '',
        piUpload: getLastSuffix(data?.piUpload || ''),
        piUploadurl: data?.piUpload || '',
        currency: data?.currency?.currency || '',
        particulars: data?.particulars || []
    };

    const particularsColumns = [

        {
            id: 'productName',
            label: 'Product Name',
            accessor: 'productName',
        },
        {
            id: 'qty',
            label: 'Quantity',
            accessor: 'qty',
            styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'rate',
            label: 'Rate',
            accessor: 'rate',
            styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'unitName',
            label: 'Unit',
            accessor: 'unitName',
            styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'amounts',
            label: 'Amount',
            accessor: 'amounts',
            styleAccessor: {
                textAlign: 'right',
            },
        },


    ];



    const footerData = [
        {
            label: 'Total',
            footerValue: '',
            style: {
                textAlign: 'right',
            },
            colSpan: 1,

        },
        {
            label: '',
            footerValue: totalQty,
            style: {
                textAlign: 'center',

            },

        },
        {
            label: '',
            footerValue: amountData,
            style: {
                textAlign: 'right',

            },
            colSpan: 3,

        },
    ];
    const printContent = () => {
        const elementId = 'contentId';
        const iframeId = 'print-iframe';
        printFunction(elementId, iframeId);
    };


    return (
        <>
            <Box sx={{ mx: 'auto', mt: 0 }}>
                <Formik
                    initialValues={values}
                    enableReinitialize
                >
                    {() => (
                        <Form>
                            <Grid container spacing={1} style={{ position: "sticky" }}>
                                <Grid item xs={4}>
                                    <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
                                        Proforma Invoice
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Box>
                                        <IconButton aria-label="delete">
                                            <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                        </IconButton>
                                        <IconButton aria-label="print" onClick={printContent}>
                                            <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                        </IconButton>
                                        <IconButton aria-label="close" onClick={handleClose}>
                                            <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={0} sx={{ px: "0%" }}>
                                <Grid item xs={12}>
                                    <CustomCardView title="Bill To" label={labels} data={values} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                            Particulars
                                        </Typography>
                                    </Box>
                                    <DetailsParticulars
                                        title={'Particulars'}
                                        columns={particularsColumns}
                                        data={dataParticular}
                                        footerData={footerData}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <br />
                                    <Box>
                                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                            In Words
                                        </Typography>
                                        <Typography style={{ color: "#212529", fontWeight: 400, fontSize: "14px", textTransform: "uppercase" }}>
                                            Fifteen thousand four hundred forty one.
                                            &nbsp;Only
                                        </Typography>
                                    </Box>
                                    <br />
                                    <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                            PI Document :
                                        </Typography>
                                        <a href={values.piUploadurl} target="_blank" rel="noopener noreferrer" style={{ color: "#007bff", textDecoration: "none" }}>
                                            {values.piUpload}
                                        </a>
                                    </Box>

                                    <Box sx={{ pt: 5 }}>
                                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                            Note :
                                        </Typography>
                                        <Typography style={{ color: "#212529", fontWeight: 400, fontSize: "14px", textTransform: "capitalize" }}>
                                            {values.note}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <br />
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                            Summary
                                        </Typography>
                                    </Box>
                                    <Card sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 1, maxWidth: 400 }} variant="outlined">
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography>Total Quantity:</Typography>
                                            <Typography>{totalQty}</Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography>Amount:</Typography>
                                            <Typography> {amountData}</Typography>
                                        </Box>

                                        <Divider sx={{ my: 0 }} />

                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography fontWeight="bold">Grand Total:</Typography>
                                            <Typography fontWeight="bold">{amountData}</Typography>
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>

            <CustomPrintModel contentId="contentId">
                <Typography style={{ textAlign: "center" }}>Proforma Invoice </Typography>
                <table border='1px solid black' style={{ width: '100%', borderCollapse: 'collapse', }}>
                    <tbody>
                        <tr >
                            <td style={{ padding: '0px', }}>
                                <p style={{ borderBottom: '1px solid black', width: "100%" }}>Manufacture</p>

                                <p style={{ margin: 0 }}>
                                    601, 6th floor, p3 MAHARASHTRA Pune - 202020
                                </p>
                            </td>
                            <td style={{ padding: '4px' }}>PO 554545.</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '4px', }}>
                                Supplier
                                <p style={{ margin: 0 }}>
                                    cust and suppl(cr) 10, Bihar, Banka - 202020 9865000000
                                </p>
                            </td>
                            <td style={{ padding: '4px' }}>Reference.</td>
                        </tr>
                        <tr>
                            <th>Particular Name</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Unit</th>
                            <th>Amount</th>
                        </tr>
                        <tbody>
                            <tr style={{ borderBottom: 'none' }}>
                                <td>Item 1</td>
                                <td>10</td>
                                <td>$5.00</td>
                                <td>Kg</td>
                                <td>$50.00</td>
                            </tr>
                            <tr style={{ borderBottom: 'none' }}>
                                <td>Item 2</td>
                                <td>20</td>
                                <td>$3.00</td>
                                <td>L</td>
                                <td>$60.00</td>
                            </tr>
                            <tr  >
                                <td>Item 3</td>
                                <td>15</td>
                                <td>$2.50</td>
                                <td>Box</td>
                                <td>$37.50</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total</td>
                                <td>$147.50</td>
                            </tr>
                        </tfoot>
                    </tbody>
                </table>
            </CustomPrintModel >
        </>

    );
};

export default ProformaInvoiceView;
