import React, { useState } from "react";
import {
    Divider,
    Button,
    TableCell,
    TableContainer,
    Paper,
    Typography,
    CardHeader,
    Table,
    TableBody,
    TableRow,
    Card,
    Box,
    TableHead,
    Grid,
    Avatar,
    IconButton,
    CardMedia,
    note,
    CardContent
} from "@mui/material";
import { ArrowBack, ArrowForwardIos as ArrowForwardIosIcon, Visibility as VisibilityIcon, ArrowDropDownRounded as ArrowDropDownRoundedIcon } from '@mui/icons-material';

const ImageGallery = ({ title, buttonTitle, imageData, handleOpen }) => {
    const [index, setIndex] = useState(0);

    const handleNext = () => {
        setIndex((prevIndex) => (prevIndex + 3 < imageData.length ? prevIndex + 3 : prevIndex));
    };

    const handleBack = () => {
        setIndex((prevIndex) => (prevIndex - 3 >= 0 ? prevIndex - 3 : 0));
    };

    const mediaStyle = {
        display: 'block',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '200px',
        objectFit: 'contain',
        width: '100%',
    };

    const cardContainerStyle = {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        scrollbarWidth: 'none', // For Firefox
        msOverflowStyle: 'none',  // For Internet Explorer and Edge
    };


    const buttoncreate = {
        backgroundColor: "#007bff",
        color: "#ffff",
        textTransform: "capitalize",
        borderRadius: 4,
        border: "2px solid #007bff",
        height: 30,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const buttonStyle = {
        alignSelf: 'center',
        backgroundColor: "#007BFF",
        color: "#fff",
        textTransform: "capitalize"
    };

    const disabledButtonStyle = {
        backgroundColor: '#66AFFF',
        color: 'white',
        pointerEvents: 'none',
    };

    const tableCellStyle = {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'left'
    };

    const cardtittile = {
        padding: "8px",
        paddingLeft: "35px",
        fontWeight: 600,
        fontSize: "16px",
        color: "#212529",
    };

    return (
        <div>
            <Card variant="outlined">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <span style={cardtittile}> {title}</span>
                    <span style={{ marginRight: "12px" }}>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            endIcon={<ArrowDropDownRoundedIcon />}
                            style={buttoncreate}
                            onClick={handleOpen}
                        >
                            {buttonTitle}
                        </Button>
                    </span>
                </div>
                <TableContainer style={{ overflowX: 'auto' }}>
                    <Table style={{ minWidth: '100%', border: '1px solid #dee2e6', borderCollapse: 'collapse' }}>
                        <TableHead>
                            <TableRow style={{ border: '1px solid #dee2e6', }}>
                                <TableCell style={tableCellStyle}>
                                    <Button
                                        style={index === 0 ? { ...buttonStyle, ...disabledButtonStyle } : buttonStyle}
                                        startIcon={<ArrowBack />}
                                        onClick={handleBack} disabled={index === 0}>
                                        Back
                                    </Button>
                                </TableCell>
                                <TableCell style={tableCellStyle}>
                                    <div style={cardContainerStyle}>
                                        {imageData.slice(index, index + 3).map((image, idx) => (
                                            <Card variant="outlined" key={idx} style={{ width: "300px", margin: "2px" }}>
                                                <CardMedia
                                                    style={mediaStyle}
                                                    image={image.imgPath}
                                                    title={image.label}
                                                />
                                                <CardHeader
                                                    avatar={
                                                        <Avatar style={{ backgroundColor: "#007BFF" }} aria-label="recipe">
                                                            {image.label.charAt(0)}
                                                        </Avatar>
                                                    }
                                                    action={
                                                        <IconButton aria-label="settings">
                                                            <a href={image.imgPath} target="_blank" rel="noopener noreferrer">
                                                                <VisibilityIcon style={{ color: "#007BFF" }} />
                                                            </a>
                                                        </IconButton>
                                                    }
                                                    title={image.label}
                                                    subheader={image.date}
                                                />
                                                <CardContent style={{ marginTop: "-15px" }}>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        {image.notes}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </div>
                                </TableCell>
                                <TableCell style={tableCellStyle}>
                                    <Button
                                        endIcon={<ArrowForwardIosIcon />}
                                        style={buttonStyle}
                                        onClick={handleNext} disabled={index + 3 >= imageData.length}>
                                        Next
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </Card>
        </div>
    );
};

export default ImageGallery;
