// ImportConstant.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DetailPageCustum from '../../common/DetailsPage/DetailPageCustum';
import { READ_IMPORT_SHIPMENT } from '../../../services/apiRoutes';
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
import CustomLoader from '../../common/CustomLoader';

const ImportDetail = () => {
    const { importShipmentId } = useParams();
    const [shipmentDetails, setShipmentDetails] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(READ_IMPORT_SHIPMENT(importShipmentId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setShipmentDetails(response.data.shipment);
            })
            .catch(error => {
                console.error('Error fetching shipment details:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [importShipmentId]);

    if (!shipmentDetails) {
        return <p>   <CustomLoader open={loading} /> </p>;
    }

    const tableData = [
        { label: 'Company Name', value: shipmentDetails.supplier?.clientCompanyName || 'N/A' },
        { label: 'mobile', value: shipmentDetails.supplier?.mobile || 'N/A' },
        { label: 'Shipment ID', value: shipmentDetails.shipmentId || 'N/A' },
        { label: 'CIF/FOB Name', value: shipmentDetails.cifFob?.cifFobName || 'N/A' },
        { label: 'currency', value: shipmentDetails.currency?.currency || 'N/A' },
        { label: 'payment Method', value: shipmentDetails.paymentMethod?.paymentMethodName || 'N/A' },
        { label: 'No. of Containers', value: shipmentDetails.noOfContainers || 'N/A' },
        { label: 'Shipment Type', value: shipmentDetails.shipmentType?.shipmentTypeName || 'N/A' },

    ];

    return <DetailPageCustum Icon={DirectionsBoatOutlinedIcon} title="Import Shipment Details" data={tableData} />;
};

export default ImportDetail;
