import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Grid, CircularProgress, Divider, Table, TableHead, TableCell, TableBody, TableRow, Switch } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import { READ_PROFILE, UPDATE_PROFILE, } from '../../../services/apiRoutes';
import CustomButton from '../../common/CustomButton';
import CustomTextField from '../../common/CustomTextField';
import CustomStyle from '../../common/CustomStyle';
import CustomTextarea from '../../common/CustomTextarea';

const ValidationSchema = Yup.object().shape({
    profileName: Yup.string().required("Name is required"),
    profileDescription: Yup.string().required("Description is required"),
});
const UpdateProfiles = ({ handleClose, profileId, setMessage, fetchData, viewData }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!profileId) return;
        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_PROFILE(profileId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                setData(response.data.companyProfiles);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [profileId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_PROFILE(profileId), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "Export updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                },);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    profileName: data?.profileName || '',
                    profileDescription: data?.profileDescription || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleBlur, handleChange, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Profile
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "5px" }}>
                            <Grid item xs={12} sm={6}>
                                <CustomTextField
                                    name="profileName"
                                    type="text"
                                    fieldLabel="Profile Name"
                                    value={values.profileName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.profileName && Boolean(errors.profileName)}
                                    helperText={touched.profileName && errors.profileName}
                                    placeholder="Enter Profile Name"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomTextarea
                                    fieldLabel="Profile Description"
                                    placeholder="Enter Profile Description"
                                    rows={4}
                                    name='profileDescription'
                                    value={values.profileDescription}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.profileDescription && Boolean(errors.profileDescription)}
                                    helperText={touched.profileDescription && errors.profileDescription}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateProfiles;
