import React, { useMemo, useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import DataTable from "../../../components/common/DataTable";
import { genericGet } from "../../../services/apiServices";
import { READ_EXPORT_SHIPMENT_COSTING } from "../../../services/apiRoutes";
import { AuthContext } from "../../../context/AuthContext";
import CustomLoader from "../../common/CustomLoader";
import axios from "axios";
import CustomReport from "../../common/CustomReport";

const title = "Export Shipment Costing Booking Wise";

const ExportShipmentCostingReportView = () => {
    const { commercialInvExpId } = useParams();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'etaDate', header: 'Date', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'piNumber', header: 'PI No.', size: 150, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'clientCompanyName', header: 'Customer Name', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'mailingCountry', header: 'Country', size: 100, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'numberOfContainers', header: 'No. Of Container', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'totalQty', header: 'Total Qty', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'piAmount', header: 'PI Amount', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'piAmountBaseCurrency', header: 'PI Amount in Base Currency', size: 200, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'paymentAmount', header: 'Advance Amount', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'amountInBaseCurrency', header: 'Advance Amount in Base Currency', size: 200, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'finalAmount', header: 'Final Amount', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'finalAmountBaseCurrency', header: 'Final Amount in Base Currency', size: 200, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'bankCharges', header: 'Bank Charges', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'seaFreight', header: 'Sea Freight', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'seaFreightBaseCurrency', header: 'Sea Freight in Base Currency', size: 200, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'shippingLineCharges', header: 'Shipping Line Charges', size: 200, tableHeader: {
                    justifyContent: 'flex-start',
                }, tableStyle: { textAlign: "center" },
            },
            { accessorKey: 'agencyName', header: 'Forward Agency Charges', size: 200, },
            { accessorKey: 'chaName', header: 'Clearing Agency Charges', size: 200 },
            { accessorKey: 'customDuty', header: 'Custom Duty', size: 150 },
            { accessorKey: 'tax', header: 'Tax', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'miscellaneousExpenses', header: 'Miscellaneous Expenses', size: 200 },
            { accessorKey: 'totalExpenses', header: 'Total Expenses', size: 150 },
            { accessorKey: 'costPerUnit', header: 'Cost per Unit', size: 150, tableStyle: { textAlign: "center" }, },
        ],
        []
    );

    const fetchData = async (commercialInvExpId) => {
        try {
            setLoading(true);
            const accessToken = sessionStorage.getItem("accessToken");
            const response = await axios.get(READ_EXPORT_SHIPMENT_COSTING(commercialInvExpId), {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.data.success && response.data.data.length > 0) {
                const invoiceData = response.data.data[0];
                const clientCompany = invoiceData.clientCompany || {};
                // Calculate piAmount by summing up all "amount" values in proformaInvoices.particulars
                const piAmount = invoiceData.proformaInvoices?.reduce((total, invoice) => {
                    return total + (invoice.particulars?.reduce((sum, item) => sum + (Number(item.amount) || 0), 0) || 0);
                }, 0) || 0;

                const totalQty = invoiceData.particulars.reduce((acc, item) => acc + Number(item.qty || 0), 0);
                const totalAmount = invoiceData.particulars.reduce((acc, item) => acc + (Number(item.qty || 0) * parseFloat(item.rate || 0)), 0);
                const costPerUnit = totalQty > 0 ? (totalAmount / totalQty).toFixed(2) : '0.00';
                const mappedData = [{
                    index: invoiceData.commercialInvExpId || "",
                    etaDate: invoiceData.date || "",
                    piAmount: piAmount,
                    piNumber: invoiceData.proformaInvoiceNo || "",
                    clientCompanyName: invoiceData?.customer?.clientCompanyName || "",
                    mailingCountry: invoiceData?.customer?.mailingCountry?.name || "",
                    numberOfContainers: invoiceData.numberOfContainers || "",
                    totalQty: invoiceData.particulars?.reduce((sum, item) => sum + (Number(item.qty) || 0), 0) || 0,
                    piAmountBaseCurrency: invoiceData.totalPiAmountBaseCurrency || "",
                    advanceAmount: invoiceData.advanceAmount || "",
                    advanceAmountBaseCurrency: invoiceData.advanceAmountBaseCurrency || "",
                    finalAmount: invoiceData.finalAmount || "",
                    finalAmountBaseCurrency: invoiceData.finalAmountBaseCurrency || "",
                    freightForwardAgency: invoiceData.freightForwardAgency || "",
                    customDuty: invoiceData.customDuty || "",
                    tax: invoiceData.tax || "",
                    miscellaneousExpenses: invoiceData.miscellaneousExpenses || "",
                    costPerUnit: costPerUnit || "",

                    paymentAmount: invoiceData.paymentVoucher?.reduce((sum, item) => sum + (Number(item.amount) || 0), 0) || 0,
                    amountInBaseCurrency: invoiceData.paymentVoucher?.reduce((sum, item) => sum + (Number(item.amountInBaseCurrency) || 0), 0) || 0,
                    bankCharges: invoiceData.paymentVoucher?.reduce((sum, item) => sum + (Number(item.bankCharges) || 0), 0) || 0,
                    seaFreight: invoiceData.shippingBooking?.reduce((sum, item) => sum + (Number(item.seaFreight) || 0), 0) || 0,
                    shippingLineCharges: invoiceData.shippingBooking?.reduce((sum, item) => sum + (Number(item.shippingLineCharges) || 0), 0) || 0,
                    chaName: invoiceData.shippingBooking?.map(item => item.customClearingAgency?.chaName) || [],
                    agencyName: invoiceData.shippingBooking?.map(item => item.freightForwardAgency?.agencyName) || [],

                    totalExpenses: invoiceData.expenseVoucher?.reduce((sum, item) => sum + (Number(item.amount) || 0), 0) || 0,
                }];

                setData(mappedData);
            } else {
                setError("No commercial invoice data found.");
                setData([]);
            }
        } catch (err) {
            setError(err.message);
            setData([]);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (!commercialInvExpId) return;
        setLoading(true);
        fetchData(commercialInvExpId);
    }, [commercialInvExpId, token]);


    const defaultView = 'exportshipmentcostingreport';
    const mainContent = (
        <>
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
            />
        </>
    );

    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>
    );
};

export default ExportShipmentCostingReportView;
