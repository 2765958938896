
import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton, Paper, Typography, Divider, CircularProgress } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DataTable from "../../components/common/DataTable";
import { getColorPlate, getRating } from "../../services/CommonServices";
import CustomMaster from "../common/CustomMaster";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomModal from "../common/CustomModal";
import { UPDATE_RATING } from "../../services/apiRoutes";
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import CustomButton from "../common/CustomButton";
import CustomTextField from "../common/CustomTextField";
import CustomStyle from "../common/CustomStyle";
import MessageBox from "../common/CustomMessageBox";
import CustomAutocomplete from "../common/CustomAutoComplete";
import CustomStatus from "../common/CustomStatus";

const ValidationSchema = Yup.object().shape({
    ratingName: Yup.string().required('rating Name is required'),
    priority: Yup.string().required('priority is required'),
    colorId: Yup.string().required('color is required'),
});
const title = "Ratings";
const Ratings = () => {
    const [ratings, setRatings] = useState([]);
    const [colorList, setColorList] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);

    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })

    const handleRowClick = (row) => {
        setSelectedRow(row.original);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_RATING(selectedRow.id), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchRatings();
                    handleCloseUpdate();
                },);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };


    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: "Rating",
                header: "Rating",
                tableHeader: {
                    justifyContent: 'flex-start',
                },
                size: 100,
                Cell: ({ row }) => (
                    <>
                        <CustomStatus
                            title={row.original.title}
                            sx={{
                                backgroundColor: row.original.backgroundColor,
                                color: row.original.color,
                                textAlign: "center",
                                display: "inline-block",
                                border: `1px solid ${row.original.color}`,
                            }}
                        />
                    </>
                ),
                enableSorting: false
            },
            {
                accessorKey: 'priority', header: 'priority', tableStyle: { textAlign: "center" }, size: 200
            },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                            <IconButton onClick={() => handleRowClick(row)}>
                                <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                            </IconButton>
                        </div>
                    </>
                ),
            },
        ],
        []
    );


    const fetchRatings = async () => {
        const ratingsData = await getRating();
        setRatings(ratingsData);
        const colorData = await getColorPlate();
        setColorList(colorData);
    };

    useEffect(() => {
        fetchRatings();
    }, []);
    const defaultView = 'ratings';
    const mainContent = (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={ratings}
                />
            </Box>
            <CustomModal
                modalStyles={{
                    maxHeight: '35vh',
                    top: "10%",
                }}
                open={updateModal}
                handleClose={handleCloseUpdate}
            >
                {selectedRow && (
                    <Box sx={{ mx: 'auto', mt: 0 }}>
                        <Formik
                            initialValues={{
                                ratingName: selectedRow?.title || '',
                                priority: selectedRow?.priority || '',
                                colorId: selectedRow?.colorId ? Number(selectedRow.colorId) : '',
                            }}
                            validationSchema={ValidationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting, errors, touched, handleChange, values, setFieldValue }) => (
                                <Form>
                                    <Grid container spacing={1} style={CustomStyle.container}>
                                        <Grid item xs={4} md={4}>
                                            <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                                Update Rating
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                            <Box style={CustomStyle.buttonBox}>
                                                <CustomButton
                                                    startIcon={<SaveRounded />}
                                                    title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                                    size="small"
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                />
                                                <CustomButton
                                                    startIcon={<ClearRounded />}
                                                    title="Cancel"
                                                    size="small"
                                                    variant="contained"
                                                    onClick={handleCloseUpdate}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container spacing={2} sx={{ px: "5%", paddingTop: "5px" }}>
                                        <Grid item xs={12} md={6}>
                                            <CustomTextField
                                                placeholder="Enter Rating Name"
                                                name="ratingName"
                                                fieldLabel="Rating Name"
                                                required
                                                value={values.ratingName}
                                                onChange={handleChange}
                                                error={touched.ratingName && !!errors.ratingName}
                                                helperText={touched.ratingName && errors.ratingName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CustomTextField
                                                placeholder="Enter Priority"
                                                name="priority"
                                                fieldLabel="Priority"
                                                required
                                                value={values.priority}
                                                onChange={handleChange}
                                                error={touched.priority && !!errors.priority}
                                                helperText={touched.priority && errors.priority}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <CustomAutocomplete
                                                options={colorList}
                                                fieldLabel="Select Color"
                                                required
                                                getOptionLabel={(option) => `${option.colorName}`}
                                                renderOption={(props, option) => (
                                                    <li {...props}
                                                        style={{
                                                            backgroundColor: option.backgroundColor,
                                                            color: option.color,
                                                            border: `1px solid ${option.color}`,
                                                            display: 'inline-flex',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            textAlign: 'center',
                                                            whiteSpace: 'nowrap',
                                                            borderRadius: '4px',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            textTransform: "capitalize",
                                                            height: "25px",
                                                            padding: "2px 6px 2px 6px",
                                                            zIndex: -1,
                                                            marginLeft: "40px",
                                                            marginRight: "40px",
                                                            marginTop: "10px",
                                                            marginBottom: "10px"

                                                        }}>
                                                        {`${option.colorName}`}
                                                    </li>
                                                )}
                                                value={colorList.find(color => color.colorId === values.colorId) || null}
                                                onChange={(_, newValue) => {
                                                    setSelectedColor(newValue);
                                                    setFieldValue("colorId", newValue ? newValue.colorId : "");
                                                }}
                                                placeholder="Select color"
                                            />
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                )}
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
        </>
    );
    return (
        <CustomMaster
            defaultView={defaultView}
            mainContent={mainContent} />



    );
};

export default Ratings;
