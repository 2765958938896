import React from "react";
import { Box, Grid, Divider, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomButton from "../common/CustomButton";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from "../common/CustomAutoComplete";
import CustomTextarea from "../common/CustomTextarea";
import { POST_ROLE } from "../../services/apiRoutes";
import { genericPost } from '../../services/authServices'
import CustomStyle from "../common/CustomStyle";

const RolesAdd = ({ handleClose, companyRoles, setMessage, getRoles }) => {
    const validationSchema = Yup.object({
        roleName: Yup.string().required("Role is required"),
    });
    const initialValues = {
        roleName: "",
        parentRoleId: 0,
        roleDescription: '',
        level: 0

    };
    const handleSubmit = async (values) => {
        try {
            const response = await genericPost(POST_ROLE, values);

            if (response.status == 201) {
                setMessage({
                    open: true,
                    text: "Role added successfully",
                    type: "success",
                });
                getRoles()
                handleClose();
            } else {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Company added successfully",
                    type: "error",
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: "error",
            });
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Create Role
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title="Save"
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%", paddingTop: "5px" }}>
                            <Grid item xs={12} sm={6}>
                                <CustomTextField
                                    name="roleName"
                                    type="text"
                                    fieldLabel="Role"
                                    value={values.roleName}
                                    required={true}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.roleName && Boolean(errors.roleName)}
                                    helperText={touched.roleName && errors.roleName}
                                    placeholder="Enter Role "
                                />
                            </Grid>



                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={companyRoles}
                                    fieldLabel="Report to"
                                    getOptionLabel={(option) => option.title}
                                    value={values.userLevel}
                                    onChange={(e, newValue) =>
                                        setFieldValue("parentRoleId", newValue.id)
                                    }
                                    onBlur={handleBlur}
                                    placeholder="Select"

                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <CustomTextarea
                                    fieldLabel="Role Description"
                                    placeholder="Enter Role Description"
                                    rows={4}
                                    name='roleDescription'
                                    value={values.roleDescription}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default RolesAdd;
