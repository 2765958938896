import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider, Checkbox, FormControlLabel } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import { genericPost } from '../../../services/authServices';
import { CREATE_PRODUCTS_LIST } from '../../../services/apiRoutes';
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomStyle from "../../common/CustomStyle";
import CustomPrefixSuffixField from "../../common/CustomPrefixSuffixField";


const ValidationSchema = Yup.object().shape({
    productName: Yup.string().required('Product Name is required'),
    clientCompanyId: Yup.number().required('Client Company is required'),
    hsn: Yup.string().required('HSN is required'),
    unitId: Yup.number().required('Unit is required'),
    productsGroupId: Yup.number().required('product Group is required'),
    taxPersentage: Yup.number().required('Tax % is required'),
    dutyPersentage: Yup.number().required('Duty % is required'),
});

const CreateManageProducts = ({ handleClose, setMessage, fetchData, clientCompanyList, UnitList, productGroup }) => {

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                productName: values.productName,
                clientCompanyId: values.clientCompanyId,
                hsn: values.hsn,
                dutyPersentage: values.dutyPersentage,
                taxPersentage: values.taxPersentage,
                unitId: values.unitId,
                productsGroupId: values.productsGroupId,
                quantity: values.quantity,
                primaryQty: values.primaryQty,
                primaryUnit: values.primaryUnit,
                secondaryQty: values.secondaryQty,
                secondaryUnit: values.secondaryUnit,
                alternateUnitType: values.alternateUnitType,

            };
            const response = await genericPost(CREATE_PRODUCTS_LIST, payload);

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Product added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        productName: '',
        clientCompanyId: '',
        hsn: '',
        dutyPersentage: '',
        taxPersentage: '',
        unitId: '',
        productsGroupId: '',
        quantity: '',
        primaryQty: '',
        primaryUnit: '',
        secondaryQty: '',
        secondaryUnit: '',
        alternateUnitType: false,
    });

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid
                            container
                            spacing={1}
                            style={{ position: "sticky" }}
                        >
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px", paddingTop: "5px" }}>
                                    Add Product
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={8}
                                md={8}
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%", paddingTop: "5px" }}>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={clientCompanyList}
                                    fieldLabel="Client Company"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={clientCompanyList.find((option) => option.id === values.clientCompanyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('clientCompanyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Client Company"
                                    error={touched.clientCompanyId && !!errors.clientCompanyId}
                                    helperText={touched.clientCompanyId && errors.clientCompanyId}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter HSN Number"
                                    name="hsn"
                                    fieldLabel="HSN Number"
                                    required={true}
                                    value={values.hsn}
                                    onChange={handleChange}
                                    error={touched.hsn && !!errors.hsn}
                                    helperText={touched.hsn && errors.hsn}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={productGroup}
                                    fieldLabel="Product Group Name"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={productGroup.find((option) => option.id === values.productsGroupId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('productsGroupId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Product Group Name"
                                    error={touched.productsGroupId && !!errors.productsGroupId}
                                    helperText={touched.productsGroupId && errors.productsGroupId}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Product Name"
                                    name="productName"
                                    fieldLabel="Product Name"
                                    required={true}
                                    value={values.productName}
                                    onChange={handleChange}
                                    error={touched.productName && !!errors.productName}
                                    helperText={touched.productName && errors.productName}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomPrefixSuffixField
                                    suffix='%'
                                    placeholder="Enter duty"
                                    name="dutyPersentage"
                                    fieldLabel="Duty Persentage"
                                    required={true}
                                    type="number"
                                    value={values.dutyPersentage}
                                    onChange={handleChange}
                                    error={touched.dutyPersentage && !!errors.dutyPersentage}
                                    helperText={touched.dutyPersentage && errors.dutyPersentage}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomPrefixSuffixField
                                    suffix='%'
                                    placeholder="Enter tax"
                                    name="taxPersentage"
                                    fieldLabel="Tax Persentage"
                                    required={true}
                                    type="number"
                                    value={values.taxPersentage}
                                    onChange={handleChange}
                                    error={touched.taxPersentage && !!errors.taxPersentage}
                                    helperText={touched.taxPersentage && errors.taxPersentage}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <CustomAutocomplete
                                    options={UnitList}
                                    fieldLabel="Unit"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={UnitList.find((option) => option.id === values.unitId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('unitId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Unit"
                                    error={touched.unitId && !!errors.unitId}
                                    helperText={touched.unitId && errors.unitId}
                                />
                            </Grid>
                            {/* @@@@@@@@@@@@@@@@@@ Alternate unit */}
                            <Grid item xs={12} md={3}>
                                <Typography style={{
                                    color: "#212529",
                                    fontWeight: "600",
                                    fontSize: "14px"
                                }}>Alternate Unit</Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="yes"
                                            checked={values.alternateUnitType === true}
                                            onChange={() => setFieldValue("alternateUnitType", true)}
                                        />
                                    }
                                    label="Yes"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="no"
                                            checked={values.alternateUnitType === false}
                                            onChange={() => setFieldValue("alternateUnitType", false)}
                                        />
                                    }
                                    label="No"
                                />
                            </Grid>
                            {values.alternateUnitType && (
                                <>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            type="number"
                                            placeholder="Enter quantity"
                                            name="quantity"
                                            fieldLabel="Quantity"
                                            value={values.quantity}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomAutocomplete
                                            readOnly={true}
                                            sx={CustomStyle.readOnly}
                                            options={UnitList}
                                            fieldLabel="Unit"
                                            getOptionLabel={(option) => option.title}
                                            value={UnitList.find((option) => option.id === values.unitId) || null}
                                            onChange={(e, newValue) =>
                                                setFieldValue('unitId', newValue ? newValue.id : '')
                                            }
                                            placeholder="Enter Unit"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            type="number"
                                            placeholder="Enter primaryQty"
                                            name="primaryQty"
                                            fieldLabel="Primary Quantity"
                                            value={values.primaryQty}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomAutocomplete
                                            options={UnitList}
                                            fieldLabel="Primary Unit"
                                            getOptionLabel={(option) => option.title}
                                            value={UnitList.find((option) => option.id === values.primaryUnit) || null}
                                            onChange={(e, newValue) =>
                                                setFieldValue('primaryUnit', newValue ? newValue.id : '')
                                            }
                                            placeholder="Select Primary Unit"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            type="number"
                                            placeholder="Enter secondaryQty"
                                            name="secondaryQty"
                                            fieldLabel="Secondary Quantity"
                                            value={values.secondaryQty}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomAutocomplete
                                            options={UnitList}
                                            fieldLabel="Secondary Unit"
                                            getOptionLabel={(option) => option.title}
                                            value={UnitList.find((option) => option.id === values.secondaryUnit) || null}
                                            onChange={(e, newValue) =>
                                                setFieldValue('secondaryUnit', newValue ? newValue.id : '')
                                            }
                                            placeholder="Select Secondary Unit"
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateManageProducts;
