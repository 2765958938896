import React, { useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider, FormLabel } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomButton from "../../common/CustomButton";
import { genericPost } from '../../../services/authServices';
import { CREATE_IMPORT_SHIPPING_MARK, CREATE_NOTES } from '../../../services/apiRoutes';
import CustomStyle from "../../common/CustomStyle";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ValidationSchema = Yup.object().shape({
    // note: Yup.string().required('note is required'),
});

const CreateExportNotes = ({ handleClose, setMessage, fetchData, clientCompanyId, data, exportShipmentId }) => {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const onEditorStateChange = (state) => {
        setEditorState(state);
    };
    const convertToHTMLContent = () => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);
        return htmlContent;
    };
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const htmlContent = convertToHTMLContent();
            const note = htmlContent;
            const payload = {
                leadId: values.leadId,
                clientCompanyTrue: values.clientCompanyTrue,
                importShipmentId: values.importShipmentId,
                clientCompanyId: values.clientCompanyId,
                exportShipmentId: values.exportShipmentId,
                note: note,
            };

            const response = await genericPost(CREATE_NOTES, payload);

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Shipping mark added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        note: '',
        leadId: '',
        clientCompanyTrue: '',
        importShipmentId: '',
        clientCompanyId: clientCompanyId,
        exportShipmentId: exportShipmentId,
    });

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Create Notes
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>
                            <Grid item xs={12} md={12}>
                                <FormLabel sx={{ paddingBottom: "10px" }} style={fieldLabelstyle}>Notes</FormLabel>
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={onEditorStateChange}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    wrapperStyle={{
                                        border: '1px solid #CED4DA', borderRadius: '4px',
                                    }}
                                    editorStyle={{ padding: "10px" }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateExportNotes;

const fieldLabelstyle = {
    color: "#212529",
    fontWeight: "600",
    fontSize: "14px",
};