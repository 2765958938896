import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider, FormControlLabel, Checkbox } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomButton from "../common/CustomButton";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from '../common/CustomAutoComplete';
import CustomTextarea from "../common/CustomTextarea";
import { genericPost } from '../../services/authServices';
import { Create_contact } from '../../services/apiRoutes';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import { getStates, getCities } from "../../services/CommonServices";
import CustomStyle from "../common/CustomStyle";
const ValidationSchema = Yup.object().shape({
    contactAdminId: Yup.number().required('Contact Admin is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    selectCompanyNameId: Yup.number().required('Company Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    mobile: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Mobile is required'),

    mailingStreet: Yup.string().required('Mailing Street is required'),
    millingCountryId: Yup.number().required('Milling Country is required'),
    millingStateId: Yup.number().required('Milling State is required'),
    millingCityId: Yup.number().required('Milling City is required'),
});

const CreateContact = ({ handleClose, contacts, rating, businessGroups, conutries, contactDepartments, setMessage, fetchContacts, owner, clientCompany, titles }) => {
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [otherStates, setOtherStates] = useState([]);
    const [otherCities, setOtherCities] = useState([]);
    const handleSubmit = async (values, { setSubmitting }) => {

        try {
            const response = await genericPost(Create_contact, values, {
            });

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Create_contact added successfully",
                    type: "success",
                });
                fetchContacts()
                setTimeout(() => {
                    handleClose()
                }, 3000)
                handleClose()
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };
    const handleChangeMillingCountry = async (newValue) => {
        const countryId = newValue ? newValue.id : '';
        if (countryId) {
            const stateData = await getStates({ id: countryId });
            setStates(stateData);
            setCities([]);
        } else {
            setStates([]);
            setCities([]);
        }
    };

    const handleChangeOtherCountry = async (newValue) => {
        const countryId = newValue ? newValue.id : '';
        if (countryId) {
            const stateData = await getStates({ id: countryId });
            setOtherStates(stateData);
            setOtherCities([]);
        } else {
            setOtherStates([]);
            setOtherCities([]);
        }
    };

    const handleChangeMillingState = async (newValue) => {
        const stateId = newValue ? newValue.id : '';
        if (stateId) {
            const cityData = await getCities({ id: stateId });
            setCities(cityData);
        } else {
            setCities([]);
        }
    };

    const handleChangeOtherState = async (newValue) => {
        const stateId = newValue ? newValue.id : '';
        if (stateId) {
            const cityData = await getCities({ id: stateId });
            setOtherCities(cityData);
        } else {
            setOtherCities([]);
        }
    };

    const handleCopyMailingToOthers = async (setFieldValue, values) => {
        setFieldValue('othersStreet', values.mailingStreet);
        setFieldValue('otherCountryId', values.millingCountryId);
        setFieldValue('otherStateId', values.millingStateId);
        setFieldValue('otherCityId', values.millingCityId);
        setFieldValue('otherPincode', values.millingPincode);

        if (values.millingCountryId) {
            const stateData = await getStates({ id: values.millingCountryId });
            setOtherStates(stateData);

            if (values.millingStateId) {
                const cityData = await getCities({ id: values.millingStateId });
                setOtherCities(cityData);
            }
        }
    };

    const handleCopyOthersToMailing = async (setFieldValue, values) => {
        setFieldValue('mailingStreet', values.othersStreet);
        setFieldValue('millingCountryId', values.otherCountryId);
        setFieldValue('millingStateId', values.otherStateId);
        setFieldValue('millingCityId', values.otherCityId);
        setFieldValue('millingPincode', values.otherPincode);

        if (values.otherCountryId) {
            const stateData = await getStates({ id: values.otherCountryId });
            setStates(stateData);

            if (values.otherStateId) {
                const cityData = await getCities({ id: values.otherStateId });
                setCities(cityData);
            }
        }
    };


    const [formData, setFormData] = useState({
        contactAdminId: '',
        firstName: '',
        lastName: '',
        selectCompanyNameId: '',
        title: '',
        email: '',
        departmentId: '',
        mobile: '',
        phone: '',
        asstPhone: '',
        assistant: '',
        businessGroupId: '',
        emailOptOutId: '',
        skypeId: '',
        secondaryEmail: '',
        reportingTo: '',
        mailingStreet: '',
        millingCountryId: '',
        millingStateId: '',
        millingCityId: '',
        millingPincode: '',
        othersStreet: '',
        otherCountryId: '',
        otherStateId: '',
        otherCityId: '',
        otherPincode: '',
        description: '',
    });



    useEffect(() => {
        setFormData({
            contactAdminId: '',
            firstName: '',
            lastName: '',
            selectCompanyNameId: '',
            title: '',
            email: '',
            departmentId: '',
            mobile: '',
            phone: '',
            asstPhone: '',
            assistant: '',
            businessGroupId: '',
            emailOptOutId: '',
            skypeId: '',
            secondaryEmail: '',
            reportingTo: '',
            mailingStreet: '',
            millingCountryId: '',
            millingStateId: '',
            millingCityId: '',
            millingPincode: '',
            othersStreet: '',
            otherCountryId: '',
            otherStateId: '',
            otherCityId: '',
            otherPincode: '',
            description: '',
        })
    }, [])

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Create Contacts
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <SaveRounded />}
                                        title="Save"
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title="Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ px: "5%", paddingTop: "5px" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={owner}
                                    fieldLabel="Contact Admin"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={owner.find((option) => option.id === values.contactAdminId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('contactAdminId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Admin"
                                    error={touched.contactAdminId && !!errors.contactAdminId}
                                    helperText={touched.contactAdminId && errors.contactAdminId}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={clientCompany}
                                    fieldLabel="Select Company"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={clientCompany.find((option) => option.id === values.selectCompanyNameId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('selectCompanyNameId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Company"
                                    error={touched.selectCompanyNameId && !!errors.selectCompanyNameId}
                                    helperText={touched.selectCompanyNameId && errors.selectCompanyNameId}

                                />

                            </Grid>

                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>

                                <CustomAutocomplete
                                    options={titles}
                                    fieldLabel="Title"
                                    getOptionLabel={(option) => option.title}
                                    value={titles.find((option) => option.id === values.title) || null}
                                    onChange={(e, newValue) => setFieldValue('title', newValue ? newValue.id : '')}
                                    placeholder="Title"

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <CustomTextField
                                    name="firstName"
                                    fieldLabel="First Name"
                                    required={true}
                                    placeholder="Enter First Name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    error={touched.firstName && !!errors.firstName}
                                    helperText={touched.firstName && errors.firstName}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <CustomTextField
                                    name="lastName"
                                    fieldLabel="Last Name"
                                    required={true}
                                    placeholder="Enter Last Name"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    error={touched.lastName && !!errors.lastName}
                                    helperText={touched.lastName && errors.lastName}
                                />

                            </Grid>


                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="email"
                                    fieldLabel="Email"
                                    required={true}
                                    placeholder="Enter Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    error={touched.email && !!errors.email}
                                    helperText={touched.email && errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <CustomTextField
                                    type="number"
                                    name="mobile"
                                    fieldLabel="Mobile"
                                    required={true}
                                    placeholder="Enter Mobile"
                                    value={values.mobile}
                                    onChange={handleChange}
                                    error={touched.mobile && !!errors.mobile}
                                    helperText={touched.mobile && errors.mobile}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <CustomTextField
                                    type="number"
                                    name="phone"
                                    fieldLabel="Phone"
                                    placeholder="Enter Phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="number"
                                    name="asstPhone"
                                    fieldLabel="Asst Phone"
                                    placeholder="Enter Asst Phone"
                                    value={values.asstPhone}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="assistant"
                                    fieldLabel="Assistant"
                                    placeholder="Enter Assistant"
                                    value={values.assistant}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={contactDepartments}
                                    fieldLabel="Department"
                                    getOptionLabel={(option) => option.title}
                                    value={contactDepartments.find((option) => option.id === values.departmentId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('departmentId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Department"
                                />

                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={businessGroups}
                                    fieldLabel="Business Group"
                                    getOptionLabel={(option) => option.title}
                                    value={businessGroups.find((option) => option.id === values.businessGroupId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('businessGroupId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Business Group"

                                />
                            </Grid>


                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="skypeId"
                                    fieldLabel="Skype Id"
                                    placeholder="Enter Skype Id"
                                    value={values.skypeId}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box sx={{ mt: 2 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                style={{ marginTop: "10px" }}

                                                name="emailOptOutId"
                                                checked={!!values.emailOptOutId}
                                                onChange={(e) => setFieldValue('emailOptOutId', e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Email Opt Out"
                                    />
                                </Box>
                            </Grid>


                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="secondaryEmail"
                                    fieldLabel="Secondary Email"
                                    placeholder="Enter Secondary Email"
                                    value={values.secondaryEmail}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={contacts}
                                    fieldLabel="Reporting To"
                                    getOptionLabel={(option) => option.firstName}
                                    value={contacts.find((option) => option.contactId === values.reportingTo) || null}
                                    onChange={(e, newValue) => setFieldValue('reportingTo', newValue ? newValue.contactId : '')}
                                    placeholder="Select Reporting To"
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} style={{ paddingBottom: "5px" }}>
                                    <Grid item xs={12} md={8}>
                                        <Typography style={{ color: "#212529", fontWeight: 500, fontSize: "20px" }}>
                                            Address Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CustomAutocomplete
                                            options={top100Films}
                                            getOptionLabel={(option) => option.title}
                                            placeholder="Copy Address"
                                            onChange={(event, newValue) => {
                                                if (newValue?.id === 1) {
                                                    handleCopyMailingToOthers(setFieldValue, values);
                                                } else if (newValue?.id === 2) {
                                                    handleCopyOthersToMailing(setFieldValue, values);
                                                }
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="mailingStreet"
                                    required={true}
                                    type="text"
                                    fieldLabel="Mailing Street"
                                    placeholder="Enter Mailing Street"
                                    value={values.mailingStreet}
                                    onChange={handleChange}
                                    error={touched.mailingStreet && !!errors.mailingStreet}
                                    helperText={touched.mailingStreet && errors.mailingStreet}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="othersStreet"
                                    type="text"
                                    fieldLabel="Others Street"
                                    placeholder="Enter Other Street"
                                    value={values.othersStreet}
                                    onChange={handleChange}

                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={conutries}
                                    fieldLabel="Mailing Country"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={conutries.find((option) => option.id === values.millingCountryId) || null}
                                    onChange={(e, newValue) => {
                                        setFieldValue('millingCountryId', newValue ? newValue.id : '');
                                        handleChangeMillingCountry(newValue);
                                    }}
                                    placeholder="Select Mailing Country"
                                    error={touched.millingCountryId && !!errors.millingCountryId}
                                    helperText={touched.millingCountryId && errors.millingCountryId}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={conutries}
                                    fieldLabel="Other Country"
                                    getOptionLabel={(option) => option.title}
                                    value={conutries.find((option) => option.id === values.otherCountryId) || null}
                                    onChange={(e, newValue) => {
                                        setFieldValue('otherCountryId', newValue ? newValue.id : '');
                                        handleChangeOtherCountry(newValue);
                                    }}
                                    placeholder="Select Other Country"

                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={states}
                                    fieldLabel="Mailing State"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={states.find((option) => option.id === values.millingStateId) || null}
                                    onChange={(e, newValue) => {
                                        setFieldValue('millingStateId', newValue ? newValue.id : '');
                                        handleChangeMillingState(newValue);
                                    }}
                                    placeholder="Select Mailing State"
                                    error={touched.millingStateId && !!errors.millingStateId}
                                    helperText={touched.millingStateId && errors.millingStateId}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={otherStates}
                                    fieldLabel="Other State"
                                    getOptionLabel={(option) => option.title}
                                    value={otherStates.find((option) => option.id === values.otherStateId) || null}
                                    onChange={(e, newValue) => {
                                        setFieldValue('otherStateId', newValue ? newValue.id : '');
                                        handleChangeOtherState(newValue);
                                    }}
                                    placeholder="Select Other State"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={cities}
                                    fieldLabel="Mailing City"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={cities.find((option) => option.id === values.millingCityId) || null}
                                    onChange={(e, newValue) => setFieldValue('millingCityId', newValue ? newValue.id : '')}
                                    placeholder="Select Mailing City"
                                    error={touched.millingCityId && !!errors.millingCityId}
                                    helperText={touched.millingCityId && errors.millingCityId}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={otherCities}
                                    fieldLabel="Other City"
                                    getOptionLabel={(option) => option.title}
                                    value={otherCities.find((option) => option.id === values.otherCityId) || null}
                                    onChange={(e, newValue) => setFieldValue('otherCityId', newValue ? newValue.id : '')}
                                    placeholder="Select Other City"

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="millingPincode"
                                    type="number"
                                    fieldLabel="Mailing Pincode"
                                    placeholder="Enter Mailing Pincode"
                                    value={values.millingPincode}
                                    onChange={handleChange}

                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="otherPincode"
                                    type="number"
                                    fieldLabel="Other Pincode"
                                    placeholder="Enter Other Pincode"
                                    value={values.otherPincode}
                                    onChange={handleChange}

                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <CustomTextarea
                                    name="description"
                                    fieldLabel="Note"
                                    multiline
                                    rows={4}
                                    value={values.description}
                                    onChange={handleChange}
                                    placeholder="Enter Note"
                                />
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateContact;


const top100Films = [
    { title: "Mailing to Others ", id: 1 },
    { title: "Others to Mailing ", id: 2 },
];
