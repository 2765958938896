import React, { useState } from 'react';
import axios from 'axios';
import { Box, Typography, Grid, CircularProgress, Divider, Input } from '@mui/material';
import { Formik, Form } from 'formik';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import CustomButton from '../../common/CustomButton';
import CustomStyle from '../../common/CustomStyle';
import { COMPANY_LOGO, COMPANY_FOOTER, COMPANY_SIGN_STAMP } from '../../../services/apiRoutes';
import * as Yup from 'yup';





const UpdateCompanyImages = ({ handleClose, fetchData, setMessage }) => {
    const [images, setImages] = useState({
        companyLogo: null,
        companyFooter: null,
        companySignStamp: null,
    });

    const handleImageChange = (e, field) => {
        setImages((prev) => ({ ...prev, [field]: e.target.files[0] }));
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        const authToken = sessionStorage.getItem('accessToken');
        const uploadFields = [
            { key: 'companyLogo', url: COMPANY_LOGO },
            { key: 'companyFooter', url: COMPANY_FOOTER },
            { key: 'companySignStamp', url: COMPANY_SIGN_STAMP }
        ];

        for (const field of uploadFields) {
            if (images[field.key]) {
                const formData = new FormData();
                formData.append(field.key, images[field.key]);
                try {
                    const response = await axios.post(field.url, formData, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (response.status === 200 && response.data.success) {
                        setMessage({ open: true, text: `${field.key} updated successfully`, type: "success" });
                    } else {
                        setMessage({ open: true, text: `Failed to update ${field.key}`, type: "error" });
                    }
                } catch (error) {
                    console.error(`Error updating ${field.key}:`, error.response?.data || error);
                    setMessage({ open: true, text: `Upload failed for ${field.key}`, type: 'error' });
                }
            }
        }

        setSubmitting(false);
        fetchData();
        handleClose();
    };

    return (
        <Formik
            initialValues={{}}
            onSubmit={handleSubmit}>
            {({ isSubmitting, touched, errors }) => (
                <Form>
                    <Box sx={{ mx: 'auto', mt: 0 }}>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Company Documents
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Box sx={{ flexGrow: 1, p: 2 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <Typography style={{
                                        color: "#212529",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        textTransform: "capitalize"
                                    }}
                                    >Company Logo</Typography>
                                    <Input type="file" accept="image/*" onChange={(e) => handleImageChange(e, 'companyLogo')} />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Typography style={{
                                        color: "#212529",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        textTransform: "capitalize"
                                    }}>Company Footer</Typography>
                                    <Input type="file" accept="image/*" onChange={(e) => handleImageChange(e, 'companyFooter')} />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Typography style={{
                                        color: "#212529",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        textTransform: "capitalize"
                                    }}>Company Sign & Stamp</Typography>
                                    <Input type="file" accept="image/*" onChange={(e) => handleImageChange(e, 'companySignStamp')} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default UpdateCompanyImages;
